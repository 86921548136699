import * as React from 'react';
import { GlobalState } from '../globalState';
import { BlockCard } from '../controls/cards/blockCard';
import { Collection } from '../entities/collection';
import { LabelledTexbox } from '../controls/labelledTextbox';
import { Nullable } from '../tools/nullable';
import { Observer } from '../tools/observable';
import { PatreonLevel, PatreonTools } from '../tools/patreonTools';
import { Block } from '../entities/block';

require('../scss/blocksPage.scss');

interface IBlocksPageProps {
    globalState: GlobalState;
}

export class BlocksPage extends React.Component<IBlocksPageProps, { filter: string }> {
    private scroller: HTMLElement;
    private onCustomButtonClickedObserver: Nullable<Observer<void>>;

    constructor(props: IBlocksPageProps) {
        super(props);

        this.state = { filter: this.props.globalState.pageCustomData.filter || '' };
    }

    onScroll() {
        this.props.globalState.pageCustomData.scroll = this.scroller.scrollTop;
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onCustomButtonRequired.notifyObservers(
            window.innerWidth > 768 ? this.props.globalState.translate('NewBlock') : '+'
        );
        this.onCustomButtonClickedObserver = this.props.globalState.onCustomButtonClicked.add(() => {
            if (
                PatreonTools.PledgeLevel === PatreonLevel.None
            ) {
                this.props.globalState.onError.notifyObservers(this.props.globalState.translate('OnlyForPatreon'));
                return;
            }
            this.props.globalState.onNewBlockRequired.notifyObservers();
        });
    }

    componentWillUnmount() {
        this.props.globalState.onCustomButtonClicked.remove(this.onCustomButtonClickedObserver);
    }

    componentDidMount() {
        this.scroller = document.getElementById('scrollElement')!;

        const customData = this.props.globalState.pageCustomData;
        const scrollPosition = customData && customData.scroll ? customData.scroll : 0;
        this.scroller.scrollTop = scrollPosition;
    }

    blurFilter(evt: React.KeyboardEvent<HTMLInputElement>) {
        if (evt.which === 13) {
            (evt.nativeEvent.srcElement! as HTMLInputElement).blur();
        }
    }

    deleteBlock(b: Block) {
        if (!Collection.UserStore.CustomBlocks) {
            return;
        }

        let index = Collection.Blocks.indexOf(b);
        Collection.Blocks.splice(index, 1);

        for (index = 0; index < Collection.UserStore.CustomBlocks.length; index++) {
            if (Collection.UserStore.CustomBlocks[index].id === b.id) {
                Collection.UserStore.CustomBlocks.splice(index, 1);
                break;
            }
        }

        Collection.RegisterSave();
        this.forceUpdate();
    }

    render() {
        const total = Collection.Blocks.sort((a, b) => {
            if (a.isCustom && !b.isCustom) {
                return -1;
            }

            if (!a.isCustom && b.isCustom) {
                return 1;
            }

            if (a.index === b.index) {
                return 0;
            }

            if (a.index > b.index) {
                return -1;
            }

            return 1;
        });

        let blocks = total.slice();

        const translate = this.props.globalState.translate.bind(this.props.globalState);

        if (this.props.globalState.hideCompleted) {
            blocks = blocks.filter((b) => b.sets.filter((e) => e.isComplete).length !== b.sets.length);
        }

        if (this.props.globalState.hideZeroCardSet) {
            blocks = blocks.filter((b) => b.sets.filter((e) => e.inCollectionCardCount > 0).length > 0);
        }

        const list = blocks.filter((b) => {
            if (this.state.filter) {
                const filter = this.state.filter.toLowerCase();

                if (b.name.toLocaleLowerCase().indexOf(filter) === -1) {
                    const sets = b.sets.filter((s) => s.name.toLocaleLowerCase().indexOf(filter) !== -1);

                    if (sets.length === 0) {
                        return false;
                    }
                }
            }
            return true;
        });

        setTimeout(() => {
            this.props.globalState.onHeaderTextChanged.notifyObservers(
                `${total.length - blocks.length} / ${blocks.length}`
            );
        });

        const suggestionForNames = Collection.Blocks.map((b) => b.name);

        return (
            <div className="page">
                <div className="blocks-page">
                    <div className="blocks-page-filter">
                        <LabelledTexbox
                            label={translate('Name')}
                            globalState={this.props.globalState}
                            onKeyPress={(evt) => this.blurFilter(evt)}
                            onChange={(value) => {
                                this.setState({ filter: value });
                                this.props.globalState.pageCustomData.filter = value;
                            }}
                            suggestions={suggestionForNames}
                            value={this.state.filter}
                            placeholder={translate('AnyWord')}
                        />
                    </div>
                    <div className="blocks" id="scrollElement" onScroll={() => this.onScroll()}>
                        {list.map((b) => {
                            return <BlockCard key={b.name} globalState={this.props.globalState} block={b} onDelete={() => this.deleteBlock(b)} />;
                        })}
                    </div>
                </div>
            </div>
        );
    }
}
