import { Set } from './set';
import { BinaryReader } from '../tools/binaryReader';
import { GlobalState } from '../globalState';
import { Collection } from './collection';
import { StringHelpers } from '../tools/stringHelpers';

export class Block {
    public id: number;

    public name: string;

    public index: number;

    public sets: Set[];

    public ignore: boolean;

    public isCustom = false;

    public get totalCardCount(): number {
        let total = 0;

        this.sets.forEach((s) => {
            total += s.totalCardCount;
        });

        return total;
    }

    public get totalPrice(): number {
        let value = 0;

        this.sets.forEach((s) => {
            value += s.totalPrice;
        });

        return value;
    }

    public get inCollectionCardCount(): number {
        let inCollection = 0;

        this.sets.forEach((s) => {
            inCollection += s.inCollectionCardCount;
        });

        return inCollection;
    }

    public updateUserStore() {
        if (!this.isCustom) {
            return;
        }

        if (!Collection.UserStore) {
            return;
        }

        if (!Collection.UserStore.CustomBlocks) {
            Collection.UserStore.CustomBlocks = [];
        }

        const customBlock = {
            name: this.name,
            index: this.index,
            sets: this.sets.map((s) => s.id),
            id: this.id
        };

        for (let index = 0; index < Collection.UserStore.CustomBlocks.length; index++) {
            if (Collection.UserStore.CustomBlocks[index].id === customBlock.id) {
                Collection.UserStore.CustomBlocks[index] = customBlock;
                return;
            }
        }

        Collection.UserStore.CustomBlocks.push(customBlock);
    }

    public static Deserialize(reader: BinaryReader): Block {
        const block = new Block();

        block.id = reader.readInt32();
        block.name = reader.readString();
        block.index = reader.readInt32();

        block.ignore = GlobalState.LoadSettings('DisabledBlockNames', '').split('*').indexOf(block.name) !== -1;

        const expansionsCount = reader.readInt32();
        block.sets = [];

        const ignoreForeignSet = GlobalState.LoadBoolSettings('IgnoreForeignSets', false);
        const ignoreArtCards = GlobalState.LoadBoolSettings('IgnoreArtCards', false);
        const ignoreOversizedCards = GlobalState.LoadBoolSettings('IgnoreOversizedCards', false);
        const ignoreSubstituteCards = GlobalState.LoadBoolSettings('IgnoreSubstituteCards', false);
        const ignoreMinigames = GlobalState.LoadBoolSettings('IgnoreMinigames', false);

        for (let index = 0; index < expansionsCount; index++) {
            const expansion = Set.Deserialize(reader, block.ignore);

            if (
                !block.ignore &&
                (!ignoreArtCards || !StringHelpers.EndsWith(expansion.name, 'Art Series')) &&
                (!ignoreSubstituteCards || !StringHelpers.EndsWith(expansion.name, 'Substitute Cards')) &&
                (!ignoreOversizedCards || !StringHelpers.EndsWith(expansion.name, 'Oversized')) &&
                (!ignoreMinigames || !StringHelpers.EndsWith(expansion.name, 'Minigames')) &&
                (!ignoreForeignSet || !expansion.foreignOnly) &&
                !(GlobalState.LoadSettings('DisabledSetNames', '').split('*').indexOf(expansion.name) !== -1)
            ) {
                expansion.block = block;
                block.sets.push(expansion);
                Collection.SetsIndex[expansion.id] = expansion;

                if (expansion.extras) {
                    expansion.extras.block = block;
                    block.sets.push(expansion.extras);
                    Collection.SetsIndex[expansion.extras.id] = expansion.extras;
                }
            }
        }

        return block;
    }
}
