import * as React from 'react';
import { GlobalState } from '../globalState';
import { Button } from './button';
import { Slider } from './slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { Counter } from '../entities/counter';
import { ColorPicker } from './colorPicker';
import { MobileTools } from '../tools/mobileTools';

interface INewPlayerDialogProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class NewPlayerDialog extends React.Component<
    INewPlayerDialogProps,
    { isVisible: boolean; name: string; value: string; color: string }
> {
    private valueAsNumber = 20;
    private focusDone = false;

    constructor(props: INewPlayerDialogProps) {
        super(props);

        this.state = { isVisible: false, name: '', value: '20', color: this.getRandomColor() };
    }

    getRandomColor(): string {
        return ColorPicker.Colors[(Math.random() * ColorPicker.Colors.length) | 0];
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onNewPlayerRequired.add(() => {
            this.focusDone = false;
            this.setState({
                isVisible: true,
                name: '',
                value: '20',
                color: this.getRandomColor()
            });

            this.props.globalState.onBlurRequired.notifyObservers(true);
        });

        document.addEventListener('keydown', (evt) => {
            if (evt.keyCode === 27 && this.state.isVisible) {
                this.onConfirmation(false);
            }
        });
    }

    onConfirmation(value: boolean) {
        if (value) {
            const newCounter = new Counter();

            newCounter.Color = this.state.color;
            newCounter.Counter = this.valueAsNumber;
            newCounter.InitialCounter = this.valueAsNumber;
            newCounter.Name = this.state.name;
            newCounter.Poison = 0;

            this.props.globalState.onNewPlayerCreated.notifyObservers(newCounter);
        }

        this.setState({ isVisible: false });

        this.props.globalState.onBlurRequired.notifyObservers(false);
    }

    componentDidUpdate() {
        if (MobileTools.IsMobile) {
            return;
        }
        if (this.state.isVisible && !this.focusDone) {
            this.focusDone = true;
            document.getElementById('player-queryText')!.focus();
        }
    }

    onChange(newValue: number) {
        if (this.valueAsNumber === newValue) {
            return;
        }

        this.valueAsNumber = newValue;

        this.setState({ value: this.valueAsNumber.toFixed(0) });
    }

    sync(target: HTMLInputElement) {
        const value: number | undefined = parseInt(target.value);

        if (!isNaN(value)) {
            this.valueAsNumber = value;
        }
        this.setState({ value: target.value });
    }

    onBlur(target: HTMLInputElement) {
        const value: number | undefined = parseInt(target.value);

        if (isNaN(value)) {
            this.setState({ value: this.valueAsNumber.toFixed(0) });
        }
    }

    increase() {
        this.valueAsNumber = Math.min(GlobalState.MaxCardCount, this.valueAsNumber + 1);

        this.setState({ value: this.valueAsNumber.toFixed(0) });
    }

    decrease() {
        this.valueAsNumber = Math.max(0, this.valueAsNumber - 1);
        this.setState({ value: this.valueAsNumber.toFixed(0) });
    }

    render() {
        if (!this.state.isVisible) {
            return null;
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);

        return (
            <div className="player-dialog-container">
                <div className="player-dialog-background"></div>
                <div className={'player-dialog' + (this.props.darkMode ? ' dark' : '')}>
                    <div className="player-dialog-title">{translate('New player')}</div>
                    <div className="player-dialog-name">
                        <div className="player-label">{translate('Name')}</div>
                        <input
                            tabIndex={1}
                            id="player-queryText"
                            type="text"
                            autoComplete="none"
                            value={this.state.name}
                            onChange={(evt) => this.setState({ name: evt.target.value })}
                        />
                    </div>
                    <div className="player-dialog-life">
                        <div className="count-dialog-number">
                            <div className="player-label">{translate('Life:')}</div>
                            <input
                                tabIndex={2}
                                type="text"
                                autoComplete="none"
                                value={this.state.value}
                                onChange={(evt) => this.sync(evt.target)}
                                onBlur={(evt) => this.onBlur(evt.target)}
                            />
                            <div className="count-dialog-minus button" onClick={() => this.decrease()}>
                                <FontAwesomeIcon icon={faMinusSquare} />
                            </div>
                            <div className="count-dialog-plus button" onClick={() => this.increase()}>
                                <FontAwesomeIcon icon={faPlusSquare} />
                            </div>
                        </div>
                        <div className="count-dialog-slider">
                            <Slider
                                className="slider-slider"
                                globalState={this.props.globalState}
                                min={0}
                                max={GlobalState.MaxCardCount}
                                defaultValue={this.valueAsNumber}
                                onChange={(v) => this.onChange(v)}
                            />
                        </div>
                    </div>
                    <div className="player-dialog-colors-container">
                        <div className="player-label">{translate('Color')}</div>
                        <ColorPicker
                            globalState={this.props.globalState}
                            tabIndex={3}
                            onColorChanged={(color) => this.setState({ color: color })}
                            color={this.state.color}
                        />
                    </div>
                    <div className="player-dialog-accept">
                        <Button
                            tabIndex={15}
                            className="player-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(true)}
                        >
                            {translate('OK')}
                        </Button>
                    </div>
                    <div className="player-dialog-cancel">
                        <Button
                            tabIndex={16}
                            className="player-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(false)}
                        >
                            {translate('Cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
