import { faExpand, faExpandArrows, faImage, faPencil } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Card } from '../entities/card';
import { Collection } from '../entities/collection';
import { GlobalState } from '../globalState';
import { Nullable } from '../tools/nullable';
import { Observer } from '../tools/observable';

require('../scss/loreExplorer.scss');

interface ILoreExplorerPageProps {
    globalState: GlobalState;
    cardIds: number[];
    selectedIndex: number;
}

export class LoreExplorerPage extends React.Component<ILoreExplorerPageProps, { selectedIndex: number, fit: 'contain' | 'cover' | 'scale-down', imageOpacity: number }> {
    private cards: Card[];
    private onPrevObserver: Nullable<Observer<void>>;
    private onNextObserver: Nullable<Observer<void>>;
    private onKeyUpObserver: Nullable<Observer<KeyboardEvent>>;
    private onCustomButtonObserver: Nullable<Observer<void>>;
    private capturedX: number;
    private capturedY: number;
    private capturedPointerId: number;
    private captureTime: Date;

    constructor(props: ILoreExplorerPageProps) {
        super(props);

        this.cards = this.props.cardIds.map((id) => Collection.CardsIndex[id]);

        const selectedIndex = this.cards.length ? (this.props.selectedIndex !== undefined ? this.props.selectedIndex : 0) : -1;
        this.state = { selectedIndex: selectedIndex, fit: GlobalState.LoadSettings('ExplorerFitMode', 'contain') as 'contain' | 'cover' | 'scale-down', imageOpacity: 0 };

        if (this.cards.length > 1) {
            this.requestNavigationButtons();
        }

        if (this.state.selectedIndex > -1) {
            this.processNewCard(0, true);
            this.showCustomMenu();
        }

    }

    requestNavigationButtons() {
        this.props.globalState.onPrevButtonRequired.notifyObservers(this.state.selectedIndex > 0);
        this.props.globalState.onNextButtonRequired.notifyObservers(this.state.selectedIndex < this.cards.length - 1);
    }

    UNSAFE_componentWillMount() {
        this.onNextObserver = this.props.globalState.onNext.add(() => {
            this.moveNext();
        });

        this.onPrevObserver = this.props.globalState.onPrev.add(() => {
            this.movePrev();
        });

        this.onKeyUpObserver = this.props.globalState.onKeyUp.add(evt => {
            switch (evt.keyCode) {
                case 39:
                    this.moveNext();
                    break;
                case 37:
                    this.movePrev();
                    break;
            }
        });

        this.onCustomButtonObserver = this.props.globalState.onCustomButtonClicked.add(() => {
            this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({
                index: -4,
                uniqueID: this.state.selectedIndex,
                cardIds: this.cards.map((c) => c.id)
            });
        });
    }

    componentDidUpdate() {
        this.requestNavigationButtons();
        this.showCustomMenu();
    }

    moveNext() {
        if (this.state.selectedIndex < this.cards.length - 1) {
            this.processNewCard(this.state.selectedIndex + 1);
        }
    }

    movePrev() {
        if (this.state.selectedIndex > 0) {
            this.processNewCard(this.state.selectedIndex - 1);
        }
    }

    processNewCard(newIndex: number, initialCall = false) {
        const nextCard = this.cards[newIndex];
        this.props.globalState.onHeaderTitleChanged.notifyObservers({
            title: `${nextCard.name} (${nextCard.type})`,
            isFlip: false
        });

        if (!initialCall) {
            this.setState({ selectedIndex: newIndex, imageOpacity: 0 });
        }

        this.props.globalState.getPreviousNavigationData().uniqueID = newIndex;
    }

    componentWillUnmount() {
        this.props.globalState.onNext.remove(this.onNextObserver);
        this.props.globalState.onPrev.remove(this.onPrevObserver);
        this.props.globalState.onKeyUp.remove(this.onKeyUpObserver);
        this.props.globalState.onCustomButtonClicked.remove(this.onCustomButtonObserver);
    }

    onPointerDown(evt: React.PointerEvent<HTMLDivElement>) {
        this.capturedX = evt.pageX;
        this.capturedY = evt.pageY;
        this.capturedPointerId = evt.pointerId;
        this.captureTime = new Date();
    }

    onTouchDown(evt: React.TouchEvent<HTMLDivElement>) {
        this.capturedX = evt.touches[0].pageX;
        this.capturedY = evt.touches[0].pageY;
        this.capturedPointerId = evt.touches[0].identifier;
        this.captureTime = new Date();
    }

    onPointerMove(evt: React.PointerEvent<HTMLDivElement>) {
        if (evt.pointerId !== this.capturedPointerId || evt.pointerType !== 'touch') {
            return;
        }

        if (new Date().getTime() - this.captureTime.getTime() > 500) {
            this.capturedPointerId = -1;
            return;
        }

        const yDist = Math.abs(evt.clientY - this.capturedY);

        if (yDist > 15) {
            this.capturedPointerId = -1;
            return;
        }

        if (evt.pageX > this.capturedX + 30) {
            this.movePrev();
            this.capturedPointerId = -1;
        }

        if (evt.pageX < this.capturedX - 30) {
            this.moveNext();
            this.capturedPointerId = -1;
        }
    }

    onTouchMove(evt: React.TouchEvent<HTMLDivElement>) {
        if (evt.touches[0].identifier !== this.capturedPointerId || evt.type !== 'touchmove') {
            return;
        }

        if (new Date().getTime() - this.captureTime.getTime() > 500) {
            this.capturedPointerId = -1;
            return;
        }

        const yDist = Math.abs(evt.touches[0].clientY - this.capturedY);

        if (yDist > 15) {
            this.capturedPointerId = -1;
            return;
        }

        if (evt.touches[0].pageX > this.capturedX + 30) {
            this.movePrev();
            this.capturedPointerId = -1;
        }

        if (evt.touches[0].pageX < this.capturedX - 30) {
            this.moveNext();
            this.capturedPointerId = -1;
        }
    }

    scrollHorizontally(evt: React.WheelEvent<HTMLDivElement>) {
        const delta = Math.max(-1, Math.min(1, evt.deltaY));

        if (delta > 0) {
            this.moveNext();
        } else {
            this.movePrev();
        }
    }

    showCustomMenu() {
        this.props.globalState.onCustomButtonRequired.notifyObservers(
            this.props.globalState.translate('Card')
        );

        const menus = [
            {
                label: this.props.globalState.translate('Cover'),
                action: () => {
                    this.setState({ fit: 'cover' });
                    this.props.globalState.storeSettings('ExplorerFitMode', 'cover');
                },
                icon: faExpandArrows
            }, {
                label: this.props.globalState.translate('Contain'),
                action: () => {
                    this.setState({ fit: 'contain' });
                    this.props.globalState.storeSettings('ExplorerFitMode', 'contain');
                },
                icon: faExpand
            }, {
                label: this.props.globalState.translate('ScaleDown'),
                action: () => {
                    this.setState({ fit: 'scale-down' });
                    this.props.globalState.storeSettings('ExplorerFitMode', 'scale-down');
                },
                icon: faImage
            }
        ];

        if (this.state.fit === 'cover') {
            menus.splice(0, 1);
        } else if (this.state.fit === 'contain') {
            menus.splice(1, 1);
        } else {
            menus.splice(2, 1);
        }

        this.props.globalState.onCustomMenuRequired.notifyObservers(menus);
    }

    onLoad() {
        this.setState({ imageOpacity: 1 });
    }

    render() {
        let activeCard: Nullable<Card> = null;

        if (this.state.selectedIndex >= 0) {
            activeCard = this.cards[this.state.selectedIndex];
        }

        return (
            <div className="page">
                <div className="lore-explorer-page"
                    onWheel={(evt) => this.scrollHorizontally(evt)}
                    onTouchMove={(evt) => this.onTouchMove(evt)}
                    onTouchStart={(evt) => this.onTouchDown(evt)}
                    onPointerDown={(evt) => this.onPointerDown(evt)}
                    onPointerMove={(evt) => this.onPointerMove(evt)}
                >
                    {
                        activeCard &&
                        (
                            <>
                                <img id='lore-explorer-image' src={activeCard.cropUrl} style={{
                                    objectFit: this.state.fit,
                                    opacity: this.state.imageOpacity
                                }} onLoad={() => this.onLoad()} />
                                <div className='lore-explorer-flavor'>
                                    {activeCard.flavor ||
                                        (activeCard.isStorySpotlight ? this.props.globalState.translate('Spotlight') : '')}
                                </div>
                                <div className='lore-explorer-set'>
                                    {activeCard.setName}
                                </div>
                                <div className='lore-explorer-author'>
                                    <FontAwesomeIcon icon={faPencil} style={{
                                        transform: 'scale(0.8)'
                                    }} /> {' ' + activeCard.author}
                                </div>
                            </>
                        )
                    }
                    {
                        !activeCard &&
                        <div className='no-card'>{this.props.globalState.translate('NoCardWithFlavorFound')}</div>
                    }
                </div>
            </div>
        );
    }
}
