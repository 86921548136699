import * as React from 'react';
import { GlobalState } from '../globalState';
import { Collection } from '../entities/collection';
import { Button } from '../controls/button';
import { MagicCardMarket } from '../tools/prices/magicCardMarket';
import { Observable } from '../tools/observable';
import { Set } from '../entities/set';
import { PriceTools } from '../tools/priceTools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { CancellationToken } from '../tools/cancellationToken';
import { Card } from '../entities/card';
import { Scryfall } from '../tools/prices/scryfall';
import { Deck } from '../entities/deck';
import { DeckEntry } from '../entities/deckEntry';

require('../scss/pricesPage.scss');

interface IPricesPageProps {
    globalState: GlobalState;
    selector?: string;
    id?: number;
}

export class PricesPage extends React.Component<
    IPricesPageProps,
    {
        expansionIndex: number;
        deckIndex: number;
        providerIndex: number;
        logs: {
            icon?: string;
            label: string;
            oldValue: string;
            newValue: string;
            oldPrice: number;
            newPrice: number;
            notFound: boolean;
        }[];
        launched: boolean;
        progress: number;
        total: number;
    }
> {
    private _isUnmounted = false;
    private _currentProgress = 0;
    private _lockObject = new CancellationToken();
    private _sets: Set[];
    private _decks: Deck[];

    constructor(props: IPricesPageProps) {
        super(props);

        this._sets = Collection.SortedSets;
        this._decks = Collection.SortedDecks;

        let expansionIndex = 0;
        let deckIndex = 0;

        if (this.props.selector === 'S') {
            const selectedSet = this._sets.filter((s) => s.id === this.props.id)[0];
            expansionIndex = this._sets.indexOf(selectedSet) + 2;
        } else if (this.props.selector) {
            const selectedDeck = this._decks.filter((d) => d.UniqueID === this.props.selector)[0];
            deckIndex = this._decks.indexOf(selectedDeck) + 2;
        }

        this.state = {
            expansionIndex: expansionIndex,
            deckIndex: deckIndex,
            providerIndex: Math.min(2, GlobalState.LoadIntSettings('ProviderIndex', 0)),
            logs: [],
            launched: false,
            progress: 0,
            total: 0
        };
    }

    componentWillUnmount() {
        this._isUnmounted = true;
        this._lockObject.isCancellationRequested = true;
    }

    updateUsingProvider(): Promise<void> {
        const progressObservable = new Observable<number>();
        progressObservable.add((_value) => {
            this._currentProgress += 1;
        });

        this._currentProgress = 0;
        this._lockObject.isCancellationRequested = false;

        return new Promise((resolve, reject) => {
            if (this.state.expansionIndex < 2 && this.state.deckIndex === 0) {
                const expansionUpdateObservable = new Observable<{
                    set: Set;
                    oldPrice: number;
                    notFound: boolean;
                }>();

                expansionUpdateObservable.add((log) => {
                    if (this._isUnmounted) {
                        return;
                    }
                    this.state.logs.push({
                        icon: log.set.icon,
                        label: log.set.name,
                        oldValue: PriceTools.Format(log.oldPrice, this.props.globalState),
                        newValue: PriceTools.Format(log.set.totalPrice, this.props.globalState),
                        oldPrice: log.oldPrice,
                        newPrice: log.set.totalPrice,
                        notFound: log.notFound
                    });
                    this.state.logs.sort((a, b) => a.label.localeCompare(b.label));
                    this.setState({ logs: this.state.logs, progress: this._currentProgress });
                });
                this.setState({
                    progress: 0,
                    total:
                        this.state.expansionIndex === 1
                            ? Collection.Cards.filter((c) => c.count).length
                            : Collection.Cards.length
                });

                switch (this.state.providerIndex) {
                    case 0:
                        MagicCardMarket.UpdateAllExpansions(
                            expansionUpdateObservable,
                            progressObservable,
                            this._lockObject,
                            this.state.expansionIndex === 1
                        )
                            .then(() => {
                                resolve();
                            })
                            .catch((err) => {
                                reject(err);
                            });
                        break;
                    case 1:
                    case 2:
                        Scryfall.UpdateAllExpansions(
                            expansionUpdateObservable,
                            progressObservable,
                            this._lockObject,
                            this.state.expansionIndex === 1
                        )
                            .then(() => {
                                resolve();
                            })
                            .catch((err) => {
                                reject(err);
                            });
                        break;
                }
            } else if (this.state.deckIndex === 0) {
                const expansion = this._sets[this.state.expansionIndex - 2];
                this.setState({ progress: 0, total: expansion.cards.length });

                const cardUpdateObservable = new Observable<{ card: Card; oldPrice: number; notFound: boolean }>();
                cardUpdateObservable.add((log) => {
                    if (this._isUnmounted) {
                        return;
                    }
                    this.state.logs.push({
                        label: log.card.name,
                        oldValue: PriceTools.Format(log.oldPrice, this.props.globalState),
                        newValue: PriceTools.Format(log.card.price, this.props.globalState),
                        oldPrice: log.oldPrice,
                        newPrice: log.card.price,
                        notFound: log.notFound
                    });
                    this.state.logs.sort((a, b) => a.label.localeCompare(b.label));
                    this.setState({ logs: this.state.logs, progress: this._currentProgress });
                });

                switch (this.state.providerIndex) {
                    case 0:
                        MagicCardMarket.UpdateExpansionPrice(
                            expansion,
                            null,
                            progressObservable,
                            undefined,
                            cardUpdateObservable,
                            this._lockObject
                        )
                            .then(() => {
                                resolve();
                            })
                            .catch((err) => {
                                reject(err);
                            });
                        break;
                    case 1:
                    case 2:
                        Scryfall.UpdateExpansionPrice(
                            expansion,
                            null,
                            progressObservable,
                            undefined,
                            cardUpdateObservable,
                            this._lockObject
                        )
                            .then(() => {
                                resolve();
                            })
                            .catch((err: any) => {
                                reject(err);
                            });
                        break;
                }
            } else {
                let cards: DeckEntry[] = [];

                if (this.state.deckIndex === 1) {
                    for (const deck of this._decks) {
                        const deckCards = Deck.AllCards(deck);
                        const deckCardsInCollection = deckCards.filter((c) => Collection.CardsIndex[c.CardID].count > 0);
                        if (deckCardsInCollection.length > 0) {
                            cards.push(...deckCardsInCollection);
                        }

                    }
                } else {
                    const deck = this._decks[this.state.deckIndex - 2];
                    cards = Deck.AllCards(deck);
                }

                const uniqueCards: Card[] = [];

                cards.forEach((c) => {
                    const card = Collection.CardsIndex[c.CardID];
                    if (uniqueCards.indexOf(card) === -1) {
                        uniqueCards.push(card);
                    }
                });
                this.setState({ progress: 0, total: uniqueCards.length });

                const cardUpdateObservable = new Observable<{ card: Card; oldPrice: number; notFound: boolean }>();
                cardUpdateObservable.add((log) => {
                    if (this._isUnmounted) {
                        return;
                    }
                    this.state.logs.push({
                        label: `${log.card.name} (${log.card.set.name})`,
                        oldValue: PriceTools.Format(log.oldPrice, this.props.globalState),
                        newValue: PriceTools.Format(log.card.price, this.props.globalState),
                        oldPrice: log.oldPrice,
                        newPrice: log.card.price,
                        notFound: log.notFound
                    });
                    this.state.logs.sort((a, b) => a.label.localeCompare(b.label));
                    this.setState({ logs: this.state.logs, progress: this._currentProgress });
                });

                const tasks = [];
                for (const card of uniqueCards) {
                    switch (this.state.providerIndex) {
                        case 0:
                            tasks.push(
                                MagicCardMarket.UpdateExpansionPrice(
                                    card.set,
                                    card,
                                    progressObservable,
                                    undefined,
                                    cardUpdateObservable,
                                    this._lockObject
                                )
                            );
                            break;
                        case 1:
                        case 2:
                            tasks.push(
                                Scryfall.UpdateExpansionPrice(
                                    card.set,
                                    card,
                                    progressObservable,
                                    undefined,
                                    cardUpdateObservable,
                                    this._lockObject
                                )
                            );
                            break;
                    }
                }

                Promise.all(tasks)
                    .then(() => {
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            }
        });
    }

    update() {
        this.setState({ launched: true, logs: [] });

        this.props.globalState.storeNumberSettings('ProviderIndex', this.state.providerIndex);

        this.updateUsingProvider()
            .then(() => {
                if (this._lockObject.isCancellationRequested) {
                    this.setState({ launched: false, logs: [] });
                    return;
                }

                this.setState({ launched: false });

                if (this.state.logs.length === 0) {
                    this.props.globalState.onError.notifyObservers(this.props.globalState.translate('NoData'));
                    return;
                }

                Collection.RegisterSaveExt();
                this.props.globalState.onMessage.notifyObservers(this.props.globalState.translate('PricesUpdateSuccessful'));
                this.props.globalState.onNotificationRequired.notifyObservers(this.props.globalState.translate('PricesUpdateSuccessful'));
            })
            .catch((_err) => {
                this.props.globalState.onError.notifyObservers(this.props.globalState.translate('UnknownError'));
            });
    }

    cancel() {
        this._lockObject.isCancellationRequested = true;
        this.forceUpdate();
    }

    render() {
        const translate = this.props.globalState.translate.bind(this.props.globalState);

        const progressStyle = {
            width: (this.state.progress * 100) / this.state.total + '%'
        };

        return (
            <div className="page">
                <div className="prices-page">
                    {this.state.launched && (
                        <div className="prices-progress">
                            <div className="prices-progress-bar-back"></div>
                            <div className="prices-progress-bar" style={progressStyle}></div>
                            <div className="prices-progress-label">
                                {`${this.state.progress} / ${this.state.total}`}
                            </div>
                            {!this._lockObject.isCancellationRequested && (
                                <Button
                                    className="prices-cancel"
                                    globalState={this.props.globalState}
                                    onClicked={() => this.cancel()}
                                >
                                    {translate('Cancel')}
                                </Button>
                            )}
                        </div>
                    )}
                    {!this.state.launched && (
                        <div className="prices-header">
                            {this.state.deckIndex === 0 && (
                                <div className="prices-set-label">{this.props.globalState.translate('Set')}</div>
                            )}
                            {this.state.deckIndex === 0 && (
                                <select
                                    className="prices-set-list"
                                    value={this.state.expansionIndex}
                                    onChange={(evt) => {
                                        const expansionIndex = parseInt(evt.target.value);
                                        this.setState({ expansionIndex: expansionIndex });
                                    }}
                                >
                                    <option value="0">{translate('AllExpansions')}</option>
                                    <option value="1">{translate('AllExpansionsFromCollection')}</option>
                                    {this._sets.map((exp, i) => {
                                        return (
                                            <option key={exp.name + i} value={i + 2}>
                                                {exp.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            )}
                            {this.state.expansionIndex === 0 && (
                                <div className="prices-deck-label">{this.props.globalState.translate('Deck_')}</div>
                            )}
                            {this.state.expansionIndex === 0 && (
                                <select
                                    className="prices-deck-list"
                                    value={this.state.deckIndex}
                                    onChange={(evt) => {
                                        const deckIndex = parseInt(evt.target.value);
                                        this.setState({ deckIndex: deckIndex });
                                    }}
                                >
                                    <option value="0">{translate('AllDecks')}</option>
                                    <option value="1">{translate('AllDecksFromCollection')}</option>
                                    {this._decks.map((deck, i) => {
                                        return (
                                            <option key={deck.Name + i} value={i + 2}>
                                                {deck.Name}
                                            </option>
                                        );
                                    })}
                                </select>
                            )}
                            <div className="prices-provider-label">{this.props.globalState.translate('Provider')}</div>
                            <select
                                className="prices-provider-list"
                                value={this.state.providerIndex}
                                onChange={(evt) => {
                                    const providerIndex = parseInt(evt.target.value);
                                    this.setState({ providerIndex: providerIndex });
                                }}
                            >
                                <option value="0">CardMarket</option>
                                <option value="1">TCGPlayer</option>
                                <option value="2">Scryfall</option>
                            </select>
                            <Button
                                className="prices-button"
                                globalState={this.props.globalState}
                                onClicked={() => this.update()}
                            >
                                {translate('UpdatePrices')}
                            </Button>
                        </div>
                    )}
                    <div className="prices-log">
                        {this.state.logs.map((log, i) => {
                            const change =
                                log.oldPrice > 0
                                    ? (((log.newPrice - log.oldPrice) / log.oldPrice) * 100).toFixed(2)
                                    : '0.00';
                            return (
                                <div key={log.label + i} className="price-log">
                                    {log.icon && (
                                        <div className="price-log-icon">
                                            <img src={log.icon} />
                                        </div>
                                    )}
                                    <div className="price-log-label">{log.label}</div>
                                    <div className="price-log-old">{log.oldValue}</div>
                                    {!log.notFound && (
                                        <div className="price-log-arrow">
                                            <FontAwesomeIcon icon={faArrowRight} />
                                        </div>
                                    )}
                                    {!log.notFound && (
                                        <div
                                            className={
                                                'price-log-new' +
                                                (log.newPrice > log.oldPrice
                                                    ? ' improve'
                                                    : log.newPrice < log.oldPrice
                                                        ? ' regress'
                                                        : '')
                                            }
                                        >
                                            {log.newValue}
                                        </div>
                                    )}
                                    {log.notFound && <div className={'price-log-new'}>{translate('NotFound')}</div>}
                                    {!log.notFound && (
                                        <div
                                            className={
                                                'price-log-percentage' +
                                                (log.newPrice > log.oldPrice
                                                    ? ' improve'
                                                    : log.newPrice < log.oldPrice
                                                        ? ' regress'
                                                        : '')
                                            }
                                        >
                                            {`(${change}%)`}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}
