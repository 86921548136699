export class LinkTools {
    public static OpenLink(url: string) {
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.target = '_blank';
        link.style.visibility = 'hidden';
        document.body.appendChild(link);

        link.click();
        setTimeout(function () {
            document.body.removeChild(link);
        }, 0);
    }
}
