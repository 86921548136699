import * as React from 'react';
import { GlobalState } from '../globalState';

interface ICardTextMultiLinksProps {
    globalState: GlobalState;
    label: string;
    labels: string[];
    links: string[];
    linkIndex: number;
}

export class CardTextMultiLinks extends React.Component<ICardTextMultiLinksProps, Record<string, unknown>> {
    constructor(props: ICardTextMultiLinksProps) {
        super(props);
    }

    navigate(index: number, link: string) {
        if (!link) {
            return;
        }
        this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({ index: index, uniqueString: link });
    }

    render() {
        return (
            <div className="card-element">
                <div className="card-element-label">{this.props.label}</div>
                <div className="card-element-links">
                    {this.props.labels.map((label, index) => {
                        return (
                            <div
                                key={index}
                                className={`card-element-link ${this.props.links[index] ? 'link' : ''}`}
                                onClick={() => this.navigate(this.props.linkIndex, this.props.links[index])}
                            >
                                {label}
                            </div>
                        );
                    })}
                </div>
                <div className="card-element-border"></div>
            </div>
        );
    }
}
