import * as React from 'react';
import { GlobalState } from '../globalState';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { Slider } from './slider';
import { MobileTools } from '../tools/mobileTools';

interface INumberControlProps {
    globalState: GlobalState;
    value: number;
    onChange: (value: number) => void;
    max?: number;
    isFocused?: boolean;
}

export class NumberControl extends React.Component<INumberControlProps, { value: string }> {
    private valueAsNumber = 0;
    private inputControl: HTMLInputElement;

    constructor(props: INumberControlProps) {
        super(props);

        this.valueAsNumber = this.props.value;
        this.state = { value: this.props.value.toString() };
    }

    shouldComponentUpdate(nextProps: INumberControlProps, nextState: { value: string }) {
        if (nextProps !== this.props) {
            this.valueAsNumber = nextProps.value;
            nextState.value = nextProps.value.toString();
        }

        return true;
    }

    componentDidMount() {
        if (MobileTools.IsMobile) {
            return;
        }

        if (!this.props.isFocused) {
            return;
        }

        this.inputControl.focus();
    }

    sync(target: HTMLInputElement) {
        const value: number | undefined = parseInt(target.value);

        if (!isNaN(value)) {
            this.valueAsNumber = value;
            this.valueAsNumber = Math.min(this.props.max || GlobalState.MaxCardCount, this.valueAsNumber);
            this.valueAsNumber = Math.max(0, this.valueAsNumber);
            this.props.onChange(this.valueAsNumber);
        }
        this.setState({ value: target.value });
    }

    onBlur(target: HTMLInputElement) {
        const value: number | undefined = parseInt(target.value);

        if (isNaN(value)) {
            this.setState({ value: this.valueAsNumber.toFixed(0) });
        }
    }

    increase() {
        this.valueAsNumber = Math.min(this.props.max || GlobalState.MaxCardCount, this.valueAsNumber + 1);

        this.setState({ value: this.valueAsNumber.toFixed(0) });
        this.props.onChange(this.valueAsNumber);
    }

    decrease() {
        this.valueAsNumber = Math.max(0, this.valueAsNumber - 1);
        this.setState({ value: this.valueAsNumber.toFixed(0) });
        this.props.onChange(this.valueAsNumber);
    }

    onChange(newValue: number) {
        if (this.valueAsNumber === newValue) {
            return;
        }

        this.valueAsNumber = newValue;
        this.props.onChange(this.valueAsNumber);

        this.setState({ value: this.valueAsNumber.toFixed(0) });
    }

    render() {
        return (
            <div className="number-container">
                <div className="number-data">
                    <input
                        ref={(r) => (this.inputControl = r!)}
                        tabIndex={1}
                        type="text"
                        autoComplete="none"
                        value={this.state.value}
                        onChange={(evt) => this.sync(evt.target)}
                        onBlur={(evt) => this.onBlur(evt.target)}
                    />
                </div>
                <div className="number-minus button" onClick={() => this.decrease()}>
                    <FontAwesomeIcon icon={faMinusSquare} />
                </div>
                <div className="number-slider">
                    <Slider
                        className="slider-slider"
                        globalState={this.props.globalState}
                        min={0}
                        max={this.props.max || GlobalState.MaxCardCount}
                        defaultValue={this.valueAsNumber}
                        onChange={(v) => this.onChange(v)}
                    />
                </div>
                <div className="number-plus button" onClick={() => this.increase()}>
                    <FontAwesomeIcon icon={faPlusSquare} />
                </div>
            </div>
        );
    }
}
