import * as React from 'react';
import { GlobalState } from '../globalState';
import { TextRecognition } from '../tools/textRecognition';
import { Button } from '../controls/button';
import { Card } from '../entities/card';
import { CardCard } from '../controls/cards/cardCard';
import { Collection } from '../entities/collection';
import { LabelledListbox } from '../controls/labelledListbox';

require('../scss/cardScannerPage.scss');

interface ICardScannerPageProps {
    globalState: GlobalState;
}

export class CardScannerPage extends React.Component<
    ICardScannerPageProps,
    {
        cards: Card[];
        noCamera: boolean;
        scanAvailable: boolean;
        scanInProgress: boolean;
        cameras: string[];
    }
> {
    private stream: MediaStream;
    private _unMounted = false;
    private _deviceIds: string[] = [];

    constructor(props: ICardScannerPageProps) {
        super(props);

        this.state = { cards: [], noCamera: false, scanAvailable: false, scanInProgress: false, cameras: [] };
    }

    componentDidMount() {
        navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
            const cameras: string[] = [];
            this._deviceIds = [];

            for (let i = 0; i !== deviceInfos.length; ++i) {
                const deviceInfo = deviceInfos[i];
                const option = document.createElement('option');
                option.value = deviceInfo.deviceId;
                if (deviceInfo.kind !== 'videoinput') {
                    continue;
                }

                cameras.push(deviceInfo.label || 'camera ' + (cameras.length + 1));
                this._deviceIds.push(deviceInfo.deviceId);
            }

            this.setState({ cameras: cameras });

            if (cameras.length) {
                this.getStream();
            } else {
                this.setState({ noCamera: true });
            }
        });
    }

    getStream() {
        this.disconnect();
        const constraints = {
            audio: false,
            video: {
                deviceId: this._deviceIds[
                    Math.min(this._deviceIds.length - 1, GlobalState.LoadIntSettings('cameraIndex', 0))
                ],
                facingMode: 'environment'
            }
        };
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
                if (this._unMounted) {
                    return;
                }
                this.stream = stream;
                const video = document.querySelector('#camera-stream')! as HTMLVideoElement;
                video.setAttribute('autoplay', '');
                video.setAttribute('muted', '');
                video.setAttribute('playsinline', '');
                video.srcObject = stream;
                // video.play();
                this.setState({ scanAvailable: true, noCamera: false });
            })
            .catch((err) => {
                if (this._unMounted) {
                    return;
                }
                this.setState({ noCamera: true });
                const message = this.props.globalState.translate('ErrorCamera');
                this.props.globalState.onError.notifyObservers(message + ': ' + err.name);
            });
    }

    componentWillUnmount() {
        this._unMounted = true;
        this.disconnect();
    }

    disconnect() {
        if (!this.stream) {
            return;
        }
        const track = this.stream.getTracks()[0]; // if only one media track
        track.stop();
    }

    recognize() {
        this.setState({ scanAvailable: false, scanInProgress: true });
        const renderingCanvas = document.querySelector('#renderingCanvas')! as HTMLCanvasElement;
        const video = document.querySelector('#camera-stream')! as HTMLVideoElement;
        // Get the exact size of the video element.
        const width = video.videoWidth;
        const height = video.videoHeight;

        // Context object for working with the canvas.
        const context = renderingCanvas.getContext('2d')!;

        // Set the canvas to the same dimensions as the video.
        renderingCanvas.width = width;
        renderingCanvas.height = height;

        // Draw a copy of the current frame from the video on the canvas.
        context.drawImage(video, 0, 0, width, height, 0, 0, renderingCanvas.width, renderingCanvas.height);

        TextRecognition.ScanAsync(context, width, height, GlobalState.LoadSettings('CardScannerLang', 'en'))
            .then((results) => {
                const cards: Card[] = [Collection.Cards[0]];
                for (const result of results) {
                    const foundCards = Collection.GetCardsByNameApprox(result.toLowerCase());

                    if (foundCards.length > 0) {
                        cards.push(...foundCards);
                        break;
                    }
                }

                if (cards.length === 0) {
                    this.props.globalState.onError.notifyObservers(this.props.globalState.translate('NoCardFound'));
                }
                this.setState({ cards: cards, scanAvailable: true, scanInProgress: false });
            })
            .catch((_err) => {
                const message = this.props.globalState.translate('ErrorCamera');
                this.props.globalState.onError.notifyObservers(message);
                this.setState({ scanAvailable: true, scanInProgress: false });
            });
    }

    renderElement(card: Card): React.ReactElement<any> {
        return (
            <CardCard
                globalState={this.props.globalState}
                card={card}
                key={card.id}
                collectionMode={true}
                scanMode={true}
            />
        );
    }

    render() {
        const translate = this.props.globalState.translate.bind(this.props.globalState);

        const options = [
            { value: 'en', label: 'EN' },
            { value: 'fr', label: 'FR' }
        ];

        const cameras = [];

        for (const camera of this.state.cameras) {
            cameras.push({ value: camera, label: camera });
        }

        return (
            <div className="page">
                {
                    <div className="card-scanner-page">
                        {this.state.noCamera && (
                            <div className="card-scanner-page-no-camera">
                                {this.props.globalState.translate('NoCamera')}
                            </div>
                        )}
                        <div className="card-scanner-setting">
                            {this.state.cameras.length > 0 && (
                                <>
                                    <LabelledListbox
                                        value={
                                            this.state.cameras[
                                            Math.min(
                                                this.state.cameras.length - 1,
                                                GlobalState.LoadIntSettings('cameraIndex', 0)
                                            )
                                            ]
                                        }
                                        globalState={this.props.globalState}
                                        label={translate('Camera')}
                                        options={cameras}
                                        onChange={(value) => {
                                            this.props.globalState.storeNumberSettings(
                                                'cameraIndex',
                                                this.state.cameras.indexOf(value)
                                            );
                                            this.getStream();
                                            this.forceUpdate();
                                        }}
                                    />
                                    <div className="jump" />
                                </>
                            )}
                            <LabelledListbox
                                value={GlobalState.LoadSettings('CardScannerLang', 'en')}
                                globalState={this.props.globalState}
                                label={translate('CardScannerLang')}
                                options={options}
                                onChange={(value) => {
                                    this.props.globalState.storeSettings('CardScannerLang', value);
                                    this.forceUpdate();
                                }}
                            />
                        </div>
                        {!this.state.noCamera && (
                            <>
                                <canvas id="renderingCanvas" />
                                <div className="card-scanner-preview">
                                    <video autoPlay={true} muted={true} playsInline={true} id="camera-stream"></video>
                                    {this.state.scanAvailable && (
                                        <Button
                                            className="card-scanner-scan"
                                            globalState={this.props.globalState}
                                            onClicked={() => this.recognize()}
                                        >
                                            {this.props.globalState.translate('Scan')}
                                        </Button>
                                    )}
                                    {this.state.scanInProgress && (
                                        <div className="card-scanner-no-scan">
                                            {this.props.globalState.translate('Scanning')}
                                        </div>
                                    )}
                                </div>
                                <div className="card-scanner-cards">
                                    {this.state.cards.map((card) => {
                                        return this.renderElement(card);
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                }
            </div>
        );
    }
}
