import * as React from 'react';
import { GlobalState } from '../globalState';

interface IColorPickerProps {
    globalState: GlobalState;
    color: string;
    onColorChanged: (color: string) => void;
    tabIndex: number;
    useAccentColor?: boolean;
}

export class ColorPicker extends React.Component<IColorPickerProps, { color: string; focusedIndex: number }> {
    public static Colors = [
        '#34495E',
        '#2DCC70',
        '#3598DB',
        '#9B58B5',
        '#1BBC9B',
        '#6358CE',
        '#E64E3D',
        '#F3C310',
        '#A9A7A8',
        '#EA1E63',
        '#4C4C4C',
        '#795547',
        '#92a8d1',
        '#034f84',
        '#f7cac9',
        '#f7786b',
        '#a5d4e6',
        '#cecbd8',
        '#50394c',
        '#b5e7a0',
        '#feb236',
        '#d64161',
        '#c1946a',
        '#ffef96'
    ];

    public static AccentColors = [
        '#CC7529',
        '#99b433',
        '#00a300',
        '#1e7145',
        '#ff0097',
        '#9f00a7',
        '#7e3878',
        '#603cba',
        '#00aba9',
        '#2d89ef',
        '#2b5797',
        '#ffc40d',
        '#e3a21a',
        '#da532c',
        '#ee1111',
        '#b91d47'
    ];

    constructor(props: IColorPickerProps) {
        super(props);

        this.state = { color: this.props.color, focusedIndex: -1 };
    }

    changeColor(color: string) {
        this.setState({ color: color });
        this.props.onColorChanged(color);
    }

    render() {
        const colors = this.props.useAccentColor ? ColorPicker.AccentColors : ColorPicker.Colors;

        return (
            <div
                className="color-picker"
                onFocus={() => this.setState({ focusedIndex: colors.indexOf(this.state.color) })}
                onBlur={() => this.setState({ focusedIndex: -1 })}
                onKeyDown={(evt) => {
                    if (this.state.focusedIndex === -1) {
                        return;
                    }
                    if (evt.keyCode === 13 || evt.keyCode === 32) {
                        this.changeColor(colors[this.state.focusedIndex]);
                        return;
                    }
                    if (evt.keyCode === 38) {
                        // up
                        if (this.state.focusedIndex >= 4) {
                            this.setState({ focusedIndex: this.state.focusedIndex - 4 });
                        }
                        return;
                    }
                    if (evt.keyCode === 40) {
                        // down
                        if (this.state.focusedIndex < colors.length - 5) {
                            this.setState({ focusedIndex: this.state.focusedIndex + 4 });
                        }
                        return;
                    }
                    if (evt.keyCode === 37) {
                        // left
                        this.setState({ focusedIndex: Math.max(0, this.state.focusedIndex - 1) });
                        return;
                    }
                    if (evt.keyCode === 39) {
                        // right
                        this.setState({ focusedIndex: Math.min(colors.length - 1, this.state.focusedIndex + 1) });
                        return;
                    }
                }}
                tabIndex={this.props.tabIndex}
            >
                {colors.map((color, i) => {
                    return (
                        <div
                            key={color}
                            className={
                                'color-picker-color' +
                                (i === this.state.focusedIndex ? ' focused' : '') +
                                (color === this.state.color ? ' color-selected' : '')
                            }
                            onClick={() => {
                                this.changeColor(color);
                                this.setState({ focusedIndex: i });
                            }}
                            style={{
                                background: color
                            }}
                        />
                    );
                })}
            </div>
        );
    }
}
