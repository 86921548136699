import { GlobalState } from '../globalState';

export class CardRarity {
    public static Rarities: CardRarity[] = [];

    public constructor(public id: number, public key: string, public name: string, public color?: string) {}

    public static GetRarityById(id: number): CardRarity {
        return CardRarity.Rarities.filter((r) => r.id === id)[0];
    }

    public static CreateList(gs: GlobalState) {
        CardRarity.Rarities.push(new CardRarity(0, '', gs.translate('AllRarities')));
        CardRarity.Rarities.push(new CardRarity(1, 'Common', gs.translate('Common'), 'DarkGray'));
        CardRarity.Rarities.push(new CardRarity(2, 'Uncommon', gs.translate('Uncommon'), '#657177'));
        CardRarity.Rarities.push(new CardRarity(3, 'Rare', gs.translate('Rare'), 'orange'));
        CardRarity.Rarities.push(new CardRarity(4, 'Mythic', gs.translate('Mythic'), '#FA8803'));
        CardRarity.Rarities.push(new CardRarity(5, 'Special', gs.translate('Special'), 'purple'));
    }
}
