import { Set } from '../../entities/set';
import { Collection } from '../../entities/collection';
import { Observable } from '../../tools/observable';
import { Card } from '../../entities/card';
import { CancellationToken } from '../../tools/cancellationToken';
import { StringHelpers } from '../stringHelpers';
import { ICard } from '../../entities/ICard';
import { Nullable } from '../nullable';

export class MagicCardMarket {
    private static PriceCache: { [key: number]: { Normal: number; Foil: number } };
    private static CardURLCache: { [key: number]: { [key: number]: string } } = {};
    private static AvailableFetch = 8;
    private static CacheUpdateInProgress = false;

    public static async GetLinkAsync(card: ICard) {
        let setName = card.set.prepareSetNameForMKM(true);
        setName = setName.replace("'", '');

        if (card.cardMarketId) {
            if (!MagicCardMarket.CardURLCache[card.set.id]) {
                MagicCardMarket.CardURLCache[card.set.id] = await (
                    await fetch(`https://urzabackend.azurewebsites.net/cardmarket/cardUrlIds?expansionName=${setName}`)
                ).json();
            }

            if (MagicCardMarket.CardURLCache[card.set.id][card.cardMarketId]) {
                return Promise.resolve(
                    'https://www.cardmarket.com' + MagicCardMarket.CardURLCache[card.set.id][card.cardMarketId]
                );
            }
        }

        const cardName = card.nameEn
            .replace(/\s/g, '-')
            .replace(',', '')
            .replace('(', '')
            .replace(')', '')
            .replace('v.', 'v')
            .replace("'", '-');

        if (card.isToken) {
            // eslint-disable-next-line max-len
            return Promise.resolve(
                `https://www.cardmarket.com/en/Magic/Products/Search?searchString=${cardName}&utm_campaign=card_prices&utm_medium=text`
            );
        }
        setName = setName.replace(/\s/g, '-');
        setName = setName.replace(/:/g, '');
        return Promise.resolve(`https://www.cardmarket.com/en/Magic/Products/Singles/${setName}/${cardName}`);
    }

    private static CheckCacheUpdateState(resolve: () => void) {
        if (this.CacheUpdateInProgress === true) {
            setTimeout(() => this.CheckCacheUpdateState(resolve), 250);
        } else {
            resolve();
        }
    }

    private static UpdateCache(): Promise<void> {
        return new Promise((resolve) => {
            if (this.CacheUpdateInProgress === true) {
                this.CheckCacheUpdateState(resolve);
                return;
            }

            if (this.PriceCache) {
                resolve();
                return;
            }

            this.CacheUpdateInProgress = true;
            return fetch('https://urzabackend.azurewebsites.net/cardmarket/pricecache')
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    this.PriceCache = data;
                    this.CacheUpdateInProgress = false;
                    resolve();
                });
        });
    }

    private static GetExpansionPrice(
        expansion: Set,
        card: Nullable<ICard>,
        progressObservable: Observable<number>,
        cardUpdateObservable?: Observable<{ card: Card; oldPrice: number; notFound: boolean }>,
        lockObject?: CancellationToken
    ) {
        expansion.pricesWereUpdated = false;

        const mkmName = expansion.prepareSetNameForMKM();
        const urlEncode = encodeURIComponent(mkmName);

        const fallbacks: string[] = [];

        fallbacks.push('Game Day Promos', 'Prerelease Promos', 'Release Promos', 'Buy a Box Promos');

        const additions: string[] = [];

        if (expansion.name.indexOf(' Promos') !== -1) {
            additions.push(encodeURIComponent('Open House Promos'));
        }

        if (mkmName.indexOf(' Extras') === -1) {
            additions.push(encodeURIComponent(mkmName + ': Extras'));
        }

        if (mkmName.indexOf('Tokens') !== -1) {
            additions.push(encodeURIComponent(mkmName.replace('Tokens', '')));
        }

        switch (expansion.name) {
            case 'Pro Tour':
                additions.push('DCI Promos');
                break;
        }

        return fetch(`https://urzabackend.azurewebsites.net/cardmarket/cardProductIds?expansionName=${urlEncode}`)
            .then((response) => {
                this.AvailableFetch++;
                return response.json();
            })
            .then((data: { [key: string]: number }) => {
                const tasks = additions.map((f) => {
                    return fetch(`https://urzabackend.azurewebsites.net/cardmarket/cardProductIds?expansionName=${f}`)
                        .then((response) => {
                            return response.json();
                        })
                        .then((dataOther: { [key: string]: number }) => {
                            for (const key in dataOther) {
                                if (!data[key]) {
                                    data[key] = dataOther[key];
                                }
                            }
                        });
                });

                return Promise.all(tasks).then(() => {
                    // if (!Object.keys(data).length) {
                    const promoTasks = fallbacks.map((f) => {
                        return fetch(
                            `https://urzabackend.azurewebsites.net/cardmarket/cardProductIds?expansionName=${f}`
                        )
                            .then((response) => {
                                return response.json();
                            })
                            .then((dataOther: { [key: string]: number }) => {
                                for (const key in dataOther) {
                                    if (!data[key]) {
                                        data[key] = dataOther[key];
                                    }
                                }
                            });
                    });

                    return Promise.all(promoTasks).then(() => {
                        return this.ProcessExpansionAsync(
                            data,
                            expansion,
                            card,
                            progressObservable,
                            cardUpdateObservable,
                            lockObject
                        );
                    });
                });
            });
    }

    private static GetCardProductId(card: ICard, alreadyDone: string[], data: { [key: string]: number }) {
        let fixedName = card.nameEn;
        const expansion = card.set;

        fixedName = fixedName.replace('v. 1', 'Version 1');
        fixedName = fixedName.replace('v. 2', 'Version 2');
        fixedName = fixedName.replace('v. 3', 'Version 3');
        fixedName = fixedName.replace('v. 4', 'Version 4');
        fixedName = fixedName.replace('v. 5', 'Version 5');
        fixedName = fixedName.replace('v. 6', 'Version 6');
        fixedName = fixedName.replace('v. 7', 'Version 7');
        fixedName = fixedName.replace('v. 8', 'Version 8');

        if (StringHelpers.EndsWith(card.scryfallNumber, 's')) {
            fixedName = fixedName + ' (Version 1)';
        } else if (StringHelpers.EndsWith(card.scryfallNumber, 'p')) {
            fixedName = fixedName + ' (Version 2)';
        }

        if (card.otherCard) {
            for (const productName in data) {
                if (StringHelpers.StartsWith(productName, fixedName)) {
                    fixedName = productName;
                    break;
                }
            }
        }
        let productId = data[fixedName];

        if (!productId && expansion.name.indexOf('Tokens') !== -1) {
            const lookup = fixedName + ' Token';
            for (const productName in data) {
                if (StringHelpers.StartsWith(productName, lookup)) {
                    productId = data[productName];
                    break;
                } else if (productName.indexOf(fixedName) !== -1 && productName.indexOf('Token') !== -1) {
                    productId = data[productName];
                    break;
                }
            }
        }

        if (!productId && fixedName.indexOf(' (Version 1)') !== -1) {
            productId = data[fixedName.replace(' (Version 1)', ' (V.1)')];
        }

        if (!productId && fixedName.indexOf(' (Version 2)') !== -1) {
            productId = data[fixedName.replace(' (Version 2)', ' (V.2)')];
        }

        if (!productId) {
            const regex = /\s\(Version\s\d\)/;
            const matches = regex.exec(fixedName);

            if (matches && matches.length) {
                const lookup = fixedName.replace(matches[0], '');
                for (const productName in data) {
                    if (StringHelpers.StartsWith(productName, lookup)) {
                        productId = data[productName];
                        break;
                    }
                }
            }
        }

        if (!productId) {
            for (const productName in data) {
                if (alreadyDone.indexOf(productName) !== -1) {
                    continue;
                }

                if (StringHelpers.StartsWith(productName, fixedName)) {
                    alreadyDone.push(productName);
                    productId = data[productName];

                    if (this.PriceCache[productId].Normal || this.PriceCache[productId].Foil) {
                        break;
                    } else {
                        productId = 0;
                    }
                }
            }
        }

        return productId;
    }

    private static ProcessExpansionAsync(
        data: { [key: string]: number },
        expansion: Set,
        targetCard: Nullable<ICard>,
        progressObservable: Observable<number>,
        cardUpdateObservable?: Observable<{ card: Card; oldPrice: number; notFound: boolean }>,
        lockObject?: CancellationToken
    ) {
        const alreadyDone: string[] = [];
        return Promise.all(
            expansion.sortedCards.map((card) => {
                return new Promise((resolve) => {
                    if (targetCard && card !== targetCard) {
                        resolve(undefined);
                        return;
                    }
                    if (card.isBackFace) {
                        progressObservable.notifyObservers(1);
                        resolve(undefined);
                        return;
                    }

                    if (lockObject && lockObject.isCancellationRequested) {
                        resolve(undefined);
                        return;
                    }
                    setTimeout(() => {
                        if (lockObject && lockObject.isCancellationRequested) {
                            resolve(undefined);
                            return;
                        }

                        const productId = card.cardMarketId || this.GetCardProductId(card, alreadyDone, data);

                        if (productId && this.PriceCache[productId]) {
                            const oldPrice = card.price;
                            card.price = this.PriceCache[productId].Normal;
                            card.foilPrice = this.PriceCache[productId].Foil;
                            expansion.pricesWereUpdated = true;
                            if (cardUpdateObservable) {
                                cardUpdateObservable.notifyObservers({
                                    card: card,
                                    oldPrice: oldPrice,
                                    notFound: false
                                });
                            }
                        } else if (cardUpdateObservable) {
                            cardUpdateObservable.notifyObservers({
                                card: card,
                                oldPrice: card.price,
                                notFound: true
                            });
                        }

                        progressObservable.notifyObservers(1);
                        resolve(undefined);
                    }, 0);
                });
            })
        );
    }

    public static UpdateExpansionPrice(
        set: Set,
        card: Nullable<ICard>,
        progressObservable: Observable<number>,
        expansionUpdateObservable?: Observable<{ set: Set; oldPrice: number; notFound: boolean }>,
        cardUpdateObservable?: Observable<{ card: Card; oldPrice: number; notFound: boolean }>,
        lockObject?: CancellationToken
    ) {
        const oldPrice = set.totalPrice;

        return this.UpdateCache()
            .then(() => {
                return this.GetExpansionPrice(set, card, progressObservable, cardUpdateObservable, lockObject);
            })
            .then(() => {
                if (expansionUpdateObservable) {
                    expansionUpdateObservable.notifyObservers({
                        set: set,
                        oldPrice: oldPrice,
                        notFound: !set.pricesWereUpdated
                    });
                }
            });
    }

    public static UpdateAllExpansions(
        expansionUpdateObservable: Observable<{ set: Set; oldPrice: number; notFound: boolean }>,
        progressObservable: Observable<number>,
        lockObject: CancellationToken,
        onlyCollection: boolean
    ) {
        let sets = Collection.SortedSets;

        if (onlyCollection) {
            sets = sets.filter((s) => s.inCollectionCardCount > 0);
        }

        return this.UpdateCache().then(() => {
            return Promise.all(
                sets.map((expansion) => {
                    if (this.AvailableFetch > 0) {
                        this.AvailableFetch--;
                        return new Promise((resolve, reject) => {
                            return this.UpdateExpansionPrice(
                                expansion,
                                null,
                                progressObservable,
                                expansionUpdateObservable,
                                undefined,
                                lockObject
                            )
                                .then(() => {
                                    resolve(undefined);
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        });
                    }

                    return new Promise((resolve, reject) => {
                        const interval = setInterval(() => {
                            if (lockObject && lockObject.isCancellationRequested) {
                                clearInterval(interval);
                                resolve(undefined);
                                return;
                            }
                            if (this.AvailableFetch > 0) {
                                this.AvailableFetch--;
                                clearInterval(interval);
                                this.UpdateExpansionPrice(
                                    expansion,
                                    null,
                                    progressObservable,
                                    expansionUpdateObservable,
                                    undefined,
                                    lockObject
                                )
                                    .then(() => resolve(undefined))
                                    .catch((err) => {
                                        reject(err);
                                    });
                                return;
                            }
                        }, 500);
                    });
                })
            );
        });
    }
}
