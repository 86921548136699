import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Nullable } from '../tools/nullable';
import { Observer } from '../tools/observable';
import { GlobalState } from '../globalState';

require('../scss/virtualizedList.scss');

interface IVirtualizedListProps {
    globalState: GlobalState;
    dataSource: any[];
    renderElement: (data: any, index: number) => React.ReactElement<any>;
    className?: string;
    cellWidth?: number;
    cellHeight: number;
    topParentClassName: string;
    tooltipOnProperty?: string;
    offset?: number;
    directContainer?: boolean;
    horizontalScrollerClass?: string;
    onHorizontalScroll?: (left: number) => void;
    diff: number;
}

export class VirtualizedList extends React.Component<IVirtualizedListProps> {
    private activeList = new Array<Nullable<Node>>();
    private scroller: HTMLElement;
    private contentHost: HTMLElement;
    private previousStartRowIndex: number;
    private previousEndRowIndex = -1;
    private clientWidth: number;
    private dataCount: number;
    private currentScrollTop: number;
    private currentScrollerHeight: number;
    private cachedCellWidth?: number;
    private cachedDataSource: any[];
    private updatedClientWidth: number;
    private rafIndex: number;
    private onHamburgerClickedObserver: Nullable<Observer<void>>;
    private onWindowSizeChangedObserver: Nullable<Observer<number>>;
    private topParent: HTMLDivElement;
    private horizontalScroller: HTMLDivElement;

    constructor(props: IVirtualizedListProps) {
        super(props);
    }

    componentDidUpdate() {
        this.updateContentHostHeight();
    }

    updateContentHostHeight() {
        const dataSource = this.props.dataSource;
        const cellWidth = this.props.cellWidth;
        const cellHeight = this.props.cellHeight;
        const columns = cellWidth ? Math.floor(this.updatedClientWidth / cellWidth) : 1;
        const rows = Math.ceil(dataSource.length / columns);
        const height = rows * cellHeight;
        this.contentHost.style.height = height + 'px';
    }

    wipeCache() {
        this.cachedDataSource = [];
    }

    updateContent(force: boolean) {
        const dataSource = this.props.dataSource;
        if (!this.topParent) {
            this.topParent = document.querySelector('.' + this.props.topParentClassName)! as HTMLDivElement;
        }

        const parent = this.topParent;
        const offset = this.props.offset || 0;
        const diff = 0;

        if (this.props.directContainer) {
            this.scroller.style.height = parent.clientHeight + offset - diff + 'px';
        } else {
            let scrollHeight = 0;
            if (this.props.horizontalScrollerClass) {
                if (!this.horizontalScroller) {
                    this.horizontalScroller = document.querySelector(
                        '.' + this.props.horizontalScrollerClass
                    ) as HTMLDivElement;
                }
                scrollHeight = this.horizontalScroller.offsetHeight - this.horizontalScroller.clientHeight;
            }

            if (parent.children.length === 3) {
                this.scroller.style.height =
                    parent.clientHeight -
                    parent.children[0].clientHeight -
                    parent.children[1].clientHeight -
                    offset -
                    scrollHeight -
                    diff +
                    'px';
            } else {
                this.scroller.style.height =
                    parent.clientHeight - parent.children[0].clientHeight - offset - scrollHeight - diff + 'px';
            }
        }

        let toRemove: Node[] | undefined;

        if (
            (this.dataCount !== undefined && this.dataCount !== dataSource.length) ||
            (this.clientWidth && this.clientWidth !== this.updatedClientWidth) ||
            this.cachedCellWidth !== this.props.cellWidth ||
            this.cachedDataSource !== dataSource
        ) {
            this.updateContentHostHeight();
            toRemove = new Array<Node>();
            // Clean all
            for (let index = 0; index < this.activeList.length; index++) {
                if (!this.activeList[index]) {
                    continue;
                }

                toRemove.push(this.activeList[index]!);
                this.activeList[index] = null;
            }

            this.previousStartRowIndex = -1;
            this.cachedDataSource = dataSource;
            this.cachedCellWidth = this.props.cellWidth;
            force = true;
        }

        if (
            !force &&
            this.currentScrollTop === this.scroller.scrollTop &&
            this.currentScrollerHeight === this.scroller.clientHeight
        ) {
            this.rafIndex = requestAnimationFrame(() => this.updateContent(false));
            return;
        }

        if (!toRemove) {
            toRemove = new Array<Node>();
        }

        const scrollPosition = this.scroller.scrollTop;
        this.currentScrollerHeight = this.scroller.clientHeight;
        this.currentScrollTop = scrollPosition;
        this.dataCount = dataSource.length;
        this.clientWidth = this.updatedClientWidth;
        const cellWidth = this.props.cellWidth;
        const cellHeight = this.props.cellHeight;
        const columns = cellWidth
            ? GlobalState.IsRunningOnFoldableDevice
                ? 2 * Math.floor((this.clientWidth / 2 - GlobalState.FoldWidth) / cellWidth)
                : Math.floor(this.clientWidth / cellWidth)
            : 1;
        const rows = Math.ceil(this.dataCount / columns);
        const height = rows * cellHeight;
        const clientHeight = this.scroller.clientHeight;
        const columIndexFor2ndScreen = (columns / 2) | 0;

        const startRowIndex = Math.floor((scrollPosition / height) * rows);
        const endRowIndex = startRowIndex + Math.ceil((clientHeight / height) * rows);

        if (this.previousStartRowIndex !== -1) {
            // Remove out of view
            for (let row = this.previousStartRowIndex; row < startRowIndex; row++) {
                for (let column = 0; column < columns; column++) {
                    const index = column + row * columns;

                    if (!this.activeList[index]) {
                        continue;
                    }

                    toRemove.push(this.activeList[index]!);
                    this.activeList[index] = null;
                }
            }

            for (let row = endRowIndex + 1; row <= this.previousEndRowIndex; row++) {
                for (let column = 0; column < columns; column++) {
                    const index = column + row * columns;

                    if (!this.activeList[index]) {
                        continue;
                    }

                    toRemove.push(this.activeList[index]!);
                    this.activeList[index] = null;
                }
            }
        }

        // Create new
        for (let row = startRowIndex; row <= endRowIndex; row++) {
            for (let column = 0; column < columns; column++) {
                const index = column + row * columns;
                const dataToRender = dataSource[index];

                if (this.activeList[index]) {
                    continue; // Already exists
                }

                if (!dataToRender) {
                    continue;
                }

                let host: HTMLDivElement;
                let reused = false;
                let root: ReactDOM.Root;

                if (toRemove.length) {
                    host = toRemove.splice(0, 1)[0] as HTMLDivElement;
                    reused = true;
                     root = (host as any).root;
                } else {
                    host = document.createElement('div');
                    host.style.position = 'absolute';
                    (host as any).root = root = ReactDOM.createRoot(host);
                }

                root.render(this.props.renderElement(dataToRender, row * columns + column) as any);

                if (!reused) {
                    this.contentHost.appendChild(host);
                }

                host.style.width = cellWidth + 'px';
                host.style.height = cellHeight + 'px';
                if (cellWidth) {
                    let left = column * cellWidth;

                    if (GlobalState.IsRunningOnFoldableDevice) {
                        if (column >= columIndexFor2ndScreen) {
                            left = this.updatedClientWidth / 2 + (column - columIndexFor2ndScreen) * cellWidth;
                        }
                    }

                    host.style.left = left + 'px';
                } else {
                    host.style.left = '0px';
                }
                host.style.top = row * cellHeight + 'px';

                this.activeList[index] = host;
            }
        }

        for (const host of toRemove) {
            if ((host as any).root) {
                (host as any).root.unmount();
            }
            this.contentHost.removeChild(host);
        }

        this.previousStartRowIndex = startRowIndex;
        this.previousEndRowIndex = endRowIndex;

        this.rafIndex = requestAnimationFrame(() => this.updateContent(false));
    }

    onResize() {
        this.updatedClientWidth = this.contentHost.clientWidth;
    }

    UNSAFE_componentWillMount() {
        this.onHamburgerClickedObserver = this.props.globalState.onHamburgerClicked.add(() => {
            this.onResize();
        });
        this.rafIndex = requestAnimationFrame(() => this.updateContent(false));
        this.onWindowSizeChangedObserver = this.props.globalState.onWindowSizeChanged.add(() => {
            this.onResize();
        });
    }

    componentDidMount() {
        this.scroller = document.getElementById('scrollElement')!;
        this.contentHost = document.getElementById('contentHost')!;
        this.onResize();
        this.updateContentHostHeight();
    }

    componentWillUnmount() {
        if (this.rafIndex !== undefined) {
            cancelAnimationFrame(this.rafIndex);
        }

        this.props.globalState.onWindowSizeChanged.remove(this.onWindowSizeChangedObserver);
        this.props.globalState.onHamburgerClicked.remove(this.onHamburgerClickedObserver);

        this.activeList.forEach((c) => {
            if (!c || !(c as any).root) {
                return;
            }
            setTimeout(() => {
                (c as any).root.unmount();
            }, 0);
        });
    }

    onScroll() {
        this.props.globalState.pageCustomData.scroll = this.scroller.scrollTop;

        if (this.props.onHorizontalScroll) {
            this.props.onHorizontalScroll(this.scroller.scrollLeft);
        }
    }

    render() {
        return (
            <div className={this.props.className || ''} id="scrollElement"  onScroll={() => this.onScroll()}>
                <div className="virtualized-list flex" id="contentHost" />
            </div>
        );
    }
}
