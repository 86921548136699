import { CardColor } from '../entities/cardColor';
import { CardRarity } from '../entities/cardRarity';
import { GlobalState } from '../globalState';
import { ICard } from '../entities/ICard';
import { DownloadTools } from './downloadTools';
import { PriceTools } from './priceTools';

export class CSVExport {
    private static PrepareLine(
        card: ICard,
        globalState: GlobalState,
        additionalColumns: { columnName: string; dataExtractor: (card: ICard) => string }[]
    ): string {
        const line = [];

        line.push(`"${card.name.replaceAll('"', '\'')}"`);
        line.push(`"${card.type.replaceAll('"', '\'')}"`);
        line.push(CardColor.GetColorById(card.colorId).name);
        line.push(CardRarity.GetRarityById(card.rarityId).name);
        line.push(`"${card.author.replaceAll('"', '\'')}"`);
        line.push(card.force);
        line.push(card.defense);
        line.push(card.manaCost.trim());
        line.push(card.convertedManaCost);
        line.push(card.count);
        line.push(card.foilCount);
        line.push(card.specialFoilCount);
        line.push(PriceTools.Format(card.price, globalState));
        line.push(PriceTools.Format(card.foilPrice, globalState));
        line.push(card.number);
        line.push(`"${card.set.name}"`);
        line.push(card.set.code);
        line.push(card.id);
        line.push(card.multiverseId);
        line.push(card.comments.replace(/\r\n|\r|\n/g, '\\n'));
        line.push(card.toTrade);
        line.push(card.wanted);
        line.push(`"${card.condition}"`);
        line.push(`"${card.grading}"`);
        line.push(`"${card.languages}"`);
        line.push(card.tcgPlayerId);
        line.push(card.cardMarketId);
        line.push(card.scryfallId);


        for (const additionalColumn of additionalColumns) {
            line.push(additionalColumn.dataExtractor(card));
        }

        return line.join(',') + '\r\n';
    }

    public static ExportAsync(
        cards: ICard[],
        globalState: GlobalState,
        additionalColumns: { columnName: string; dataExtractor: (card: ICard) => string }[] = []
    ): Promise<void> {
        let csvContent = '"sep=,"\r\n';
        const header = [
            'Name',
            'Type',
            'Color',
            'Rarity',
            'Author',
            'Power',
            'Toughness',
            'Mana cost',
            'Converted mana cost',
            'Count',
            'Foil count',
            'Special foil count',
            'Price',
            'Foil price',
            'Number',
            'Set',
            'Set code',
            'ID',
            'Multiverse ID',
            'Comments',
            'To trade',
            'Wanted',
            'Condition',
            'Grading',
            'Languages',
            'TCG ID',
            'Cardmarket ID',
            'Scryfall ID'
        ];

        for (const additionalColumn of additionalColumns) {
            header.push(additionalColumn.columnName);
        }

        csvContent += header.join(',') + '\r\n';

        cards.forEach((card) => {
            csvContent += this.PrepareLine(card, globalState, additionalColumns);
        });

        const universalBOM = '\uFEFF';
        const data = universalBOM + csvContent;

        return DownloadTools.DownloadAsync(data, 'export', globalState, '.csv', globalState.translate('CsvFile'));
    }
}
