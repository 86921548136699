import * as React from 'react';
import { GlobalState } from '../globalState';
import { Button } from './button';
import { LabelledTexbox } from './labelledTextbox';
import { Collection } from '../entities/collection';
import { ColorPicker } from './colorPicker';
import { MobileTools } from '../tools/mobileTools';
import { LabelledTagPicker } from './labelledTagPicker';

interface IListEditorDialogProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class ListEditorDialog extends React.Component<
    IListEditorDialogProps,
    {
        isVisible: boolean;
        name: string;
        group: string;
        color: string;
        tags: string[];
        creationMode: boolean;
    }
    > {
    private focusDone = false;

    constructor(props: IListEditorDialogProps) {
        super(props);

        this.state = {
            isVisible: false,
            name: '',
            group: '',
            tags: [],
            color: this.getRandomColor(),
            creationMode: false
        };
    }

    getRandomColor(): string {
        return ColorPicker.Colors[(Math.random() * ColorPicker.Colors.length) | 0];
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onListEditorRequired.add((state) => {
            this.focusDone = false;
            this.setState({
                isVisible: true,
                name: state.name,
                creationMode: state.color ? false : true,
                tags: state.tags,
                group: state.group,
                color: state.color || this.getRandomColor()
            });

            this.props.globalState.onBlurRequired.notifyObservers(true);
        });

        document.addEventListener('keydown', (evt) => {
            if (evt.keyCode === 13 && this.state.isVisible && document.activeElement === document.body) {
                this.onConfirmation(true);
                return;
            }
            if (evt.keyCode === 27 && this.state.isVisible) {
                this.onConfirmation(false);
            }
        });
    }

    onConfirmation(value: boolean) {
        this.setState({ isVisible: false });
        this.props.globalState.onBlurRequired.notifyObservers(false);

        if (value) {
            const response = {
                name: this.state.name,
                group: this.state.group,
                color: this.state.color,
                tags: this.state.tags
            };

            this.props.globalState.onListEditorValidated.notifyObservers(response);
        } else {
            this.props.globalState.onListEditorValidated.notifyObservers(null);
        }
    }

    componentDidUpdate() {
        if (MobileTools.IsMobile) {
            return;
        }
        if (this.state.isVisible && !this.focusDone) {
            this.focusDone = true;
            document.getElementById('list-queryText')!.focus();
        }
    }

    render() {
        if (!this.state.isVisible) {
            return null;
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);
        const groups = Collection.ListGroups.map((lg) => lg.Name).sort();

        return (
            <div className="list-dialog-container">
                <div className="list-dialog-background"></div>
                <div className={'list-dialog' + (this.props.darkMode ? ' dark' : '')}>
                    <div className="list-dialog-title">
                        {this.state.creationMode ? translate('New list') : translate('Edit list')}
                    </div>
                    <div className="list-dialog-name">
                        <div className="list-label">{translate('Name')}</div>
                        <input
                            tabIndex={1}
                            id="list-queryText"
                            autoComplete="none"
                            type="text"
                            value={this.state.name}
                            onChange={(evt) => this.setState({ name: evt.target.value })}
                        />
                    </div>
                    <div className="list-dialog-group">
                        <LabelledTexbox
                            label={translate('Group')}
                            globalState={this.props.globalState}
                            onChange={(value) => {
                                this.setState({ group: value });
                            }}
                            tabIndex={2}
                            suggestions={groups}
                            minCharacterForSuggestion={0}
                            value={this.state.group}
                        />
                    </div>
                    <div className="list-dialog-tags">
                        <LabelledTagPicker
                            darkMode={this.props.darkMode}
                            value={this.state.tags}
                            globalState={this.props.globalState}
                            label={translate('Tags_')}
                            tabIndex={3}
                            onChange={(value) => {
                                this.setState({ tags: value });
                            }}
                        />
                    </div>
                    <div className="list-dialog-colors-container">
                        <div className="list-label">{translate('Color')}</div>
                        <ColorPicker
                            globalState={this.props.globalState}
                            tabIndex={4}
                            onColorChanged={(color) => this.setState({ color: color })}
                            color={this.state.color}
                        />
                    </div>
                    <div className="list-dialog-accept">
                        <Button
                            className="list-dialog-button"
                            tabIndex={5}
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(true)}
                        >
                            {translate('OK')}
                        </Button>
                    </div>
                    <div className="list-dialog-cancel">
                        <Button
                            className="list-dialog-button"
                            tabIndex={6}
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(false)}
                        >
                            {translate('Cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
