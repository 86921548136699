import { GlobalState } from '../globalState';

export class CardGrading {
    public static CardGradings: string[] = [];
    public static GradingKeys: string[] = [];
    public static DecodingOffset = 4;
    public static DecodingMask = 0xf;

    public constructor(public name: string) {}

    public static CreateList(gs: GlobalState) {
        const translate = gs.translate.bind(gs);

        this.GradingKeys.push('Undefined');

        this.GradingKeys.push('PR 1');
        this.GradingKeys.push('FR 1.5');
        this.GradingKeys.push('GOOD 2');
        this.GradingKeys.push('VG 3');
        this.GradingKeys.push('VG-EX 4');
        this.GradingKeys.push('EX 5');
        this.GradingKeys.push('EX-MT 6');
        this.GradingKeys.push('NM7');
        this.GradingKeys.push('NM-MT 8');
        this.GradingKeys.push('MINT 9');
        this.GradingKeys.push('GEM-MT 10');

        CardGrading.CardGradings.push(translate('Undefined'));
        CardGrading.CardGradings.push('PR 1');
        CardGrading.CardGradings.push('FR 1.5');
        CardGrading.CardGradings.push('GOOD 2');
        CardGrading.CardGradings.push('VG 3');
        CardGrading.CardGradings.push('VG-EX 4');
        CardGrading.CardGradings.push('EX 5');
        CardGrading.CardGradings.push('EX-MT 6');
        CardGrading.CardGradings.push('NM7');
        CardGrading.CardGradings.push('NM-MT 8');
        CardGrading.CardGradings.push('MINT 9');
        CardGrading.CardGradings.push('GEM-MT 10');
    }

    public static Decode(encodedGradings: number, limitTo: number) {
        const gradings = [];
        while (encodedGradings) {
            const index = encodedGradings & CardGrading.DecodingMask;
            if (index !== 0) {
                gradings.push(CardGrading.CardGradings[index]);
            }

            encodedGradings = encodedGradings >> CardGrading.DecodingOffset;
            limitTo--;

            if (limitTo === 0) {
                break;
            }
        }

        const final = [];

        for (const grading of gradings) {
            final.push(`${1}x${grading}`);
        }
        return final.join(', ');
    }

    public static Decoder(encodedGradings: string): number[] {
        const gradings: number[] = [];

        if (encodedGradings === '') {
            return gradings;
        }

        const parts = encodedGradings.split(',');

        for (const part of parts) {
            const splits = part.split('x');
            const count = parseInt(splits[0]);
            const grading = part.replace(splits[0]+'x', '');
            let gradingIndex = CardGrading.CardGradings.indexOf(grading);
            if (gradingIndex === -1) {
                gradingIndex = CardGrading.GradingKeys.indexOf(grading);
            }
            for (let index = 0; index < count; index++) {
                gradings.push(gradingIndex);
            }
        }
        return gradings;
    }

    public static Deserialize(storedValue: string) {
        const indexes = this.Decoder(storedValue);

        return this.Encoder(indexes);
    }

    public static Encoder(gradings: number[]) {
        const final = [];

        if (gradings.length > 1) {
            const first = gradings[0];
            if (gradings.every((x) => x === first)) {
                return `${gradings.length}x${CardGrading.CardGradings[first]}`;
            }
        }

        for (const grading of gradings) {
            if (grading === 0) {
                continue;
            }
            final.push(`${1}x${CardGrading.CardGradings[grading]}`);
        }
        return final.join(', ');
    }
}
