import * as React from 'react';
import { GlobalState } from '../globalState';
import { Collection } from '../entities/collection';

interface IDeckPickerProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class DeckPicker extends React.Component<
    IDeckPickerProps
    > {
    constructor(props: IDeckPickerProps) {
        super(props);

        this.state = {deckIndex: 0};
    }

    render() {
        const translate = this.props.globalState.translate.bind(this.props.globalState);
        const decks = Collection.Decks.filter((d) => !d.Locked).sort((a, b) => a.Name.localeCompare(b.Name));

        const activeDeck = GlobalState.LoadSettings('ActiveDeck');
        let deckIndex = decks.map(d => d.UniqueID).indexOf(activeDeck);

        const targets = [translate('Deck_'), translate('Sideboard'), translate('Maybeboard__')];
        const targetIndex = GlobalState.LoadIntSettings('ActiveDeckTarget');

        if (decks.length > 0) {
            if (deckIndex === -1) {
                deckIndex = 0;
                this.props.globalState.storeSettings('ActiveDeck', decks[deckIndex].UniqueID);
            }
        }

        return (
            <div className={'active-deck' + (this.props.darkMode ? ' dark' : '')}>
                <div className="active-deck-title">
                    {translate('ActiveDeck')}
                </div>
                <select
                    className="active-deck-list"
                    value={deckIndex}
                    tabIndex={1}
                    onChange={(evt) => {
                        let index = parseInt(evt.target.value);
                        if (index > decks.length) {
                            index = decks.length - 1;
                        }

                        this.props.globalState.storeSettings('ActiveDeck', decks[index].UniqueID);

                        this.forceUpdate();
                    }}
                >
                    {decks.map((d, i) => {
                        return (
                            <option key={d.UniqueID} value={i}>
                                {d.Name}
                            </option>
                        );
                    })}
                </select>
                <div className="active-deck-target">
                    {translate('Target')}
                </div>
                <select
                    className="active-deck-target-list"
                    value={targetIndex}
                    tabIndex={1}
                    onChange={(evt) => {
                        let index = parseInt(evt.target.value);
                        if (index > targets.length) {
                            index = targets.length - 1;
                        }

                        this.props.globalState.storeNumberSettings('ActiveDeckTarget', index);

                        this.forceUpdate();
                    }}
                >
                    {targets.map((d, i) => {
                        return (
                            <option key={d} value={i}>
                                {d}
                            </option>
                        );
                    })}
                </select>
                {
                    deckIndex > -1 && decks[deckIndex].backgroundUrl &&
                    <img className='active-deck-image' src={decks[deckIndex].backgroundUrl} />
                }
            </div>
        );
    }
}
