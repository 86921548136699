import * as React from 'react';
import { Collection } from '../entities/collection';
import { GlobalState } from '../globalState';
import { Button } from './button';
import { Checkbox } from './checkbox';
import { LabelledTexbox } from './labelledTextbox';

require('../scss/controls/customDatabase.scss');

interface ICustomDatabaseProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class CustomDatabase extends React.Component<
    ICustomDatabaseProps,
    { isVisible: boolean; filter: string }
> {
    private _disabledEntityCache: string[] = [];
    private _allEntityNames: string[] = [];
    private _setMode: boolean;

    constructor(props: ICustomDatabaseProps) {
        super(props);

        this.state = { isVisible: false, filter: '' };
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onCustomDatabaseRequired.add((setMode) => {
            this._setMode = setMode;
            const disabledNames = GlobalState.LoadSettings(this._setMode ? 'DisabledSetNames' : 'DisabledBlockNames', '');

            if (disabledNames) {
                this._disabledEntityCache = disabledNames.split('*');
            } else {
                this._disabledEntityCache = [];
            }

            this._allEntityNames = this._setMode ? Collection.Sets.map(e => e.name) : Collection.Blocks.map(b => b.name);

            for (const disabledName of this._disabledEntityCache) {
                if (this._allEntityNames.indexOf(disabledName) === -1) {
                    this._allEntityNames.push(disabledName);
                }
            }

            this._allEntityNames = this._allEntityNames.sort((a, b) => a.localeCompare(b));

            this.setState({ isVisible: true });
            this.props.globalState.onBlurRequired.notifyObservers(true);
        });
    }

    onConfirmation(value: boolean) {
        this.setState({ isVisible: false });

        if (value) {
            this.props.globalState.storeSettings(this._setMode ? 'DisabledSetNames' : 'DisabledBlockNames', this._disabledEntityCache.join('*'));
            location.reload();
        }

        this.props.globalState.onBlurRequired.notifyObservers(false);
    }

    blurFilter(evt: React.KeyboardEvent<HTMLInputElement>) {
        if (evt.which === 13) {
            (evt.nativeEvent.srcElement! as HTMLInputElement).blur();
        }
    }

    render() {
        if (!this.state.isVisible) {
            return null;
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);
        const filter = this.state.filter.toLowerCase();

        return (
            <div className="custom-database-container">
                <div className="custom-database-background"></div>
                <div className={'custom-database' + (this.props.darkMode ? ' dark' : '')}>
                    <div className="custom-database-title">{translate(this._setMode ? 'UpdateSetDatabaseDesc' : 'UpdateBlockDatabaseDesc')}</div>
                    <div className="custom-database-filter">
                        <LabelledTexbox
                            label={translate('Name')}
                            onlyInput={true}
                            globalState={this.props.globalState}
                            onKeyPress={(evt) => this.blurFilter(evt)}
                            onChange={(value) => {
                                this.setState({ filter: value });
                            }}
                            value={this.state.filter}
                            placeholder={translate('filter:')}
                        />
                    </div>
                    <div className="custom-database-option">
                        <div className="custom-database-deselect">
                            <Button
                                tabIndex={2}
                                className="custom-database-button"
                                globalState={this.props.globalState}
                                onClicked={() => {
                                    this._disabledEntityCache = [];
                                    this.forceUpdate();
                                }}
                            >
                                {translate('All')}
                            </Button>
                        </div>
                        <div className="custom-database-select">
                            <Button
                                tabIndex={2}
                                className="custom-database-button"
                                globalState={this.props.globalState}
                                onClicked={() => {
                                    this._disabledEntityCache = [...this._allEntityNames];
                                    this.forceUpdate();
                                }}
                            >
                                {translate('None')}
                            </Button>
                        </div>
                    </div>
                    <div className="custom-database-container">
                        {this._allEntityNames.filter(s => !filter || s.toLocaleLowerCase().indexOf(filter) !== -1).map((b, i) => {
                            return (
                                <div className="blockLine" key={i}>
                                    <div className="blockLine-label">{b}</div>
                                    <div className="blockLine-checkbox">
                                        <Checkbox
                                            globalState={this.props.globalState}
                                            isChecked={this._disabledEntityCache.indexOf(b) === -1}
                                            onCheckedChanged={(value) => {
                                                const index = this._disabledEntityCache.indexOf(b);

                                                if (!value) {
                                                    if (index === -1) {
                                                        this._disabledEntityCache.push(b);
                                                    }
                                                } else if (index !== -1) {
                                                    this._disabledEntityCache.splice(index, 1);
                                                }

                                                this.forceUpdate();
                                            }}
                                        ></Checkbox>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="custom-database-accept">
                        <Button
                            tabIndex={3}
                            className="custom-database-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(true)}
                        >
                            {translate('OK')}
                        </Button>
                    </div>
                    <div className="custom-database-cancel">
                        <Button
                            tabIndex={4}
                            className="custom-database-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(false)}
                        >
                            {translate('Cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
