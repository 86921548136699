import * as React from 'react';
import { GlobalState, SelectionData, SearchData } from 'globalState';
import { Nullable } from 'tools/nullable';
import { Observer } from 'tools/observable';
import { AboutPage } from './aboutPage';
import { SettingsPage } from './settingsPage';
import { BlocksPage } from './blocksPage';
import { SetsPage } from './setsPage';
import { CardsPage } from './cardsPage';
import { SearchPage } from './searchPage';
import { CardPage } from './cardPage';
import { StatisticsPage } from './statisticsPage';
import { Collection } from '../entities/collection';
import { AlbumViewPage } from './albumViewPage';
import { PricesPage } from './pricesPage';
import { LifeCountersPage } from './lifeCountersPage';
import { DecksPage } from './decksPage';
import { DeckStatisticsPage } from './deckStatistics';
import { SimulatorPage } from './simulatorPage';
import { CardScannerPage } from './cardScannerPage';
import { ChecklistPage } from './checklistPage';
import { ListPage } from './listPage';
import { LoreExplorerPage } from './loreExplorerPage';
import { HelpPage } from './helpPage';

interface IPageDispatcherProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class PageDispatcher extends React.Component<
    IPageDispatcherProps,
    {
        selectedMenuIndex: number;
        uniqueId?: number;
        uniqueString?: string;
        searchData?: SearchData;
        cardIds?: number[];
    }
> {
    private onSelectedMenuIndexObserver: Nullable<Observer<SelectionData>>;
    private currentIndex: number;

    constructor(props: IPageDispatcherProps) {
        super(props);

        this.state = { selectedMenuIndex: this.props.globalState.selectedMenuIndex };

        props.globalState.onForcePageRefresh.add(() => {
            this.forceUpdate();
            Collection.Cards.forEach((c) => {
                this.props.globalState.onCardUpdated.notifyObservers(c);
            });
        });
    }

    componentDidMount() {
        const page = document.getElementsByClassName('page')[0];

        if (page) {
            page.classList.add('enterAnimation');
        }
    }

    UNSAFE_componentWillMount() {
        this.onSelectedMenuIndexObserver = this.props.globalState.onSelectedMenuIndexChanged.add((selection) => {
            if (selection.index !== -3) {
                // Search
                if (
                    selection.index === this.currentIndex &&
                    selection.uniqueID === this.state.uniqueId &&
                    selection.cardIds === this.state.cardIds
                ) {
                    return;
                }
            }

            const page = document.getElementsByClassName('page')[0];

            if (page) {
                setTimeout(() => {
                    this.setState({
                        selectedMenuIndex: selection.index,
                        uniqueString: selection.uniqueString,
                        uniqueId: selection.uniqueID,
                        searchData: selection.searchData,
                        cardIds: selection.cardIds
                    });
                    const page = document.getElementsByClassName('page')[0];
                    if (page) {
                        page.classList.remove('exitAnimation');
                        page.classList.add('enterAnimation');

                        setTimeout(() => {
                            page.classList.remove('enterAnimation');
                        }, 150);
                    }
                }, 150);
                page.classList.remove('enterAnimation');
                page.classList.add('exitAnimation');
            } else {
                this.setState({
                    selectedMenuIndex: selection.index,
                    uniqueString: selection.uniqueString,
                    uniqueId: selection.uniqueID,
                    searchData: selection.searchData,
                    cardIds: selection.cardIds
                });
            }
        });
    }

    componentWillUnmount() {
        this.props.globalState.onSelectedMenuIndexChanged.remove(this.onSelectedMenuIndexObserver);
    }

    render() {
        return <div className="pageHost">{this.renderContent()}</div>;
    }

    renderContent() {
        this.currentIndex = this.state.selectedMenuIndex;

        switch (this.state.selectedMenuIndex) {
            case -8:
                const deckToSimulate = Collection.Decks.filter((d) => d.UniqueID === this.state.uniqueString!)[0];
                return <SimulatorPage globalState={this.props.globalState} deck={deckToSimulate} />;
            case -7:
                const deckToAnalyze = Collection.Decks.filter((d) => d.UniqueID === this.state.uniqueString!)[0];
                return <DeckStatisticsPage globalState={this.props.globalState} deck={deckToAnalyze} />;
            case -6:
                const deck = Collection.Decks.filter((d) => d.UniqueID === this.state.uniqueString!)[0];
                return <CardsPage globalState={this.props.globalState} deck={deck} />;
            case -5:
                return (
                    <PricesPage
                        globalState={this.props.globalState}
                        selector={this.state.uniqueString}
                        id={this.state.uniqueId}
                    />
                );
            case -4:
                return (
                    <CardPage
                        globalState={this.props.globalState}
                        cardIds={this.state.cardIds!}
                        selectedIndex={this.state.uniqueId!}
                    />
                );
            case -3:
                return <CardsPage globalState={this.props.globalState} searchData={this.state.searchData} />;
            case -2:
                const expansion = Collection.GetExpansionById(this.state.uniqueId!)!;
                return <CardsPage globalState={this.props.globalState} parentExpansion={expansion} />;
            case -1:
                const block = Collection.GetBlockById(this.state.uniqueId!);
                return <SetsPage globalState={this.props.globalState} parentBlock={block} />;
            case 0:
                return <BlocksPage globalState={this.props.globalState} />;
            case 1:
                return <CardsPage globalState={this.props.globalState} />;
            case 2:
                return <SearchPage globalState={this.props.globalState} darkMode={this.props.darkMode} />;
            case 3:
                return <AboutPage globalState={this.props.globalState} />;
            case 4:
                return <SettingsPage globalState={this.props.globalState} />;
            case 6:
                return <StatisticsPage globalState={this.props.globalState} />;
            case 7:
                if (this.state.uniqueId) {
                    const expansionForAlbum = Collection.GetExpansionById(this.state.uniqueId!)!;
                    return <AlbumViewPage globalState={this.props.globalState} set={expansionForAlbum} />;
                }
                return <AlbumViewPage globalState={this.props.globalState} searchData={this.state.searchData!} />;
            case 9:
                return <LifeCountersPage globalState={this.props.globalState} />;
            case 10:
                return <DecksPage globalState={this.props.globalState} />;
            case 11:
                return <CardScannerPage globalState={this.props.globalState} />;
            case 12:
                return <ChecklistPage globalState={this.props.globalState} />;
            case 13:
                return <ListPage globalState={this.props.globalState} />;
            case 14:
                return <LoreExplorerPage globalState={this.props.globalState}
                    selectedIndex={this.state.uniqueId!}
                    cardIds={this.state.cardIds!} />;
            case 15:
                return <HelpPage globalState={this.props.globalState} />;
        }

        return null;
    }
}
