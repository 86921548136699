import * as React from 'react';
import { GlobalState } from '../globalState';
import { Nullable } from '../tools/nullable';
import { ICard } from '../entities/ICard';
import { ContextMenuItem } from './contextMenuItem';
import {
    faCheck,
    faUndo,
    faBox,
    faShoppingBasket,
    faShoppingCart,
    faCheckDouble,
    faPlus,
    faTags
} from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import {
    faTreasureChest,
    faMinus,
    faTrash,
    faExchange,
    faInfo,
    faHelmetBattle,
    faArrowRight,
    faGem,
    faCrown,
    faUnicorn,
    faIcons,
    faInfoSquare
} from '@fortawesome/pro-solid-svg-icons';
import { faSun } from '@fortawesome/free-regular-svg-icons';
import { CardFormat } from '../entities/cardFormat';
import { Deck } from '../entities/deck';
import { DeckCard } from '../entities/deckCard';

interface IContextMenuProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class ContextMenu extends React.Component<
    IContextMenuProps,
    {
        visible: boolean;
        left: number;
        top: number;
        card: Nullable<ICard>;
        deck?: Deck;
    }
> {
    constructor(props: IContextMenuProps) {
        super(props);

        this.state = { visible: false, left: 0, top: 0, card: null };
    }

    UNSAFE_componentWillMount() {
        document.addEventListener('click', () => {
            if (!this.state.visible) {
                return;
            }
            this.setState({ visible: false });
        });

        this.props.globalState.onContextMenuRequired.add((options) => {
            this.setState({
                visible: true,
                left: options.left,
                top: options.top,
                card: options.card,
                deck: options.deck
            });
        });

        this.props.globalState.onContextMenuCommand.add(() => {
            this.setState({
                visible: false
            });
        });
    }

    componentDidUpdate() {
        const contextMenu = document.querySelector('.contextmenu') as HTMLDivElement;

        if (!contextMenu) {
            return;
        }
        contextMenu.style.transition = '';
        contextMenu.style.transform = 'translateY(25px)';
        contextMenu.style.opacity = '0';

        setTimeout(() => {
            contextMenu.style.transition = 'transform 150ms, opacity 150ms';
            contextMenu.style.transform = 'translateY(0px)';
            contextMenu.style.opacity = '1';
        });
    }

    render() {
        const card = this.state.card;
        const translate = this.props.globalState.translate.bind(this.props.globalState);

        return (
            this.state.visible &&
            card !== null && (
                <>
                    <div className="contextmenu-click-blocker" onContextMenu={(evt: React.MouseEvent<HTMLDivElement>) => {
                        evt.preventDefault();
                        this.setState({ visible: false });
                    }}/>
                    <div
                        className={'contextmenu' + (this.props.darkMode ? ' dark' : '')}
                        style={{
                            transform: 'translateY(25px)',
                            transition: '',
                            opacity: 0,
                            top: this.state.top + 'px',
                            left: this.state.left + 'px'
                        }}
                    >
                        {!card.isSelected && (
                            <ContextMenuItem
                                globalState={this.props.globalState}
                                onClicked={() => {
                                    this.props.globalState.onContextMenuCommand.notifyObservers({
                                        card: card,
                                        command: 'select'
                                    });
                                }}
                                label={translate('Select')}
                                icon={faCheck}
                            />
                        )}
                        {card.isSelected && (
                            <ContextMenuItem
                                globalState={this.props.globalState}
                                onClicked={() => {
                                    this.props.globalState.onContextMenuCommand.notifyObservers({
                                        card: card,
                                        command: 'unselect'
                                    });
                                }}
                                label={translate('Unselect')}
                                icon={faUndo as Icon}
                            />
                        )}
                        {
                            <ContextMenuItem
                                globalState={this.props.globalState}
                                onClicked={() => {
                                    this.props.globalState.onContextMenuCommand.notifyObservers({
                                        card: card,
                                        command: 'selectAll'
                                    });
                                }}
                                label={translate('SelectAll')}
                                icon={faCheckDouble}
                            />
                        }
                        <ContextMenuItem globalState={this.props.globalState} />
                        <ContextMenuItem
                            globalState={this.props.globalState}
                            onClicked={() => {
                                this.props.globalState.onContextMenuCommand.notifyObservers({
                                    card: card,
                                    command: 'collection'
                                });
                            }}
                            label={translate('Collection_')}
                            icon={faTreasureChest as Icon}
                        />
                        <ContextMenuItem
                            globalState={this.props.globalState}
                            onClicked={() => {
                                this.props.globalState.onContextMenuCommand.notifyObservers({
                                    card: card,
                                    command: 'addToCollection'
                                });
                            }}
                            label={translate('AddToCollection')}
                            icon={faPlus as Icon}
                        />
                        <ContextMenuItem
                            globalState={this.props.globalState}
                            onClicked={() => {
                                this.props.globalState.onContextMenuCommand.notifyObservers({
                                    card: card,
                                    command: 'removeFromCollection'
                                });
                            }}
                            label={translate('RemoveFromCollection')}
                            icon={faMinus as Icon}
                        />
                        {!!this.state.deck && (
                            <ContextMenuItem
                                globalState={this.props.globalState}
                                onClicked={() => {
                                    this.props.globalState.onContextMenuCommand.notifyObservers({
                                        card: card,
                                        command: 'changeDeckProxyState',
                                        option: !((card as DeckCard).isDeckProxy)
                                    });
                                }}
                                label={(card as DeckCard).isDeckProxy ? translate('UnflagAsProxy') : translate('FlagAsProxy')}
                                icon={faUnicorn as Icon}
                            />
                        )}
                        {!this.state.deck && (
                            <ContextMenuItem
                                globalState={this.props.globalState}
                                onClicked={() => {
                                    this.props.globalState.onContextMenuCommand.notifyObservers({
                                        card: card,
                                        command: 'changeProxyState',
                                        option: !card.isProxy
                                    });
                                }}
                                label={card.isProxy ? translate('UnflagAsProxy') : translate('FlagAsProxy')}
                                icon={faUnicorn as Icon}
                            />
                        )}
                        {!this.state.deck && (
                            <ContextMenuItem
                                globalState={this.props.globalState}
                                onClicked={() => {
                                    this.props.globalState.onContextMenuCommand.notifyObservers({
                                        card: card,
                                        command: 'changeIcon'
                                    });
                                }}
                                label={translate('CustomIcon')}
                                icon={faIcons as Icon}
                            />
                        )}
                        {!this.state.deck && card.canBeFoil && (
                            <ContextMenuItem
                                globalState={this.props.globalState}
                                onClicked={() => {
                                    this.props.globalState.onContextMenuCommand.notifyObservers({
                                        card: card,
                                        command: 'foil'
                                    });
                                }}
                                label={translate('Foils')}
                                icon={faSun as Icon}
                            />
                        )}
                        {!this.state.deck && card.canBeSpecialFoil && (
                            <ContextMenuItem
                                globalState={this.props.globalState}
                                onClicked={() => {
                                    this.props.globalState.onContextMenuCommand.notifyObservers({
                                        card: card,
                                        command: 'specialFoil'
                                    });
                                }}
                                label={translate('SpecialFoils')}
                                icon={faGem as Icon}
                            />
                        )}
                        {!this.state.deck &&
                            <ContextMenuItem
                                globalState={this.props.globalState}
                                onClicked={() => {
                                    this.props.globalState.onContextMenuCommand.notifyObservers({
                                        card: card,
                                        command: 'specifics'
                                    });
                                }}
                                label={translate('Characteristics')}
                                icon={faInfoSquare as Icon}
                            />
                        }
                        <ContextMenuItem
                            globalState={this.props.globalState}
                            onClicked={() => {
                                this.props.globalState.onContextMenuCommand.notifyObservers({
                                    card: card,
                                    command: 'tags'
                                });
                            }}
                            label={translate('Tags')}
                            icon={faTags as Icon}
                        />
                        <ContextMenuItem globalState={this.props.globalState} />
                        {!!this.state.deck &&
                            card.isCommander &&
                            (this.state.deck.format === CardFormat.BrawlIndex ||
                                this.state.deck.format === CardFormat.CommanderIndex ||
                                this.state.deck.format === CardFormat.DuelCommanderIndex ||
                                !this.state.deck.format) && (
                                <ContextMenuItem
                                    globalState={this.props.globalState}
                                    onClicked={() => {
                                        this.props.globalState.onContextMenuCommand.notifyObservers({
                                            card: card,
                                            command: 'commander'
                                        });
                                    }}
                                    label={
                                        !card.isDeckCommander
                                            ? this.props.globalState.translate('SetAsCommander')
                                            : this.props.globalState.translate('RemoveCommander')
                                    }
                                    icon={faHelmetBattle as Icon}
                                />
                            )}
                        {!!this.state.deck && (
                            <ContextMenuItem
                                globalState={this.props.globalState}
                                onClicked={() => {
                                    this.props.globalState.onContextMenuCommand.notifyObservers({
                                        card: card,
                                        command: 'moveInsideDeck'
                                    });
                                }}
                                label={translate('Move')}
                                icon={faArrowRight as Icon}
                            />
                        )}
                        {!!this.state.deck && card.reprints.length > 1 && (
                            <ContextMenuItem
                                globalState={this.props.globalState}
                                onClicked={() => {
                                    this.props.globalState.onContextMenuCommand.notifyObservers({
                                        card: card,
                                        command: 'swapReprint'
                                    });
                                }}
                                label={translate('SwapReprints')}
                                icon={faExchange as Icon}
                            />
                        )}
                        <ContextMenuItem
                            globalState={this.props.globalState}
                            onClicked={() => {
                                this.props.globalState.onContextMenuCommand.notifyObservers({
                                    card: card,
                                    command: 'deck'
                                });
                            }}
                            label={translate('Decks_')}
                            icon={faBox as Icon}
                        />
                        {!!this.state.deck && (
                            <ContextMenuItem
                                globalState={this.props.globalState}
                                onClicked={() => {
                                    this.props.globalState.onContextMenuCommand.notifyObservers({
                                        card: card,
                                        command: 'quickRemoveFromDeck'
                                    });
                                }}
                                label={translate('QuickRemoveFromDeck')}
                                icon={faTrash as Icon}
                            />
                        )}
                        {card.isCommander && <ContextMenuItem globalState={this.props.globalState} />}
                        {card.isCommander && (
                            <ContextMenuItem
                                globalState={this.props.globalState}
                                onClicked={() => {
                                    this.props.globalState.onContextMenuCommand.notifyObservers({
                                        card: card,
                                        command: 'edhrec'
                                    });
                                }}
                                label="EDHRec"
                                icon={faInfo as Icon}
                            />
                        )}
                        <ContextMenuItem globalState={this.props.globalState} />
                        <ContextMenuItem
                            globalState={this.props.globalState}
                            onClicked={() => {
                                this.props.globalState.onContextMenuCommand.notifyObservers({
                                    card: card,
                                    command: 'ordered'
                                });
                            }}
                            label={translate('SwitchOrderedFlag')}
                            icon={faShoppingBasket as Icon}
                        />
                        <ContextMenuItem
                            globalState={this.props.globalState}
                            onClicked={() => {
                                this.props.globalState.onContextMenuCommand.notifyObservers({
                                    card: card,
                                    command: 'ebay'
                                });
                            }}
                            label="Ebay"
                            icon={faShoppingCart as Icon}
                        />
                        <ContextMenuItem
                            globalState={this.props.globalState}
                            onClicked={() => {
                                this.props.globalState.onContextMenuCommand.notifyObservers({
                                    card: card,
                                    command: 'mcm'
                                });
                            }}
                            label="CardMarket"
                            icon={faShoppingCart as Icon}
                        />
                        <ContextMenuItem
                            globalState={this.props.globalState}
                            onClicked={() => {
                                this.props.globalState.onContextMenuCommand.notifyObservers({
                                    card: card,
                                    command: 'tcg'
                                });
                            }}
                            label="TCGPlayer"
                            icon={faShoppingCart as Icon}
                        />
                        <ContextMenuItem
                            globalState={this.props.globalState}
                            onClicked={() => {
                                this.props.globalState.onContextMenuCommand.notifyObservers({
                                    card: card,
                                    command: 'scryfall'
                                });
                            }}
                            label="Scryfall"
                            icon={faCrown as Icon}
                        />
                    </div>
                </>
            )
        );
    }
}
