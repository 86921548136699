import { BinaryReader } from '../tools/binaryReader';

export class CardSize {
    public isPlane: boolean;
    public isScheme: boolean;

    public static Deserialize(reader: BinaryReader): CardSize {
        const size = new CardSize();

        size.isPlane = reader.readBoolean();
        size.isScheme = reader.readBoolean();

        return size;
    }
}
