import * as React from 'react';
import { GlobalState } from '../globalState';
import { Button } from './button';
import { ICard } from '../entities/ICard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Collection } from '../entities/collection';
import { Nullable } from '../tools/nullable';

interface ITagDialogProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class TagDialog extends React.Component<
    ITagDialogProps,
    {
        isVisible: boolean;
        creationMode: boolean;
    }
> {
    private _cards: Nullable<ICard[]>;
    private _commonTags: string[];
    private _cachedCommonTags: string[];
    private _blockShortcuts = false;

    constructor(props: ITagDialogProps) {
        super(props);

        this.state = { isVisible: false, creationMode: false };
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onTagRequired.add((cards) => {
            this.setState({
                isVisible: true
            });

            if (cards && cards.length && (cards[0] as any).tags) {
                this._cards = cards as ICard[];
                this._commonTags = this._cards[0].tags ? this._cards[0].tags.slice(0) : [];

                for (let index = 1; index < this._cards.length; index++) {
                    const card = this._cards[index];
                    const copy = this._commonTags.slice(0);

                    for (const tag of copy) {
                        if (card.tags.indexOf(tag) === -1) {
                            const sourceIndex = this._commonTags.indexOf(tag);
                            this._commonTags.splice(sourceIndex, 1);
                        }
                    }
                }
            } else {
                this._cards = null;
                const tags = (cards as string[]) || [];
                this._commonTags = tags.slice(0);
            }

            this._cachedCommonTags = this._commonTags.slice(0);
            this._commonTags = this._commonTags.sort();

            this.props.globalState.onBlurRequired.notifyObservers(true);
        });

        document.addEventListener('keydown', (evt) => {
            if (this._blockShortcuts) {
                return;
            }

            if (evt.keyCode === 13 && this.state.isVisible && document.activeElement === document.body) {
                this.onConfirmation(true);
                return;
            }
            if (evt.keyCode === 27 && this.state.isVisible) {
                this.onConfirmation(false);
            }
        });
    }

    onConfirmation(value: boolean) {
        this.setState({ isVisible: false });
        this.props.globalState.onBlurRequired.notifyObservers(false);

        if (value) {
            // Delete
            for (const tag of this._cachedCommonTags) {
                const index = this._commonTags.indexOf(tag);

                if (index === -1) {
                    if (this._cards) {
                        this._cards.forEach((c) => {
                            const tagIndex = c.tags.indexOf(tag);
                            if (tagIndex > -1) {
                                c.tags.splice(tagIndex, 1);
                                c.updateTagsInStorage();
                            }
                        });
                    }
                }
            }

            // New
            for (const tag of this._commonTags) {
                const index = this._cachedCommonTags.indexOf(tag);

                if (index === -1) {
                    if (this._cards) {
                        this._cards.forEach((c) => {
                            if (c.tags.indexOf(tag) !== -1) {
                                return;
                            }
                            c.tags.push(tag);
                            c.tags = c.tags.sort();
                        });
                    }
                }
            }
        }
        this.props.globalState.onTagUpdated.notifyObservers({ tags: this._commonTags, cancelled: !value });
    }

    onAdd() {
        this._blockShortcuts = true;
        const cardsWithTags = Collection.Cards.filter((c) => c.tags);
        let suggestions: string[] = [];

        for (const card of cardsWithTags) {
            suggestions.push(...card.tags);
        }

        suggestions = [...new Set(suggestions)].filter((s) => this._commonTags.indexOf(s) === -1);

        this.props.globalState
            .showQuestionDialog(this.props.globalState.translate('NewTag'), '', false, suggestions)
            .then((value) => {
                setTimeout(() => (this._blockShortcuts = false));
                if (value && this._commonTags.indexOf(value.toLowerCase()) === -1) {
                    this._commonTags.push(value.toLowerCase());
                    this._commonTags = this._commonTags.sort();

                    this.forceUpdate();
                }
            });
    }

    onDelete(tag: string) {
        const index = this._commonTags.indexOf(tag);

        if (index === -1) {
            return;
        }

        this._commonTags.splice(index, 1);
        this.forceUpdate();
    }

    render() {
        if (!this.state.isVisible) {
            return null;
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);

        return (
            <div className="tag-dialog-container">
                <div className="tag-dialog-background"></div>
                <div className={'tag-dialog' + (this.props.darkMode ? ' dark' : '')}>
                    <div className="tag-dialog-title">{translate('Tags')}</div>
                    <div className="tag-dialog-tags">
                        {this._commonTags.map((t) => {
                            return (
                                <div key={t} className="tag-dialog-tag" title={t}>
                                    <div className="tag-dialog-tag-label">{t}</div>
                                    <Button
                                        className={'tag-dialog-button delete' + (this.props.darkMode ? '' : ' light')}
                                        globalState={this.props.globalState}
                                        onClicked={() => this.onDelete(t)}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                    <div className="tag-dialog-new">
                        <Button
                            className={'tag-dialog-button add' + (this.props.darkMode ? '' : ' light')}
                            tabIndex={3}
                            globalState={this.props.globalState}
                            onClicked={() => this.onAdd()}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    </div>
                    <div className="tag-dialog-accept">
                        <Button
                            className="tag-dialog-button"
                            tabIndex={4}
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(true)}
                        >
                            {translate('OK')}
                        </Button>
                    </div>
                    <div className="tag-dialog-cancel">
                        <Button
                            className="tag-dialog-button"
                            tabIndex={5}
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(false)}
                        >
                            {translate('Cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
