import * as React from 'react';
import { Collection } from '../entities/collection';
import { GlobalState } from '../globalState';
import { Button } from './button';
import { Checkbox } from './checkbox';
import { LabelledTexbox } from './labelledTextbox';

require('../scss/controls/hiddenCardList.scss');

interface IHiddenCardListProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class HiddenCardList extends React.Component<
    IHiddenCardListProps,
    { isVisible: boolean; filter: string }
> {
    private _activatedCards: number[] = [];

    constructor(props: IHiddenCardListProps) {
        super(props);

        this.state = { isVisible: false, filter: '' };
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onHiddenCardListRequired.add(() => {
            this.setState({ isVisible: true });
            this.props.globalState.onBlurRequired.notifyObservers(true);
        });
    }

    onConfirmation(value: boolean) {
        this.setState({ isVisible: false });

        if (value && this._activatedCards.length > 0) {
            for (const id of this._activatedCards) {
                const idIndex = Collection.ExcludedCardIds.indexOf(id);
                if (idIndex !== -1) {
                    Collection.ExcludedCardIds.splice(idIndex, 1);
                }

                delete Collection.ExcludedCardNames[id];
            }
            this.props.globalState.onWaitRingRequired.notifyObservers(true);
            Collection.StoreExcludedCardIds();
            Collection.ForceSaveUserSettings();
            Collection.OnUserSettingsSaveDoneObservable.addOnce(() => {
                location.reload();
            });
        }

        this.props.globalState.onBlurRequired.notifyObservers(false);
    }

    blurFilter(evt: React.KeyboardEvent<HTMLInputElement>) {
        if (evt.which === 13) {
            (evt.nativeEvent.srcElement! as HTMLInputElement).blur();
        }
    }

    render() {
        if (!this.state.isVisible) {
            return null;
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);
        const filter = this.state.filter.toLowerCase();

        const cardNames: {id: string, name: string}[] = [];

        // eslint-disable-next-line guard-for-in
        for (const key in Collection.ExcludedCardNames) {
            cardNames.push({ id: key, name: Collection.ExcludedCardNames[key] });
        }

        cardNames.sort();

        return (
            <div className="hidden-card-list-container">
                <div className="hidden-card-list-background"></div>
                <div className={'hidden-card-list' + (this.props.darkMode ? ' dark' : '')}>
                    <div className="hidden-card-list-title">{translate('HiddenCardList')}</div>
                    <div className="hidden-card-list-filter">
                        <LabelledTexbox
                            label={translate('Name')}
                            onlyInput={true}
                            globalState={this.props.globalState}
                            onKeyPress={(evt) => this.blurFilter(evt)}
                            onChange={(value) => {
                                this.setState({ filter: value });
                            }}
                            value={this.state.filter}
                            placeholder={translate('filter:')}
                        />
                    </div>
                    <div className="hidden-card-list-container">
                        {cardNames.filter(s => !filter || s.name.toLocaleLowerCase().indexOf(filter) !== -1).map((b, i) => {
                            const cardId = parseInt(b.id);
                            return (
                                <div className="blockLine" key={i}>
                                    <div className="blockLine-label">{b.name}</div>
                                    <div className="blockLine-checkbox">
                                        <Checkbox
                                            globalState={this.props.globalState}
                                            isChecked={this._activatedCards.indexOf(cardId) !== -1}
                                            onCheckedChanged={(value) => {
                                                const index = this._activatedCards.indexOf(cardId);

                                                if (value) {
                                                    if (index === -1) {
                                                        this._activatedCards.push(cardId);
                                                    }
                                                } else if (index !== -1) {
                                                    this._activatedCards.splice(index, 1);
                                                }

                                                this.forceUpdate();
                                            }}
                                        ></Checkbox>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="hidden-card-list-accept">
                        <Button
                            tabIndex={2}
                            className="hidden-card-list-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(true)}
                        >
                            {translate('OK')}
                        </Button>
                    </div>
                    <div className="hidden-card-list-cancel">
                        <Button
                            tabIndex={3}
                            className="hidden-card-list-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(false)}
                        >
                            {translate('Cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
