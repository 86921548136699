import * as React from 'react';
import { GlobalState } from '../globalState';

interface IMultiSelectProps {
    globalState: GlobalState;
    label: string;
    values: any[];
    elements: { label: string; image: string; id: any }[];
    onChange?: (values: any[]) => void;
}

export class MultiSelect extends React.Component<IMultiSelectProps, { values: any[] }> {
    constructor(props: IMultiSelectProps) {
        super(props);

        this.state = { values: this.props.values };
    }

    shouldComponentUpdate(nextProps: IMultiSelectProps, nextState: { values: any[] }) {
        if (this.props.values !== nextProps.values) {
            nextState.values = nextProps.values;
        }

        return true;
    }

    select(id: number) {
        const index = this.state.values.indexOf(id);

        if (index >= 0) {
            this.state.values.splice(index, 1);
        } else {
            this.state.values.push(id);
        }
        this.setState({ values: this.state.values });

        if (this.props.onChange) {
            this.props.onChange(this.state.values);
        }
    }

    render() {
        return (
            <div className="multiselect-element">
                <div className="multiselect-label">{this.props.label}</div>
                <div className="multiselect-list">
                    {this.props.elements.map((element) => {
                        return (
                            <div
                                key={element.label + element.id}
                                className="multiselect-entry"
                                onClick={() => this.select(element.id)}
                            >
                                <img src={element.image} className="multiselect-entry-img" />
                                <div className="multiselect-entry-label">{element.label}</div>
                                <div
                                    className={
                                        'multiselect-selection' +
                                        (this.props.values.indexOf(element.id) === -1 ? ' hidden' : '')
                                    }
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
