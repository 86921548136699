import * as React from 'react';
import { GlobalState } from '../globalState';

interface ICardTextMultiLineProps {
    globalState: GlobalState;
    label: string;
    value: string;
    italics?: boolean;
}

export class CardTextMultiLine extends React.Component<ICardTextMultiLineProps, Record<string, unknown>> {
    constructor(props: ICardTextMultiLineProps) {
        super(props);
    }

    renderText(): string {
        const regex = /\(([^/(]+?)\)/g;
        const result: string[] = [];
        let value = this.props.value;
        let matches = regex.exec(this.props.value);
        if (matches) {
            while (matches) {
                let symbol: string = matches[1];
                const index = value.indexOf(matches[0]);
                const text = value.substring(0, index).replace(/(?:\r\n|\r|\n)/g, '<br>');
                value = value.substring(index + matches[0].length);

                result.push(`<span class="card-element-multiline-text">${text}</span>`);

                if (symbol.length < 4) {
                    switch (symbol) {
                        case 'B':
                            symbol = 'Black';
                            break;
                        case 'U':
                            symbol = 'Blue';
                            break;
                        case 'G':
                            symbol = 'Green';
                            break;
                        case 'W':
                            symbol = 'White';
                            break;
                        case 'R':
                            symbol = 'Red';
                            break;
                    }

                    result.push(`<img src="/images/symbols/${symbol}.png" class="card-element-multiline-mana" />`);
                } else {
                    result.push(
                        `<span class="card-element-multiline-text">${matches[0].replace(
                            /(?:\r\n|\r|\n)/g,
                            '<br>'
                        )}</span>`
                    );
                }

                matches = regex.exec(this.props.value);
            }
            result.push(`<span class="card-element-multiline-text">${value}</div>`);
            return result.join('');
        }
        return `<span class="card-element-multiline-text">${this.props.value.replace(/(?:\r\n|\r|\n)/g, '<br>')}</div>`;
    }

    render() {
        return (
            <div className="card-element-multiline">
                <div className="card-element-multiline-label">{this.props.label}</div>
                <div
                    className={'card-element-multiline-value' + (this.props.italics ? ' italics' : '')}
                    dangerouslySetInnerHTML={{
                        __html: this.renderText()
                    }}
                />
                <div className="card-element-multiline-border"></div>
            </div>
        );
    }
}
