import * as React from 'react';
import { GlobalState } from '../globalState';
import { Button } from './button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomIcon } from '../entities/customIcon';

require('../scss/controls/cardIconDialog.scss');

interface ICardIconDialogProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class CardIconDialog extends React.Component<
    ICardIconDialogProps,
    { isVisible: boolean, activeIcon: number }
> {
    constructor(props: ICardIconDialogProps) {
        super(props);

        this.state = { isVisible: false, activeIcon: 0};
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onCardIconDialogRequired.add((data) => {

            this.setState({
                isVisible: true,
                activeIcon: data.activeIcon
            });

            this.props.globalState.onBlurRequired.notifyObservers(true);
        });

        document.addEventListener('keydown', (evt) => {
            if (evt.keyCode === 27 && this.state.isVisible) {
                this.onConfirmation(false);
                return;
            }
        });
    }

    onConfirmation(value: boolean) {
        if (value) {
            this.props.globalState.onCardIconPicked.notifyObservers({ icon: this.state.activeIcon });
        } else {
            this.props.globalState.onCardIconPicked.notifyObservers({ icon: undefined });
        }

        this.setState({ isVisible: false });

        this.props.globalState.onBlurRequired.notifyObservers(false);
    }

    render() {
        if (!this.state.isVisible) {
            return null;
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);

        return (
            <div className="card-icon-dialog-container">
                <div className="card-icon-dialog-background"></div>
                <div className={'card-icon-dialog' + (this.props.darkMode ? ' dark' : '')}>
                    <div className="card-icon-dialog-title">{translate('PickCardIcon')}</div>
                    <div className="card-icon-dialog-container">
                        {CustomIcon.CustomIcons.map((icon, i) => {
                            return (
                                <div
                                    key={i}
                                    className={'icon-line' + (this.state.activeIcon === i ? ' selected' : '')}
                                    onClick={() => this.setState({ activeIcon: i })}
                                >
                                    <div className='icon'>
                                        {icon.icon && <FontAwesomeIcon icon={icon.icon} />}
                                    </div>
                                    <div className='label'>
                                        {icon.label}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="card-icon-dialog-accept">
                        <Button
                            tabIndex={2}
                            className="card-icon-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(true)}
                        >
                            {translate('OK')}
                        </Button>
                    </div>
                    <div className="card-icon-dialog-cancel">
                        <Button
                            tabIndex={3}
                            className="card-icon-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(false)}
                        >
                            {translate('Cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
