import * as React from 'react';
import { GlobalState } from '../globalState';
import { Collection } from '../entities/collection';
import { Nullable } from '../tools/nullable';
import { Observer } from '../tools/observable';
import { LabelledTexbox } from '../controls/labelledTextbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import { ListGroup } from '../entities/listGroup';
import { List } from '../entities/list';
import { GUID } from '../tools/guid';
import { ListCard } from '../controls/cards/listCard';

require('../scss/listPage.scss');

interface IListPageProps {
    globalState: GlobalState;
}

export class ListPage extends React.Component<IListPageProps, { filter: string }> {
    private scroller: HTMLElement;
    private onCustomButtonClickedObserver: Nullable<Observer<void>>;
    private onWindowSizeChangedObserver: Nullable<Observer<number>>;
    private currentList: Nullable<List>;
    private currentListGroup: Nullable<ListGroup>;

    constructor(props: IListPageProps) {
        super(props);

        this.state = {
            filter:
                this.props.globalState.pageCustomData && this.props.globalState.pageCustomData.filter
                    ? this.props.globalState.pageCustomData.filter
                    : ''
        };
    }

    updateListFromUI(list: List, value: { name: string; group: string; color: string; tags: string[] }) {
        list.ColorCode = value.color;
        list.Name = value.name;
        list.Tags = value.tags;

        const parent = Collection.ListGroups.filter((lg) => lg.Name.toLowerCase() === value.group.toLowerCase());

        if (parent && parent.length === 1) {
            parent[0].Lists.push(list);
        } else {
            const newParent = new ListGroup();
            newParent.Lists = [list];
            newParent.Name = value.group;

            Collection.ListGroups.push(newParent);
        }
        this.forceUpdate();

        Collection.RegisterSave();
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onCustomButtonRequired.notifyObservers(
            window.innerWidth > 768 ? this.props.globalState.translate('New list') : '+'
        );
        this.onCustomButtonClickedObserver = this.props.globalState.onCustomButtonClicked.add(() => {
            this.props.globalState.onListEditorValidated.addOnce((value) => {
                if (!value) {
                    return;
                }

                let list: List;
                if (!this.currentList) {
                    list = new List();
                    list.UniqueID = GUID.Generate();
                    Collection.Lists.push(list);
                } else {
                    list = this.currentList;

                    if (this.currentListGroup) {
                        let index = this.currentListGroup.Lists.indexOf(list);

                        if (index > -1) {
                            this.currentListGroup.Lists.splice(index, 1);

                            if (this.currentListGroup.Lists.length === 0) {
                                index = Collection.ListGroups.indexOf(this.currentListGroup);
                                Collection.ListGroups.splice(index, 1);
                            }
                        }
                    }
                }

                this.updateListFromUI(list, value);
            });

            this.props.globalState.onListEditorRequired.notifyObservers({
                name: '',
                group: '',
                tags: []
            });

            this.currentList = null;
            this.currentListGroup = null;
        });

        this.onWindowSizeChangedObserver = this.props.globalState.onWindowSizeChanged.add((width) => {
            this.props.globalState.onCustomButtonRequired.notifyObservers(
                width > 768 ? this.props.globalState.translate('New list') : '+'
            );
        });
    }

    componentWillUnmount() {
        this.props.globalState.onCustomButtonClicked.remove(this.onCustomButtonClickedObserver);
        this.props.globalState.onWindowSizeChanged.remove(this.onWindowSizeChangedObserver);
    }

    onScroll() {
        this.props.globalState.pageCustomData.scroll = this.scroller.scrollTop;
    }

    componentDidMount() {
        this.scroller = document.getElementById('scrollElement')!;

        const customData = this.props.globalState.pageCustomData;
        const scrollPosition = customData && customData.scroll ? customData.scroll : 0;
        this.scroller.scrollTop = scrollPosition;
    }

    editList(list: List, listGroup: ListGroup) {
        this.props.globalState.onListEditorValidated.addOnce((value) => {
            if (!value) {
                return;
            }

            if (this.currentListGroup) {
                let index = this.currentListGroup.Lists.indexOf(list);

                if (index > -1) {
                    this.currentListGroup.Lists.splice(index, 1);

                    if (this.currentListGroup.Lists.length === 0) {
                        index = Collection.ListGroups.indexOf(this.currentListGroup);
                        Collection.ListGroups.splice(index, 1);
                    }
                }
            }

            this.updateListFromUI(list, value);
        });

        this.props.globalState.onListEditorRequired.notifyObservers({
            name: list.Name,
            group: listGroup.Name,
            color: list.ColorCode,
            tags: list.Tags
        });

        this.currentList = list;
        this.currentListGroup = listGroup;
    }

    renderListGroup(listGroup: ListGroup) {
        const lists = listGroup.Lists.filter((l) => {
            if (this.state.filter) {
                const filter = this.state.filter.toLowerCase();

                if (l.Name.toLocaleLowerCase().indexOf(filter) === -1) {
                    return false;
                }
            }
            return true;
        }).sort((a, b) => a.Name.localeCompare(b.Name));

        if (lists.length === 0) {
            return null;
        }

        const isOpen = ListGroup.IsListGroupOpen(this.props.globalState, listGroup.Name);

        return (
            <div key={listGroup.Name} className="list-group">
                <div className="list-group-header">
                    <div className="list-group-title">{`${listGroup.Name || ''} (${listGroup.Lists.length})`}</div>
                    <div
                        className="list-group-edit"
                        onClick={() => {
                            this.props.globalState
                                .showQuestionDialog(this.props.globalState.translate('DeckGroupName'), listGroup.Name)
                                .then((newName) => {
                                    if (!newName) {
                                        return;
                                    }
                                    listGroup.Name = newName;
                                    Collection.RegisterSave();
                                    this.forceUpdate();
                                });
                        }}
                    >
                        <FontAwesomeIcon icon={faPen as Icon} />
                    </div>
                    <div
                        className="list-group-collapse"
                        onClick={() => {
                            ListGroup.SetListGroupOpen(this.props.globalState, listGroup.Name, !isOpen);
                            this.forceUpdate();
                        }}
                    >
                        <FontAwesomeIcon icon={isOpen ? faMinusSquare : faPlusSquare} />
                    </div>
                </div>
                {isOpen && (
                    <div className="list-group-list">
                        {lists.map((list, i) => {
                            return (
                                <ListCard
                                    key={list.Name + listGroup.Name + i}
                                    globalState={this.props.globalState}
                                    onDelete={() => this.forceUpdate()}
                                    onClone={() => this.forceUpdate()}
                                    onEdit={() => this.editList(list, listGroup)}
                                    list={list}
                                    listGroup={listGroup}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }

    blurFilter(evt: React.KeyboardEvent<HTMLInputElement>) {
        if (evt.which === 13) {
            (evt.nativeEvent.srcElement! as HTMLInputElement).blur();
        }
    }

    render() {
        const translate = this.props.globalState.translate.bind(this.props.globalState);
        const listGroups = Collection.ListGroups.sort((a, b) => (a.Name || '').localeCompare(b.Name || ''));

        const suggestionForNames = Collection.Cards.map((c) => c.nameEn);

        if (Collection.UseFrenchForTexts) {
            for (const cardName of Collection.Cards.map((c) => c.nameFr)) {
                if (!cardName) {
                    continue;
                }
                suggestionForNames.push(cardName);
            }
        }

        return (
            <div className="page">
                <div className="list-page">
                    <div className="list-page-card-filter">
                        <LabelledTexbox
                            label={translate('Name')}
                            globalState={this.props.globalState}
                            onKeyPress={(evt) => this.blurFilter(evt)}
                            onChange={(value) => {
                                this.setState({ filter: value });
                            }}
                            suggestions={suggestionForNames}
                            value={this.state.filter}
                            placeholder={translate('AnyWord')}
                        />
                    </div>

                    <div className="list-page-list" id="scrollElement" onScroll={() => this.onScroll()}>
                        {listGroups.map((lg) => {
                            return this.renderListGroup(lg);
                        })}
                    </div>

                    <div className="list-page-popup-host"></div>
                </div>
            </div>
        );
    }
}
