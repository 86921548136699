import * as React from 'react';
import { GlobalState } from '../globalState';
import { Button } from './button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { SecureJSON } from '../tools/secureJSON';
import { Collection } from '../entities/collection';
import { Block } from '../entities/block';

require('../scss/controls/customChecklistDialog.scss');

interface CustomChecklistDialogProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class CustomChecklistDialog extends React.Component<
    CustomChecklistDialogProps,
    { isVisible: boolean; excluded: string[]; isExpanded: string[] }
> {
    constructor(props: CustomChecklistDialogProps) {
        super(props);

        let isExpanded: string[] = [];

        if (GlobalState.DoesSettingsExist('CustomChecklistExpanded')) {
            const result = SecureJSON.Parse<string[]>(GlobalState.LoadSettings('CustomChecklistExpanded'));
            if (result) {
                isExpanded = result;
            }
        }

        this.state = { isVisible: false, excluded: [], isExpanded: isExpanded };
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onCustomChecklistDialogRequired.add(() => {
            let excluded: string[] = [];

            if (GlobalState.DoesSettingsExist('CustomChecklist')) {
                const result = SecureJSON.Parse<string[]>(GlobalState.LoadSettings('CustomChecklist'));
                if (result) {
                    excluded = result;
                }
            }

            this.setState({ isVisible: true, excluded: excluded });
            this.props.globalState.onBlurRequired.notifyObservers(true);
        });
    }

    onConfirmation(value: boolean) {
        if (value) {
            this.props.globalState.storeSettings('CustomChecklist', JSON.stringify(this.state.excluded));
        }

        this.setState({ isVisible: false });

        this.props.globalState.onBlurRequired.notifyObservers(false);
    }

    switch(set: string) {
        const excluded = this.state.excluded;
        const index = excluded.indexOf(set);

        if (index === -1) {
            excluded.push(set);
        } else {
            excluded.splice(index, 1);
        }

        this.forceUpdate();
    }

    switchBlock(b: Block, newState: boolean) {
        const excluded = this.state.excluded;

        for (const set of b.sets) {
            const index = excluded.indexOf(set.name);

            if (index === -1 && !newState) {
                excluded.push(set.name);
            } else if (index !== -1 && newState) {
                excluded.splice(index, 1);
            }
        }

        this.forceUpdate();
    }

    expandBlock(b: Block) {
        const isExpanded = this.state.isExpanded;

        const index = isExpanded.indexOf(b.name);

        if (index === -1) {
            isExpanded.push(b.name);
        } else {
            isExpanded.splice(index, 1);
        }

        this.props.globalState.storeSettings('CustomChecklistExpanded', JSON.stringify(this.state.isExpanded));

        this.forceUpdate();
    }

    render() {
        if (!this.state.isVisible) {
            return null;
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);

        const blocks = Collection.Blocks.sort((a, b) => {
            if (a.index === b.index) {
                return 0;
            }

            if (a.index > b.index) {
                return -1;
            }

            return 1;
        });

        return (
            <div className="custom-checklist-dialog-container">
                <div className="custom-checklist-dialog-background"></div>
                <div className={'custom-checklist-dialog' + (this.props.darkMode ? ' dark' : '')}>
                    <div className="custom-checklist-dialog-title">{translate('CustomChecklist')}</div>
                    <div className="custom-checklist-dialog-container">
                        <div className="custom-checklist-dialog-desc">{translate('CustomChecklistDesc')}</div>
                        {blocks
                            .sort((b) => b.index)
                            .map((b, i) => {
                                const isEnabled =
                                    b.sets.filter((s) => this.state.excluded.indexOf(s.name) !== -1).length === 0;
                                const isExpanded = this.state.isExpanded.indexOf(b.name) !== -1;
                                return (
                                    <div key={i}>
                                        <div className="blockLine">
                                            <div className="block-expand" onClick={() => this.expandBlock(b)}>
                                                {!isExpanded && <FontAwesomeIcon icon={faPlus} />}
                                                {isExpanded && <FontAwesomeIcon icon={faMinus} />}
                                            </div>
                                            <div className="block-label">{b.name}</div>
                                            <div
                                                className={'block-switch button' + (!isEnabled ? ' disable' : '')}
                                                title={translate('ChangeSortDirection')}
                                                onClick={() => this.switchBlock(b, !isEnabled)}
                                            >
                                                {isEnabled && <FontAwesomeIcon icon={faCheck} />}
                                                {!isEnabled && <FontAwesomeIcon icon={faTimes} />}
                                            </div>
                                        </div>
                                        {isExpanded &&
                                            b.sets.map((s, i) => {
                                                const isEnabled = this.state.excluded.indexOf(s.name) === -1;
                                                return (
                                                    <div className="setLine" key={'set-' + i}>
                                                        <div className="set-label">{s.name}</div>
                                                        <div
                                                            className={
                                                                'set-switch button' + (!isEnabled ? ' disable' : '')
                                                            }
                                                            title={translate('ChangeSortDirection')}
                                                            onClick={() => this.switch(s.name)}
                                                        >
                                                            {isEnabled && <FontAwesomeIcon icon={faCheck} />}
                                                            {!isEnabled && <FontAwesomeIcon icon={faTimes} />}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                );
                            })}
                    </div>
                    <div className="custom-checklist-dialog-accept">
                        <Button
                            tabIndex={2}
                            className="custom-checklist-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(true)}
                        >
                            {translate('OK')}
                        </Button>
                    </div>
                    <div className="custom-checklist-dialog-cancel">
                        <Button
                            tabIndex={3}
                            className="custom-checklist-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(false)}
                        >
                            {translate('Cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
