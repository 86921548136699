import { Set } from './set';
import { BinaryReader } from '../tools/binaryReader';
import { Collection } from './collection';
import { Nullable } from '../tools/nullable';
import { CardSize } from './cardSize';
import { Ruling } from './ruling';
import { StringHelpers } from '../tools/stringHelpers';
import { CardFormat } from '../entities/cardFormat';
import { Deck } from './deck';
import { ICard } from './ICard';
import { CardRarity } from './cardRarity';
import { CardColor } from './cardColor';
import { CountTools } from '../tools/countTools';
import { GlobalState } from '../globalState';
import { CardLanguage } from './cardLanguage';
import { CardCondition } from './cardCondition';
import { CardGrading } from './cardGrading';

export class Card implements ICard {
    public set: Set;
    public id: number;
    public number: number;
    public _tcgPlayerId: number;
    public _cardMarketId: number;
    public _scryfallId: string;
    public _canTransform: boolean;
    public _canBeSpecialFoil: boolean;
    public numberComplement: string;
    public nameEn: string;
    public shortNameEn: string;
    public nameFr: string;
    public author: string;
    private _colorId: number;
    public rarityId: number;
    public isBackFace: boolean;
    public force: Nullable<number>;
    public defense: Nullable<number>;
    public power: string;
    private _picturePath: string;
    public textEn: string;
    public textFr: string;
    public flavorEn: string;
    public flavorFr: string;
    public typeEn: string;
    public typeFr: string;
    public multiverseIdEn: number;
    public multiverseIdFr: number;
    public manaCost: string;
    public convertedManaCost: number;
    public tag: string;
    public size: CardSize;
    public rulings: Ruling[];
    public canBeFoil: boolean;
    public canBeStandard: boolean;
    public isPromo: boolean;
    public scryfallNumber: string;
    public isStorySpotlight: boolean;
    public isSerialized: boolean;

    public wasAlreadyLoadedOnce = false;

    public forceStdQuality = false;

    public isSelected = false;

    public otherCard: Card;
    public otherCards: Card[];

    public get picturePath(): string {
        const exclusionSets = [
            'Magic: The Baseballing',
            'Creepshow',
            'Raining Cats and Dogs',
            'Just Add Milk: Second Helpings'
        ];

        if (this.isBackFace && !this.canTransform && !this.isToken && this.setName.indexOf('Art Series') === -1 && this.setName.indexOf('Minigames') === -1 && exclusionSets.indexOf(this.setName) === -1) {
            if (this.nameEn !== 'Undercity') {
                return this.otherCard.picturePath;
            }
        }
        return this._picturePath;
    }

    public set picturePath(value: string) {
        this._picturePath = value;
    }

    public get tcgPlayerId(): number {
        if (this.isBackFace) {
            return this.otherCard.tcgPlayerId;
        }
        return this._tcgPlayerId;
    }

    public get cardMarketId(): number {
        return this._cardMarketId;
    }

    public get scryfallId(): string {
        return this._scryfallId;
    }

    public get canTransform(): boolean {
        if (this.isBackFace) {
            return this.otherCard._canTransform;
        }
        return this._canTransform;
    }

    public get canBeSpecialFoil(): boolean {
        if (this.isBackFace && this.otherCard) {
            return this.otherCard._canBeSpecialFoil;
        }
        return this._canBeSpecialFoil;
    }

    public get gathererLink() {
        return 'http://gatherer.wizards.com/Pages/Card/Details.aspx?multiverseid=' + this.multiverseId;
    }

    public get multiverseId() {
        return Collection.UseFrenchForTexts && this.multiverseIdFr ? this.multiverseIdFr : this.multiverseIdEn;
    }

    public get name() {
        return Collection.UseFrenchForTexts && this.nameFr ? this.nameFr : this.nameEn;
    }

    public get cropUrl() {
        return 'https://urzabackend.azurewebsites.net/Crop/' + this.picturePath.replace('\\', '/');
    }

    public get type() {
        return Collection.UseFrenchForTexts && this.typeFr ? this.typeFr : this.typeEn;
    }

    public get text() {
        return Collection.UseFrenchForTexts && this.textFr ? this.textFr : this.textEn;
    }

    public get flavor() {
        return Collection.UseFrenchForTexts && this.flavorFr ? this.flavorFr : this.flavorEn;
    }

    public get nameForSearch() {
        if (Collection.UseFrenchForTexts) {
            return (this.nameEn + this.nameFr).toLowerCase();
        }
        return this.nameEn.toLowerCase();
    }

    public get typeForSearch() {
        if (Collection.UseFrenchForTexts) {
            return (this.typeEn + this.typeFr).toLowerCase();
        }
        return this.typeEn.toLowerCase();
    }

    public get textForSearch() {
        if (Collection.UseFrenchForTexts) {
            return (this.textEn + this.textFr).toLowerCase();
        }
        return this.textEn.toLowerCase();
    }

    public get flavorForSearch() {
        if (Collection.UseFrenchForTexts) {
            return (this.flavorEn + this.flavorFr).toLowerCase();
        }
        return this.flavorEn.toLowerCase();
    }

    private _isCreature: boolean;
    public get isCreature(): boolean {
        if (this._isCreature === undefined) {
            this._isCreature =
                (this.power !== '' && this.typeEn.indexOf('Vehicle') === -1 && !this.isPlaneswalker) ||
                StringHelpers.StartsWith(this.typeEn, 'Creature ');
        }
        return this._isCreature;
    }

    private _isLegendary: boolean;
    public get isLegendary(): boolean {
        if (this._isLegendary === undefined) {
            this._isLegendary = this.typeEn.toLowerCase().indexOf('legendary') !== -1;
        }
        return this._isLegendary;
    }

    private _isCommander: boolean;
    public get isCommander(): boolean {
        if (this._isCommander === undefined) {
            this._isCommander =
                (this.isLegendary && (this.isCreature || this.isPlaneswalker || this.isBackground)) ||
                (this.isPlaneswalker && this.textEn.toLowerCase().indexOf('can be your commander') !== -1);
        }
        return this._isCommander;
    }

    private _isConspiracy: boolean;
    public get isConspiracy(): boolean {
        if (this._isConspiracy === undefined) {
            this._isConspiracy = this.typeEn === 'Conspiracy';
        }
        return this._isConspiracy;
    }

    private _isPhenomenon: boolean;
    public get isPhenomenon(): boolean {
        if (this._isPhenomenon === undefined) {
            this._isPhenomenon = this.typeEn === 'Phenomenon';
        }
        return this._isPhenomenon;
    }

    private _isInstant: boolean;
    public get isInstant(): boolean {
        if (this._isInstant === undefined) {
            this._isInstant = !this.isCreature && !this.isLand && (this.typeEn.toLowerCase().indexOf('instant') !== -1 || this.typeEn.toLowerCase().indexOf('interrupt') !== -1);
        }
        return this._isInstant;
    }

    private _isEnchantment: boolean;
    public get isEnchantment(): boolean {
        if (this._isEnchantment === undefined) {
            this._isEnchantment =
                !this.isCreature && !this.isLand && this.typeEn.toLowerCase().indexOf('enchant') !== -1;
        }
        return this._isEnchantment;
    }

    private _isBackground: boolean;
    public get isBackground(): boolean {
        if (this._isBackground === undefined) {
            this._isBackground =
                this.isEnchantment && this.typeEn.toLowerCase().indexOf('background') !== -1;
        }
        return this._isBackground;
    }

    private _isSorcery: boolean;
    public get isSorcery(): boolean {
        if (this._isSorcery === undefined) {
            this._isSorcery = !this.isCreature && !this.isLand && this.typeEn.toLowerCase().indexOf('sorcery') !== -1;
        }
        return this._isSorcery;
    }

    private _isSpell: boolean;
    public get isSpell(): boolean {
        if (this._isSpell === undefined) {
            this._isSpell = this.isEnchantment || this.isInstant || this.isSorcery;
        }
        return this._isSpell;
    }

    private _isPlaneswalker: boolean;
    public get isPlaneswalker(): boolean {
        if (this._isPlaneswalker === undefined) {
            this._isPlaneswalker =
                StringHelpers.StartsWith(this.typeEn, 'Planeswalker') ||
                StringHelpers.StartsWith(this.typeEn, 'Legendary Planeswalker');
        }
        return this._isPlaneswalker;
    }

    private _isPlane: boolean;
    public get isPlane(): boolean {
        if (this._isPlane === undefined) {
            this._isPlane = StringHelpers.EndsWith(this.setName, ' Planes') && !this.isPhenomenon;
        }
        return this._isPlane;
    }

    private _isScheme: boolean;
    public get isScheme(): boolean {
        if (this._isScheme === undefined) {
            this._isScheme = StringHelpers.EndsWith(this.setName, ' Schemes');
        }
        return this._isScheme;
    }

    private _isArtifact: boolean;
    public get isArtifact(): boolean {
        if (this._isArtifact === undefined) {
            this._isArtifact = this.typeEn.toLowerCase().indexOf('artifact') !== -1;
        }
        return this._isArtifact;
    }

    private _isEldrazi: boolean;
    public get isEldrazi(): boolean {
        if (this._isEldrazi === undefined) {
            this._isEldrazi = this.typeEn.toLowerCase().indexOf('eldrazi') !== -1;
        }
        return this._isEldrazi;
    }

    private _isLand: boolean;
    public get isLand(): boolean {
        if (this._isLand === undefined) {
            this._isLand =
                (this.typeEn.toLowerCase().indexOf(' land') !== -1 || StringHelpers.StartsWith(this.typeEn, 'Land')) &&
                this.typeEn.toLowerCase().indexOf('enchant') === -1;
        }
        return this._isLand;
    }

    private _isPermanent: boolean;
    public get isPermanent(): boolean {
        if (this._isPermanent === undefined) {
            this._isPermanent =
                this.isLand || this.isCreature || this.isEnchantment || this.isArtifact || this.isPlaneswalker;
        }
        return this._isPermanent;
    }

    public get validFor(): string {
        return this.validForList.join(', ');
    }

    public get validForList(): string[] {
        return CardFormat.CardFormats.filter((f) => f.predicate && f.predicate(this))
            .map((f) => f.name)
            .sort();
    }

    public get deckCount(): number {
        return Collection.Decks.filter((d) => Deck.Some(d, (c) => c.CardID === this.id, true)).length;
    }

    public get activeDeckCount(): number {
        return Collection.Decks.filter((d) => !d.disabled && Deck.Some(d, (c) => c.CardID === this.id, true)).length;
    }

    public get totalEntryCount(): number {
        return CountTools.Sum(
            Collection.Decks.filter((d) => !d.disabled).map((d) =>
                CountTools.Sum(
                    Deck.AllCards(d, true).map((c) =>
                        c.CardID === this.id || (this.otherCard && c.CardID === this.otherCard.id) ? c.EntryCount : 0
                    )
                )
            )
        );
    }

    public getDeckString(state: GlobalState): string {
        const decks = Collection.Decks.filter(
            (d) =>
                !d.disabled &&
                Deck.Some(d, (c) => c.CardID === this.id || (this.otherCard && c.CardID === this.otherCard.id), true)
        );

        if (decks.length === 0) {
            return '';
        }

        return `${state.translate('Total')} [${this.totalEntryCount}], ` + decks
            .sort((a, b) => a.Name.localeCompare(b.Name))
            .map((d) => {
                return `${d.Name} [${Deck.AllCards(d, true)
                    .filter((c) => c.CardID === this.id || (this.otherCard && c.CardID === this.otherCard.id))
                    .map((c) => c.EntryCount)
                    .reduce((previous, c) => previous + c)}]`;
            })
            .join(', ');
    }

    public getDeckData(state: GlobalState): { labels?: string[], links?: string[] } {
        const decks = Collection.Decks.filter(
            (d) =>
                !d.disabled &&
                Deck.Some(d, (c) => c.CardID === this.id || (this.otherCard && c.CardID === this.otherCard.id), true)
        );

        if (decks.length === 0) {
            return {};
        }

        const sortedList = decks.sort((a, b) => a.Name.localeCompare(b.Name));

        const result = {
            labels: [`${state.translate('Total')} [${this.totalEntryCount}]`],
            links: ['']
        };

        for (const deck of sortedList) {
            result.labels.push(`${deck.Name} [${Deck.AllCards(deck, true)
                .filter((c) => c.CardID === this.id || (this.otherCard && c.CardID === this.otherCard.id))
                .map((c) => c.EntryCount)
                .reduce((previous, c) => previous + c)}]`);
            result.links.push(`${deck.UniqueID}`);
        }

        return result;
    }

    private _reprints: Card[];
    public get reprints(): Card[] {
        if (!this._reprints) {
            if (Collection.CardsNameIndex[this.shortNameEn]) {
                this._reprints = Collection.CardsNameIndex[this.shortNameEn];
            } else {
                this._reprints = [];
            }
        }
        return this._reprints;
    }

    // User-defined
    public get setName() {
        return this.set.name;
    }

    public get rarity() {
        return CardRarity.GetRarityById(this.rarityId).name;
    }

    public get colorId() {
        return (this.isLand && !this.isCreature) ? 10 : this._colorId;
    }

    public get color() {
        return CardColor.GetColorById(this.colorId).name;
    }

    public _toTrade: number = 0;
    public get toTrade(): number {
        if (this.isBackFace) {
            return this.otherCard._toTrade;
        }

        return this._toTrade;
    }

    public set toTrade(value: number) {
        if (this.isBackFace && this.otherCard) {
            this.otherCard._toTrade = value;
            return;
        }
        this._toTrade = value;

        if (Collection.UserStore) {
            if (!Collection.UserStore.ToTrade) {
                Collection.UserStore.ToTrade = {};
            }
            Collection.UserStore.ToTrade[this.id] = value;
        }
    }

    public _wanted: number = 0;
    public get wanted(): number {
        if (this.isBackFace) {
            return this.otherCard._wanted;
        }

        return this._wanted;
    }

    public set wanted(value: number) {
        if (this.isBackFace && this.otherCard) {
            this.otherCard._wanted = value;
            return;
        }
        this._wanted = value;

        if (Collection.UserStore) {
            if (!Collection.UserStore.Wanted) {
                Collection.UserStore.Wanted = {};
            }
            Collection.UserStore.Wanted[this.id] = value;
        }
    }

    public _price = 0;
    public get price(): number {
        if (this.isProxy) {
            return 0;
        }
        if (this.isBackFace) {
            return this.otherCard.price;
        }

        if (!this.canBeStandard) {
            return Math.max(this._price, this._foilPrice);
        }

        return this._price;
    }

    public set price(value: number) {
        if (this.isBackFace && this.otherCard) {
            this.otherCard.price = value;
            return;
        }
        this._price = value;

        if (Collection.UserStoreExt) {
            Collection.UserStoreExt.Prices[this.id] = value;
        }
    }

    public _foilPrice = 0;
    public get foilPrice(): number {
        if (this.isProxy) {
            return 0;
        }
        if (this.isBackFace) {
            return this.otherCard.foilPrice;
        }

        if (!this.canBeFoil || !this._foilPrice || this._price > this._foilPrice) {
            return this._price;
        }

        return this._foilPrice;
    }

    public set foilPrice(value: number) {
        if (this.isBackFace && this.otherCard) {
            this.otherCard.foilPrice = value;
            return;
        }
        this._foilPrice = value;

        if (Collection.UserStoreExt) {
            Collection.UserStoreExt.FoilPrices[this.id] = value;
        }
    }

    public get collectionValue() {
        return this.price * (this.count - this.foilCount) + this.foilPrice * this.foilCount;
    }

    public _count = 0;
    public get count(): number {
        if (this.isBackFace) {
            return this.otherCard.count;
        }
        if (this._count === null) {
            return Math.max(0, this._foilCount + this._specialFoilCount);
        }
        return this._count;
    }

    public set count(value: number) {
        if (this.isBackFace) {
            this.otherCard.count = value;
            return;
        }
        this._count = value;

        if (value) {
            this.isOrdered = false;
            const defaultLanguage = GlobalState.LoadSettings('DefaultLanguage');
            const languageIndex = CardLanguage.CardLanguages.indexOf(defaultLanguage);

            if (languageIndex !== -1) {
                const currentLanguages = CardLanguage.Decoder(this.languages);

                if (currentLanguages.length > value) {
                    currentLanguages.splice(value - 1, currentLanguages.length - value);
                } else {
                    for (let index = currentLanguages.length; index < value; index++) {
                        currentLanguages.push(languageIndex);
                    }
                }

                this.languages = CardLanguage.Encoder(currentLanguages);
            }

            const dates = this.dates;
            if (dates.length > value) {
                    dates.splice(value - 1, dates.length - value);
            } else {
                const now = new Date();
                for (let index = dates.length; index < value; index++) {
                    dates.push(now);
                }
            }
            this.dates = dates;

            const defaultCondition = GlobalState.LoadSettings('DefaultCondition');
            let conditionIndex = -1;

            for (const condition of CardCondition.CardConditions) {
                if (condition.name === defaultCondition) {
                    conditionIndex = condition.index;
                    break;
                }
            }

            if (conditionIndex !== -1) {
                const currentConditions = CardCondition.Decoder(this.condition);

                if (currentConditions.length > value) {
                    currentConditions.splice(value - 1, currentConditions.length - value);
                } else {
                    for (let index = currentConditions.length; index < value; index++) {
                        currentConditions.push(conditionIndex);
                    }
                }

                this.condition = CardCondition.Encoder(currentConditions);
            }

            const defaultGrading = GlobalState.LoadSettings('DefaultGrading');
            const gradingIndex = CardGrading.CardGradings.indexOf(defaultGrading);

            if (gradingIndex !== -1) {
                const currentgradings = CardGrading.Decoder(this.grading);

                if (currentgradings.length > value) {
                    currentgradings.splice(value - 1, currentgradings.length - value);
                } else {
                    for (let index = currentgradings.length; index < value; index++) {
                        currentgradings.push(gradingIndex);
                    }
                }

                this.grading = CardGrading.Encoder(currentgradings);
            }
        } else {
            this.grading = '';
            this.condition = '';
            this.languages = '';
            this.dates = [];
        }

        if ((this.foilCount + this.specialFoilCount > this.count)) {
            while (this.foilCount + this.specialFoilCount > this.count) {
                if (this.specialFoilCount > 0) {
                    this.specialFoilCount = this.specialFoilCount - 1;
                } else {
                    this.foilCount = this.foilCount - 1;
                }
            }
        } else if (!this.canBeStandard) {
            if (this.foilCount + this.specialFoilCount < this.count) {
                if (this.canBeFoil) {
                    this.foilCount = this.count - this.specialFoilCount;
                } else if (this.canBeSpecialFoil) {
                    this.specialFoilCount = this.count;
                }
            }
        }

        Collection.UserStore.Count[this.id] = value;
    }

    public getFoilState(globalState: GlobalState): string {
        return (
            (this.canBeSpecialFoil ? globalState.translate('SpecialFoil') : '') +
            (this.canBeFoil ? (this.canBeSpecialFoil ? ' / ' : '') + globalState.translate('Foil') : '') +
            (this.canBeStandard ? (this.canBeFoil || this.canBeSpecialFoil ? ' / ' : '') + globalState.translate('NonFoil') : '')
        );
    }

    public _icon = 0;
    public get icon(): number {
        if (this.isBackFace) {
            return this.otherCard.icon;
        }

        return this._icon;
    }

    public set icon(value: number) {
        if (this.isBackFace) {
            this.otherCard.icon = value;
            return;
        }
        this._icon = value;

        Collection.UserStore.Icon[this.id] = value;
    }

    public _foilCount = 0;
    public get foilCount(): number {
        if (this.isBackFace) {
            return this.otherCard.foilCount;
        }

        if (!this.canBeFoil) {
            return 0;
        }

        return this._foilCount;
    }

    public set foilCount(value: number) {
        if (this.isBackFace) {
            this.otherCard.foilCount = value;
            return;
        }
        this._foilCount = value;

        Collection.UserStore.Foils[this.id] = value;
    }

    public _specialFoilCount = 0;
    public get specialFoilCount(): number {
        if (this.isBackFace) {
            return this.otherCard.specialFoilCount;
        }

        if (!this.canBeSpecialFoil) {
            return 0;
        }

        return this._specialFoilCount;
    }

    public set specialFoilCount(value: number) {
        if (this.isBackFace) {
            this.otherCard.specialFoilCount = value;
            return;
        }
        this._specialFoilCount = value;

        Collection.UserStore.FoilEtcheds[this.id] = value;
    }

    public _languages = '';
    public get languages(): string {
        if (this.isBackFace) {
            return this.otherCard.languages;
        }
        return this._languages;
    }

    public set languages(value: string) {
        if (this.isBackFace) {
            this.otherCard.languages = value;
            return;
        }
        this._languages = value;

        Collection.UserStore.Languages[this.id] = value;
    }

    public _comments = '';
    public get comments(): string {
        if (this.isBackFace) {
            return this.otherCard.comments;
        }
        return this._comments;
    }

    public set comments(value: string) {
        if (this.isBackFace) {
            this.otherCard.comments = value;
            return;
        }
        this._comments = value;

        Collection.UserStore.Comments[this.id] = value;
    }

    public _tags: string[] = [];
    public get tags(): string[] {
        if (this.isBackFace) {
            return this.otherCard.tags;
        }
        return this._tags;
    }

    public set tags(value: string[]) {
        if (this.isBackFace) {
            this.otherCard.tags = value;
            return;
        }
        this._tags = value;
        this.updateTagsInStorage();
    }

    public updateTagsInStorage() {
        if (this.isBackFace) {
            this.otherCard.updateTagsInStorage();
            return;
        }
        if (!Collection.UserStore.Tags) {
            Collection.UserStore.Tags = {};
        }
        Collection.UserStore.Tags[this.id] = this._tags.join(',');
        Collection.RegisterSave();
    }

    public _condition = '';
    public get condition(): string {
        if (this.isBackFace) {
            return this.otherCard.condition;
        }
        return this._condition;
    }

    public set condition(value: string) {
        if (this.isBackFace) {
            this.otherCard.condition = value;
            return;
        }
        this._condition = value;

        Collection.UserStore.Conditions[this.id] = value;
    }

    public _grading = '';
    public get grading(): string {
        if (this.isBackFace) {
            return this.otherCard.grading;
        }
        return this._grading;
    }

    public set grading(value: string) {
        if (this.isBackFace) {
            this.otherCard.grading = value;
            return;
        }
        this._grading = value;

        Collection.UserStore.Gradings[this.id] = value;
    }

    public _dates: Date[] = [];
    public get dates(): Date[]  {
        if (this.isBackFace) {
            return this.otherCard.dates;
        }
        return this._dates;
    }

    public set dates(value: Date[]) {
        if (this.isBackFace) {
            this.otherCard._dates = value;
            return;
        }
        this._dates = value;

        Collection.UserStore.Added[this.id] = value.join(',');
    }

    public _isOrdered = false;
    public get isOrdered(): boolean {
        if (this.isBackFace) {
            return this.otherCard.isOrdered;
        }
        return this._isOrdered;
    }

    public set isOrdered(value: boolean) {
        if (this.isBackFace) {
            this.otherCard.isOrdered = value;
            return;
        }
        this._isOrdered = value;

        Collection.UserStore.IsOrdered[this.id] = value;
    }

    public _isProxy = false;
    public get isProxy(): boolean {
        if (this.isBackFace) {
            return this.otherCard.isProxy;
        }
        return this._isProxy;
    }

    public set isProxy(value: boolean) {
        if (this.isBackFace) {
            this.otherCard.isProxy = value;
            return;
        }
        this._isProxy = value;

        Collection.UserStore.IsProxy[this.id] = value;
    }

    public get isToken() {
        if (this.typeEn.toLowerCase().indexOf('token') !== -1) {
            return true;
        }

        if (this.typeEn.toLowerCase() === 'emblem') {
            return true;
        }

        if (this.set.name.toLowerCase().indexOf('token') !== -1) {
            return true;
        }

        return false;
    }

    public get isDungeon() {
        if (this.typeEn.toLowerCase().indexOf('dungeon') !== -1) {
            return true;
        }

        return false;
    }

    public get isEmblem() {
        if (this.typeEn.toLowerCase().indexOf('emblem') !== -1) {
            return true;
        }

        return false;
    }

    public prepareCardNameForTCGPlayer(replaceSpace: boolean) {
        let cardName = this.isBackFace ? this.otherCard.nameEn : this.nameEn;

        if (this.set.name.indexOf('2019 Gift Pack') !== -1) {
            if (replaceSpace) {
                cardName += ' 2018 gift pack';
            } else {
                cardName += ' (2018 gift pack)';
            }
        }

        cardName = cardName.replace(/\s\/\/\s/g, '-');
        cardName = cardName.replace(/’/g, "'").replace(/Æ/g, 'AE');
        cardName = cardName.replace(/&/g, 'and');

        cardName = StringHelpers.RemoveAccents(cardName);

        if (replaceSpace) {
            cardName = cardName.replace(/\s/g, '+');
        }

        return cardName;
    }

    public static Deserialize(reader: BinaryReader, mustIgnore: (card: Card) => boolean): Card {
        const card = new Card();

        card.id = reader.readInt32();
        card.number = reader.readInt32();
        card._tcgPlayerId = reader.readInt32();
        card._cardMarketId = reader.readInt32();
        card._scryfallId = reader.readString();
        card._canTransform = reader.readBoolean();
        card._canBeSpecialFoil = reader.readBoolean();
        card.numberComplement = reader.readString();
        card.scryfallNumber = reader.readString();

        card.nameEn = reader.readString();
        card.nameFr = reader.readString();

        card.author = reader.readString();
        card._colorId = reader.readInt32();

        card.rarityId = reader.readInt32();

        card.isBackFace = reader.readBoolean();
        card.canBeFoil = reader.readBoolean();
        card.canBeStandard = reader.readBoolean();
        card.isPromo = reader.readBoolean();
        card.isStorySpotlight = reader.readBoolean();
        card.isSerialized = reader.readBoolean();

        card.force = reader.readInt32();
        if (card.force < 0) {
            card.force = null;
        }
        card.defense = reader.readInt32();
        if (card.defense < 0) {
            card.defense = null;
        }
        card.power = reader.readString();

        card.picturePath = reader.readString();
        card.price = reader.readDouble();

        card.textEn = reader.readString();
        card.textFr = reader.readString();

        card.flavorEn = reader.readString();
        card.flavorFr = reader.readString();

        card.typeEn = reader.readString();
        card.typeFr = reader.readString();

        card.multiverseIdEn = reader.readInt32();
        card.multiverseIdFr = reader.readInt32();

        card.manaCost = reader.readString();
        card.convertedManaCost = reader.readInt32();

        card.tag = reader.readString();

        card.size = CardSize.Deserialize(reader);

        const rulingsCount = reader.readInt32();
        card.rulings = new Array<Ruling>(rulingsCount);
        for (let index = 0; index < rulingsCount; index++) {
            card.rulings[index] = Ruling.Deserialize(reader);
        }

        if (!mustIgnore(card)) {
            Collection.CardsIndex[card.id] = card;

            const shortNameEn = card.nameEn.trim().replace(/\s\(v. \d+\)/, '');

            if (!Collection.CardsNameIndex[shortNameEn]) {
                Collection.CardsNameIndex[shortNameEn] = [];
            }

            Collection.CardsNameIndex[shortNameEn].push(card);
            card.shortNameEn = shortNameEn;

            Collection.Cards.push(card);
        }

        return card;
    }
}
