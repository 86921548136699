import { GlobalState } from '../globalState';

export class CardColor {
    public static Colors: CardColor[] = [];
    public static SortedColors: CardColor[] = [];
    public static ManaSources: CardColor[] = [];
    public static ColorIdentities: CardColor[] = [];

    public constructor(
        public id: number,
        public key: string,
        public sortOrder: number,
        public name: string,
        public color?: string,
        public code?: string
    ) {}

    public static GetColorById(id: number): CardColor {
        return CardColor.Colors.filter((c) => c.id === id)[0];
    }

    public static GetColorByCode(code: string): CardColor {
        return CardColor.Colors.filter((c) => c.code === code)[0];
    }

    public static CreateList(gs: GlobalState) {
        CardColor.Colors.push(new CardColor(0, '', 0, gs.translate('AllColors')));
        CardColor.Colors.push(new CardColor(1, 'Black', 3, gs.translate('Black'), '#505050', 'B'));
        CardColor.Colors.push(new CardColor(2, 'Red', 4, gs.translate('Red'), '#E03014', 'R'));
        CardColor.Colors.push(new CardColor(3, 'Blue', 2, gs.translate('Blue'), '#648FCC', 'U'));
        CardColor.Colors.push(new CardColor(4, 'White', 1, gs.translate('White'), '#FFC044', 'W'));
        CardColor.Colors.push(new CardColor(5, 'Green', 5, gs.translate('Green'), '#59B865', 'G'));
        // CardColor.Colors.push(new CardColor(7, "Land", 7, gs.translate("Land"), "#8B6F5B"));
        CardColor.Colors.push(new CardColor(8, 'Multicolors', 6, gs.translate('Multicolors'), '#DFBA0D'));
        CardColor.Colors.push(new CardColor(10, 'Colorless', 7, gs.translate('Colorless'), '#68617A'));
        // CardColor.Colors.push(new CardColor(11, "Scheme", 10, gs.translate("Scheme"), "#804139"));
        // CardColor.Colors.push(new CardColor(12, "Plane", 11, gs.translate("Plane"), "#C8643F"));
        // CardColor.Colors.push(new CardColor(13, "Phenomenon", 12, gs.translate("Phenomenon"), "#CE8240"));
        // CardColor.Colors.push(new CardColor(14, "Conspiracy", 13, gs.translate("Conspiracy"), "#A9A7A8"));
        // CardColor.Colors.push(new CardColor(15, "Emblem", 14, gs.translate("Emblem"), "#CECECE"));

        CardColor.SortedColors = CardColor.Colors.slice(0);

        const sortFunction = (a: CardColor, b: CardColor) => {
            if (a.sortOrder === b.sortOrder) {
                return 0;
            }

            if (a.sortOrder > b.sortOrder) {
                return 1;
            }

            return -1;
        };

        CardColor.SortedColors.sort(sortFunction);

        CardColor.ManaSources.push(new CardColor(1, 'Black', 3, gs.translate('Black'), '', '(B)'));
        CardColor.ManaSources.push(new CardColor(2, 'Red', 4, gs.translate('Red'), '', '(R)'));
        CardColor.ManaSources.push(new CardColor(3, 'Blue', 2, gs.translate('Blue'), '', '(U)'));
        CardColor.ManaSources.push(new CardColor(4, 'White', 1, gs.translate('White'), '', '(W)'));
        CardColor.ManaSources.push(new CardColor(5, 'Green', 5, gs.translate('Green'), '', '(G)'));
        CardColor.ManaSources.push(new CardColor(10, 'Colorless', 6, gs.translate('Colorless'), '', '(C)'));

        CardColor.ColorIdentities.push(new CardColor(1, 'Black', 3, gs.translate('Black'), '', 'B'));
        CardColor.ColorIdentities.push(new CardColor(2, 'Red', 4, gs.translate('Red'), '', 'R'));
        CardColor.ColorIdentities.push(new CardColor(3, 'Blue', 2, gs.translate('Blue'), '', 'U'));
        CardColor.ColorIdentities.push(new CardColor(4, 'White', 1, gs.translate('White'), '', 'W'));
        CardColor.ColorIdentities.push(new CardColor(5, 'Green', 5, gs.translate('Green'), '', 'G'));

        CardColor.ManaSources.sort(sortFunction);

        CardColor.ColorIdentities.sort(sortFunction);
    }
}
