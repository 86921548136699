import * as React from 'react';
import { GlobalState } from '../globalState';
import { Button } from './button';
import { DeckEntry } from '../entities/deckEntry';
import { Deck } from '../entities/deck';

interface MoveInsideDeckDialogProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class MoveInsideDeckDialog extends React.Component<
    MoveInsideDeckDialogProps,
    {
        isVisible: boolean;
        title: string;
    }
> {
    private deck: Deck;
    private cards: DeckEntry[];
    private fromMain: boolean;
    private fromSideboard: boolean;
    private fromMaybeboard: boolean;

    constructor(props: MoveInsideDeckDialogProps) {
        super(props);

        this.state = { isVisible: false, title: '' };
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onMoveInsideDeckRequired.add((data) => {
            this.cards = data.cards;
            this.deck = data.deck;
            this.setState({
                isVisible: true,
                title: data.title
            });

            this.fromMain = this.deck.Cards.indexOf(this.cards[0]) !== -1;
            this.fromSideboard = this.deck.Sideboard.indexOf(this.cards[0]) !== -1;
            this.fromMaybeboard = this.deck.Reserve.indexOf(this.cards[0]) !== -1;

            this.props.globalState.onBlurRequired.notifyObservers(true);
        });

        document.addEventListener('keydown', (evt) => {
            if (!this.state.isVisible) {
                return;
            }

            switch (evt.keyCode) {
                case 27: {
                    this.onConfirmation(false);
                    return;
                }
            }
        });
    }

    moveTo(index: number) {
        for (const entry of this.cards) {
            const currentCount = entry.EntryCount;
            const isCommander = entry.isCommander;

            Deck.Update(this.deck, this.fromMain ? 0 : this.fromSideboard ? 1 : 2, entry.CardID, 0);

            Deck.Update(
                this.deck,
                index,
                entry.CardID,
                currentCount + Deck.GetCurrentCount(this.deck, index, entry.CardID)
            );
            Deck.UpdateCommanderStatus(this.deck, index, entry.CardID, isCommander);
        }
        this.onConfirmation(true);
    }

    onConfirmation(value: boolean) {
        this.props.globalState.onMoveInsideDeckDone.notifyObservers(value);

        this.props.globalState.onBlurRequired.notifyObservers(false);

        this.setState({ isVisible: false });
    }

    render() {
        if (!this.state.isVisible) {
            return null;
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);

        return (
            <div className="move-inside-deck-dialog-container">
                <div className="move-inside-deck-dialog-background"></div>
                <div className={'move-inside-deck-dialog' + (this.props.darkMode ? ' dark' : '')}>
                    <div className="move-inside-deck-dialog-title">{this.state.title}</div>
                    <div className="move-inside-deck-dialog-list">
                        {!this.fromMain && (
                            <Button
                                tabIndex={2}
                                className="move-inside-deck-dialog-button"
                                globalState={this.props.globalState}
                                onClicked={() => this.moveTo(0)}
                            >
                                {translate('Deck')}
                            </Button>
                        )}
                        {!this.fromSideboard && (
                            <Button
                                tabIndex={2}
                                className="move-inside-deck-dialog-button"
                                globalState={this.props.globalState}
                                onClicked={() => this.moveTo(1)}
                            >
                                {translate('sideboard')}
                            </Button>
                        )}
                        {!this.fromMaybeboard && (
                            <Button
                                tabIndex={2}
                                className="move-inside-deck-dialog-button"
                                globalState={this.props.globalState}
                                onClicked={() => this.moveTo(2)}
                            >
                                {translate('Maybeboard')}
                            </Button>
                        )}
                        <Button
                            tabIndex={3}
                            className="move-inside-deck-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(false)}
                        >
                            {translate('Cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
