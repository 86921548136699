import { FetchResponse } from './syncProvider';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import { GlobalState } from '../globalState';
import { Collection } from '../entities/collection';
import { faTreasureChest } from '@fortawesome/pro-solid-svg-icons';

export class UrzaDirectSyncProvider {
    private _serverUrl = 'https://urzabackend.azurewebsites.net/';
    public name = 'UrzaDirect';
    public icon = faTreasureChest as Icon;

    public disconnect() {
        // Nothing to do
    }

    public loginAsync(): Promise<void> {
        Collection.GlobalState.workLocally = false;
        Collection.GlobalState.storeSettings('provider', 'UrzaDirect', true);
        return Promise.resolve();
    }

    public saveDataAsync(filename: string, data: string, _onTokenDown: () => void): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const storedToken = GlobalState.LoadSettings('PatreonToken', '');
                const response = await fetch(this._serverUrl + 'storage/store'
                    , {
                        method: 'POST',
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            data: data,
                            token: storedToken,
                            filename: filename
                        })
                    });
                const message = await response.text();

                if (message) {
                    reject(message);
                } else {
                    resolve();
                }
            } catch (e) {
                reject(e);
            }
        });
    }

    public fetchFileAsync(filename: string, _onTokenDown: () => void): Promise<FetchResponse> {
        return new Promise(async (resolve) => {
            try {
                const storedToken = GlobalState.LoadSettings('PatreonToken', '');
                const response = await fetch(this._serverUrl + `storage/load/?token=${storedToken}&filename=${filename}`
                    , {
                        method: 'GET'
                    });
                const content = await response.json();

                const fetchResponse = new FetchResponse();
                if (content.status) {
                    fetchResponse.error = content.title;
                } else {
                    fetchResponse.content = content.data;
                    fetchResponse.dateModified = new Date(content.date);
                }

                resolve(fetchResponse);
            } catch (e) {
                const fetchResponse = new FetchResponse();
                fetchResponse.error = e.message;

                resolve(fetchResponse);
            }
        });
    }

    public fetchUrl(url: string, response: FetchResponse): Promise<string> {
        return Promise.resolve(response.content);
    }
}
