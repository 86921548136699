declare const gtag: any;

export class Analytics {
    public static PageView(page: string) {
        gtag('config', 'UA-80640438-2', {
            page_title: page,
            page_path: `/${page}`
        });
    }

    public static IsIOs() {
        if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
            return true;
        }

        return navigator.maxTouchPoints > 0 && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
    }
}
