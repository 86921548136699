import * as React from 'react';
import { GlobalState, SelectionData } from 'globalState';
import { Observer } from '../tools/observable';
import { Nullable } from '../tools/nullable';
import { Collection } from '../entities/collection';

interface IMenuItemProps {
    label?: string;
    index?: number;
    globalState: GlobalState;
    url?: string;
    large?: boolean;
    onClick?: () => void;
    additionalSelectionCheck?: (selection: SelectionData) => boolean;
    children: React.ReactNode;
}

export class MenuItem extends React.Component<IMenuItemProps, { isSelected: boolean }> {
    private onSelectedMenuIndexObserver: Nullable<Observer<SelectionData>>;

    constructor(props: IMenuItemProps) {
        super(props);

        this.state = { isSelected: false };
    }

    UNSAFE_componentWillMount() {
        this.onSelectedMenuIndexObserver = this.props.globalState.onSelectedMenuIndexChanged.add((selection) => {
            this.setState({
                isSelected:
                    selection.index === this.props.index &&
                    (!this.props.additionalSelectionCheck || this.props.additionalSelectionCheck(selection))
            });
        });
    }

    componentWillUnmount() {
        this.props.globalState.onSelectedMenuIndexChanged.remove(this.onSelectedMenuIndexObserver);
    }

    select() {
        if (this.props.onClick) {
            this.props.onClick();
        }
        if (this.props.index === undefined) {
            return;
        }
        if (this.props.url) {
            this.props.globalState.showConfirmDialog(this.props.globalState.translate('PatreonDesc')).then((value) => {
                if (!value) {
                    return;
                }

                const link = document.createElement('a');
                link.setAttribute('href', this.props.url!);
                link.target = '_blank';
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                setTimeout(function () {
                    document.body.removeChild(link);
                }, 0);
            });
            return;
        }
        if (this.props.index === -4) {
            const cards = Collection.Cards;
            const card = cards[(Math.random() * cards.length) | 0];
            this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({
                index: this.props.index,
                uniqueID: 0,
                cardIds: [card.id]
            });
            return;
        }

        this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({ index: this.props.index });
    }

    render() {
        return (
            <div className={'menu-item' + (this.state.isSelected ? ' selected' : '')} title={this.props.label}>
                <div
                    className={'menu-item-internal' + (this.props.large ? ' menu-item-large' : '')}
                    onClick={() => this.select()}
                >
                    <div className="menu-item-icon">{this.props.children}</div>
                    <div className="menu-item-label">{this.props.label}</div>
                </div>
            </div>
        );
    }
}
