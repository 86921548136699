import * as React from 'react';
import { GlobalState } from '../globalState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Nullable } from '../tools/nullable';
import { Observer } from '../tools/observable';

interface IExpandableFooterButtonProps {
    globalState: GlobalState;
    icon: IconDefinition;
    text: string;
    isExpanded: boolean;
    isActivated?: boolean;
    highlightKey: string;
    id?: string;
    excludedClicks?: string[];
    children: React.ReactNode;
}

export class ExpandableFooterButton extends React.Component<IExpandableFooterButtonProps, { isOpened: boolean }> {
    private onClickHandler: () => void;
    private rootRef: React.RefObject<HTMLDivElement>;
    private onHighlightFooterButtonRequiredObserver: Nullable<Observer<string>>;

    constructor(props: IExpandableFooterButtonProps) {
        super(props);
        this.state = { isOpened: false };

        this.onClickHandler = this.onDocumentClick.bind(this);
        this.rootRef = React.createRef();

        this.onHighlightFooterButtonRequiredObserver = this.props.globalState.onHighlightFooterButtonRequired.add(key => {
            if (key === this.props.highlightKey) {
                this.rootRef.current?.classList.add('highlighted');
                setTimeout(() => {
                    this.rootRef.current?.classList.remove('highlighted');
                }, 500);

            }
        });
    }

    oncomponentWillUnmount() {
        document.removeEventListener('click', this.onClickHandler);
        this.props.globalState.onHighlightFooterButtonRequired.remove(this.onHighlightFooterButtonRequiredObserver);
    }

    onDocumentClick(evt: MouseEvent) {
        let target = evt.target as any;

        while (target) {

            if (target.className) {
                if (target.className.indexOf && target.className.indexOf('actionButton-panel') !== -1) {
                    return;
                }

                if (this.props.excludedClicks && this.props.excludedClicks.indexOf(target.className) !== -1) {
                    return;
                }
            }

            target = target.parentElement;
        }
        this.setState({ isOpened: false });
        document.removeEventListener('click', this.onClickHandler);
    }

    expand() {
        const newState = !this.state.isOpened;
        this.setState({ isOpened: newState });

        setTimeout(() => {
            if (newState) {
                document.addEventListener('click', this.onClickHandler);
            } else {
                document.removeEventListener('click', this.onClickHandler);
            }
        });
    }

    render() {
        return (
            <div style={{
                position: 'relative'
            }}>
                <div
                    id={this.props.id}
                    ref={this.rootRef}
                    className={
                        'actionButton' +
                        (this.props.isExpanded ? ' expanded' : '') +
                        (this.props.isActivated ? ' activated' : '')
                    }
                    title={this.props.text}
                    onClick={() => this.expand()}
                >
                    <div className="actionButton-icon">
                        <FontAwesomeIcon icon={this.props.icon} />
                    </div>
                    <div className={'actionButton-label' + (!this.props.isExpanded ? ' collapsed' : '')}>
                        {this.props.text}
                    </div>
                </div>
                {
                    this.state.isOpened &&
                    <div className="actionButton-panel enterAnimation">{this.props.children}</div>
                }
            </div>
        );
    }
}
