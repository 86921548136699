import * as React from 'react';
import { GlobalState } from '../globalState';
import { Pie } from 'react-chartjs-2';
import { ArcElement, CategoryScale, Chart, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface IPieChartProps {
    globalState: GlobalState;
    className: string;
    labels: string[];
    colors: string[];
    numbers: number[];
    title: string;
}

export class PieChart extends React.Component<IPieChartProps> {
    constructor(props: IPieChartProps) {
        super(props);
    }

    render() {
        const darkMode = GlobalState.LoadBoolSettings('DarkMode', false);
        const borderColors: string[] = [];

        for (let index = 0; index < this.props.colors.length; index++) {
            borderColors.push(darkMode ? '#333333' : '#EEEEEE');
        }

        const data: {
            labels: string[];
            datasets: {
                data: number[];
                backgroundColor: string[];
                hoverBackgroundColor: string[];
                borderColor: string[];
                datalabels: any;
            }[];
        } = {
            labels: this.props.labels,
            datasets: [
                {
                    data: this.props.numbers,
                    backgroundColor: this.props.colors,
                    hoverBackgroundColor: this.props.colors,
                    borderColor: borderColors,
                    datalabels: {
                        align: 'center',
                        anchor: 'center'
                    }
                }
            ]
        };

        const options = {
            maintainAspectRatio: false,
            plugins: {
                title: {
                    display: true,
                    text: this.props.title,
                    font: {
                        color: darkMode ? '#BBBBBB' : '#444444',
                        size: 16
                    }
                },
                datalabels: {
                    color: 'white',
                    formatter: (value: number, context: any) => {
                        const dataset = context.dataset;
                        const total = dataset.data.reduce((sum: number, current: number) => sum + current, 0);
                        return `${((value * 100) / total).toFixed(0)}%`;
                    }
                }
            }
        };

        Chart.register(CategoryScale);
        Chart.register(LinearScale);
        Chart.register(ArcElement);
        Chart.register(Title, Tooltip, Legend);
        Chart.register(ChartDataLabels);

        return (
            <div className={this.props.className}>
                <div className="pie-chart">
                    <Pie data={data} options={options as any} />
                </div>
            </div>
        );
    }
}
