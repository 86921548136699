import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faAlien, faExclamation, faMoneyBill, faStar, faSun } from '@fortawesome/pro-solid-svg-icons';
import { GlobalState } from '../globalState';
import { Nullable } from '../tools/nullable';

export class CustomIcon {
    public static CustomIcons: CustomIcon[] = [];

    public constructor(
        public icon: Nullable<IconDefinition>,
        public label: string
    ) {}

    public static CreateList(gs: GlobalState) {
        CustomIcon.CustomIcons.push(new CustomIcon(null, gs.translate('None')));
        CustomIcon.CustomIcons.push(new CustomIcon(faStar, gs.translate('Star')));
        CustomIcon.CustomIcons.push(new CustomIcon(faExclamation, gs.translate('Exclamation')));
        CustomIcon.CustomIcons.push(new CustomIcon(faSun, gs.translate('Sun')));
        CustomIcon.CustomIcons.push(new CustomIcon(faAlien, gs.translate('Alien')));
        CustomIcon.CustomIcons.push(new CustomIcon(faMoneyBill, gs.translate('Money')));
    }
}

