import * as React from 'react';
import { GlobalState } from '../globalState';
import { SetCard } from '../controls/cards/setCard';
import { Block } from '../entities/block';
import { Collection } from '../entities/collection';
import { Set } from '../entities/set';
import { LabelledTexbox } from '../controls/labelledTextbox';
import { StringHelpers } from '../tools/stringHelpers';

require('../scss/setsPage.scss');

interface ISetsPageProps {
    globalState: GlobalState;
    parentBlock: Block;
}

export class SetsPage extends React.Component<ISetsPageProps, { filter: string }> {
    private scroller: HTMLElement;

    constructor(props: ISetsPageProps) {
        super(props);

        this.state = { filter: this.props.globalState.pageCustomData.filter || '' };
    }

    onScroll() {
        this.props.globalState.pageCustomData.scroll = this.scroller.scrollTop;
    }

    componentDidMount() {
        this.scroller = document.getElementById('scrollElement')!;

        const customData = this.props.globalState.pageCustomData;
        const scrollPosition = customData && customData.scroll ? customData.scroll : 0;
        this.scroller.scrollTop = scrollPosition;
    }

    blurFilter(evt: React.KeyboardEvent<HTMLInputElement>) {
        if (evt.which === 13) {
            (evt.nativeEvent.srcElement! as HTMLInputElement).blur();
        }
    }

    render() {
        const block = this.props.parentBlock;
        const source = block.sets.slice(0);
        const additionals: Set[] = [];

        const sets = source.sort((a, b) => {
            if (a.orderInBlock === b.orderInBlock) {
                return 0;
            }

            if (a.orderInBlock > b.orderInBlock) {
                return 1;
            }

            return -1;
        });

        if (GlobalState.LoadBoolSettings('DisplayLinkedSet', false)) {
            if (block.name.indexOf('Promos') === -1) {
                // Not a promo block
                const copyOfSource = source.slice(0);

                Collection.Sets.forEach((e) => {
                    if (copyOfSource.indexOf(e) !== -1) {
                        return;
                    }

                    copyOfSource.forEach((ee) => {
                        if (StringHelpers.StartsWith(e.name, ee.name) && additionals.indexOf(e) === -1) {
                            const insertionIndex = source.indexOf(ee);
                            source.splice(insertionIndex + 1, 0, e);
                            additionals.push(e);
                        }
                    });
                });
            }
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);
        let active = sets.slice();

        if (this.props.globalState.hideCompleted) {
            active = active.filter((s) => !s.isComplete);
        }

        if (this.props.globalState.hideZeroCardSet) {
            active = active.filter((e) => e.inCollectionCardCount > 0);
        }

        const list = active.filter((s) => {
            if (this.state.filter) {
                const filter = this.state.filter.toLowerCase();

                if (s.name.toLocaleLowerCase().indexOf(filter) === -1) {
                    return false;
                }
            }
            return true;
        });

        setTimeout(() => {
            this.props.globalState.onHeaderTextChanged.notifyObservers(
                `${sets.length - active.length} / ${sets.length}`
            );
        });

        const suggestionForNames = active.map((s) => s.name);

        return (
            <div className="page">
                <div className="expansions-page">
                    <div className="expansions-page-filter">
                        <LabelledTexbox
                            label={translate('Filter')}
                            globalState={this.props.globalState}
                            onKeyPress={(evt) => this.blurFilter(evt)}
                            onChange={(value) => {
                                this.setState({ filter: value });
                                this.props.globalState.pageCustomData.filter = value;
                            }}
                            suggestions={suggestionForNames}
                            value={this.state.filter}
                            placeholder={translate('AnyWord')}
                        />
                    </div>
                    <div className="expansions" id="scrollElement" onScroll={() => this.onScroll()}>
                        {list.map((s) => {
                            return <SetCard key={s.name} globalState={this.props.globalState} set={s} />;
                        })}
                    </div>
                </div>
            </div>
        );
    }
}
