import { Set } from '../../entities/set';
import { Collection } from '../../entities/collection';
import { Observable } from '../../tools/observable';
import { Card } from '../../entities/card';
import { CancellationToken } from '../../tools/cancellationToken';
import { PriceTools } from '../../tools/priceTools';
import { ICard } from '../../entities/ICard';
import { Nullable } from '../nullable';

interface IScryfallData {
    name: string;
    artist: string;
    collector_number: string;
    multiverse_ids: number[];
    card_faces: IScryfallData[];
    image_uris: {
        art_crop: string;
    };
    prices: { usd: number; eur: number; usd_foil: number; usd_etched: number };
}

export class Scryfall {
    private static AvailableFetch = 2;

    private static GetCorrectCode(code: string): string {
        switch (code) {
            case 'PAL96':
                return 'PARL';
            case 'PGP1':
                return 'G18';
            case 'DD3':
                return 'DVD';
            case 'TMED1':
            case 'TMED2':
                return 'TMED';
            case 'MED1':
            case 'MED2':
            case 'MED3':
                return 'MED';
            case 'G98':
                return 'JGP';
            case 'P01':
                return 'MPR';
            case 'CELD':
                return 'ELD';
        }

        return code;
    }

    private static GetCardFromList(card: ICard, target: IScryfallData[]) {
        if (card.scryfallNumber) {
            return target.filter((e) => e.collector_number === card.scryfallNumber);
        }

        let entry = target.filter((e) => e.name === card.nameEn);

        if (entry.length !== 1 && card.set.name === 'Unhinged') {
            // Special case for alternate foil
            entry = target.filter((e) => e.collector_number === card.number + card.numberComplement);
            const otherEntry = target.filter((e) => e.collector_number === card.number + '★');

            if (otherEntry.length === 1) {
                entry[0].prices.usd_foil = otherEntry[0].prices.usd_foil;
            }
        }

        if (entry.length === 0) {
            const fixedName = card.nameEn.replace(/\(v\..+\)/, '').trim();
            entry = target.filter((e) => e.name === fixedName && e.artist === card.author);
        }

        if (entry.length !== 1) {
            entry = target.filter((e) => e.multiverse_ids[0] === card.multiverseIdEn);
        }

        if (entry.length !== 1) {
            entry = target.filter((e) => e.collector_number === card.number + card.numberComplement);
        }

        return entry;
    }

    private static FetchFromUrl(url: string, target: IScryfallData[]): Promise<void> {
        return fetch(url)
            .then((response) => {
                this.AvailableFetch++;

                return response.json();
            })
            .then((list: IScryfallData[] | IScryfallData) => {
                if ((list as IScryfallData[]).length) {
                    target.push(...(list as IScryfallData[]));
                } else {
                    target[0] = list as IScryfallData;
                }

                return Promise.resolve();
            });
    }

    private static ExtractPrice(card: Card, expansion: Set, entry: IScryfallData[], cardUpdateObservable?: Observable<{ card: Card; oldPrice: number; notFound: boolean }>) {
        expansion.pricesWereUpdated = true;

        let price = entry[0].prices.usd;
        const foil = entry[0].prices.usd_foil;
        const specialFoil = entry[0].prices.usd_etched;

        if (foil !== undefined && foil > 0) {
            card.foilPrice = PriceTools.ToEuro(foil);
            if (!price) {
                price = foil;
            }
        } else if (specialFoil !== undefined && specialFoil > 0) {
            card.foilPrice = PriceTools.ToEuro(specialFoil);
            if (!price) {
                price = specialFoil;
            }
        }

        if (!price) {
            price = PriceTools.ToDollar(entry[0].prices.eur);
        }

        if (price !== undefined && price > 0) {
            const oldPrice = card.price;
            card.price = PriceTools.ToEuro(price);
            if (cardUpdateObservable) {
                cardUpdateObservable.notifyObservers({
                    card: card,
                    oldPrice: oldPrice,
                    notFound: false
                });
            }
        }
    }

    private static WaitForTimeout(ms: number = 0) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    private static async GetExpansionPrice(
        expansion: Set,
        targetCard: Nullable<Card>,
        progressObservable: Observable<number>,
        cardUpdateObservable?: Observable<{ card: Card; oldPrice: number; notFound: boolean }>,
        lockObject?: CancellationToken
    ) {
        const code = this.GetCorrectCode(expansion.code);
        const target = new Array<IScryfallData>();

        expansion.pricesWereUpdated = false;

        await this.FetchFromUrl(
            `https://urzabackend.azurewebsites.net/scryfall/setprices?setId=${code}`,
            target
        );

        for (const card of expansion.sortedCards) {
            if (targetCard && card !== targetCard) {
                continue;
            }

            if (card.isBackFace) {
                progressObservable.notifyObservers(1);
                continue;
            }

            await this.WaitForTimeout();

            if (lockObject && lockObject.isCancellationRequested) {
                continue;
            }

            const entry = this.GetCardFromList(card, target);

            if (entry.length === 1) {
                this.ExtractPrice(card, expansion, entry, cardUpdateObservable);
            } else if (cardUpdateObservable) {
                await this.FetchFromUrl(`https://api.scryfall.com/cards/${card.scryfallId}?format=json&pretty=true`, target);
                if (target.length === 1) {
                    this.ExtractPrice(card, expansion, target, cardUpdateObservable);
                } else {
                    cardUpdateObservable.notifyObservers({
                        card: card,
                        oldPrice: card.price,
                        notFound: true
                    });
                }

            }

            progressObservable.notifyObservers(1);
        }
    }

    public static UpdateExpansionPrice(
        set: Set,
        card: Nullable<Card>,
        progressObservable: Observable<number>,
        expansionUpdateObservable?: Observable<{ set: Set; oldPrice: number; notFound: boolean }>,
        cardUpdateObservable?: Observable<{ card: Card; oldPrice: number; notFound: boolean }>,
        lockObject?: CancellationToken
    ) {
        const oldPrice = set.totalPrice;

        return this.GetExpansionPrice(set, card, progressObservable, cardUpdateObservable, lockObject).then(
            () => {
                if (expansionUpdateObservable) {
                    expansionUpdateObservable.notifyObservers({
                        set: set,
                        oldPrice: oldPrice,
                        notFound: !set.pricesWereUpdated
                    });
                }
            }
        );
    }

    public static UpdateAllExpansions(
        expansionUpdateObservable: Observable<{ set: Set; oldPrice: number; notFound: boolean }>,
        progressObservable: Observable<number>,
        lockObject: CancellationToken,
        onlyCollection = false
    ): Promise<void[]> {
        let sets = Collection.SortedSets;

        if (onlyCollection) {
            sets = sets.filter((s) => s.inCollectionCardCount > 0);
        }

        return Promise.all(
            sets.map(
                (expansion): Promise<void> => {
                    if (this.AvailableFetch > 0) {
                        this.AvailableFetch--;
                        return new Promise((resolve) => {
                            return this.UpdateExpansionPrice(
                                expansion,
                                null,
                                progressObservable,
                                expansionUpdateObservable,
                                undefined,
                                lockObject
                            )
                                .then(() => {
                                    resolve();
                                })
                                .catch((_err) => {
                                    resolve(); // Swallow it
                                });
                        });
                    }

                    return new Promise((resolve) => {
                        const interval = setInterval(() => {
                            if (lockObject && lockObject.isCancellationRequested) {
                                clearInterval(interval);
                                return resolve();
                            }
                            if (this.AvailableFetch > 0) {
                                this.AvailableFetch--;
                                clearInterval(interval);
                                setTimeout(() => {
                                    this.UpdateExpansionPrice(
                                        expansion,
                                        null,
                                        progressObservable,
                                        expansionUpdateObservable,
                                        undefined,
                                        lockObject
                                    )
                                        .then(() => resolve())
                                        .catch((_err) => {
                                            resolve(); // Swallow it
                                        });
                                }, 250);
                                return;
                            }
                        }, 500);
                    });
                }
            )
        );
    }

    public static GetCardLink(card: ICard): string {
        let cardIndex = '';

        if (card.scryfallNumber) {
            cardIndex = card.scryfallNumber;
        } else {
            cardIndex = card.number + (card.isPromo ? 's' : '') + card.numberComplement;
        }

        const setCode = card.set.code;

        return `https://scryfall.com/card/${this.GetCorrectCode(
            this.GetCorrectCode(setCode)
        ).toLowerCase()}/${cardIndex}`;
    }
}
