export class TextRecognition {
    private static SubscriptionKey = '6435db13f3ae40b98fef7d39c62f8f64';

    private static ProcessResult(data: {
        regions: {
            lines: {
                words: {
                    text: string;
                }[];
            }[];
        }[];
    }) {
        const strings: string[] = [];

        data.regions.forEach((region) => {
            region.lines.forEach((line) => {
                const build = line.words.map((w) => w.text).join(' ');
                strings.push(build);
            });
        });
        return strings;
    }

    public static ScanAsync(
        context: CanvasRenderingContext2D,
        width: number,
        height: number,
        lang: string
    ): Promise<string[]> {
        return new Promise((resolve, reject) => {
            const uriBase = 'https://eastus.api.cognitive.microsoft.com/vision/v2.1/ocr';

            const xhr = new XMLHttpRequest();
            xhr.open('POST', uriBase + `?language=${lang}&detectOrientation=false`, true);
            xhr.setRequestHeader('Content-Type', 'application/octet-stream');
            xhr.setRequestHeader('Ocp-Apim-Subscription-Key', this.SubscriptionKey);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    if (xhr.status === 400) {
                        reject();
                        return;
                    }
                    resolve(this.ProcessResult(JSON.parse(xhr.responseText)));
                }
            };

            // Edge
            if (!context.canvas.toBlob) {
                const binStr = atob(context.canvas.toDataURL('image/jpg', 0.5).split(',')[1]);
                const len = binStr.length;
                const arr = new Uint8Array(len);

                for (let i = 0; i < len; i++) {
                    arr[i] = binStr.charCodeAt(i);
                }
                xhr.send(arr);
                return;
            }

            context.canvas.toBlob((blob) => {
                const reader = new FileReader();
                reader.addEventListener('loadend', () => {
                    const arrayBuffer = reader.result;

                    xhr.send(arrayBuffer);
                });
                reader.readAsArrayBuffer(blob!);
            }, 'image/jpg');
        });
    }
}
