import * as React from 'react';
import { GlobalState } from '../globalState';
import { Button } from './button';
import { Set } from '../entities/set';
import { Collection } from '../entities/collection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Checkbox } from './checkbox';
import { LabelledTexbox } from './labelledTextbox';

interface IExpansionPickerProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class ExpansionPicker extends React.Component<
    IExpansionPickerProps,
    {
        isVisible: boolean;
        title: string;
        sets: Set[];
        owned: boolean;
        cheapest: boolean;
        excludePromos: boolean;
        filter: string;
    }
> {
    constructor(props: IExpansionPickerProps) {
        super(props);

        this.state = {
            isVisible: false,
            title: '',
            sets: [],
            owned: false,
            cheapest: false,
            excludePromos: false,
            filter: ''
        };
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onExpansionPickerRequired.add((value) => {
            this.setState({ isVisible: true, title: value, sets: [] });
            this.props.globalState.onBlurRequired.notifyObservers(true);
        });

        document.addEventListener('keydown', (evt) => {
            if (!this.state.isVisible) {
                return;
            }

            switch (evt.keyCode) {
                case 27: {
                    this.onConfirmation(false);
                    return;
                }
            }
        });
    }

    onConfirmation(value: boolean) {
        if (value) {
            this.props.globalState.onExpansionsPicked.notifyObservers({
                sets: this.state.sets,
                owned: this.state.owned,
                cheapest: this.state.cheapest,
                excludePromos: this.state.excludePromos
            });
        } else {
            this.props.globalState.onExpansionsPicked.notifyObservers();
        }

        this.props.globalState.onBlurRequired.notifyObservers(false);

        this.setState({ isVisible: false });
    }

    select(e: Set) {
        const index = this.state.sets.indexOf(e);

        if (index === -1) {
            this.state.sets.push(e);
        } else {
            this.state.sets.splice(index, 1);
        }

        this.forceUpdate();
    }

    blurFilter(evt: React.KeyboardEvent<HTMLInputElement>) {
        if (evt.which === 13) {
            (evt.nativeEvent.srcElement! as HTMLInputElement).blur();
        }
    }

    render() {
        if (!this.state.isVisible) {
            return null;
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);

        return (
            <div className="expansion-picker-container">
                <div className="expansion-picker-background"></div>
                <div className={'expansion-picker' + (this.props.darkMode ? ' dark' : '')}>
                    <div className="expansion-picker-title">{this.state.title}</div>
                    <div className="expansion-picker-priority">
                        <div className="priority-content">
                            <div className="priority-content-label">{translate('OnlyOwned')}</div>
                            <div className="priority-checkbox">
                                <Checkbox
                                    globalState={this.props.globalState}
                                    isChecked={this.state.owned}
                                    onCheckedChanged={(value) => {
                                        this.setState({ owned: value });
                                    }}
                                ></Checkbox>
                            </div>
                        </div>
                        <div className="priority-content">
                            <div className="priority-content-label">{translate('Cheapest')}</div>
                            <div className="priority-checkbox">
                                <Checkbox
                                    globalState={this.props.globalState}
                                    isChecked={this.state.cheapest}
                                    onCheckedChanged={(value) => {
                                        this.setState({ cheapest: value });
                                    }}
                                ></Checkbox>
                            </div>
                        </div>
                        <div className="priority-content">
                            <div className="priority-content-label">{translate('ExcludePromos')}</div>
                            <div className="priority-checkbox">
                                <Checkbox
                                    globalState={this.props.globalState}
                                    isChecked={this.state.excludePromos}
                                    onCheckedChanged={(value) => {
                                        this.setState({ excludePromos: value });
                                    }}
                                ></Checkbox>
                            </div>
                        </div>
                        <div className="priority-content">
                            <div className="priority-textbox">
                                <LabelledTexbox
                                    label=''
                                    onlyInput={true}
                                    globalState={this.props.globalState}
                                    onKeyPress={(evt) => this.blurFilter(evt)}
                                    onChange={(value) => {
                                        this.setState({ filter: value });
                                    }}
                                    value={this.state.filter}
                                    placeholder={translate('Filter')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="expansion-picker-data">
                        {Collection.SortedSets.filter(s => !s.isArtSeries && (!this.state.filter || s.name.toLowerCase().indexOf(this.state.filter.toLowerCase()) !== -1)).map((e) => {
                            return (
                                <div key={e.name} className="expansion-picker-expansion" onClick={() => this.select(e)}>
                                    <div className="expansion-picker-expansion-icon">
                                        <img src={e.icon} alt='icon'/>
                                    </div>
                                    {this.state.sets.indexOf(e) !== -1 && (
                                        <div className="expansion-picker-expansion-check">
                                            <FontAwesomeIcon icon={faCheckCircle} />
                                        </div>
                                    )}
                                    <div
                                        className={
                                            'expansion-picker-expansion-label' +
                                            (this.state.sets.indexOf(e) !== -1 ? ' selected' : '')
                                        }
                                    >
                                        {e.name}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="expansion-picker-accept">
                        <Button
                            tabIndex={2}
                            className="expansion-picker-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(true)}
                        >
                            {translate('OK')}
                        </Button>
                    </div>
                    <div className="expansion-picker-cancel">
                        <Button
                            tabIndex={3}
                            className="expansion-picker-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(false)}
                        >
                            {translate('Cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
