import * as React from 'react';
import { GlobalState } from '../globalState';

interface IDualSliderProps {
    globalState: GlobalState;
    min: number;
    max: number;
    defaultValue0: number;
    defaultValue1: number;
    onChange: (value0: number, value1: number) => void;
    className?: string;
    darkMode: boolean;
}

export class DualSlider extends React.Component<IDualSliderProps, { value0: number; value1: number }> {
    constructor(props: IDualSliderProps) {
        super(props);

        this.state = { value0: this.props.defaultValue0, value1: this.props.defaultValue1 };
    }

    shouldComponentUpdate(nextProps: IDualSliderProps, nextState: { value0: number; value1: number }) {
        if (nextProps !== this.props) {
            if (nextProps.defaultValue0 !== this.props.defaultValue0) {
                nextState.value0 = Math.min(this.props.max, nextProps.defaultValue0);
            }

            if (nextProps.defaultValue1 !== this.props.defaultValue1) {
                nextState.value1 = Math.max(this.props.min, nextProps.defaultValue1);
            }
        }

        return true;
    }

    onChange0(value: number) {
        if (this.state.value1 < value) {
            value = this.state.value1;
        }

        this.props.onChange(value, this.state.value1);
        this.setState({ value0: value });
    }

    onChange1(value: number) {
        if (this.state.value0 > value) {
            this.props.onChange(value, value);
            this.setState({ value1: value, value0: value });
            return;
        }

        this.props.onChange(this.state.value0, value);
        this.setState({ value1: value });
    }

    clickOnBack(evt: React.MouseEvent<HTMLDivElement>) {
        const dist = this.props.max - this.props.min;

        const position = (evt.nativeEvent.offsetX / (evt.target as HTMLDivElement).clientWidth) * dist;

        const newValue = Math.min(this.props.max, Math.max(this.props.min, this.props.min + position));
        const dist0 = Math.abs(this.state.value0 - newValue);
        const dist1 = Math.abs(this.state.value1 - newValue);
        const minDist = dist * 0.05;

        if (dist0 < minDist || dist1 < minDist) {
            return;
        }

        if (dist0 < dist1) {
            this.setState({ value0: newValue });
            this.props.onChange(newValue, this.state.value1);
        } else {
            this.setState({ value1: newValue });
            this.props.onChange(this.state.value0, newValue);
        }
    }

    render() {
        const dist = this.props.max - this.props.min;
        const step0 = (this.state.value0 - this.props.min) / dist;
        const step1 = (this.state.value1 - this.props.min) / dist;
        const backgroundColor = this.props.darkMode ? '#555555' : '#BBBBBB';
        const accentColor = GlobalState.LoadSettings('AccentColor', '#CC7529');
        const fillStyle = {
            background: `linear-gradient(to right, ${backgroundColor} ${step0 * 100}%, ${accentColor} ${
                step0 * 100
            }%, ${accentColor} ${step1 * 100}%,  ${backgroundColor} ${step1 * 100}%)`
        };

        return (
            <div
                className={'dual-slider-container' + (this.props.className ? ' ' + this.props.className : '')}
                onClick={(evt) => this.clickOnBack(evt)}
            >
                <input
                    type="range"
                    style={fillStyle}
                    min={this.props.min}
                    max={this.props.max}
                    onChange={(evt) => this.onChange0(parseFloat(evt.target.value))}
                    value={this.state.value0}
                    className="dual-slider slider-left"
                />
                <input
                    type="range"
                    min={this.props.min}
                    max={this.props.max}
                    onChange={(evt) => this.onChange1(parseFloat(evt.target.value))}
                    value={this.state.value1}
                    className="dual-slider slider-right"
                />
            </div>
        );
    }
}
