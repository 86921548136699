import { Deck } from '../entities/deck';
import { Collection } from '../entities/collection';
import { GlobalState } from '../globalState';
import { DeckGroup } from '../entities/deckGroup';
import { Nullable } from './nullable';
import { StringHelpers } from './stringHelpers';
import { DeckEntry } from '../entities/deckEntry';
import { Card } from '../entities/card';
import { GUID } from './guid';
import { Set } from '../entities/set';
import { DownloadTools } from './downloadTools';

export class DeckTools {
    private static _PrepareCards(cards: DeckEntry[]) {
        const cardsToExport: { name: string, entryCount: number, isCommander: boolean }[] = [];

        const sortFunc = (a: DeckEntry, b: DeckEntry) => {
            const cardA = Collection.CardsIndex[a.CardID];
            const cardB = Collection.CardsIndex[b.CardID];

            if (a.isCommander !== b.isCommander) {
                if (a.isCommander) {
                    return -1;
                }

                return 1;
            }

            if (cardA.nameEn < cardB.nameEn) {
                return -1;
            }

            if (cardA.nameEn > cardB.nameEn) {
                return 1;
            }

            return 0;

        };

        cards.sort(sortFunc).forEach((card) => {
            const cardToExport = {
                name: Collection.CardsIndex[card.CardID].nameEn.trim(),
                entryCount: card.EntryCount,
                isCommander: card.isCommander
            };

            for (const other of cardsToExport) {
                if (other.name === cardToExport.name) {
                    other.entryCount += cardToExport.entryCount;
                    return;
                }
            }
            cardsToExport.push(cardToExport);
        });

        return cardsToExport;
    }

    public static ExportToTXT(deck: Deck, globalState: GlobalState) {
        let csvContent = '';

        this._PrepareCards(deck.Cards).forEach((entry) => {
            csvContent += entry.entryCount + ' ';
            csvContent += entry.name + (entry.isCommander ? ' *CMDR* ': '') + '\r\n';
        });

        if (deck.Sideboard.length) {
            csvContent += 'Sideboard:\r\n\r\n';
            this._PrepareCards(deck.Sideboard).forEach((entry) => {
                csvContent += entry.entryCount + ' ';
                csvContent += entry.name + (entry.isCommander ? ' *CMDR* ': '') + '\r\n';
            });
        }

        return DownloadTools.DownloadAsync(csvContent, deck.Name, globalState, '.txt', globalState.translate('TxtFile'), true);
    }

    public static ExportToCSV(deck: Deck, globalState: GlobalState) {
        let csvContent = '"sep=,"\r\n';
        const header = ['Count', 'Name', 'Section'];

        csvContent += header.join(',') + '\r\n';

        deck.Cards.forEach((entry) => {
            const line = [];

            const card = Collection.CardsIndex[entry.CardID];

            line.push(entry.EntryCount);
            line.push(`"${card.nameEn}"`);
            line.push('main');

            csvContent += line.join(',') + '\r\n';
        });

        deck.Sideboard.forEach((entry) => {
            const line = [];

            const card = Collection.CardsIndex[entry.CardID];

            line.push(entry.EntryCount);
            line.push(`"${card.nameEn}"`);
            line.push('sideboard');

            csvContent += line.join(',') + '\r\n';
        });

        const universalBOM = '\uFEFF';
        const data = universalBOM + csvContent;

        return DownloadTools.DownloadAsync(data, deck.Name, globalState,'.csv', globalState.translate('CsvFile'), true);
    }

    public static CreateDeck(name: string, color: string, group: string) {
        const deck = new Deck();
        deck.ColorCode = color;
        deck.Name = name;
        deck.UniqueID = GUID.Generate();
        Collection.Decks.push(deck);
        deck.Cards = [];
        deck.Reserve = [];
        deck.Sideboard = [];

        const parent = Collection.DeckGroups.filter((dg) => dg.Name.toLowerCase() === group.toLowerCase());

        if (parent && parent.length === 1) {
            parent[0].Decks.push(deck);
        } else {
            const newParent = new DeckGroup();
            newParent.Decks = [deck];
            newParent.Name = group;

            Collection.DeckGroups.push(newParent);
        }

        return deck;
    }

    public static ImportAsync(
        data: string,
        filename: string,
        extension: string,
        globalState: GlobalState
    ): Promise<Nullable<Deck>> {
        return new Promise((resolve) => {
            globalState.onDeckRequired.notifyObservers({
                name: filename,
                group: ''
            });

            globalState.onDeckValidated.addOnce((value) => {
                if (!value) {
                    resolve(null);
                    return;
                }

                if (extension === 'mtga') {
                    const deck = this.CreateDeck(value.name, value.color, value.group);
                    this.ImportFromMagicArena(data, deck);
                    resolve(deck);
                    return;
                }

                globalState.showExpansionPicker(globalState.translate('Priorities')).then((options) => {
                    if (!options) {
                        resolve(null);
                        return;
                    }

                    const deck = this.CreateDeck(value.name, value.color, value.group);

                    const sets = options ? options.sets : [];
                    const owned = options ? options.owned : false;
                    const cheapest = options ? options.cheapest : false;
                    const excludePromos = options ? options.excludePromos : false;

                    switch (extension) {
                        case 'csv':
                            this.ImportFromDeckBox(data, deck, sets, owned, cheapest, excludePromos);
                            break;
                        default:
                            this.ImportFromTappedOut(data, deck, sets, owned, cheapest, excludePromos);
                            break;
                    }

                    resolve(deck);
                });
            });
        });
    }

    private static ImportFromDeckBox(
        data: string,
        deck: Deck,
        sets: Set[],
        owned: boolean,
        cheapest: boolean,
        excludePromos: boolean
    ) {
        const allTextLines = data.split(/\r\n|\n/);

        let headerLine = 0;
        let separator = ',';
        if (allTextLines[0].indexOf('"sep=') !== -1) {
            headerLine++;
            separator = allTextLines[0].substring(6, 5);
        }

        const headers = Collection.GetCells(allTextLines[headerLine], separator);

        let countIndex = headers.indexOf('Count');
        if (countIndex === -1) {
            countIndex = headers.indexOf('count');
        }

        let nameIndex = headers.indexOf('Name');
        if (nameIndex === -1) {
            nameIndex = headers.indexOf('name');
        }

        let setIndex = headers.indexOf('Set');
        if (setIndex === -1) {
            setIndex = headers.indexOf('set');
        }
        if (setIndex === -1) {
            setIndex = headers.indexOf('Edition');
        }
        if (setIndex === -1) {
            setIndex = headers.indexOf('edition');
        }

        let sectionIndex = headers.indexOf('Section');
        if (sectionIndex === -1) {
            sectionIndex = headers.indexOf('section');
        }

        const urzaIdIndex = headers.indexOf('ID');
        let multiverseIdIndex = headers.indexOf('MultiverseID');
        if (urzaIdIndex === -1) {
            if (multiverseIdIndex === -1) {
                multiverseIdIndex = headers.indexOf('multiverseid');
            }
            if (multiverseIdIndex === -1) {
                multiverseIdIndex = headers.indexOf('mvid');
            }
        }

        const deckCountIndex = headers.indexOf('Deck count');
        const sideboardCountIndex = headers.indexOf('Sideboard count');
        const maybeboardCountIndex = headers.indexOf('Maybeboard count');

        let card: Nullable<Card>;
        let forceSideboard = false;

        for (let i = headerLine + 1; i < allTextLines.length; i++) {
            const cells = Collection.GetCells(allTextLines[i], separator);

            if (cells.length > nameIndex) {
                if (nameIndex > -1 && cells[nameIndex] === 'SIDEBOARD') {
                    forceSideboard = true;
                    continue;
                }

                let section = '';

                if (sectionIndex > -1 && cells.length > sectionIndex) {
                    section = cells[sectionIndex];
                }

                if (urzaIdIndex > -1 && cells.length > urzaIdIndex) {
                    const urzaId = parseInt(cells[urzaIdIndex]);
                    card = Collection.CardsIndex[urzaId];
                } else if (multiverseIdIndex > -1 && cells.length > multiverseIdIndex) {
                    const multiverseId = parseInt(cells[multiverseIdIndex]);
                    card = Collection.GetCardByMultiverseId(multiverseId);
                } else {
                    let name = '';
                    let set: Nullable<Set> = null;

                    if (nameIndex > -1) {
                        name = cells[nameIndex];
                    }

                    if (setIndex > -1) {
                        set = Collection.GetExpansionByName(cells[setIndex]);

                        if (!set) {
                            set = Collection.GetExpansionByCode(cells[setIndex]);
                        }
                    }

                    card = Collection.GetCardByName(name, set ? [set] : sets, owned, cheapest, excludePromos, true);
                }

                let count = 1;

                if (countIndex > -1 && cells.length > countIndex) {
                    count = parseInt(cells[countIndex]);
                }

                if (card === null) {
                    continue;
                }

                if (deckCountIndex > -1 && cells.length > deckCountIndex && cells.length > sideboardCountIndex && cells.length > maybeboardCountIndex) {
                    const deckCount = parseInt(cells[deckCountIndex]);
                    const sideboardCount = parseInt(cells[sideboardCountIndex]);
                    const maybeboardCount = parseInt(cells[maybeboardCountIndex]);

                    if (deckCount > 0) {
                        const deckEntry = new DeckEntry();

                        deckEntry.EntryCount = deckCount;
                        deckEntry.CardID = card.id;
                        deck.Cards.push(deckEntry);
                    }

                    if (sideboardCount > 0) {
                        const deckEntry = new DeckEntry();

                        deckEntry.EntryCount = sideboardCount;
                        deckEntry.CardID = card.id;
                        deck.Sideboard.push(deckEntry);
                    }

                    if (maybeboardCount > 0) {
                        const deckEntry = new DeckEntry();

                        deckEntry.EntryCount = maybeboardCount;
                        deckEntry.CardID = card.id;
                        deck.Reserve.push(deckEntry);
                    }
                } else {
                    const deckEntry = new DeckEntry();

                    deckEntry.EntryCount = count;
                    deckEntry.CardID = card.id;

                    if (!forceSideboard && (section === 'main' || section === '')) {
                        deck.Cards.push(deckEntry);
                    } else {
                        deck.Sideboard.push(deckEntry);
                    }
                }
            }
        }
    }

    public static ExportToMoxfield(deck: Deck, globalState: GlobalState) {
        let content = '';

        deck.Cards.forEach((entry) => {
            const card = Collection.CardsIndex[entry.CardID];

            content += entry.EntryCount + ' ';
            content += card.nameEn + ' (';
            content += card.set.code + ') ';
            content += card.number;
            content += '\r\n';
        });

        if (deck.Sideboard.length) {
            content += 'Sideboard:\r\n';
            deck.Sideboard.forEach((entry) => {
                const card = Collection.CardsIndex[entry.CardID];

                content += entry.EntryCount + ' ';
                content += card.nameEn + ' (';
                content += card.set.code + ') ';
                content += card.number;
                content += '\r\n';
            });
        }


        return DownloadTools.DownloadAsync(content, deck.Name, globalState, '.txt', globalState.translate('MoxfieldFile'), true);
    }

    public static ExportToMagicArena(deck: Deck, globalState: GlobalState) {
        let content = '';

        deck.Cards.forEach((entry) => {
            const card = Collection.CardsIndex[entry.CardID];

            content += entry.EntryCount + ' ';
            content += card.nameEn + ' (';
            content += card.set.code + ') ';
            content += card.number + '\r\n';
        });

        if (deck.Sideboard.length) {
            content += '\r\n';
            deck.Sideboard.forEach((entry) => {
                const card = Collection.CardsIndex[entry.CardID];

                content += entry.EntryCount + ' ';
                content += card.nameEn + ' (';
                content += card.set.code + ') ';
                content += card.number + '\r\n';
            });
        }

        return DownloadTools.DownloadAsync(content, deck.Name, globalState, '.mtga', globalState.translate('MtgaFile'), true);
    }

    private static ImportFromMagicArena(data: string, deck: Deck) {
        const lines = data.split('\n');
        let isSideboard = false;

        for (let line of lines) {
            line = line.trim();

            if (!line) {
                isSideboard = true;
                continue;
            }

            const regex = /(\d)\s(.+?)\s\((.+)\)/g;
            const match = regex.exec(line);

            if (match && match.length > 0) {
                const count = parseInt(match[1]);
                const name = match[2];
                const setCode = match[3];
                const set = Collection.GetExpansionByCode(setCode)!;

                const card = Collection.GetCardByName(name, [set], false, false, false, true);

                if (card === null) {
                    continue;
                }

                const deckEntry = new DeckEntry();

                deckEntry.EntryCount = count;
                deckEntry.CardID = card.id;

                if (isSideboard) {
                    deck.Sideboard.push(deckEntry);
                } else {
                    deck.Cards.push(deckEntry);
                }
            }
        }
    }

    private static ImportFromTappedOut(
        data: string,
        deck: Deck,
        sets: Set[],
        owned: boolean,
        cheapest: boolean,
        excludePromos: boolean
    ) {
        const lines = data.split('\n');
        let isSideboard = false;
        let multiverseId = 0;

        for (let line of lines) {
            line = line.trim();

            if (StringHelpers.StartsWith(line, '//')) {
                const regex = /\/\/\/mvid:(\d+)/g;

                const match = regex.exec(line);

                if (match) {
                    multiverseId = parseInt(match[1]);
                } else {
                    multiverseId = 0;
                }

                continue;
            }

            if (StringHelpers.StartsWith(line, 'Sideboard')) {
                isSideboard = true;
                multiverseId = 0;
                continue;
            }

            if (StringHelpers.StartsWith(line, 'SB: ')) {
                isSideboard = true;
                line = line.replace('SB: ', '');
            }

            let count;
            let name = '';
            if (line.indexOf('\t') > -1) {
                const splits = line.split('\t');
                count = parseInt(splits[0]);
                name = splits[1];
            } else {
                const spaceIndex = line.indexOf(' ');
                if (spaceIndex === -1) {
                    multiverseId = 0;
                    continue;
                }
                count = parseInt(line.substring(0, spaceIndex));
                name = line.substring(spaceIndex).trim();
            }
            let card: Nullable<Card> = null;

            if (multiverseId) {
                card = Collection.GetCardByMultiverseId(multiverseId);
            }

            if (!card) {
                card = Collection.GetCardByName(name, sets, owned, cheapest, excludePromos, true);
            }

            if (!card) {
                if (name.indexOf('/') !== -1) {
                    const split = name.split('/');
                    name = split[0].trim().toLowerCase();

                    const candidates = Collection.GetCardsByNameStartingWith(name);

                    if (candidates.length > 0) {
                        for (let candidateIndex = 0; candidateIndex < candidates.length; candidateIndex++) {
                            const candidate = candidates[candidateIndex];
                            if (candidate.tag === 'a') {
                                card = candidate;
                                break;
                            }
                        }
                    }
                }
            }

            if (!card) {
                multiverseId = 0;
                continue;
            }

            const deckEntry = new DeckEntry();

            deckEntry.EntryCount = count;
            deckEntry.CardID = card.id;

            if (isSideboard) {
                deck.Sideboard.push(deckEntry);
            } else {
                deck.Cards.push(deckEntry);
            }

            multiverseId = 0;
        }
    }
}
