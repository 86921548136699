import * as React from 'react';
import { GlobalState, SearchData } from '../globalState';
import { Set } from '../entities/set';
import { Card } from '../entities/card';
import { CardCard } from '../controls/cards/cardCard';
import { Collection } from '../entities/collection';

require('../scss/albumViewPage.scss');

interface IAlbumViewPageProps {
    globalState: GlobalState;
    set?: Set;
    searchData?: SearchData;
}

export class AlbumViewPage extends React.Component<IAlbumViewPageProps> {
    private cards: Card[];
    private scroller: HTMLElement;

    constructor(props: IAlbumViewPageProps) {
        super(props);

        if (this.props.set) {
            this.cards = this.props.set.cards.slice().sort((a, b) => {
                if (a.number === b.number) {
                    return 0;
                }

                if (a.number < b.number) {
                    return -1;
                }

                return 1;
            });
        } else if (this.props.searchData) {
            this.cards = Collection.Search(this.props.searchData).sort((a, b) => {
                if (a.number === b.number) {
                    return 0;
                }

                if (a.number < b.number) {
                    return -1;
                }

                return 1;
            });
        }
    }

    onScroll() {
        this.props.globalState.pageCustomData.scroll = this.scroller.scrollLeft;
    }

    componentDidMount() {
        this.scroller = document.getElementById('scrollElement')!;

        const customData = this.props.globalState.pageCustomData;
        const scrollPosition = customData && customData.scroll ? customData.scroll : 0;
        this.scroller.scrollLeft = scrollPosition;
    }

    navigateToCard(card: Card) {
        this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({
            index: -4,
            uniqueID: this.cards.indexOf(card),
            cardIds: this.cards.map((c) => c.id)
        });
    }

    scrollHorizontally(evt: React.WheelEvent<HTMLDivElement>) {
        const delta = Math.max(-1, Math.min(1, evt.deltaY));

        evt.currentTarget.scrollLeft += delta * 50;
    }

    render() {
        const pages: Card[][] = [];

        let currentPage: Card[];
        this.cards.forEach((card) => {
            if (card.isBackFace) {
                return;
            }
            if (!currentPage || currentPage.length === 9) {
                currentPage = [];
                pages.push(currentPage);
            }

            currentPage.push(card);
        });

        return (
            <div className="page">
                <div
                    className="albumView-page"
                    onWheel={(evt) => this.scrollHorizontally(evt)}
                    id="scrollElement"
                    onScroll={() => this.onScroll()}
                >
                    {pages.map((page, i) => {
                        return (
                            <div className="album-page" key={i}>
                                <div className="album-page-title">
                                    {this.props.globalState.translate('Page#') + (i + 1)}
                                </div>
                                <div className="album-page-cards">
                                    {page.map((card) => {
                                        return (
                                            <CardCard
                                                key={card.id}
                                                albumMode={true}
                                                onClick={() => this.navigateToCard(card)}
                                                globalState={this.props.globalState}
                                                card={card}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
