import { GlobalState } from '../globalState';

export class PriceTools {
    private static ConversionRate = 0.87;

    public static UpdateRate() {
        fetch('https://urzabackend.azurewebsites.net/currency')
            .then(response => response.json())
            .then(response => {
                PriceTools.ConversionRate = parseFloat(response.rates.EUR.rate);
            })
            .catch(() => {
               // Ignore error
           });
    }

    public static ToDollar(value: number) {
        return value / PriceTools.ConversionRate;
    }

    public static ToEuro(value: number) {
        return value * PriceTools.ConversionRate;
    }

    public static Format(value: number, _gs: GlobalState): string {
        if (!GlobalState.LoadBoolSettings('UseEuroInsteadOfDollar', false)) {
            return '$' + this.ToDollar(value).toFixed(2);
        }

        return value.toFixed(2) + '‎€';
    }

    public static ToLocalCurrency(value: number, _gs: GlobalState): number {
        if (!GlobalState.LoadBoolSettings('UseEuroInsteadOfDollar', false)) {
            return this.ToDollar(value);
        }

        return value;
    }

    public static FromLocalCurrency(value: number, _gs: GlobalState): number {
        if (!GlobalState.LoadBoolSettings('UseEuroInsteadOfDollar', false)) {
            return this.ToEuro(value);
        }

        return value;
    }
}
