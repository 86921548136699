import { ICard } from './ICard';
import { GlobalState } from '../globalState';

export class CardSuperType {
    public static SuperTypes: CardSuperType[] = [];

    public constructor(public id: number, public name: string, public predicate: (card: ICard) => boolean) {}

    public static GetSuperTypeById(id: number): CardSuperType {
        return this.SuperTypes.filter((c) => c.id === id)[0];
    }

    public static CreateList(gs: GlobalState) {
        this.SuperTypes.push(new CardSuperType(1, gs.translate('Artifacts'), (card) => card.isArtifact));
        this.SuperTypes.push(new CardSuperType(20, gs.translate('NonCreatureArtifacts'), (card) => card.isArtifact && !card.isCreature));
        this.SuperTypes.push(new CardSuperType(21, gs.translate('NonArtifactCreatures'), (card) => !card.isArtifact && card.isCreature));
        this.SuperTypes.push(new CardSuperType(22, gs.translate('NonCreatures'), (card) => !card.isCreature));
        this.SuperTypes.push(new CardSuperType(2, gs.translate('Commanders'), (card) => card.isCommander));
        this.SuperTypes.push(new CardSuperType(17, gs.translate('Conspiracies'), (card) => card.isConspiracy));
        this.SuperTypes.push(new CardSuperType(3, gs.translate('Creatures'), (card) => card.isCreature));
        this.SuperTypes.push(new CardSuperType(19, gs.translate('Dungeons'), (card) => card.isDungeon));
        this.SuperTypes.push(new CardSuperType(4, gs.translate('Eldrazis'), (card) => card.isEldrazi));
        this.SuperTypes.push(new CardSuperType(5, gs.translate('Emblems'), (card) => card.isEmblem));
        this.SuperTypes.push(new CardSuperType(6, gs.translate('Enchantments'), (card) => card.isEnchantment));
        this.SuperTypes.push(new CardSuperType(7, gs.translate('Instants'), (card) => card.isInstant));
        this.SuperTypes.push(new CardSuperType(8, gs.translate('Lands'), (card) => card.isLand));
        this.SuperTypes.push(new CardSuperType(22, gs.translate('NonLands'), (card) => !card.isLand));
        this.SuperTypes.push(new CardSuperType(18, gs.translate('Permanents'), (card) => card.isPermanent));
        this.SuperTypes.push(new CardSuperType(9, gs.translate('Legendary'), (card) => card.isLegendary));
        this.SuperTypes.push(new CardSuperType(16, gs.translate('Phenomenons'), (card) => card.isPhenomenon));
        this.SuperTypes.push(new CardSuperType(15, gs.translate('Planes'), (card) => card.isPlane));
        this.SuperTypes.push(new CardSuperType(10, gs.translate('Planeswalkers'), (card) => card.isPlaneswalker));
        this.SuperTypes.push(new CardSuperType(14, gs.translate('Schemes'), (card) => card.isScheme));
        this.SuperTypes.push(new CardSuperType(11, gs.translate('Sorceries'), (card) => card.isSorcery));
        this.SuperTypes.push(new CardSuperType(12, gs.translate('Spells'), (card) => card.isSpell));
        this.SuperTypes.push(new CardSuperType(13, gs.translate('Tokens'), (card) => card.isToken));
    }
}
