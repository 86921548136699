import { GlobalState } from '../globalState';
import { List } from './list';

export class ListGroup {
    public Name: string;
    public Lists: List[];

    public static IsListGroupOpen(globalState: GlobalState, name: string) {
        return GlobalState.LoadBoolSettings('ListGroup-' + name);
    }

    public static SetListGroupOpen(globalState: GlobalState, name: string, value: boolean) {
        return globalState.storeBoolSettings('ListGroup-' + name, value);
    }
}
