import * as React from 'react';
import { GlobalState } from '../globalState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface IFooterButtonProps {
    globalState: GlobalState;
    icon: IconDefinition;
    text: string;
    isExpanded: boolean;
    isActivated?: boolean;
    onclick?: () => void;
    id?: string;
}

export class FooterButton extends React.Component<IFooterButtonProps> {
    constructor(props: IFooterButtonProps) {
        super(props);
    }

    render() {
        return (
            <div
                id={this.props.id}
                className={
                    'actionButton' +
                    (this.props.isExpanded ? ' expanded' : '') +
                    (this.props.isActivated ? ' activated' : '')
                }
                title={this.props.text}
                onClick={() => {
                    if (this.props.onclick) {
                        this.props.onclick();
                    }
                }}
            >
                <div className="actionButton-icon">
                    <FontAwesomeIcon icon={this.props.icon} />
                </div>
                <div className={'actionButton-label' + (!this.props.isExpanded ? ' collapsed' : '')}>
                    {this.props.text}
                </div>
            </div>
        );
    }
}
