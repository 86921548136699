import * as React from 'react';
import { GlobalState } from '../globalState';
import { Button } from './button';
import { ColorPicker } from './colorPicker';
import { MobileTools } from '../tools/mobileTools';
import { Block } from '../entities/block';
import { Collection } from '../entities/collection';
import { Checkbox } from './checkbox';
import { Set } from '../entities/set';
import { LabelledTexbox } from './labelledTextbox';
import { Nullable } from '../tools/nullable';

interface INewBlockDialogProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class NewBlockDialog extends React.Component<
    INewBlockDialogProps,
    { isVisible: boolean; name: string; filter: string }
> {
    private focusDone = false;
    private _activeSets: Set[] = [];
    private _activeBlock: Nullable<Block> = null;

    constructor(props: INewBlockDialogProps) {
        super(props);

        this.state = { isVisible: false, name: '', filter: '' };
    }

    getRandomColor(): string {
        return ColorPicker.Colors[(Math.random() * ColorPicker.Colors.length) | 0];
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onNewBlockRequired.add(() => {
            this.focusDone = false;
            this.setState({
                isVisible: true,
                name: '',
                filter: ''
            });

            this._activeSets = [];
            this._activeBlock = null;
            this.props.globalState.onBlurRequired.notifyObservers(true);
        });

        this.props.globalState.onEditBlockRequired.add((b) => {
            this.focusDone = false;
            this.setState({
                isVisible: true,
                name: b.name,
                filter: ''
            });

            this._activeSets = b.sets.slice(0);

            this._activeBlock = b;
            this.props.globalState.onBlurRequired.notifyObservers(true);
        });

        document.addEventListener('keydown', (evt) => {
            if (evt.keyCode === 27 && this.state.isVisible) {
                this.onConfirmation(false);
            }
        });
    }

    onConfirmation(value: boolean) {
        if (value) {
            const newBlock = this._activeBlock || new Block();
            newBlock.name = this.state.name;
            if (!this._activeBlock) {
                newBlock.id = Collection.Blocks.filter(b => b.id <= 0).map(b => b.id).reduce((a, b) => Math.min(a, b), 0) - 1;
                newBlock.index = Collection.Blocks.map(b => b.index).reduce((a, b) => Math.max(a, b), 0) + 1;
                newBlock.isCustom = true;
                Collection.Blocks.push(newBlock);
            }
            newBlock.sets = this._activeSets.slice(0);

            newBlock.updateUserStore();

            Collection.RegisterSave();
        }

        this.setState({ isVisible: false });

        this.props.globalState.onBlurRequired.notifyObservers(false);
    }

    componentDidUpdate() {
        if (MobileTools.IsMobile) {
            return;
        }
        if (this.state.isVisible && !this.focusDone) {
            this.focusDone = true;
            document.getElementById('block-queryText')!.focus();
        }
    }

    blurFilter(evt: React.KeyboardEvent<HTMLInputElement>) {
        if (evt.which === 13) {
            (evt.nativeEvent.srcElement! as HTMLInputElement).blur();
        }
    }

    render() {
        if (!this.state.isVisible) {
            return null;
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);
        const filter = this.state.filter.toLowerCase();

        return (
            <div className="block-dialog-container">
                <div className="block-dialog-background"></div>
                <div className={'block-dialog' + (this.props.darkMode ? ' dark' : '')}>
                    <div className="block-dialog-title">{this._activeBlock ? translate('EditBlock') : translate('NewBlock')}</div>
                    <div className="block-dialog-name">
                        <div className="block-label">{translate('Name')}</div>
                        <input
                            tabIndex={1}
                            id="block-queryText"
                            type="text"
                            autoComplete="none"
                            value={this.state.name}
                            onChange={(evt) => this.setState({ name: evt.target.value })}
                        />
                    </div>
                    <div className="block-database-filter">
                        <LabelledTexbox
                            label={translate('Name')}
                            onlyInput={true}
                            globalState={this.props.globalState}
                            onKeyPress={(evt) => this.blurFilter(evt)}
                            onChange={(value) => {
                                this.setState({ filter: value });
                            }}
                            suggestions={Collection.SortedSets.map(s => s.name)}
                            value={this.state.filter}
                            placeholder={translate('filter:')}
                        />
                    </div>
                    <div className="block-database-container">
                        {Collection.SortedSets.filter(s => !filter || s.name.toLocaleLowerCase().indexOf(filter) !== -1).map((b, i) => {
                            return (
                                <div className="blockLine" key={i}>
                                    <div className="blockLine-label">{b.name}</div>
                                    <div className="blockLine-checkbox">
                                        <Checkbox
                                            globalState={this.props.globalState}
                                            isChecked={this._activeSets.indexOf(b) !== -1}
                                            onCheckedChanged={(value) => {
                                                const index = this._activeSets.indexOf(b);

                                                if (value) {
                                                    if (index === -1) {
                                                        this._activeSets.push(b);
                                                    }
                                                } else if (index !== -1) {
                                                    this._activeSets.splice(index, 1);
                                                }

                                                this.forceUpdate();
                                            }}
                                        ></Checkbox>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="block-dialog-accept">
                        <Button
                            tabIndex={15}
                            className="block-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(true)}
                        >
                            {translate('OK')}
                        </Button>
                    </div>
                    <div className="block-dialog-cancel">
                        <Button
                            tabIndex={16}
                            className="block-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(false)}
                        >
                            {translate('Cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
