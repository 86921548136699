/* eslint-disable react/no-unknown-property */
import * as React from 'react';
import { Deck } from '../../entities/deck';
import { Collection } from '../../entities/collection';
import { CardColor } from '../../entities/cardColor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEllipsisH,
    faTrashAlt,
    faEdit,
    faClone,
    faUnlock,
    faFileExport,
    faImage
} from '@fortawesome/free-solid-svg-icons';
import { DeckGroup } from '../../entities/deckGroup';
import { faLock, faFeather, faGavel, faHelmetBattle, faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { Icon, IconProp } from '@fortawesome/fontawesome-svg-core';
import { DeckTools } from '../../tools/deckTools';
import { GUID } from '../../tools/guid';
import { CardFormat } from '../../entities/cardFormat';
import { GlobalState } from '../../globalState';
import * as ReactDOM from 'react-dom/client';
import { CSVExport } from '../../tools/csvTools';
import { ICard } from '../../entities/ICard';

interface IDeckCardProps {
    globalState: GlobalState;
    deck: Deck;
    deckGroup: DeckGroup;
    onDelete: () => void;
    onEdit: () => void;
    onClone: () => void;
}

export class DeckCard extends React.Component<IDeckCardProps, { activated: boolean, showPopup: boolean }> {
    private onClickHandler: () => void;
    private popupAnchorRef: React.RefObject<HTMLInputElement>;

    constructor(props: IDeckCardProps) {
        super(props);

        this.state = { activated: false, showPopup: false };

        this.onClickHandler = this.onDocumentClick.bind(this);

        this.popupAnchorRef = React.createRef();
    }

    onClick(_evt: React.MouseEvent<HTMLDivElement>) {
        if (_evt.buttons !== 0 && _evt.buttons !== 1) {
            _evt.preventDefault();
            _evt.stopPropagation();
            return;
        }
        const deck = this.props.deck;
        this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({
            index: -6,
            uniqueString: deck.UniqueID
        });
    }

    UNSAFE_componentWillMount() {
        document.addEventListener('click', this.onClickHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onClickHandler);
    }

    onDocumentClick() {
        if (!this.state.showPopup) {
            return;
        }
        this.setState({ showPopup: false });
        this.hidePopup();
    }

    changeFormat() {
        const deck = this.props.deck;

        const options: string[] = [];

        CardFormat.CardFormats.forEach((cf, i) => {
            if (i === 0) {
                options.push(this.props.globalState.translate('None'));
                return;
            }
            options.push(cf.name);
        });

        const selectedFormat = CardFormat.GetFormatByIndex(deck.format !== undefined ? deck.format : 0);

        this.props.globalState
            .showListDialog(this.props.globalState.translate('Format_'), options, options.indexOf(selectedFormat.name).toString())
            .then((response) => {
                const index = parseInt(response);
                if (isNaN(index)) {
                    return;
                }
                deck.format = CardFormat.CardFormats[index].index;
                Collection.RegisterSave();
                this.forceUpdate();
            });
    }

    editDeck() {
        this.props.onEdit();
    }

    editNotes() {
        const deck = this.props.deck;
        this.props.globalState
            .showQuestionDialog(this.props.globalState.translate('Notes'), deck.Notes, true)
            .then((notes) => {
                if (notes !== undefined) {
                    deck.Notes = notes;
                    Collection.RegisterSave();
                }
            });
    }

    deleteDeck() {
        const deck = this.props.deck;
        const deckGroup = this.props.deckGroup;

        this.props.globalState
            .showConfirmDialog(this.props.globalState.translate('DeckDeleteConfirm'))
            .then((value) => {
                if (!value) {
                    return;
                }
                let index = Collection.Decks.indexOf(deck);

                if (index > -1) {
                    Collection.Decks.splice(index, 1);
                }

                index = deckGroup.Decks.indexOf(deck);

                if (index > -1) {
                    deckGroup.Decks.splice(index, 1);

                    if (deckGroup.Decks.length === 0) {
                        index = Collection.DeckGroups.indexOf(deckGroup);
                        Collection.DeckGroups.splice(index, 1);
                    }
                }

                Collection.SaveUserStore(new Date());
                this.props.onDelete();
            });
    }

    cloneDeck() {
        const deck = this.props.deck;
        const deckGroup = this.props.deckGroup;

        const clone: Deck = JSON.parse(JSON.stringify(deck));
        clone.Name = this.props.globalState.translate('CopyOf') + clone.Name;
        clone.UniqueID = GUID.Generate();
        deckGroup.Decks.push(clone);

        Collection.Decks.push(clone);

        Collection.SaveUserStore(new Date());
        this.props.onClone();
    }

    lock() {
        const deck = this.props.deck;
        deck.Locked = true;

        this.forceUpdate();
        Collection.RegisterSave();
    }

    unlock() {
        const deck = this.props.deck;
        deck.Locked = false;

        this.forceUpdate();
        Collection.RegisterSave();
    }

    enable() {
        const deck = this.props.deck;
        deck.disabled = false;

        this.forceUpdate();
        Collection.RegisterSave();
    }

    disable() {
        const deck = this.props.deck;
        deck.disabled = true;

        this.forceUpdate();
        Collection.RegisterSave();
    }

    export() {
        const translate = this.props.globalState.translate.bind(this.props.globalState);
        const deck = this.props.deck;
        this.props.globalState
            .showListDialog(this.props.globalState.translate('ExportTo'), ['CSV', 'DeckBox', 'Magic Arena', 'Moxfield', 'TappedOut'], '0')
            .then((format) => {
                const formatIndex = parseInt(format);
                switch (formatIndex) {
                    case 0:
                        const deckCountExtractor = (c: ICard) => {
                            return Deck.GetCurrentCount(deck, 0, c.id).toString();
                        };
                        const reserveCountExtractor = (c: ICard) => {
                            return Deck.GetCurrentCount(deck, 2, c.id).toString();
                        };
                        const sideboardCountExtractor = (c: ICard) => {
                            return Deck.GetCurrentCount(deck, 1, c.id).toString();
                        };

                        const additionnalColumns = [
                            {
                                columnName: 'Deck count',
                                dataExtractor: deckCountExtractor
                            },
                            {
                                columnName: 'Sideboard count',
                                dataExtractor: sideboardCountExtractor
                            },
                            {
                                columnName: 'Maybeboard count',
                                dataExtractor: reserveCountExtractor
                            }
                        ];

                        CSVExport.ExportAsync(Deck.AllCollectionCards(deck), this.props.globalState, additionnalColumns).then(() => {
                            this.props.globalState.onMessage.notifyObservers(translate('ExportSuccessful'));
                            this.props.globalState.onNotificationRequired.notifyObservers(this.props.globalState.translate('ExportSuccessful'));
                        });
                        break;
                    case 1:
                        DeckTools.ExportToCSV(deck, this.props.globalState).then(() => {
                            this.props.globalState.onMessage.notifyObservers(translate('ExportSuccessful'));
                            this.props.globalState.onNotificationRequired.notifyObservers(this.props.globalState.translate('ExportSuccessful'));
                        });
                        break;
                    case 2:
                        DeckTools.ExportToMagicArena(deck, this.props.globalState).then(() => {
                            this.props.globalState.onMessage.notifyObservers(translate('ExportSuccessful'));
                            this.props.globalState.onNotificationRequired.notifyObservers(this.props.globalState.translate('ExportSuccessful'));
                        });
                        break;
                    case 3:
                        DeckTools.ExportToMoxfield(deck, this.props.globalState).then(() => {
                            this.props.globalState.onMessage.notifyObservers(translate('ExportSuccessful'));
                            this.props.globalState.onNotificationRequired.notifyObservers(this.props.globalState.translate('ExportSuccessful'));
                        });
                        break;
                    case 4:
                        DeckTools.ExportToTXT(deck, this.props.globalState).then(() => {
                            this.props.globalState.onMessage.notifyObservers(translate('ExportSuccessful'));
                            this.props.globalState.onNotificationRequired.notifyObservers(this.props.globalState.translate('ExportSuccessful'));
                        });
                        break;
                }
            });
    }

    setBackground() {
        const deck = this.props.deck;
        this.props.globalState.showCardImagePicker(Deck.Cards(deck)).then((response) => {
            if (!response.url) {
                return;
            }

            if (response.url === 'none') {
                deck.backgroundUrl = '';
            } else {
                deck.backgroundUrl = response.url;
            }

            Collection.RegisterSave();
            this.forceUpdate();
        });
    }

    setCommanderAsBackground() {
        const deck = this.props.deck;

        const commander = Deck.GetCommander(deck);

        if (!commander) {
            return;
        }

        const url = Collection.CardsIndex[commander.CardID].cropUrl;
        deck.backgroundUrl = url;
        Collection.RegisterSave();
        this.forceUpdate();
    }

    renderPopup(x: number, y: number) {
        const deck = this.props.deck;

        const popupStyle = {
            top: y + 'px',
            left: x + 'px'
        };

        const translate = this.props.globalState.translate.bind(this.props.globalState);

        return (
            <div className="deck-card-title-commands-popup" style={popupStyle}>
                <div className="deck-card-title-command" onClick={() => this.editDeck()}>
                    <div className="deck-card-title-command-icon">
                        <FontAwesomeIcon icon={faEdit as IconProp} />
                    </div>
                    <div className="deck-card-title-command-label">{translate('Edit')}</div>
                </div>
                <div className="deck-card-title-command" onClick={() => this.changeFormat()}>
                    <div className="deck-card-title-command-icon">
                        <FontAwesomeIcon icon={faGavel as IconProp} />
                    </div>
                    <div className="deck-card-title-command-label">{translate('Format_')}</div>
                </div>
                <div className="deck-card-title-command" onClick={() => this.editNotes()}>
                    <div className="deck-card-title-command-icon">
                        <FontAwesomeIcon icon={faFeather as IconProp} />
                    </div>
                    <div className="deck-card-title-command-label">{translate('Notes')}</div>
                </div>
                <div className="deck-card-title-command" onClick={() => this.setBackground()}>
                    <div className="deck-card-title-command-icon">
                        <FontAwesomeIcon icon={faImage as IconProp} />
                    </div>
                    <div className="deck-card-title-command-label">{translate('ChangeBackground')}</div>
                </div>
                {(deck.format === CardFormat.CommanderIndex ||
                    deck.format === CardFormat.DuelCommanderIndex) && (
                        <div
                            className="deck-card-title-command"
                            onClick={() => this.setCommanderAsBackground()}
                        >
                            <div className="deck-card-title-command-icon">
                                <FontAwesomeIcon icon={faHelmetBattle as IconProp} />
                            </div>
                            <div className="deck-card-title-command-label">
                                {translate('UseCommanderAsBackground')}
                            </div>
                        </div>
                    )}
                <div className="deck-card-title-command" onClick={() => this.cloneDeck()}>
                    <div className="deck-card-title-command-icon">
                        <FontAwesomeIcon icon={faClone as IconProp} />
                    </div>
                    <div className="deck-card-title-command-label">{translate('Clone')}</div>
                </div>

                <div className="deck-card-title-command" onClick={() => this.export()}>
                    <div className="deck-card-title-command-icon">
                        <FontAwesomeIcon icon={faFileExport as IconProp} />
                    </div>
                    <div className="deck-card-title-command-label">{translate('Export')}</div>
                </div>
                {deck.Locked && (
                    <div className="deck-card-title-command" onClick={() => this.unlock()}>
                        <div className="deck-card-title-command-icon">
                            <FontAwesomeIcon icon={faUnlock as Icon} />
                        </div>
                        <div className="deck-card-title-command-label">{translate('Unlock')}</div>
                    </div>
                )}
                {!deck.Locked && (
                    <div className="deck-card-title-command" onClick={() => this.lock()}>
                        <div className="deck-card-title-command-icon">
                            <FontAwesomeIcon icon={faLock as Icon} />
                        </div>
                        <div className="deck-card-title-command-label">{translate('Lock')}</div>
                    </div>
                )}
                {deck.disabled && (
                    <div className="deck-card-title-command" onClick={() => this.enable()}>
                        <div className="deck-card-title-command-icon">
                            <FontAwesomeIcon icon={faEye as Icon} />
                        </div>
                        <div className="deck-card-title-command-label">{translate('Activate')}</div>
                    </div>
                )}
                {!deck.disabled && (
                    <div className="deck-card-title-command" onClick={() => this.disable()}>
                        <div className="deck-card-title-command-icon">
                            <FontAwesomeIcon icon={faEyeSlash as Icon} />
                        </div>
                        <div className="deck-card-title-command-label">{translate('Deactivate')}</div>
                    </div>
                )}
                {!deck.Locked && (
                    <div className="deck-card-title-command" onClick={() => this.deleteDeck()}>
                        <div className="deck-card-title-command-icon">
                            <FontAwesomeIcon icon={faTrashAlt as IconProp} />
                        </div>
                        <div className="deck-card-title-command-label">{translate('Delete')}</div>
                    </div>
                )}
            </div>
        );
    }

    managePopup(evt?: React.MouseEvent<HTMLDivElement>) {
        const rootElement = document.querySelector('.decks-page-popup-host')!;

        evt?.stopPropagation();
        evt?.preventDefault();

        if (!this.state.showPopup && evt) {
            if (rootElement.hasChildNodes()) {
                return;
            }
            const client = document.querySelector('.page')!;
            const deck = this.props.deck;
            const popupHeight = 38 * (deck.Locked || deck.disabled ? 8 : 9);

            const target = this.popupAnchorRef.current!.getBoundingClientRect();
            const ownClient = client.getBoundingClientRect();

            const x = (target.left - ownClient.left) - 160;
            let y = (target.top - ownClient.top) + 32;

            if (popupHeight + y > client.clientHeight) {
                y = client.clientHeight - popupHeight - 5;
            }

            let root: ReactDOM.Root;
            if ((rootElement as any).root) {
                root = (rootElement as any).root;
            } else {
                root = ReactDOM.createRoot(rootElement);
                (rootElement as any).root = root;
            }
            root.render(this.renderPopup(x, y) as any);

            this.setState({ showPopup: true });
            return;
        } else {
            this.hidePopup();
            this.setState({ showPopup: false });
        }
    }

    hidePopup() {
        const rootElement = document.querySelector('.decks-page-popup-host')!;
        if ((rootElement as any).root) {
            (rootElement as any).root.unmount();
            (rootElement as any).root = undefined;
        }
    }

    render() {
        const deck = this.props.deck;
        const style = {
            outline: '1px solid ' + deck.ColorCode
        };

        const titleStyle = {
            background: deck.ColorCode
        };

        const valueStyle = {
            color: deck.ColorCode
        };

        const translate = this.props.globalState.translate.bind(this.props.globalState);

        const colorsDictionnary: { [key: number]: boolean } = {};
        const colors: CardColor[] = [];

        const allColors = ['B', 'R', 'U', 'W', 'G'];
        deck.Cards.forEach((entry) => {
            const card = Collection.CardsIndex[entry.CardID];

            if (card.colorId === 8) {
                allColors.forEach((color) => {
                    if (card.manaCost.indexOf(color) !== -1) {
                        const id = CardColor.GetColorByCode(color).id;
                        if (colorsDictionnary[id]) {
                            return;
                        }

                        colorsDictionnary[id] = true;
                        colors.push(CardColor.Colors[id]);
                    }
                });

                return;
            } else if (card.colorId > 6) {
                return;
            }

            if (colorsDictionnary[card.colorId]) {
                return;
            }

            colorsDictionnary[card.colorId] = true;
            colors.push(CardColor.Colors[card.colorId]);
        });

        colors.sort((a, b) => {
            if (a.id === b.id) {
                return 0;
            }

            if (a.id < b.id) {
                return -1;
            }

            return 1;
        });

        return (
            <>
                {this.state.showPopup && (
                    <div className="deck-card-blocker" />
                )}
                <div
                    className={
                        'deck-card' +
                        (this.state.activated ? ' activated' : '') +
                        (deck.format && deck.format > 0 ? ' with-format' : '') +
                        (GlobalState.IsThereABackground ? ' glass' : '')
                    }
                    title={deck.Notes}
                    onPointerDown={(_evt) => {
                        this.setState({ activated: true });
                    }}
                    onPointerOut={(_evt) => {
                        this.setState({ activated: false });
                    }}
                    onPointerUp={(_evt) => {
                        this.setState({ activated: false });
                    }}
                    style={style}
                    onClick={(evt) => this.onClick(evt)}
                >
                    <div className="deck-card-title" style={titleStyle}>
                        <div className="deck-card-title-label">{deck.Name}</div>
                        {deck.Locked && (
                            <div className="deck-lock">
                                <FontAwesomeIcon icon={faLock as Icon} />
                            </div>
                        )}
                        {deck.disabled && (
                            <div className="deck-disabled">
                                <FontAwesomeIcon icon={faEyeSlash as Icon} />
                            </div>
                        )}
                        <div
                            className="deck-card-title-commands"
                            onPointerDown={(evt) => {
                                evt.stopPropagation();
                                evt.preventDefault();
                            }}
                            onClick={(evt) => {
                                this.managePopup(evt);
                            }}
                        >
                            <div className="button">
                                <FontAwesomeIcon icon={faEllipsisH as IconProp} />
                            </div>
                            <div className="deck-card-popup-host" ref={this.popupAnchorRef}>
                            </div>
                        </div>
                    </div>
                    {deck.backgroundUrl && (
                        <div className="deck-background-container">
                            <img src={deck.backgroundUrl} className="deck-background" />
                        </div>
                    )}
                    <div className="deck-card-colors">
                        {colors.map((color) => {
                            return (
                                <div key={color.id} className="deck-card-color">
                                    <img src={'/images/symbols/' + color.key + '.png'} />
                                </div>
                            );
                        })}
                    </div>
                    <div
                        className={'deck-card-deck-label' + (deck.backgroundUrl ? ' deck-text-shadow deck-white-text' : '')}
                    >
                        {translate('deck')}
                    </div>
                    <div
                        className={
                            'deck-card-sideboard-label' + (deck.backgroundUrl ? ' deck-text-shadow deck-white-text' : '')
                        }
                    >
                        {translate('sideboard')}
                    </div>
                    <div
                        className={'deck-card-deck-cards' + (deck.backgroundUrl ? ' deck-text-shadow' : '')}
                        style={valueStyle}
                    >
                        {deck.Cards.length ? deck.Cards.map((c) => c.EntryCount).reduce((a, b) => a + b) : 0}
                    </div>
                    <div
                        className={'deck-card-sideboard-cards' + (deck.backgroundUrl ? ' deck-text-shadow' : '')}
                        style={valueStyle}
                    >
                        {deck.Sideboard.length ? deck.Sideboard.map((c) => c.EntryCount).reduce((a, b) => a + b) : 0}
                    </div>
                    {deck.format !== undefined && deck.format > 0 && (
                        <div className="deck-card-format" style={titleStyle}>
                            {CardFormat.GetFormatByIndex(deck.format).name}
                        </div>
                    )}
                </div>
            </>
        );
    }
}
