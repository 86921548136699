import * as React from 'react';
import { GlobalState } from '../globalState';
import { Button } from './button';
import { LabelledTexbox } from './labelledTextbox';
import { Collection } from '../entities/collection';
import { ColorPicker } from './colorPicker';
import { MobileTools } from '../tools/mobileTools';

interface IDeckDialogProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class DeckDialog extends React.Component<
    IDeckDialogProps,
    {
        isVisible: boolean;
        name: string;
        group: string;
        color: string;
        creationMode: boolean;
    }
    > {
    private focusDone = false;

    constructor(props: IDeckDialogProps) {
        super(props);

        this.state = { isVisible: false, name: '', group: '', color: this.getRandomColor(), creationMode: false };
    }

    getRandomColor(): string {
        return ColorPicker.Colors[(Math.random() * ColorPicker.Colors.length) | 0];
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onDeckRequired.add((state) => {
            this.focusDone = false;
            this.setState({
                isVisible: true,
                name: state.name,
                creationMode: state.color ? false : true,
                group: state.group,
                color: state.color || this.getRandomColor()
            });

            this.props.globalState.onBlurRequired.notifyObservers(true);
        });

        document.addEventListener('keydown', (evt) => {
            if (evt.keyCode === 13 && this.state.isVisible && document.activeElement === document.body) {
                this.onConfirmation(true);
                return;
            }
            if (evt.keyCode === 27 && this.state.isVisible) {
                this.onConfirmation(false);
            }
        });
    }

    onConfirmation(value: boolean) {
        this.setState({ isVisible: false });
        this.props.globalState.onBlurRequired.notifyObservers(false);

        if (value) {
            const response = {
                name: this.state.name,
                group: this.state.group,
                color: this.state.color
            };

            this.props.globalState.onDeckValidated.notifyObservers(response);
        } else {
            this.props.globalState.onDeckValidated.notifyObservers(null);
        }
    }

    componentDidUpdate() {
        if (MobileTools.IsMobile) {
            return;
        }
        if (this.state.isVisible && !this.focusDone) {
            this.focusDone = true;
            document.getElementById('deck-queryText')!.focus();
        }
    }

    render() {
        if (!this.state.isVisible) {
            return null;
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);
        const groups = Collection.DeckGroups.map((dg) => dg.Name).sort();

        return (
            <div className="deck-dialog-container">
                <div className="deck-dialog-background"></div>
                <div className={'deck-dialog' + (this.props.darkMode ? ' dark' : '')}>
                    <div className="deck-dialog-title">
                        {this.state.creationMode ? translate('New deck') : translate('Edit deck')}
                    </div>
                    <div className="deck-dialog-name">
                        <div className="deck-label">{translate('Name')}</div>
                        <input
                            tabIndex={1}
                            id="deck-queryText"
                            autoComplete="none"
                            type="text"
                            value={this.state.name}
                            onChange={(evt) => this.setState({ name: evt.target.value })}
                        />
                    </div>
                    <div className="deck-dialog-group">
                        <LabelledTexbox
                            label={translate('Group')}
                            globalState={this.props.globalState}
                            onChange={(value) => {
                                this.setState({ group: value });
                            }}
                            tabIndex={2}
                            suggestions={groups}
                            minCharacterForSuggestion={0}
                            value={this.state.group}
                        />
                    </div>
                    <div className="deck-dialog-colors-container">
                        <div className="deck-label">{translate('Color')}</div>
                        <ColorPicker
                            globalState={this.props.globalState}
                            tabIndex={3}
                            onColorChanged={(color) => this.setState({ color: color })}
                            color={this.state.color}
                        />
                    </div>
                    <div className="deck-dialog-accept">
                        <Button
                            className="deck-dialog-button"
                            tabIndex={4}
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(true)}
                        >
                            {translate('OK')}
                        </Button>
                    </div>
                    <div className="deck-dialog-cancel">
                        <Button
                            className="deck-dialog-button"
                            tabIndex={5}
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(false)}
                        >
                            {translate('Cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
