import * as Encoding from 'text-encoding';

export class BinaryReader {
    private readIndex = 0;
    private source: ArrayBuffer;
    private buffer: Uint8Array;
    private view: DataView;
    private textDecoder: TextDecoder;

    public constructor(data: ArrayBuffer) {
        this.source = data;
        this.buffer = new Uint8Array(data);

        this.view = new DataView(data);
        if (typeof TextDecoder !== 'undefined') {
            this.textDecoder = new TextDecoder('utf-8');
        } else {
            this.textDecoder = new Encoding.TextDecoder();
        }
    }

    public readInt32(): number {
        this.readIndex += 4;
        return this.view.getInt32(this.readIndex - 4, false);
    }

    public readString(): string {
        const count = this.readInt32();
        const localBuffer = new Uint8Array(this.source, this.readIndex, count);

        const result = this.textDecoder.decode(localBuffer);

        this.readIndex += count;

        return result;
    }

    public readBoolean(): boolean {
        return this.buffer[this.readIndex++] !== 0;
    }

    public readDouble(): number {
        this.readIndex += 8;

        return this.view.getFloat64(this.readIndex - 8, false);
    }
}
