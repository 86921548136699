import * as React from 'react';
import { GlobalState } from '../globalState';
import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';


interface IBarChartProps {
    globalState: GlobalState;
    className: string;
    labels: string[];
    numbers: number[];
    numbers2?: number[];
    text: string;
    text2?: string;
    title: string;
}

export class BarChart extends React.Component<IBarChartProps> {
    constructor(props: IBarChartProps) {
        super(props);
    }

    render() {
        const darkMode = GlobalState.LoadBoolSettings('DarkMode', false);
        const borderColor = 'rgba(204, 117, 41, 0.5)';
        const color = 'rgba(204, 117, 41, 1.0)';
        const hoverColor = 'rgba(204, 117, 41, 1.0)';
        const color2 = 'rgba(204, 117, 41, 0.5)';
        const hoverColor2 = 'rgba(204, 117, 41, 0.5)';

        const data: {
            labels: string[];
            datasets: {
                label: string;
                data: number[];
                backgroundColor: string;
                hoverBackgroundColor: string;
                borderColor: string;
                datalabels: any;
            }[];
        } = {
            labels: this.props.labels,
            datasets: [
                {
                    label: this.props.text,
                    data: this.props.numbers,
                    backgroundColor: color,
                    hoverBackgroundColor: hoverColor,
                    borderColor: borderColor,
                    datalabels: {
                        align: 'end',
                        anchor: 'end'
                    }
                }
            ]
        };

        if (this.props.numbers2) {
            data.datasets.push({
                label: this.props.text2 || '',
                data: this.props.numbers2,
                backgroundColor: color2,
                hoverBackgroundColor: hoverColor2,
                borderColor: borderColor,
                datalabels: {
                        align: 'end',
                        anchor: 'end'
                    }
            });
        }

        const options = {
            maintainAspectRatio: false,
            plugins: {
                title: {
                    display: true,
                    text: this.props.title,
                    font: {
                        color: darkMode ? '#BBBBBB' : '#444444',
                        size: 16
                    }
                },
                tooltips: {
                    enabled: true
                },
                legend: {
                    display: false
                },
                datalabels: {
                    color: darkMode ? '#BBBBBB' : '#444444',
                    display: (context: any) => {
                        return context.dataset.data[context.dataIndex] > 0;
                    }
                }
            },
            responsive: true,
            scales: {
                x: {
                    stacked: true
                },
                y: {
                    stacked: true
                }
            }
        };

        Chart.register(CategoryScale);
        Chart.register(LinearScale);
        Chart.register(BarElement);
        Chart.register(Title, Tooltip, Legend);
        Chart.register(ChartDataLabels);

        return (
            <div className={this.props.className}>
                <div className="bar-chart">
                    <Bar data={data} options={options} />
                </div>
            </div>
        );
    }
}
