import * as React from 'react';
import { GlobalState } from 'globalState';
import { Set } from '../../entities/set';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillAlt, faClone } from '@fortawesome/free-regular-svg-icons';
import { faEllipsisH, faFeather, faPercentage, faShoppingBasket } from '@fortawesome/free-solid-svg-icons';
import { PriceTools } from '../../tools/priceTools';
import { StringHelpers } from '../../tools/stringHelpers';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import { Collection } from '../../entities/collection';

interface ISetCardProps {
    globalState: GlobalState;
    set: Set;
}

export class SetCard extends React.Component<ISetCardProps, { showPopup: boolean, activated: boolean }> {
    private blockDocumentHandler = false;
    private onClickHandler: () => void;

    constructor(props: ISetCardProps) {
        super(props);

        this.state = { showPopup: false, activated: false };
        this.onClickHandler = this.onDocumentClick.bind(this);
    }

    onClick(_evt: React.MouseEvent<HTMLDivElement>) {
        if (_evt.buttons !== 0 && _evt.buttons !== 1) {
            _evt.preventDefault();
            _evt.stopPropagation();
            return;
        }
        if (this.state.showPopup) {
            this.setState({ showPopup: false });
            return;
        }

        this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({
            index: -2,
            uniqueID: this.props.set.id
        });
    }

    editComments() {
        const set = this.props.set;
        this.setState({ showPopup: false });
        setTimeout(() => {
            this.props.globalState
                .showQuestionDialog(this.props.globalState.translate('Comments'), set.comments, true)
                .then((comments) => {
                    if (comments !== undefined) {
                        set.comments = comments;
                        Collection.RegisterSave();
                    }
                });
        });
    }

    onDocumentClick() {
        if (this.blockDocumentHandler) {
            this.blockDocumentHandler = false;
            return;
        }
        if (!this.state.showPopup) {
            return;
        }
        this.setState({ showPopup: false });
    }


    UNSAFE_componentWillMount() {
        document.addEventListener('click', this.onClickHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onClickHandler);
    }

    render() {
        const expansion = this.props.set;
        const inCollectionCardCount = expansion.inCollectionCardCount;
        const totalCardCount = expansion.totalCardCount;

        const count = `${inCollectionCardCount} / ${totalCardCount}`;
        const value = PriceTools.Format(expansion.totalPrice, this.props.globalState);
        const completion = ((inCollectionCardCount * 100) / totalCardCount).toFixed(0);
        const translate = this.props.globalState.translate.bind(this.props.globalState);

        const isOrdered =
            expansion.cards.every((c) => c.isOrdered || c.count > 0) && expansion.cards.some((c) => c.isOrdered);

        return (
            <div
                className={'expansion-card' +
                    (expansion.isComplete || isOrdered ? ' complete' : '') +
                    (this.state.activated ? ' activated' : '')}
                onPointerDown={(_evt) => {
                    this.setState({ activated: true });
                }}
                onPointerOut={(_evt) => {
                    this.setState({ activated: false });
                }}
                onPointerUp={(_evt) => {
                    this.setState({ activated: false });
                }}
                onClick={(evt) => this.onClick(evt)}
            >
                <div className={'expansion-card-logo' + (!!expansion.additionalTitle ? ' dimmed' : '')}>
                    <img src={expansion.logo} />
                </div>
                {!!expansion.additionalTitle &&
                    <div className='expansion-card-additional-title'>
                        {expansion.additionalTitle}
                    </div>
                }
                {expansion.name.indexOf('Tokens') !== -1 && expansion.name.indexOf('League Tokens') === -1 && (
                    <div className={'expansion-card-tokens' + (expansion.isComplete || isOrdered ? ' complete' : '')}>
                        Tokens
                    </div>
                )}
                {expansion.name.indexOf('Promos') !== -1 && (
                    <div className={'expansion-card-tokens' + (expansion.isComplete || isOrdered ? ' complete' : '')}>
                        Promos
                    </div>
                )}
                {StringHelpers.EndsWith(expansion.name, 'Collectors') && (
                    <div className={'expansion-card-tokens' + (expansion.isComplete || isOrdered ? ' complete' : '')}>
                        Collectors
                    </div>
                )}
                {StringHelpers.EndsWith(expansion.name, 'Art Series') && (
                    <div className={'expansion-card-tokens' + (expansion.isComplete || isOrdered ? ' complete' : '')}>
                        Art Series
                    </div>
                )}
                {StringHelpers.EndsWith(expansion.name, 'Jumpstart Front Cards') && (
                    <div className={'expansion-card-tokens' + (expansion.isComplete || isOrdered ? ' complete' : '')}>
                        Jumpstart
                    </div>
                )}
                {StringHelpers.EndsWith(expansion.name, 'Front Cards') && (
                    <div className={'expansion-card-tokens' + (expansion.isComplete || isOrdered ? ' complete' : '')}>
                        Front Cards
                    </div>
                )}
                {StringHelpers.EndsWith(expansion.name, 'Substitute Cards') && (
                    <div className={'expansion-card-tokens' + (expansion.isComplete || isOrdered ? ' complete' : '')}>
                        Substitute
                    </div>
                )}
                {StringHelpers.EndsWith(expansion.name, 'Minigames') && (
                    <div className={'expansion-card-tokens' + (expansion.isComplete || isOrdered ? ' complete' : '')}>
                        Minigames
                    </div>
                )}
                {StringHelpers.EndsWith(expansion.name, 'Extras') && (
                    <div className={'expansion-card-tokens' + (expansion.isComplete || isOrdered ? ' complete' : '')}>
                        Extras
                    </div>
                )}
                {StringHelpers.EndsWith(expansion.name, ' Expeditions') && (
                    <div className={'expansion-card-tokens' + (expansion.isComplete || isOrdered ? ' complete' : '')}>
                        Expeditions
                    </div>
                )}
                {StringHelpers.EndsWith(expansion.name, ' Oversized') && (
                    <div className={'expansion-card-tokens' + (expansion.isComplete || isOrdered ? ' complete' : '')}>
                        Oversized
                    </div>
                )}
                <div className="expansion-card-data">
                    {
                        expansion.completionPercentage > 0 && !expansion.isComplete && (
                            <div className="expansion-card-completion" style={{
                                width: `${expansion.completionPercentage}%`
                            }}></div>
                        )
                    }
                    <img className="expansion-card-data-icon" src={expansion.icon} />
                    <div className="expansion-card-data-count">
                        {count}
                        <FontAwesomeIcon className="expansion-card-data-icon-add" icon={faClone} />
                    </div>
                    <div className="expansion-card-data-value">
                        {value}
                        <FontAwesomeIcon className="expansion-card-data-icon-add" icon={faMoneyBillAlt} />
                    </div>
                    <div className="expansion-card-data-completion">
                        {completion}
                        <FontAwesomeIcon className="expansion-card-data-icon-add" icon={faPercentage} />
                    </div>
                    <div
                        className="expansion-card-title-commands"
                        onPointerDown={(evt) => {
                            evt.stopPropagation();
                            evt.preventDefault();
                        }}
                        onClick={(evt) => {
                            evt.stopPropagation();
                            evt.preventDefault();
                            this.blockDocumentHandler = true;
                            this.setState({ showPopup: !this.state.showPopup });
                        }}
                    >
                        <div className="button">
                            <FontAwesomeIcon icon={faEllipsisH} />
                        </div>
                        {
                            this.state.showPopup &&
                            <div className="expansion-card-popup-host">
                                <div className="expansion-card-title-commands-popup">
                                    <div className="expansion-card-title-command" onClick={() => this.editComments()}>
                                        <div className="expansion-card-title-command-icon">
                                            <FontAwesomeIcon icon={faFeather as Icon} />
                                        </div>
                                        <div className="expansion-card-title-command-label">{translate('Comments')}</div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={'expansion-card-border' + (expansion.isComplete ? ' complete' : '')} />
                {isOrdered && (
                    <div className="expansion-card-ordered">
                        <FontAwesomeIcon icon={faShoppingBasket} />
                    </div>
                )}
            </div>
        );
    }
}
