import * as React from 'react';
import { GlobalState } from '../globalState';
import { Deck } from '../entities/deck';
import { CountTools } from '../tools/countTools';
import { PriceTools } from '../tools/priceTools';
import { Collection } from '../entities/collection';
import { CardFormat } from '../entities/cardFormat';
import { PieChart } from '../controls/pieChart';
import { CardRarity } from '../entities/cardRarity';
import { CardColor } from '../entities/cardColor';
import { Card } from '../entities/card';
import { BarChart } from '../controls/barChart';

require('../scss/deckStatisticsPage.scss');

interface IDeckStatisticsPageProps {
    globalState: GlobalState;
    deck: Deck;
}

export class DeckStatisticsPage extends React.Component<IDeckStatisticsPageProps> {
    constructor(props: IDeckStatisticsPageProps) {
        super(props);
    }

    render() {
        const deck = this.props.deck;
        const translate = this.props.globalState.translate.bind(this.props.globalState);
        const allCards = Deck.AllCards(deck, true);

        let missingCount = 0;
        let inCollectionCount = 0;
        let missingValue = 0;
        let inCollectionValue = 0;
        let formats = CardFormat.CardFormats.slice();

        allCards.forEach((entry) => {
            const card = Collection.CardsIndex[entry.CardID];

            formats = formats.filter((f) => f.predicate && f.predicate(card));

            if (card.count >= entry.EntryCount) {
                inCollectionCount += entry.EntryCount;
                inCollectionValue += entry.EntryCount * card.price;
            } else {
                inCollectionCount += card.count;
                inCollectionValue += card.count * card.price;
                missingCount += entry.EntryCount - card.count;
                missingValue += (entry.EntryCount - card.count) * card.price;
            }
        });

        const deckCards = deck.Cards.map((de) => {
            return {
                card: Collection.CardsIndex[de.CardID],
                entryCount: de.EntryCount
            };
        });

        for (const entry of deck.Cards) {
            const otherId = Collection.LinkedCardsIndex[entry.CardID];

            if (otherId) {
                deckCards.push({
                    card: Collection.CardsIndex[otherId],
                    entryCount: entry.EntryCount
                });
            }
        }

        const validFor = formats.map((f) => f.name);

        // Rarity
        const rarityLabels: string[] = [];
        const rarityColors: string[] = [];
        const rarityValues: number[] = [];
        CardRarity.Rarities.forEach((rarity) => {
            if (!rarity.color) {
                return;
            }
            const value = CountTools.Sum(
                deckCards
                    .filter((e) => {
                        return e.card.rarityId === rarity.id;
                    })
                    .map((e) => e.entryCount)
            );

            if (value === 0) {
                return;
            }

            rarityLabels.push(rarity.name);
            rarityColors.push(rarity.color);
            rarityValues.push(value);
        });

        // Color
        const colorLabels: string[] = [];
        const colorColors: string[] = [];
        const colorValues: number[] = [];
        CardColor.SortedColors.forEach((color) => {
            if (!color.color) {
                return;
            }
            const value = CountTools.Sum(
                deckCards
                    .filter((e) => {
                        return e.card.colorId === color.id;
                    })
                    .map((e) => e.entryCount)
            );

            if (value === 0) {
                return;
            }

            colorLabels.push(color.name);
            colorColors.push(color.color);
            colorValues.push(value);
        });

        // Symbol
        const symbolLabels: string[] = [];
        const symbolColors: string[] = [];
        const symbolValues: number[] = [];

        CardColor.ManaSources.forEach((color) => {
            if (!color.code) {
                return;
            }
            const key = color.code[1];
            let value = 0;

            if (key === 'C') {
                value = CountTools.Sum(
                    deckCards.map((e) => {
                        const index = e.card.manaCost.search(/\d/);

                        if (index < 0) {
                            return 0;
                        }

                        return 1;
                    })
                );
            } else {
                value = CountTools.Sum(
                    deckCards
                        .filter((e) => {
                            return e.card.manaCost.indexOf(key) !== -1;
                        })
                        .map((e) => {
                            const keyCount = (e.card.manaCost.match(new RegExp(key, 'g')) || []).length;
                            return e.entryCount * keyCount;
                        })
                );
            }

            if (value === 0) {
                return;
            }

            symbolLabels.push(color.name);
            symbolColors.push(CardColor.GetColorById(color.id).color!);
            symbolValues.push(value);
        });

        // Type
        const typeLabels: string[] = [];
        const typeColors: string[] = [];
        const typeValues: number[] = [];
        ['Lands', 'Creatures', 'Instants', 'Enchantments', 'Sorceries', 'Planeswalkers', 'Others'].forEach(
            (type, i) => {
                let predicate: (card: Card) => boolean;
                let color: string = '';

                switch (i) {
                    case 0:
                        predicate = (card: Card) => {
                            return card.isLand;
                        };
                        color = '#34495E';
                        break;
                    case 1:
                        predicate = (card: Card) => {
                            return card.isCreature;
                        };
                        color = '#2DCC70';
                        break;
                    case 2:
                        predicate = (card: Card) => {
                            return card.isInstant;
                        };
                        color = '#3598DB';
                        break;
                    case 3:
                        predicate = (card: Card) => {
                            return card.isEnchantment;
                        };
                        color = '#9B58B5';
                        break;
                    case 4:
                        predicate = (card: Card) => {
                            return card.isSorcery;
                        };
                        color = '#1BBC9B';
                        break;
                    case 5:
                        predicate = (card: Card) => {
                            return card.isPlaneswalker;
                        };
                        color = '#6358CE';
                        break;
                    case 6:
                        predicate = (card: Card) => {
                            return (
                                !card.isLand &&
                                !card.isPlaneswalker &&
                                !card.isCreature &&
                                !card.isInstant &&
                                !card.isEnchantment &&
                                !card.isSorcery
                            );
                        };
                        color = '#E64E3D';
                        break;
                }

                const value = CountTools.Sum(
                    deckCards
                        .filter((e) => {
                            return predicate(e.card);
                        })
                        .map((e) => e.entryCount)
                );

                if (value === 0) {
                    return;
                }

                typeLabels.push(translate(type));
                typeColors.push(color);
                typeValues.push(value);
            }
        );

        // Mana
        const manaLabels: string[] = [];
        let manaValues: number[] = [];
        let manaValues2: number[] = [];
        let averageCMC = 0;
        let averageCMCCount = 0;

        manaLabels.push(translate('Land'));

        deck.Cards.forEach((entry) => {
            const card = Collection.CardsIndex[entry.CardID];

            if (card.isLand) {
                if (!manaValues[0]) {
                    manaValues[0] = 0;
                }
                manaValues[0] += entry.EntryCount;
            } else {
                if (!manaValues[card.convertedManaCost + 1]) {
                    manaValues[card.convertedManaCost + 1] = 0;
                }
                if (!manaValues2[card.convertedManaCost + 1]) {
                    manaValues2[card.convertedManaCost + 1] = 0;
                }
                if (card.isCreature) {
                    manaValues2[card.convertedManaCost + 1] += entry.EntryCount;
                } else {
                    manaValues[card.convertedManaCost + 1] += entry.EntryCount;
                }
                averageCMC += card.convertedManaCost;
                averageCMCCount += 1;
            }
        });

        for (let index = 1; index < manaValues.length; index++) {
            if (manaValues[index] || manaValues2[index]) {
                manaLabels.push((index - 1).toString());
            }
        }

        const stock = manaValues.slice();
        const stock2 = manaValues2.slice();

        manaValues = [];
        manaValues2 = [];
        if (stock[0] === undefined) {
            manaValues.push(0);
        } else {
            manaValues.push(stock[0]);
        }
        if (stock2[0] === undefined) {
            manaValues2.push(0);
        } else {
            manaValues2.push(stock2[0]);
        }

        for (let index = 1; index < manaLabels.length; index++) {
            const manaCount = parseInt(manaLabels[index]);
            manaValues.push(stock[manaCount + 1]);
            manaValues2.push(stock2[manaCount + 1]);
        }

        if (!manaValues[0]) {
            // lands
            manaLabels.splice(0, 1);
            manaValues.splice(0, 1);
            manaValues2.splice(0, 1);
        }

        // Creatures
        const creatureLabels: string[] = [];
        const creatureColors: string[] = [];
        const creatureValues: number[] = [];
        ['Creatures', 'NonCreatures'].forEach((type, i) => {
            let predicate: (card: Card) => boolean;
            let color: string = '';

            switch (i) {
                case 0:
                    predicate = (card: Card) => {
                        return card.isCreature;
                    };
                    color = '#34495E';
                    break;
                case 1:
                    predicate = (card: Card) => {
                        return !card.isCreature && !card.isLand;
                    };
                    color = '#2DCC70';
                    break;
            }

            const value = CountTools.Sum(
                deckCards
                    .filter((e) => {
                        return predicate(e.card);
                    })
                    .map((e) => e.entryCount)
            );

            if (value === 0) {
                return;
            }

            creatureLabels.push(translate(type));
            creatureColors.push(color);
            creatureValues.push(value);
        });

        return (
            <div className="page">
                <div className="deck-statistics-page">
                    <div className="deck-statistics-header">
                        <div className="deck-statistics-header-entry">
                            <div className="deck-statistics-header-label">{translate('TotalCards')}</div>
                            <div className="deck-statistics-header-value">
                                {CountTools.Sum(deck.Cards.map((c) => c.EntryCount))}
                            </div>
                        </div>
                        <div className="deck-statistics-header-entry">
                            <div className="deck-statistics-header-label">{translate('Sideboard')}</div>
                            <div className="deck-statistics-header-value">
                                {CountTools.Sum(deck.Sideboard.map((c) => c.EntryCount))}
                            </div>
                        </div>
                        <div className="deck-statistics-header-entry">
                            <div className="deck-statistics-header-label">{translate('AvgCMC')}</div>
                            <div className="deck-statistics-header-value">
                                {(averageCMC / averageCMCCount).toFixed(2)}
                            </div>
                        </div>
                        <div className="deck-statistics-header-entry">
                            <div className="deck-statistics-header-label">{translate('TotalCMC')}</div>
                            <div className="deck-statistics-header-value">{averageCMC}</div>
                        </div>
                        <div className="deck-statistics-header-entry">
                            <div className="deck-statistics-header-label">{translate('Value')}</div>
                            <div className="deck-statistics-header-value">
                                {PriceTools.Format(
                                    CountTools.Sum(
                                        allCards.map((c) => Collection.CardsIndex[c.CardID].price * c.EntryCount)
                                    ),
                                    this.props.globalState
                                )}
                            </div>
                        </div>
                        <div className="deck-statistics-header-entry">
                            <div className="deck-statistics-header-label">{translate('InCollectionDeck')}</div>
                            <div className="deck-statistics-header-value">
                                {`${inCollectionCount} / ${PriceTools.Format(
                                    inCollectionValue,
                                    this.props.globalState
                                )}`}
                            </div>
                        </div>
                        <div className="deck-statistics-header-entry">
                            <div className="deck-statistics-header-label">{translate('MissingDeck')}</div>
                            <div className="deck-statistics-header-value">
                                {`${missingCount} / ${PriceTools.Format(missingValue, this.props.globalState)}`}
                            </div>
                        </div>
                        <div className="deck-statistics-header-entry">
                            <div className="deck-statistics-header-label">{translate('ValidFor')}</div>
                            <div className="deck-statistics-header-value">{validFor.sort().join(', ')}</div>
                        </div>
                    </div>
                    <div className="decks-statistics-stats">
                        <PieChart
                            globalState={this.props.globalState}
                            labels={rarityLabels}
                            colors={rarityColors}
                            numbers={rarityValues}
                            title={this.props.globalState.translate('Rarity breakdown')}
                            className="decks-statistics-stat"
                        />
                        <PieChart
                            globalState={this.props.globalState}
                            labels={colorLabels}
                            colors={colorColors}
                            numbers={colorValues}
                            title={this.props.globalState.translate('Color breakdown')}
                            className="decks-statistics-stat"
                        />
                        <PieChart
                            globalState={this.props.globalState}
                            labels={typeLabels}
                            colors={typeColors}
                            numbers={typeValues}
                            title={this.props.globalState.translate('Type breakdown')}
                            className="decks-statistics-stat"
                        />
                        <PieChart
                            globalState={this.props.globalState}
                            labels={creatureLabels}
                            colors={creatureColors}
                            numbers={creatureValues}
                            title={this.props.globalState.translate('Creature breakdown')}
                            className="decks-statistics-stat"
                        />
                        <BarChart
                            globalState={this.props.globalState}
                            labels={manaLabels}
                            numbers={manaValues}
                            numbers2={manaValues2}
                            text={translate('NonCreatures')}
                            text2={translate('Creatures')}
                            title={this.props.globalState.translate('Mana curve')}
                            className="decks-statistics-stat"
                        />
                        <PieChart
                            globalState={this.props.globalState}
                            labels={symbolLabels}
                            colors={symbolColors}
                            numbers={symbolValues}
                            title={this.props.globalState.translate('Symbol breakdown')}
                            className="decks-statistics-stat"
                        />
                    </div>
                </div>
            </div>
        );
    }
}
