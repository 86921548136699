import { DeckEntry } from './deckEntry';
import { DeckCard } from './deckCard';
import { Collection } from './collection';
import { Nullable } from '../tools/nullable';
import { ICard } from './ICard';

export class Deck {
    public Name: string;
    public Notes: string;
    public Locked: boolean;
    public ColorCode: string;
    public Cards: DeckEntry[];
    public Reserve: DeckEntry[];
    public Sideboard: DeckEntry[];
    public UniqueID: string;
    public format?: number;
    public backgroundUrl: string;
    public disabled: boolean;

    public static AllCards(deck: Deck, ignoreReserve = false): DeckEntry[] {
        const allCards = deck.Cards.slice(0);
        if (!ignoreReserve) {
            allCards.push(...deck.Reserve);
        }
        allCards.push(...deck.Sideboard);

        return allCards;
    }

    public static AllCollectionCards(deck: Deck): ICard[] {
        const allCards = deck.Cards.map((d) => Collection.CardsIndex[d.CardID]);

        for (const card of deck.Reserve.map((d) => Collection.CardsIndex[d.CardID])) {
            if (allCards.indexOf(card) === -1) {
                allCards.push(card);
            }
        }

        for (const card of deck.Sideboard.map((d) => Collection.CardsIndex[d.CardID])) {
            if (allCards.indexOf(card) === -1) {
                allCards.push(card);
            }
        }

        return allCards;
    }

    public static Some(deck: Deck, predicate: (entry: DeckEntry) => boolean, ignoreReserve = false): boolean {
        if (!deck) {
            return false;
        }

        if (deck.Cards.some(predicate)) {
            return true;
        }

        if (!ignoreReserve && deck.Reserve.some(predicate)) {
            return true;
        }

        if (deck.Sideboard.some(predicate)) {
            return true;
        }

        return false;
    }

    public static Cards(deck: Deck): DeckCard[] {
        return deck.Cards.map((c) => new DeckCard(c.CardID, c.EntryCount, c.isCommander, !!c.isProxy));
    }

    public static Sideboard(deck: Deck): DeckCard[] {
        return deck.Sideboard.map((c) => new DeckCard(c.CardID, c.EntryCount, c.isCommander, !!c.isProxy));
    }

    public static Reserve(deck: Deck): DeckCard[] {
        return deck.Reserve.map((c) => new DeckCard(c.CardID, c.EntryCount, c.isCommander, !!c.isProxy));
    }

    public static GetCurrentCount(deck: Deck, which: number, cardId: number) {
        let cards: DeckEntry[];

        if (!deck) {
            return 0;
        }

        switch (which) {
            case 0:
                cards = deck.Cards;
                break;
            case 1:
                cards = deck.Sideboard;
                break;
            default:
                cards = deck.Reserve;
                break;
        }

        let currentCount = -1;

        cards.forEach((deckCard) => {
            if (currentCount !== -1) {
                return; // Already found
            }

            if (deckCard.CardID === cardId || deckCard.CardID === Collection.LinkedCardsIndex[cardId]) {
                currentCount = deckCard.EntryCount;
            }
        });

        return currentCount === -1 ? 0 : currentCount;
    }

    public static Update(deck: Deck, which: number, cardId: number, count: number) {
        let cards: DeckEntry[];

        if (!deck) {
            return;
        }

        switch (which) {
            case 0:
                cards = deck.Cards;
                break;
            case 1:
                cards = deck.Sideboard;
                break;
            default:
                cards = deck.Reserve;
                break;
        }

        let found = false;
        cards.forEach((deckCard) => {
            if (deckCard.CardID === cardId || deckCard.CardID === Collection.LinkedCardsIndex[cardId]) {
                deckCard.EntryCount = count;
                found = true;
            }
        });

        if (!found && count > 0) {
            const newEntry = new DeckEntry();
            newEntry.CardID = cardId;
            newEntry.EntryCount = count;
            cards.push(newEntry);
        }

        switch (which) {
            case 0:
                deck.Cards = cards.filter((dc) => dc.EntryCount > 0);
                break;
            case 1:
                deck.Sideboard = cards.filter((dc) => dc.EntryCount > 0);
                break;
            default:
                deck.Reserve = cards.filter((dc) => dc.EntryCount > 0);
                break;
        }
    }

    public static GetCommander(deck: Deck): Nullable<DeckEntry> {
        for (const card of deck.Cards) {
            if (card.isCommander) {
                return card;
            }
        }

        return null;
    }

    public static UpdateCommanderStatus(deck: Deck, which: number, cardId: number, status: boolean) {
        let cards: DeckEntry[];

        switch (which) {
            case 0:
                cards = deck.Cards;
                break;
            case 1:
                cards = deck.Sideboard;
                break;
            default:
                cards = deck.Reserve;
                break;
        }

        cards.forEach((deckCard) => {
            if (deckCard.CardID === cardId) {
                deckCard.isCommander = status;
            }
        });
    }
}
