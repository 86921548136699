import * as React from 'react';
import { GlobalState } from '../globalState';
import { Button } from './button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

interface ILabelledTagPickerProps {
    globalState: GlobalState;
    darkMode: boolean;
    label: string;
    value?: string[];
    onChange?: (value: string[]) => void;
    tabIndex?: number;
}

export class LabelledTagPicker extends React.Component<ILabelledTagPickerProps, { value?: string[] }> {
    constructor(props: ILabelledTagPickerProps) {
        super(props);

        this.state = { value: this.props.value };
    }

    shouldComponentUpdate(nextProps: ILabelledTagPickerProps, nextState: { value?: string[] }) {
        if (this.props.value !== nextProps.value) {
            nextState.value = nextProps.value;
        }

        return true;
    }

    onEditTags() {
        this.props.globalState.onTagUpdated.addOnce((data) => {
            this.setState({ value: data.tags });
            if (this.props.onChange) {
                this.props.onChange(data.tags);
            }
            this.forceUpdate();
        });
        this.props.globalState.onTagRequired.notifyObservers(this.props.value || []);
    }

    render() {
        const darkMode = this.props.darkMode;
        const tags = this.state.value?.join(', ');
        return (
            <div className="tag-picker-element">
                <div className="tag-picker-element-label">{this.props.label}</div>
                <div className="tag-picker-element-tags" title={tags}>
                    {tags}
                </div>
                <Button
                    className={'tag-picker-element-button' + (darkMode ? '' : ' light')}
                    globalState={this.props.globalState}
                    onClicked={() => this.onEditTags()}
                >
                    <FontAwesomeIcon icon={faEdit} />
                </Button>
            </div>
        );
    }
}
