import * as React from 'react';
import { ICard } from '../entities/ICard';
import { GlobalState } from '../globalState';
import { LinkTools } from '../tools/linkTool';
import { Nullable } from '../tools/nullable';
import { Button } from './button';

interface IShoppingAssistantProps {
    globalState: GlobalState;
    darkMode: boolean;
}

interface IShoppingAssistantState {
    cards: ICard[];
    currentIndex: number;
    urlGenerator: Nullable<(card: ICard) => Promise<string>>;
}

export class ShoppingAssistant extends React.Component<
    IShoppingAssistantProps,
    IShoppingAssistantState
> {

    constructor(props: IShoppingAssistantProps) {
        super(props);

        this.state = { cards: [], currentIndex: 0, urlGenerator: null };
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onShoppingAssistantRequired.add((data) => {
            this.setState({
                currentIndex: 0,
                cards: data.cards,
                urlGenerator: data.urlGenerator
            });

            this.props.globalState.onBlurRequired.notifyObservers(!!data.urlGenerator);
        });
    }

    render() {
        if (this.state.cards.length === 0) {
            return null;
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);
        const activeCard = this.state.cards[this.state.currentIndex];

        return (
            <div className="shopping-assistant-container">
                <div className={'shopping-assistant-background'} onClick={() => this.props.globalState.onShoppingAssistantRequired.notifyObservers({ cards: [], urlGenerator: null })}></div>
                <div className={'shopping-assistant' + (this.props.darkMode ? ' dark' : '')}>
                    <div className="shopping-assistant-title">{translate('ShoppingAssistant')}</div>
                    <div className="shopping-assistant-info">
                        <div className="shopping-assistant-image">
                            <img src={'https://urzabackend.azurewebsites.net/Mid/' + activeCard.picturePath} />
                        </div>
                        <Button className="shopping-assistant-tab" globalState={this.props.globalState} onClicked={async () => {
                            LinkTools.OpenLink(await this.state.urlGenerator!(activeCard));
                            if (this.state.currentIndex < this.state.cards.length - 1) {
                                this.setState({ currentIndex: this.state.currentIndex + 1 });
                            } else {
                                this.props.globalState.onShoppingAssistantRequired.notifyObservers({ cards: [], urlGenerator: null });
                            }
                        }}>
                            {translate('OpenTab')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
