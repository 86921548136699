import * as React from 'react';
import { GlobalState } from '../globalState';
import { Deck } from '../entities/deck';
import { Card } from '../entities/card';
import { Collection } from '../entities/collection';
import { CardCard } from '../controls/cards/cardCard';
import { VirtualizedList } from '../controls/virtualizedList';
import { Observer } from '../tools/observable';
import { Nullable } from '../tools/nullable';
require('../scss/simulatorPage.scss');

interface ISimulatorPageProps {
    globalState: GlobalState;
    deck: Deck;
}

enum MoveModes {
    Top = 1,
    Bottom = 2,
    Shuffle = 3
}

export class SimulatorPage extends React.Component<
    ISimulatorPageProps,
    {
        tabIndex: number;
        imageWidth: number;
    }
> {
    private onImageWidthChangedObserver: Nullable<Observer<number>>;
    private hand = new Array<Card>();
    private library = new Array<Card>();
    private battlefield = new Array<Card>();
    private graveyard = new Array<Card>();
    private exile = new Array<Card>();
    private currentCards: Card[];
    private onResetObserver: Nullable<Observer<void>>;
    private onDrawObserver: Nullable<Observer<void>>;
    private onShuffleObserver: Nullable<Observer<void>>;
    private virtualizedRender: React.RefObject<VirtualizedList>;
    private turn = 1;

    constructor(props: ISimulatorPageProps) {
        super(props);

        this.virtualizedRender = React.createRef();

        const customData = this.props.globalState.pageCustomData;
        this.state = {
            tabIndex: customData && customData.tabIndex ? customData.tabIndex : 0,
            imageWidth: props.globalState.imageWidth
        };

        if (customData.simulationData) {
            this.hand = [];
            for (let index = 0; index < customData.simulationData.hand.length; index++) {
                this.hand.push(Collection.CardsIndex[customData.simulationData.hand[index]]);
            }
            this.library = [];
            for (let index = 0; index < customData.simulationData.library.length; index++) {
                this.library.push(Collection.CardsIndex[customData.simulationData.library[index]]);
            }
            this.battlefield = [];
            for (let index = 0; index < customData.simulationData.battlefield.length; index++) {
                this.battlefield.push(Collection.CardsIndex[customData.simulationData.battlefield[index]]);
            }
            this.graveyard = [];
            for (let index = 0; index < customData.simulationData.graveyard.length; index++) {
                this.graveyard.push(Collection.CardsIndex[customData.simulationData.graveyard[index]]);
            }
            this.exile = [];
            for (let index = 0; index < customData.simulationData.exile.length; index++) {
                this.exile.push(Collection.CardsIndex[customData.simulationData.exile[index]]);
            }
            this.prepareList(this.state.tabIndex);

            this.turn = 1;
            this.props.globalState.onHeaderTextChanged.notifyObservers(`Turn: ${this.turn}`);
        } else {
            this.start();
        }
    }

    componentDidMount() {
        setTimeout(() => {
            const customData = this.props.globalState.pageCustomData;
            const scrollPosition = customData && customData.scroll ? customData.scroll : 0;

            const scroller = document.getElementById('scrollElement')!;
            scroller.scrollTop = scrollPosition;
        }, 15);
    }

    UNSAFE_componentWillMount() {
        this.onImageWidthChangedObserver = this.props.globalState.onImageWidthChanged.add((value) => {
            this.setState({ imageWidth: value });
        });
        this.onResetObserver = this.props.globalState.onReset.add(() => {
            this.start();
            this.forceUpdate();
        });
        this.onDrawObserver = this.props.globalState.onDraw.add(() => {
            this.draft();
            this.syncCustomData();
            this.forceUpdate();
        });
        this.onShuffleObserver = this.props.globalState.onShuffle.add(() => {
            this.shuffleLibrary();

            this.syncCustomData();
            this.forceUpdate();
            if (this.state.tabIndex === 1) {
                this.virtualizedRender.current!.wipeCache();
            }
        });
    }

    componentWillUnmount() {
        this.props.globalState.onImageWidthChanged.remove(this.onImageWidthChangedObserver);
        this.props.globalState.onReset.remove(this.onResetObserver);
        this.props.globalState.onDraw.remove(this.onDrawObserver);
        this.props.globalState.onShuffle.remove(this.onShuffleObserver);
    }

    pickTab(index: number) {
        if (this.state.tabIndex === index) {
            return;
        }
        const customData = this.props.globalState.pageCustomData;
        customData.tabIndex = index;

        this.prepareList(index);
        this.setState({ tabIndex: index });
    }

    shuffleLibrary() {
        for (let i = this.library.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const tmp = this.library[i];
            this.library[i] = this.library[j];
            this.library[j] = tmp;
        }
    }

    start() {
        const deck = this.props.deck;
        this.library = [];
        this.hand = [];
        this.graveyard = [];
        this.exile = [];
        this.battlefield = [];

        for (const entry of deck.Cards) {
            const card = Collection.CardsIndex[entry.CardID];

            for (let index = 0; index < entry.EntryCount; index++) {
                if (entry.isCommander) {
                    this.exile.push(card);
                } else {
                    this.library.push(card);
                }
            }
        }

        // Shuffle
        this.shuffleLibrary();

        for (let index = 0; index < 7; index++) {
            this.draft();
        }
        this.syncCustomData();
        this.prepareList(this.state.tabIndex);

        this.turn = 1;
        this.props.globalState.onHeaderTextChanged.notifyObservers(`${this.props.globalState.translate('Turn')} ${this.turn}`);
    }

    navigateToCard(card: Card) {
        this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({
            index: -4,
            uniqueID: this.currentCards.indexOf(card),
            cardIds: this.currentCards.map((c) => c.id)
        });
    }

    moveTo(card: Card, source: Card[], destination: Card[], mode: MoveModes) {
        let index = source.indexOf(card);

        if (index > -1) {
            source.splice(index, 1);
        }

        switch (mode) {
            case MoveModes.Top:
                destination.splice(0, 0, card);
                break;
            case MoveModes.Bottom:
                destination.push(card);
                break;
            case MoveModes.Shuffle:
                index = (Math.random() * destination.length) | 0;
                destination.splice(index, 0, card);
                break;
        }

        this.syncCustomData();
        this.forceUpdate();
        this.virtualizedRender.current!.wipeCache();
    }

    move(card: Card) {
        const options: string[] = [];

        const topLibrary = this.props.globalState.translate('TopLibrary');
        const bottomLibrary = this.props.globalState.translate('BottomLibrary');
        const shuffleLibrary = this.props.globalState.translate('ShuffleLibrary');
        const graveyard = this.props.globalState.translate('Graveyard');
        const battlefield = this.props.globalState.translate('Battlefield');
        const exile = this.props.globalState.translate('Exile');
        const hand = this.props.globalState.translate('Hand');

        switch (this.state.tabIndex) {
            case 0:
                options.push(battlefield);
                options.push(graveyard);
                options.push(exile);
                break;
            case 1:
                options.push(hand);
                options.push(battlefield);
                options.push(graveyard);
                options.push(exile);
                break;
            case 2:
                options.push(hand);
                options.push(graveyard);
                options.push(exile);
                break;
            case 3:
                options.push(hand);
                options.push(battlefield);
                options.push(exile);
                break;
            case 4:
                options.push(hand);
                options.push(battlefield);
                options.push(graveyard);
                break;
        }
        options.push(topLibrary);
        options.push(bottomLibrary);
        options.push(shuffleLibrary);

        this.props.globalState
            .showListDialog(this.props.globalState.translate('Move'), options, '0')
            .then((selection) => {
                const selectionIndex = parseInt(selection);
                if (isNaN(selectionIndex) || selectionIndex === -1) {
                    return;
                }

                let mode = MoveModes.Bottom;
                let destination: Card[] = this.hand;
                let source: Card[] = this.library;

                switch (options[selectionIndex]) {
                    case hand:
                        destination = this.hand;
                        break;
                    case battlefield:
                        destination = this.battlefield;
                        break;
                    case graveyard:
                        destination = this.graveyard;
                        break;
                    case exile:
                        destination = this.exile;
                        break;
                    case topLibrary:
                        destination = this.library;
                        mode = MoveModes.Top;
                        break;
                    case bottomLibrary:
                        destination = this.library;
                        mode = MoveModes.Bottom;
                        break;
                    case shuffleLibrary:
                        destination = this.library;
                        mode = MoveModes.Shuffle;
                        break;
                }

                switch (this.state.tabIndex) {
                    case 0:
                        source = this.hand;
                        break;
                    case 1:
                        source = this.library;
                        break;
                    case 2:
                        source = this.battlefield;
                        break;
                    case 3:
                        source = this.graveyard;
                        break;
                    case 4:
                        source = this.exile;
                        break;
                }

                this.moveTo(card, source, destination, mode);
            });
    }

    renderElement(card: Card): React.ReactElement<any> {
        return (
            <CardCard
                key={card.id}
                simulatorMode={true}
                isCommander={this.props.deck.Cards.some((c) => c.CardID === card.id && c.isCommander)}
                globalState={this.props.globalState}
                onClick={() => this.navigateToCard(card)}
                onAction={() => {
                    this.move(card);
                }}
                actionButton={this.props.globalState.translate('Move')}
                card={card}
            />
        );
    }

    draft() {
        if (this.library.length === 0) {
            return;
        }
        const card = this.library.splice(0, 1)[0];
        if (!card) {
            return;
        }
        this.hand.push(card);
        this.turn++;
        this.props.globalState.onHeaderTextChanged.notifyObservers(`Turn: ${this.turn}`);
    }

    syncCustomData() {
        const customData = this.props.globalState.pageCustomData;
        customData.simulationData = {
            hand: [],
            library: [],
            battlefield: [],
            graveyard: [],
            exile: []
        };

        this.hand.forEach((c) => {
            customData.simulationData!.hand.push(c.id);
        });
        this.library.forEach((c) => {
            customData.simulationData!.library.push(c.id);
        });
        this.graveyard.forEach((c) => {
            customData.simulationData!.graveyard.push(c.id);
        });
        this.battlefield.forEach((c) => {
            customData.simulationData!.battlefield.push(c.id);
        });
        this.exile.forEach((c) => {
            customData.simulationData!.exile.push(c.id);
        });

        this.props.globalState.getPreviousPageCustomData().simulationData = customData.simulationData;
        this.props.globalState.storeNavigationAndCustomData();
    }

    prepareList(tabIndex: number) {
        switch (tabIndex) {
            case 0:
                this.currentCards = this.hand;
                break;
            case 1:
                this.currentCards = this.library;
                break;
            case 2:
                this.currentCards = this.battlefield;
                break;
            case 3:
                this.currentCards = this.graveyard;
                break;
            case 4:
                this.currentCards = this.exile;
                break;
        }
    }

    render() {
        const translate = this.props.globalState.translate.bind(this.props.globalState);

        const host = document.querySelector('.client')!;
        const width = Math.min(this.state.imageWidth, host.clientWidth - 30);
        const height = Math.ceil(width * 1.42);

        return (
            <div className="page">
                <div className="simulator-page">
                    <div className="simulator-tabs">
                        <div
                            className={'simulator-tab' + (this.state.tabIndex === 0 ? ' selected' : '')}
                            onClick={() => this.pickTab(0)}
                        >
                            {`${translate('Hand')} (${this.hand.length})`}
                        </div>
                        <div
                            className={'simulator-tab' + (this.state.tabIndex === 1 ? ' selected' : '')}
                            onClick={() => this.pickTab(1)}
                        >
                            {`${translate('Library')} (${this.library.length})`}
                        </div>
                        <div
                            className={'simulator-tab' + (this.state.tabIndex === 2 ? ' selected' : '')}
                            onClick={() => this.pickTab(2)}
                        >
                            {`${translate('Battlefield')} (${this.battlefield.length})`}
                        </div>
                        <div
                            className={'simulator-tab' + (this.state.tabIndex === 3 ? ' selected' : '')}
                            onClick={() => this.pickTab(3)}
                        >
                            {`${translate('Graveyard')} (${this.graveyard.length})`}
                        </div>
                        <div
                            className={'simulator-tab' + (this.state.tabIndex === 4 ? ' selected' : '')}
                            onClick={() => this.pickTab(4)}
                        >
                            {`${translate('Exile')} (${this.exile.length})`}
                        </div>
                    </div>
                    <VirtualizedList
                        diff={114}
                        topParentClassName="simulator-page"
                        ref={this.virtualizedRender}
                        className="simulator-cards"
                        cellWidth={width}
                        cellHeight={height}
                        globalState={this.props.globalState}
                        dataSource={this.currentCards}
                        renderElement={this.renderElement.bind(this)}
                    />
                </div>
            </div>
        );
    }
}
