import * as React from 'react';
import { GlobalState } from '../globalState';

interface ISliderProps {
    globalState: GlobalState;
    min: number;
    max: number;
    defaultValue: number;
    onChange: (value: number) => void;
    className?: string;
}

export class Slider extends React.Component<ISliderProps, { value: number }> {
    constructor(props: ISliderProps) {
        super(props);

        this.state = { value: this.props.defaultValue };
    }

    shouldComponentUpdate(nextProps: ISliderProps, nextState: { value: number }) {
        if (nextProps.defaultValue !== this.props.defaultValue) {
            nextState.value = nextProps.defaultValue;
        }

        return true;
    }

    onChange(value: number) {
        this.props.onChange(value);

        this.setState({ value: value });
    }

    render() {
        return (
            <div className={'slider-container' + (this.props.className ? ' ' + this.props.className : '')}>
                <input
                    type="range"
                    min={this.props.min}
                    max={this.props.max}
                    onChange={(evt) => this.onChange(parseFloat(evt.target.value))}
                    value={this.state.value}
                    className={navigator.userAgent.indexOf('Edge') !== -1 ? 'edge-slider' : 'slider'}
                />
            </div>
        );
    }
}
