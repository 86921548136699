import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { GlobalState } from '../globalState';

interface ISettingsGroupProps {
    globalState: GlobalState;
    title: string;
    icon: IconProp;
    children: React.ReactNode; 
}

export class SettingsGroup extends React.Component<ISettingsGroupProps, { isOpen: boolean }> {
    constructor(props: ISettingsGroupProps) {
        super(props);

        this.state = { isOpen: GlobalState.LoadBoolSettings('settings-' + this.props.title, true) };
    }

    swap() {
        this.props.globalState.storeBoolSettings('settings-' + this.props.title, !this.state.isOpen);
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        return (
            <div className="setting-group">
                <div className="setting-header">
                    <div className="setting-icon"><FontAwesomeIcon icon={this.props.icon} /></div>
                    <div className="setting-title" onClick={() => this.swap()}>{this.props.title}</div>
                    <div className="setting-collapse" onClick={() => this.swap()}>
                        <FontAwesomeIcon icon={this.state.isOpen ? faMinusSquare : faPlusSquare} />
                    </div>
                </div>
                {
                    this.state.isOpen &&
                    this.props.children
                }
            </div >

        );
    }
}
