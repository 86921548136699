import { GlobalState, SelectionData, PageData } from '../globalState';
import { Nullable } from './nullable';
import { Observer } from './observable';
import { Analytics } from '../tools/analytics';
import { SecureJSON } from './secureJSON';

export class NavigationManager {
    private globalState: GlobalState;
    private onSelectedMenuIndexObserver: Nullable<Observer<SelectionData>>;
    private customDataStack: Nullable<PageData[]> = new Array<PageData>();
    private navigationStack: Nullable<SelectionData[]> = new Array<SelectionData>();
    private blockHistorization = false;

    constructor(globalState: GlobalState) {
        this.globalState = globalState;

        this.globalState.getPreviousPageCustomData = () => {
            return this.customDataStack![this.customDataStack!.length - 1];
        };

        this.globalState.getPreviousNavigationData = () => {
            return this.navigationStack![this.customDataStack!.length - 1];
        };

        this.globalState.storeNavigationAndCustomData = () => {
            this.store();
        };

        this.globalState.replaceUrl = (url: string) => {
            if (!this.navigationStack) {
                return;
            }
            const selection = this.navigationStack[this.navigationStack.length - 1];

            const stateObject = { selection: selection };
            history.replaceState(stateObject, 'UrzaGatherer', url);
        };

        this.onSelectedMenuIndexObserver = this.globalState.onSelectedMenuIndexChanged.add((selection) => {
            if (!this.customDataStack || !this.navigationStack) {
                return;
            }

            if (this.blockHistorization) {
                this.blockHistorization = false;
                return;
            }

            const currentSelection = this.navigationStack[this.navigationStack.length - 1];
            if (
                currentSelection &&
                selection.index !== -4 &&
                selection.uniqueID !== 0 &&
                currentSelection.searchData === selection.searchData &&
                currentSelection.index === selection.index &&
                currentSelection.uniqueString === selection.uniqueString &&
                currentSelection.uniqueID === selection.uniqueID
            ) {
                return;
            }

            this.customDataStack.push(this.globalState.pageCustomData);
            this.navigationStack.push(selection);

            if (this.customDataStack.length > 50) {
                this.customDataStack.splice(0, 1);
                this.navigationStack.splice(0, 1);
            }

            this.globalState.pageCustomData = {};

            this.pushState(selection);

            this.store();
        });

        window.onpopstate = (evt: PopStateEvent) => {
            if (!this.customDataStack || !this.navigationStack) {
                return;
            }

            if (evt.state && this.customDataStack.length > 1) {
                this.blockHistorization = true;
                this.globalState.pageCustomData = this.customDataStack.pop()!;
                this.navigationStack.pop()!;

                const selection = this.navigationStack[this.navigationStack.length - 1];
                this.globalState.onSelectedMenuIndexChanged.notifyObservers(selection);

                this.store();
            }
        };
    }

    private pushState(selection: SelectionData) {
        let url = '';
        switch (selection.index) {
            case -7:
                url = 'deck-statistics';
                break;
            case -6:
                url = 'deck-cards';
                break;
            case -5:
                url = 'prices';
                break;
            case -4:
                url = 'card';
                break;
            case -3:
                url = 'cards-search';
                break;
            case -2:
                url = 'cards-expansion';
                break;
            case -1:
                url = 'expansions';
                break;
            case 0:
                url = 'blocks';
                break;
            case 1:
                url = 'cards-collection';
                break;
            case 2:
                url = 'search';
                break;
            case 3:
                url = 'about';
                break;
            case 4:
                url = 'settings';
                break;
            case 6:
                url = 'statistics';
                break;
            case 7:
                url = 'album-view';
                break;
            case 9:
                url = 'life-counter';
                break;
            case 10:
                url = 'decks';
                break;
            case 14:
                url = 'lore-explorer';
                break;
        }

        const stateObject = { selection: selection };

        history.pushState(stateObject, 'UrzaGatherer', '\\');
        Analytics.PageView(url);
    }

    public store() {
        try {
            this.globalState.storeSettings('currentPageCustomData', JSON.stringify(this.globalState.pageCustomData));
            this.globalState.storeSettingsCompressed('customDataStack', JSON.stringify(this.customDataStack));
            this.globalState.storeSettingsCompressed('navigationStack', JSON.stringify(this.navigationStack));
        } catch {
            this.globalState.storeSettings('customDataStack', '');
        }
    }

    public restore() {
        const customDataStackInfo = GlobalState.LoadSettingsCompressed('customDataStack');

        if (customDataStackInfo) {
            this.customDataStack = SecureJSON.Parse<PageData[]>(customDataStackInfo);
            this.navigationStack = SecureJSON.Parse<SelectionData[]>(
                GlobalState.LoadSettingsCompressed('navigationStack')
            );

            if (!this.customDataStack || !this.navigationStack) {
                this.customDataStack = [];
                this.navigationStack = [];
                this.globalState.onSelectedMenuIndexChanged.notifyObservers({ index: 0 });
                return;
            }

            for (const nav of this.navigationStack) {
                this.pushState(nav);
            }

            this.globalState.pageCustomData = {};
            const customData = GlobalState.LoadSettings('currentPageCustomData');

            if (customData) {
                const parsedData = SecureJSON.Parse<PageData>(customData);

                if (parsedData) {
                    this.globalState.pageCustomData = parsedData;
                }
            }
            this.blockHistorization = true;
            const selection = this.navigationStack[this.navigationStack.length - 1];
            this.globalState.onSelectedMenuIndexChanged.notifyObservers(selection);
        } else {
            this.globalState.onSelectedMenuIndexChanged.notifyObservers({ index: 0 });
        }
    }

    public dispose() {
        if (this.onSelectedMenuIndexObserver) {
            this.globalState.onSelectedMenuIndexChanged.remove(this.onSelectedMenuIndexObserver);
            this.onSelectedMenuIndexObserver = null;
        }
    }
}
