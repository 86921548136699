import * as React from 'react';
import { GlobalState } from '../globalState';
import { Button } from './button';
import { ColorPicker } from './colorPicker';

require('../scss/controls/colorDialog.scss');

interface IColorDialogProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class ColorDialog extends React.Component<IColorDialogProps, { isVisible: boolean; color: string }> {
    constructor(props: IColorDialogProps) {
        super(props);

        this.state = { isVisible: false, color: '' };
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onColorDialogRequired.add((color) => {
            this.setState({
                isVisible: true,
                color: color
            });

            this.props.globalState.onBlurRequired.notifyObservers(true);
        });

        document.addEventListener('keydown', (evt) => {
            if (evt.keyCode === 27 && this.state.isVisible) {
                this.onConfirmation(false);
            }
        });
    }

    onConfirmation(value: boolean) {
        if (value) {
            this.props.globalState.onColorPicked.notifyObservers(this.state.color);
        }

        this.setState({ isVisible: false });

        this.props.globalState.onBlurRequired.notifyObservers(false);
    }

    render() {
        if (!this.state.isVisible) {
            return null;
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);

        return (
            <div className="color-dialog-container">
                <div className="color-dialog-background"></div>
                <div className={'color-dialog' + (this.props.darkMode ? ' dark' : '')}>
                    <div className="color-dialog-title">{translate('PickColor')}</div>
                    <div className="color-dialog-colors-container">
                        <div className="color-label">{translate('Color')}</div>
                        <ColorPicker
                            globalState={this.props.globalState}
                            tabIndex={1}
                            useAccentColor={true}
                            onColorChanged={(color) => this.setState({ color: color })}
                            color={this.state.color}
                        />
                    </div>
                    <div className="color-dialog-accept">
                        <Button
                            tabIndex={2}
                            className="color-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(true)}
                        >
                            {translate('OK')}
                        </Button>
                    </div>
                    <div className="color-dialog-cancel">
                        <Button
                            tabIndex={3}
                            className="color-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(false)}
                        >
                            {translate('Cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
