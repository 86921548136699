import { IconProp } from '@fortawesome/fontawesome-svg-core';

export class FetchResponse {
    url: string;
    dateModified: Date;
    error: boolean;
    content: string;
}

export interface ISyncProvider {
    loginAsync(): Promise<unknown>;
    disconnect(): void;
    saveDataAsync(filename: string, data: string, onTokenDown: () => void): Promise<void>;
    fetchFileAsync(filename: string, onTokenDown: () => void): Promise<FetchResponse>;
    fetchUrl(url: string, response: FetchResponse): Promise<string>;
    name: string;
    icon: IconProp
}
