import * as React from 'react';
import { GlobalState } from '../globalState';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { StringHelpers } from '../tools/stringHelpers';

interface ILabelledTexboxProps {
    globalState: GlobalState;
    label: string;
    placeholder?: string;
    value?: string;
    suggestions?: string[];
    onChange?: (text: string) => void;
    onKeyPress?: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
    onSuggestionPicked?: (text: string) => void;
    minCharacterForSuggestion?: number;
    tabIndex?: number;
    className?: string;
    onlyInput?: boolean;
    id?: string;
}

export class LabelledTexbox extends React.Component<
    ILabelledTexboxProps,
    { value?: string; suggestions: string[]; selectedSuggestionIndex: number }
> {
    private suggestions: string[];

    private onClickHandler: () => void;
    private blockDocumentClick = false;

    private textboxRef: React.RefObject<HTMLInputElement>;

    constructor(props: ILabelledTexboxProps) {
        super(props);

        this.textboxRef = React.createRef();

        this.state = { value: this.props.value, suggestions: [], selectedSuggestionIndex: -1 };

        this.sortSuggestions(this.props);

        this.onClickHandler = this.onDocumentClick.bind(this);
    }

    sortSuggestions(props: ILabelledTexboxProps) {
        this.suggestions = [];
        if (props.suggestions) {
            const hash: { [key: string]: boolean } = {};

            props.suggestions.forEach((suggestion) => {
                if (!hash[suggestion]) {
                    hash[suggestion] = true;
                    this.suggestions.push(suggestion);
                }
            });
            this.suggestions = this.suggestions.sort();
        }
    }

    UNSAFE_componentWillMount() {
        document.addEventListener('click', this.onClickHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onClickHandler);
    }

    onDocumentClick() {
        if (this.blockDocumentClick) {
            this.blockDocumentClick = false;
            return;
        }
        if (this.state.suggestions.length > 0) {
            this.setState({ suggestions: [] });
        }
    }

    shouldComponentUpdate(nextProps: ILabelledTexboxProps, nextState: { value?: string }) {
        if (this.props.value !== nextProps.value) {
            nextState.value = nextProps.value;
        }

        if (nextProps.suggestions !== this.props.suggestions) {
            this.sortSuggestions(nextProps);
        }

        return true;
    }

    reset() {
        this.setState({ value: '' });

        if (this.props.onChange) {
            this.props.onChange('');
        }

        this.textboxRef.current!.focus();
    }

    onSuggestionSelected(suggestion: string) {
        this.setState({ value: suggestion, suggestions: [] });
        if (this.props.onChange) {
            this.props.onChange(suggestion);
        }

        if (this.props.onSuggestionPicked) {
            this.props.onSuggestionPicked(suggestion);
        }
    }

    componentDidUpdate() {
        if (!this.state.suggestions.length || this.state.selectedSuggestionIndex === -1) {
            return;
        }

        const suggestionElement = document.getElementById('suggestion-' + this.state.selectedSuggestionIndex)!;
        const scroll = suggestionElement.parentElement!;

        if (suggestionElement.offsetTop >= scroll.scrollTop + scroll.clientHeight) {
            scroll.scrollTop = suggestionElement.offsetTop - scroll.clientHeight + suggestionElement.clientHeight;
        } else if (suggestionElement.offsetTop < scroll.scrollTop) {
            scroll.scrollTop = suggestionElement.offsetTop;
        }
    }

    render() {
        return (
            <div className="textbox-element">
                {!this.props.onlyInput && <div className="textbox-element-label">{this.props.label}</div>}
                <div className="textbox-element-container">
                    <input
                        type="text"
                        id={this.props.id}
                        ref={this.textboxRef}
                        className={(this.props.className || '') + (this.props.onlyInput && this.state.value ? ' offset' : '')}
                        autoComplete="none"
                        tabIndex={this.props.tabIndex}
                        value={this.state.value}
                        placeholder={this.props.placeholder}
                        onFocus={() => {
                            if (this.props.minCharacterForSuggestion === 0 && !this.state.value) {
                                this.blockDocumentClick = true;
                                this.setState({ suggestions: this.suggestions, selectedSuggestionIndex: -1 });
                            }
                        }}
                        onKeyDown={(evt) => {
                            if (!this.state.suggestions.length) {
                                return;
                            }
                            if (evt.keyCode === 38) {
                                this.setState({
                                    selectedSuggestionIndex: Math.max(0, this.state.selectedSuggestionIndex - 1)
                                });
                                evt.preventDefault();
                            } else if (evt.keyCode === 40) {
                                this.setState({
                                    selectedSuggestionIndex: Math.min(
                                        this.state.suggestions.length - 1,
                                        this.state.selectedSuggestionIndex + 1
                                    )
                                });
                                evt.preventDefault();
                            } else if (evt.keyCode === 13) {
                                if (this.state.selectedSuggestionIndex > -1) {
                                    this.onSuggestionSelected(
                                        this.state.suggestions[this.state.selectedSuggestionIndex]
                                    );
                                    evt.preventDefault();
                                } else {
                                    this.setState({ suggestions: [] });
                                }
                            } else if (evt.keyCode === 9) {
                                if (this.state.selectedSuggestionIndex > -1) {
                                    this.onSuggestionSelected(
                                        this.state.suggestions[this.state.selectedSuggestionIndex]
                                    );
                                    evt.preventDefault();
                                    return;
                                }
                                this.blockDocumentClick = false;
                                this.onDocumentClick();
                            } else if (evt.keyCode === 27 && this.state.suggestions.length) {
                                this.setState({ suggestions: [] });
                                evt.preventDefault();
                            }
                        }}
                        onKeyPress={(evt) => {
                            if (this.props.onKeyPress) {
                                this.props.onKeyPress(evt);
                            }
                        }}
                        onChange={(evt) => {
                            const value = evt.target.value;
                            let suggestions: string[] = [];
                            const minCharacterForSuggestion =
                                this.props.minCharacterForSuggestion !== undefined
                                    ? this.props.minCharacterForSuggestion
                                    : 3;
                            if (this.props.suggestions && value.length >= minCharacterForSuggestion) {
                                const lookup = StringHelpers.NoAccentVersion(value.toLowerCase());
                                suggestions = this.suggestions.filter(
                                    (suggestion) => StringHelpers.NoAccentVersion(suggestion.toLowerCase()).indexOf(lookup) > -1
                                );
                            }
                            this.setState({ value: value, suggestions: suggestions, selectedSuggestionIndex: -1 });
                            if (this.props.onChange) {
                                this.props.onChange(value);
                            }
                        }}
                    />
                    {this.state.suggestions.length > 0 && (
                        <div className="textbox-element-suggestions">
                            {this.state.suggestions.map((suggestion, i) => {
                                return (
                                    <div
                                        key={'suggestion-' + i}
                                        id={'suggestion-' + i}
                                        onClick={() => this.onSuggestionSelected(suggestion)}
                                        className={
                                            'textbox-element-suggestion' +
                                            (this.state.selectedSuggestionIndex === i ? ' selected-suggestion' : '')
                                        }
                                    >
                                        {suggestion}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
                {(!this.props.onlyInput || this.state.value) && (
                    <div
                        className={
                            'textbox-element-clear' + (this.props.onlyInput && this.state.value ? ' offset' : '')
                        }
                        onClick={() => this.reset()}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                )}
            </div>
        );
    }
}
