import { GlobalState } from '../globalState';

export enum PatreonLevel {
    None,
    Bronze,
    Silver,
    Gold
}

export class PatreonTools {
    private static _IsPatreon: boolean | undefined;
    private static _PledgeLevel = PatreonLevel.None;

    public static get IsPatreon() {
        return this._IsPatreon === true;
    }

    public static get PledgeLevel() {
        return this._PledgeLevel;
    }

    public static Disconnect() {
        this._PledgeLevel = PatreonLevel.None;
        this._IsPatreon = undefined;
    }

    public static GetPledgeLevelAsync(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this._IsPatreon !== undefined) {
                resolve(this._IsPatreon);
                return;
            }
            const storedToken = GlobalState.LoadSettings('PatreonToken', '');

            if (!storedToken) {
                this._IsPatreon = false;
                this._PledgeLevel = PatreonLevel.None;
                resolve(false);
                return;
            }

            const dataInfoUrl =
                'https://urzabackend.azurewebsites.net/patreonToken/' + storedToken;
            fetch(dataInfoUrl)
                .then((response) => {
                    if (response.status > 400) {
                        this._PledgeLevel = PatreonLevel.None;
                        this._IsPatreon = false;
                        resolve(this._IsPatreon);
                        return;
                    }
                    response.text().then((text) => {
                        this._PledgeLevel = <PatreonLevel>parseInt(text);
                        this._IsPatreon = this._PledgeLevel !== PatreonLevel.None;
                        resolve(this._IsPatreon);
                    });
                })
                .catch(() => {
                    resolve(false);
                });
        });
    }
}
