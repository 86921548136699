import { BinaryReader } from '../tools/binaryReader';

export class Ruling {
    public text: string;

    public static Deserialize(reader: BinaryReader): Ruling {
        const ruling = new Ruling();

        ruling.text = reader.readString();

        return ruling;
    }
}
