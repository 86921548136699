import { Collection } from './entities/collection';
import { Observable } from './tools/observable';
import { Counter } from './entities/counter';
import { ICard } from './entities/ICard';
import { Deck } from './entities/deck';
import { Nullable } from './tools/nullable';
import { Set } from './entities/set';
import { ICustomMenuDefinition } from './controls/customMenu';
import { MobileTools } from './tools/mobileTools';
import { DeckEntry } from './entities/deckEntry';
import { Block } from './entities/block';
import { Card } from './entities/card';

declare const LZString: any;

export class SearchData {
    name?: string;
    number?: number;
    regex?: string;
    nameRegex?: string;
    exclude?: string[];
    manaSymbols?: string;
    fulltext?: string;
    flavor?: string;
    author?: string;
    comment?: string;
    types?: string[];
    texts?: string[];
    blocks?: string[];
    expansions?: string[];
    price: number[];
    power: number[];
    checkPower: boolean;
    toughness: number[];
    checkToughness: boolean;
    manacost: number[];
    manaCostIsMaxRange: boolean;
    year: number[];
    checkYear: boolean;
    format: string;
    stateIndex: number;
    proxiesIndex: number;
    languageIndex: number;
    gradingIndex: number;
    conditionIndex: number;
    addedBefore?: Date;
    addedAfter?: Date;
    customIconIndex: number;
    collectionIndex: number;
    quantityIndex: number;
    reprintIndex: number;
    miscIndex: number;
    dfcIndex: number;
    tags: string[];
    colors: number[];
    colorIdentities: string[];
    multicolors: string[];
    manaSources: string[];
    rarities: number[];
    cardIds?: number[];
    deckIndex: number;
    superTypeIndex: number;
    excludedSuperTypeIndex: number;
    quantity: number[];
    checkQuantity: boolean;
    quantityToTrade: number[];
    checkQuantityToTrade: boolean;
    wantedQuantity: number[];
    checkWantedQuantity: boolean;
    mergeReprints: boolean;
    mergeReprintsForQuantity: boolean;
    hideReprints: boolean;
    setCode: string;
}

export class SelectionData {
    index: number;
    uniqueID?: number;
    searchData?: SearchData;
    cardIds?: number[];
    uniqueString?: string;
    title?: string;
}

export class PageData {
    scroll?: number;
    filter?: string;
    sortIndex?: number;
    colorIndex?: number;
    rarityIndex?: number;
    author?: string;
    type?: string;
    format?: string;
    stateIndex?: number;
    tabIndex?: number;
    searchData?: SearchData;
    isFilterExpanded?: boolean;
    expansionIndex?: number;
    deckIndex?: string;
    tagIndex?: number;
    price?: number[];
    manacost?: number[];
    power?: number[];
    toughness?: number[];
    fullText?: string;
    simulationData?: {
        hand: number[];
        library: number[];
        battlefield: number[];
        graveyard: number[];
        exile: number[];
    };
}

export class GlobalState {
    public static readonly MaxCardCount = 200;

    public onNotificationRequired = new Observable<string>();
    public selectedMenuIndex: number;
    public onSelectedMenuIndexChanged = new Observable<SelectionData>();
    public onSelectedCardChanged = new Observable<ICard>();
    public onCardUpdated = new Observable<ICard>();
    public onPrev = new Observable<void>();
    public onSearchShortcut = new Observable<void>();
    public onHeaderTextChanged = new Observable<string>();
    public onHeaderTitleChanged = new Observable<{ title: string; isFlip: boolean }>();
    public onSearchButtonRequired = new Observable<boolean>();
    public onNextButtonRequired = new Observable<boolean>();
    public onPrevButtonRequired = new Observable<boolean>();
    public onHighlightRequired = new Observable<boolean>();
    public onBlurRequired = new Observable<boolean>();
    public onCollectionCommandRequired = new Observable<boolean>();
    public onDeckCommandRequired = new Observable<boolean>();
    public onCollectionCommandActivated = new Observable<void>();
    public onDeckCommandActivated = new Observable<void>();
    public onExpansionPickerRequired = new Observable<string>();
    public onHighlightFooterButtonRequired = new Observable<string>();
    public onDataGridModeChanged = new Observable<boolean>();
    public onExpansionsPicked = new Observable<
        { sets: Set[]; owned: boolean; cheapest: boolean; excludePromos: boolean } | undefined
    >();
    public onCollectionCountRequired = new Observable<{ title: string; defaultValue: number; maxValue: number }>();
    public onMoveInsideDeckRequired = new Observable<{ title: string; cards: DeckEntry[]; deck: Deck }>();
    public onDeckCountRequired = new Observable<{
        title: string;
        masterCard: Nullable<ICard>;
        currentDeck: Nullable<Deck>;
        deckValue: number;
        sideboardValue: number;
        reserveValue: number;
    }>();
    public onCardDataRequired = new Observable<Card>();
    public onCardDataUpdated = new Observable<void>();
    public onListRequired = new Observable<{
        title: string;
        options: string[];
        values?: number[];
        data: string;
        multipleCards?: number;
        decoder: (value: string) => number[];
        encoder: (values: number[]) => string;
    }>();
    public onListSelected = new Observable<string>();
    public onNewPlayerRequired = new Observable<void>();
    public onNewBlockRequired = new Observable<void>();
    public onEditBlockRequired = new Observable<Block>();
    public onNewPlayerCreated = new Observable<Counter>();
    public onDeckRequired = new Observable<{ name: string; group: string; color?: string }>();
    public onListEditorRequired = new Observable<{ name: string; group: string; color?: string; tags: string[] }>();
    public onTagRequired = new Observable<ICard[] | string[]>();
    public onUserRequired = new Observable<void>();
    public onUserUpdated = new Observable<boolean>();
    public onShoppingAssistantRequired = new Observable<{
        cards: ICard[];
        urlGenerator: Nullable<(card: ICard) => Promise<string>>;
    }>();
    public onTagUpdated = new Observable<{ tags: string[]; cancelled: boolean }>();
    public onDeckValidated = new Observable<Nullable<{ name: string; group: string; color: string }>>();
    public onListEditorValidated = new Observable<
        Nullable<{ name: string; group: string; color: string; tags: string[] }>
    >();
    public onQuestionRequired = new Observable<{
        title: string;
        value: string;
        multiline: boolean;
        suggestions?: string[];
        help?: string;
    }>();
    public onQuestionAnswered = new Observable<string>();
    public onCollectionCount = new Observable<number>();
    public onDeckCount = new Observable<{
        deck: Deck;
        deckValue: number;
        sideboardValue: number;
        reserveValue: number;
    }>();
    public onCancelImageDownloadRequired = new Observable<void>();
    public onMoveInsideDeckDone = new Observable<boolean>();
    public onWaitRingRequired = new Observable<boolean>();
    public onProgressMessageUpdate = new Observable<string>();
    public onProgressValueUpdate = new Observable<number>();
    public onDarkModeChanged = new Observable<boolean>();
    public onColorDialogRequired = new Observable<string>();
    public onCardImageDialogRequired = new Observable<{ cards: ICard[], options: { backgroundMode: boolean } }>();
    public onCardIconDialogRequired = new Observable<{ activeIcon: number }>();
    public onCustomSortDialogRequired = new Observable<void>();
    public onCustomChecklistDialogRequired = new Observable<void>();
    public onCustomDatabaseRequired = new Observable<boolean>();
    public onHiddenCardListRequired = new Observable<void>();
    public onColorPicked = new Observable<string>();
    public onKeyUp = new Observable<KeyboardEvent>();
    public onCardImagePicked = new Observable<{ card: Nullable<ICard>, url: string }>();
    public onCardIconPicked = new Observable<{icon?: number}>();
    public onAccentColorChanged = new Observable<string>();
    public onCollectionMode = new Observable<boolean>();
    public OnOrderedChanged = new Observable<boolean>();
    public OnProxyChanged = new Observable<boolean>();
    public onSelectMode = new Observable<boolean>();
    public onCustomMenuRequired = new Observable<ICustomMenuDefinition[]>();
    public onNext = new Observable<void>();
    public onSearch = new Observable<void>();
    public onColorsChanged = new Observable<void>();
    public onLangChanged = new Observable<void>();
    public onHamburgerClicked = new Observable<void>();
    public onCustomButtonRequired = new Observable<string>();
    public onCustomButtonClicked = new Observable<void>();
    public onCoinFlipped = new Observable<boolean>();
    public onImport = new Observable<{ extension: string; filename: string; data: string }>();
    public onNewTurn = new Observable<void>();
    public onDraw = new Observable<void>();
    public onShuffle = new Observable<void>();
    public onStatistics = new Observable<void>();
    public onSimulator = new Observable<void>();
    public onDiceRolled = new Observable<{ value: number; max: number }>();
    public onReset = new Observable<void>();
    public onSave = new Observable<void>();
    public onSavedSearchesChanged = new Observable<void>();
    public onSavedSearchesSelected = new Observable<string>();
    public onSelectModeActivationRequired = new Observable<void>();
    public onContextMenuRequired = new Observable<{ top: number; left: number; card: ICard; deck?: Deck }>();
    public onCustomMenuDisplayRequired = new Observable<void>();
    public onCustomMenuClosed = new Observable<void>();
    public onContextMenuCommand = new Observable<{ card: ICard; command: string, option?: any }>();
    public onMessage = new Observable<string>();
    public onError = new Observable<string>();
    public onReloadRequiredError = new Observable<string>();
    public pageCustomData: PageData = {};
    public scrollToElement: string;
    public onWindowSizeChanged = new Observable<number>();
    public onImageWidthChanged = new Observable<number>();
    public onImageLoadingCountChanged = new Observable<number>();
    public onFlipRequired = new Observable();
    public onFoilsCommandActivated = new Observable<void>();
    public onSpecialFoilCommandActivated = new Observable<void>();
    public showFoilMenu = new Observable<boolean>();
    public showSelectCommand = new Observable<boolean>();
    public onPatreonConnected = new Observable<void>();
    public onPatreonDisconnected = new Observable<void>();
    public onForcePageRefresh = new Observable<void>();
    public onSynchronizationChanged = new Observable<void>();
    public onRaiseTheAlarm = new Observable<void>();
    public onBackgroundImageChanged = new Observable<void>();
    public selectedColors: number[] = [];
    public selectedLang: string = 'EN';
    private _workLocally = false;
    public logs = new Array<string>();

    private _synchronizationInProgress = false;
    public get synchronizationInProgress() {
        return this._synchronizationInProgress;
    }

    public set synchronizationInProgress(value: boolean) {
        if (this._synchronizationInProgress === value) {
            return;
        }

        this._synchronizationInProgress = value;
        this.onSynchronizationChanged.notifyObservers();
    }

    public get workLocally() {
        return this._workLocally;
    }

    public set workLocally(value: boolean) {
        this._workLocally = value;
        this.storeBoolSettings('WorkLocally', value, true);
    }

    public get firstConnection() {
        return GlobalState.LoadBoolSettings('FirstConnection', true);
    }

    public set firstConnection(value: boolean) {
        this.storeBoolSettings('FirstConnection', value);
    }

    public onConfirmDialog: (message: string, ok: string, cancel: string, done: (response: boolean) => void) => void;

    private strings: { [key: string]: string } = {};

    public getPreviousPageCustomData: () => PageData;
    public getPreviousNavigationData: () => SelectionData;
    public storeNavigationAndCustomData: () => void;
    public replaceUrl: (url: string) => void;

    public get syncSettingsKey() {
        return 'SyncSettings' + this.currentUser;
    }

    public get saveDateKey() {
        return 'saveDate' + this.currentUser;
    }

    public get saveDateSettingsKey() {
        return 'saveDateSettings' + this.currentUser;
    }

    public get userDataFile() {
        return `userdata${this.currentUser ? '.' + this.currentUser : ''}.ugs`;
    }

    public get userDataExtFile() {
        return `userdata-ext${this.currentUser ? '.' + this.currentUser : ''}.ugs`;
    }

    public get userSettingsFile() {
        return `usersettings${this.currentUser ? '.' + this.currentUser : ''}.ugs`;
    }

    private _currentUser?: string;
    public get currentUser() {
        if (this._currentUser === undefined) {
            this._currentUser = GlobalState.LoadSettings('CurrentUser', '');
        }

        return this._currentUser;
    }

    public set currentUser(value: string) {
        this._currentUser = value;
    }

    private _imageWidth = 200;
    public get imageWidth(): number {
        return this._imageWidth;
    }

    public set imageWidth(value: number) {
        if (this._imageWidth === value) {
            return;
        }

        this._imageWidth = value;
        this.onImageWidthChanged.notifyObservers(value);
    }

    private static _UseFoilPriceWhenOwned?: boolean;
    public static get UseFoilPriceWhenOwned() {
        if (this._UseFoilPriceWhenOwned === undefined) {
            this._UseFoilPriceWhenOwned = GlobalState.LoadBoolSettings('UseFoilPriceWhenOwned', false);
        }

        return this._UseFoilPriceWhenOwned;
    }

    public static set UseFoilPriceWhenOwned(value: boolean) {
        this._UseFoilPriceWhenOwned = value;
    }

    private _showCardsFromReservedList?: boolean;
    public get showCardsFromReservedList() {
        if (this._showCardsFromReservedList === undefined) {
            this._showCardsFromReservedList = GlobalState.LoadBoolSettings('DisplayReservedList', false);
        }

        return this._showCardsFromReservedList;
    }

    public set showCardsFromReservedList(value: boolean) {
        this._showCardsFromReservedList = value;
    }

    private _hideSplitCard?: boolean;
    public get hideSplitCard() {
        if (this._hideSplitCard === undefined) {
            this._hideSplitCard = GlobalState.LoadBoolSettings('HideSplitCard', false);
        }

        return this._hideSplitCard;
    }

    public set hideSplitCard(value: boolean) {
        this._hideSplitCard = value;
    }

    private _hideTransformCard?: boolean;
    public get hideTransformCard() {
        if (this._hideTransformCard === undefined) {
            this._hideTransformCard = GlobalState.LoadBoolSettings('HideTransformCard', false);
        }

        return this._hideTransformCard;
    }

    public set hideTransformCard(value: boolean) {
        this._hideTransformCard = value;
    }

    private _displayLargeMissingCollectionIndicator?: boolean;
    public get displayLargeMissingCollectionIndicator() {
        if (this._displayLargeMissingCollectionIndicator === undefined) {
            this._displayLargeMissingCollectionIndicator = GlobalState.LoadBoolSettings(
                'DisplayLargeMissingCollectionIndicator',
                false
            );
        }

        return this._displayLargeMissingCollectionIndicator;
    }

    public set displayLargeMissingCollectionIndicator(value: boolean) {
        this._displayLargeMissingCollectionIndicator = value;
    }

    private _displayLargeCollectionIndicator?: boolean;
    public get displayLargeCollectionIndicator() {
        if (this._displayLargeCollectionIndicator === undefined) {
            this._displayLargeCollectionIndicator = GlobalState.LoadBoolSettings(
                'DisplayLargeCollectionIndicator',
                false
            );
        }

        return this._displayLargeCollectionIndicator;
    }

    public set displayLargeCollectionIndicator(value: boolean) {
        this._displayLargeCollectionIndicator = value;
    }

    private _transparentTags?: boolean;
    public get transparentTags() {
        if (this._transparentTags === undefined) {
            this._transparentTags = GlobalState.LoadBoolSettings(
                'TransparentTags',
                false
            );
        }

        return this._transparentTags;
    }

    public set transparentTags(value: boolean) {
        this._transparentTags = value;
    }

    private _hideCompleted?: boolean;
    public get hideCompleted() {
        if (this._hideCompleted === undefined) {
            this._hideCompleted = GlobalState.LoadBoolSettings('HideCompleted', false);
        }

        return this._hideCompleted;
    }

    public set hideCompleted(value: boolean) {
        this._hideCompleted = value;
    }

    private _hideZeroCardSet?: boolean;
    public get hideZeroCardSet() {
        if (this._hideZeroCardSet === undefined) {
            this._hideZeroCardSet = GlobalState.LoadBoolSettings('HideZeroCardSet', false);
        }

        return this._hideZeroCardSet;
    }

    public set hideZeroCardSet(value: boolean) {
        this._hideZeroCardSet = value;
    }

    private _separateExtras?: boolean;
    public get separateExtras() {
        if (this._separateExtras === undefined) {
            this._separateExtras = GlobalState.LoadBoolSettings('SeparateExtras', false);
        }

        return this._separateExtras;
    }

    public set separateExtras(value: boolean) {
        this._separateExtras = value;
    }

    private _deckAsGroupList?: boolean;
    public get deckAsGroupList() {
        if (this._deckAsGroupList === undefined) {
            this._deckAsGroupList = GlobalState.LoadBoolSettings('DeckAsGroupList', false);
        }

        return this._deckAsGroupList;
    }

    public set deckAsGroupList(value: boolean) {
        this._deckAsGroupList = value;
    }

    private _blockProgressAsCards?: boolean;
    public get blockProgressAsCards() {
        if (this._blockProgressAsCards === undefined) {
            this._blockProgressAsCards = GlobalState.LoadBoolSettings('BlockProgressAsCards', false);
        }

        return this._blockProgressAsCards;
    }

    public set blockProgressAsCards(value: boolean) {
        this._blockProgressAsCards = value;
    }

    private _displayCollectionCountInAlbumView?: boolean;
    public get displayCollectionCountInAlbumView() {
        if (this._displayCollectionCountInAlbumView === undefined) {
            this._displayCollectionCountInAlbumView = GlobalState.LoadBoolSettings('DisplayCollectionCountInAlbumView', false);
        }

        return this._displayCollectionCountInAlbumView;
    }

    public set displayCollectionCountInAlbumView(value: boolean) {
        this._displayCollectionCountInAlbumView = value;
    }

    private _displayActiveDeckIcon?: boolean;
    public get displayActiveDeckIcon() {
        if (this._displayActiveDeckIcon === undefined) {
            this._displayActiveDeckIcon = GlobalState.LoadBoolSettings('DisplayActiveDeckIcon', false);
        }

        return this._displayActiveDeckIcon;
    }

    public set displayActiveDeckIcon(value: boolean) {
        this._displayActiveDeckIcon = value;
    }

    private _showReprintCount?: boolean;
    public get showReprintCount() {
        if (this._showReprintCount === undefined) {
            this._showReprintCount = GlobalState.LoadBoolSettings('DisplayReprintCount', false);
        }

        return this._showReprintCount;
    }

    public set showReprintCount(value: boolean) {
        this._showReprintCount = value;
    }

    private _commandsOnHover?: boolean;
    public get commandsOnHover() {
        if (this._commandsOnHover === undefined) {
            this._commandsOnHover = GlobalState.LoadBoolSettings('CommandsOnHover', false);
        }

        return this._commandsOnHover;
    }

    public set commandsOnHover(value: boolean) {
        this._commandsOnHover = value;
    }

    private _foilInCollectionMode?: boolean;
    public get foilInCollectionMode() {
        if (this._foilInCollectionMode === undefined) {
            this._foilInCollectionMode = GlobalState.LoadBoolSettings('FoilInCollectionMode', false);
        }

        return this._foilInCollectionMode;
    }

    public set foilInCollectionMode(value: boolean) {
        this._foilInCollectionMode = value;
    }

    private _showCardPrice?: boolean;
    public get showCardPrice() {
        if (this._showCardPrice === undefined) {
            this._showCardPrice = GlobalState.LoadBoolSettings('DisplayPrice', false);
        }

        return this._showCardPrice;
    }

    public set showCardPrice(value: boolean) {
        this._showCardPrice = value;
    }

    private _deckIndicator?: boolean;
    public get deckIndicator() {
        if (this._deckIndicator === undefined) {
            this._deckIndicator = GlobalState.LoadBoolSettings('DeckIndicator', false);
        }

        return this._deckIndicator;
    }

    public set deckIndicator(value: boolean) {
        this._deckIndicator = value;
    }

    private _foilIndicator?: boolean;
    public get foilIndicator() {
        if (this._foilIndicator === undefined) {
            this._foilIndicator = GlobalState.LoadBoolSettings('FoilIndicator', true);
        }

        return this._foilIndicator;
    }

    public set foilIndicator(value: boolean) {
        this._foilIndicator = value;
    }

    private _numberOfColumns?: number;
    public get numberOfColumns() {
        if (this._numberOfColumns === undefined) {
            this._numberOfColumns = GlobalState.LoadIntSettings('NumberOfColumns', 2);
        }

        return this._numberOfColumns;
    }

    public set numberOfColumns(value: number) {
        this._numberOfColumns = value;
    }

    private _imageLoadingCount = 0;
    private _timeoutId?: number;
    public get imageLoadingCount(): number {
        return this._imageLoadingCount;
    }

    public set imageLoadingCount(value: number) {
        if (value < 0) {
            value = 0;
        }

        if (this._imageLoadingCount === value) {
            return;
        }

        this._imageLoadingCount = value;
        this.onImageLoadingCountChanged.notifyObservers(value);

        if (this._timeoutId !== undefined) {
            clearTimeout(this._timeoutId);
            this._timeoutId = undefined;
        }

        if (value) {
            this._timeoutId = window.setTimeout(() => (this.imageLoadingCount = 0), 5000);
        }
    }

    checkImageSize() {
        if (window.innerWidth < 768) {
            const isMobile = MobileTools.IsMobile;
            this.imageWidth = (window.innerWidth - (isMobile ? 62 : 80)) / this.numberOfColumns;
            this.onImageWidthChanged.notifyObservers(this.imageWidth);
        }
    }

    public clearSettingsCache() {
        GlobalState._UseFoilPriceWhenOwned = undefined;
        this._showCardsFromReservedList = undefined;
        this._hideSplitCard = undefined;
        this._hideTransformCard = undefined;
        this._displayLargeMissingCollectionIndicator = undefined;
        this._displayLargeCollectionIndicator = undefined;
        this._transparentTags = undefined;
        this._hideCompleted = undefined;
        this._hideZeroCardSet = undefined;
        this._separateExtras = undefined;
        this._deckAsGroupList = undefined;
        this._displayCollectionCountInAlbumView = undefined;
        this._displayActiveDeckIcon = undefined;
        this._showReprintCount = undefined;
        this._commandsOnHover = undefined;
        this._foilInCollectionMode = undefined;
        this._showCardPrice = undefined;
        this._deckIndicator = undefined;
        this._foilIndicator = undefined;
        this._blockProgressAsCards = undefined;
    }

    public refreshTheme(darkMode: boolean) {
        this.onDarkModeChanged.notifyObservers(darkMode);

        const windowAsAny = window as any;
        if (windowAsAny.webkit && windowAsAny.webkit.messageHandlers) {
            windowAsAny.webkit.messageHandlers.myMsgHandler.postMessage(darkMode ? 'dark' : 'light');
        }
    }

    public get needFrenchVersion() {
        return GlobalState.LoadSettings('Language') === 'FR' || (!GlobalState.LoadSettings('Language')  && navigator.language.indexOf('fr-') >= 0);
    }

    /* eslint-disable max-len */
    public get whatsNew(): string[] {
        if (this.needFrenchVersion) {
            return [
                '<i>14.6.0:</i><br>Tarkir: Dragonstorm <br><br>',
                '<i>14.5.0:</i><br>Aetherdrift<br><br>',
                '<i>14.4.0:</i><br>Règles actualisées<br><br>',
                '<i>14.3.0:</i><br>Innistrad Remastered<br><br>',
                '<i>14.2.0:</i><br>Deal<br><br>',
                '<i>14.1.0:</i><br>Support du zoom pour le scanner<br><br>',
                '<i>14.0.0:</i><br>Nouveau serveur pour les images<br><br>',
                '<i>13.1.0:</i><br>Foundations<br><br>',
                '<i>13.0.0:</i><br>Suivi de la date d\'ajout d\'une carte à la collection<br><br>',
                '<i>12.15.0:</i><br>Duskmourn: House of Horror<br><br>',
                '<i>12.14.0:</i><br>Bloomburrow<br><br>',
                '<i>12.13.0:</i><br>Assassin\'s Creed<br><br>',
                '<i>12.12.0:</i><br>Modern Horizons 3<br><br>',
                '<i>12.11.0:</i><br>Outlaws of Thunder Junction<br><br>',
                '<i>12.10.0:</i><br>Fallout<br><br>',
                '<i>12.9.0:</i><br>Murders at Karlov Manor<br><br>',
                '<i>12.8.0:</i><br>The Lost Caverns of Ixalan<br><br>',
                '<i>12.7.0:</i><br>Nouvelle option pour définir un nombre "souhaité" par carte (En opposition de "À échanger")<br><br>',
                '<i>12.6.0:</i><br>Doctor Who<br><br>',
                '<i>12.5.0:</i><br>Vous pouvez maintenant filtrer les cartes sérialisées hors de la base de données<br><br>',
                '<i>12.4.0:</i><br>Wilds of Eldraine<br><br>',
                '<i>12.3.0:</i><br>Commander Masters<br><br>',
                '<i>12.2.0:</i><br>Le système effectue désormais une validation de protection pour éviter de charger une sauvegarde issue d\'une version plus récente<br><br>',
                '<i>12.1.0:</i><br>The Lord of the Rings: Tales of Middle-earth<br><br>',
                '<i>12.0.0:</i><br>Page d\'aide<br><br>',
                '<i>11.9.0:</i><br>March of the Machine<br><br>',
                '<i>11.8.0:</i><br>Vue album pour les listes<br><br>',
                '<i>11.7.0:</i><br>Les decks peuvent être exportés vers Moxfield<br>Lorsque les decks sont exportés, ils sont également copiés dans le presse-papier<br>Utilisez F3 comme raccourci pour la zone de recherche.<br><br>',
                '<i>11.6.0:</i><br>Phyrexia: All Will Be One<br><br>',
                '<i>11.5.0:</i><br>Starter Commander Decks<br>Dominaria Remastered<br><br>',
                '<i>11.4.0:</i><br>The Brothers\' War<br><br>',
                '<i>11.3.0:</i><br>Vous pouvez désormais chercher les détails des cartes (langue, condition, notation) sur la page de recherche avancée<br>Nouvelle option sur mobile pour choisir le nombre de colonnes pour la vue en grille<br><br>',
                '<i>11.2.0:</i><br>Unfinity<br><br>',
                '<i>11.1.0:</i><br>Warhammer 40,000<br><br>',
                '<i>11.0.0:</i><br>Amélioration de la façon dont les cartes individuelles sont référencées lorsqu\'il s\'agit de notation, de langues et de conditions<br><br>',
                '<i>10.8.0:</i><br>Nouveau champ de recherche pour les icônes personnalisées<br>Dominaria United<br><br>',
                '<i>10.7.0:</i><br>Début de la réorganisation du bloc Secret Lair (ca va être un long processus :))<br><br>',
                '<i>10.6.0:</i><br>Préparation pour Dominaria United<br>Ajout d\'un filtre sur le nom pour les listes de contrôle<br>Le mode collection fonctionne désormais également en mode liste<br><br>',
                '<i>10.5.0:</i><br>Les Patreons peuvent désormais configurer <a target="_blank" href="https://www.deltakosh.com/urzagatherer/announcements/10-5-0">une icône personnalisée</a> pour différencier les cartes<br><br>',
                '<i>10.4.0:</i><br>Ajout d\'une option pour <a target="_blank" href="https://www.deltakosh.com/urzagatherer/announcements/10-4-0">cacher des cartes individuellement</a><br><br>',
                '<i>10.3.0:</i><br>Ajout du support des <a target="_blank" href="https://www.deltakosh.com/urzagatherer/announcements/10-3-0">proxies</a><br><br>',
                '<i>10.2.0:</i><br>Il existe désormais un bouton dans les réglages pour forcer la mise à jour de la base de données<br><br>',
                '<i>10.1.0:</i><br>Ajout d\'une option pour afficher la progression des blocs en nombre de cartes<br><br>',
                '<i>10.0.0:</i><br>Les Patreons ont désormais une nouvelle option pour synchroniser leurs données sans utiliser DropBox ou OneDrive<br><br>',
                '<i>9.9.0:</i><br>Double Masters 2022<br><br>',
                '<i>9.8.0:</i><br>Vous pouvez maintenant afficher le contenu des decks en <a target="_blank" href="https://www.deltakosh.com/urzagatherer/announcements/9-8-0">mode groupé</a><br><br>',
                '<i>9.7.0:</i><br>Commander Legends: Battle for Baldur\'s Gate<br><br>',
                '<i>9.6.0:</i><br>Plus de filtres sont désormais capable d\'indiquer le nombre de cartes par option<br><br>',
                '<i>9.5.0:</i><br>Nouveau filtre: Plein texte<br><br>',
                '<i>9.4.0:</i><br>Nouveaux blocs pour les produits Secret Lair, Un-Sets, Signature Spellbooks et Archenemy<br><br>',
                '<i>9.3.0:</i><br>Streets of New Capenna<br><br>',
                '<i>9.2.0:</i><br>Toutes les types de foils spéciaux (gravés, galaxy, gilded) sont rassemblés sous le label "Foil spécial"<br><br>',
                '<i>9.1.0:</i><br>Les Patreons peuvent cacher les commandes sur les cartes et ne les faire apparaitre que lorsque la souris survole la carte (<a target="_blank" href="https://www.deltakosh.com/urzagatherer/announcements/9-1-0">plus d\'informations</a>)<br><br>',
                '<i>9.0.0:</i><br><a target="_blank" href="https://www.deltakosh.com/urzagatherer/announcements/9-0-0">Nouvelle façon d\'ajouter des cartes à un deck</a><br><br>',
                '<i>8.1.0:</i><br>Refonte de l\'interface de changement de réimpressions pour les cartes dans les decks<br><br>',
                '<i>8.0.0:</i><br>Il est désormais possible d\'avoir plusieurs <a target="_blank" href="https://www.deltakosh.com/urzagatherer/announcements/8-0-0">utilisateurs</a><br>Possibilité de cacher les extensions sans aucune carte dans la collection<br><br>',
                '<i>7.17.0:</i><br>Kamigawa: Neon Dynasty<br>Nouvelles options de recherche pour la quantité de cartes<br><br>',
                '<i>7.16.0:</i><br>Option pour afficher des boutons pour les foils dans le mode collection<br><br>',
                '<i>7.15.0:</i><br>Option pour afficher le nombre de réempressions que vous possédez par carte<br><br>',
                '<i>7.14.0:</i><br>Correction du rendu sur les petits écrans<br>Ajout du support des DFC (cartes double face) dans la page de recherche avancée<br><br>',
                '<i>7.13.0:</i><br>Optimizations internes<br><br>',
                '<i>7.12.0:</i><br>Meilleur support des foils gravés<br><br>',
                '<i>7.11.0:</i><br>Innistrad: Crimson Vow<br><br>',
                '<i>7.10.0:</i><br>Vous pouvez maintenant exporter vos decks au format de fichier csv<br><br>',
                '<i>7.9.0:</i><br>Il est désormais possible de filtrer les cartes par étiquettes<br><br>',
                '<i>7.8.0:</i><br>Innistrad: Midnight Hunt<br><br>',
                "<i>7.7.0:</i><br>Les langues, notations et conditions peuvent désormais être définies pour chaque instance d'une carte<br><br>",
                '<i>7.6.0:</i><br>Foreign White Border<br>Italian Legends<br><br>',
                '<i>7.5.0:</i><br>Les Patreons peuvent désormais créer des <a target="_blank" href="https://www.deltakosh.com/urzagatherer/announcements/7-5-0">blocs personnalisés</a><br><br>',
                '<i>7.4.0:</i><br>Lien direct vers les cartes<br><br>',
                '<i>7.3.0:</i><br>Les Patreons peuvent télécharger les images de qualité standard localement (pour pouvoir travailler hors ligne)<br><br>',
                '<i>7.2.0:</i><br>Vous pouvez maintenant ajouter des commentaires sur les extensions<br><br>',
                '<i>7.1.0:</i><br><span>Introduction de <a target="_blank" href="https://www.deltakosh.com/urzagatherer/announcements/7-1-0">l\'assistant d\'achat</a></span><br><br>',
                '<i>7.0.0:</i><br><span>Introduction de <a target="_blank" href="https://www.deltakosh.com/urzagatherer/announcements/7-0-0">l\'Explorateur de Lore</a></span><br><br>',
                '<i>6.8.0:</i><br>Nouvel indicateur visuel de collection (peut être activé dans la page des réglages) <br><br>',
                '<i>6.7.0:</i><br>Adventures in the Forgotten Realms<br><br>',
                '<i>6.6.0:</i><br>Nouveau mécanisme de login pour Patreon<br><br>',
                "<i>6.5.0:</i><br>Les Patreons peuvent désormais changer le fond d'écran<br>Suppression des histoires et des fonds d'écrans car Wizards ont encore modifié leur site web<br><br>",
                '<i>6.4.0:</i><br>Mise à jour majeure du moteur de prix pour CardMarket<br><br>',
                '<i>6.3.0:</i><br>Modern Horizons 2<br><br>',
                '<i>6.2.0:</i><br>Détection du mode sombre automatique<br><br>',
                '<i>6.1.0:</i><br>Vous pouvez désormais cacher des blocks et les retirer de la base de données<br>Commander 2021<br><br>',
                "<i>6.0.0:</i><br>Les histoires sont de retour !<br>Support des notations (PSA)<br>Strixhaven<br>Cartes Oversized<br>Possibilité d'igorer les cartes communes, peu communes et rares<br><br>",
                '<i>5.11.0:</i><br>Time Spiral Remastered<br><br>',
                '<i>5.10.0:</i><br>Kaldheim<br><br>',
                '<i>5.9.0:</i><br>Modern Horizons Art Series<br>Zendikar Rising Art Series<br><br>',
                '<i>5.8.0:</i><br>Commander Legends<br><br>',
                "<i>5.7.0:</i><br>Plus de couleurs:)<br>Vous pouvez désormais utiliser l'image du commandeur comme arrière-plan pour les decks Commander<br>Images en survol sur les datagrids<br><br>",
                '<i>5.6.0:</i><br>The List<br><br>',
                '<i>5.5.0:</i><br>Nouveau logo<br><br>',
                '<i>5.4.0:</i><br>Liste de contrôle personalisée<br>Commander Collection: Green<br><br>',
                '<i>5.3.0:</i><br>Face the Hydra<br>Battle the Horde<br>Defeat a God<br><br>',
                "<i>5.2.0:</i><br>Option pour séparer les extras de l'extension principale<br><br>",
                '<i>5.1.0:</i><br>Double Masters<br><br>',
                '<i>5.0.0:</i><br>Vous pouvez désormais étiqueter des cartes, rechercher des cartes par étiquettes ou créer un groupe de cartes basé sur des étiquettes<br><br>',
                '<i>4.23.0:</i><br>Jumpstart<br><br>',
                '<i>4.22.0:</i><br>Core Set 2021<br><br>',
                '<i>4.21.0:</i><br>Ajout de Love your LGS<br>Suppresion de Promo set for Gatherer<br>Nouvelle fonte<br><br>',
                '<i>4.20.0:</i><br>Vous pouvez maintenant forcer le système à télécharger vers ou à télécharger depuis votre fournisseur de synchronisation<br><br>',
                '<i>4.19.0:</i><br>Signature Spellbook: Chandra<br><br>',
                '<i>4.18.0:</i><br>Judge Gift Cards 2020<br>League Tokens<br><br>',
                '<i>4.17.0:</i><br>Exportation des decks vers Magic Arena<br><br>',
                '<i>4.16.0:</i><br>Ikora<br>Guru<br>Junior Super Series<br><br>',
                '<i>4.15.0:</i><br>Commander 2020<br><br>',
                '<i>4.14.0:</i><br>National Promos<br>URL - Convention Promos<br><br>',
                '<i>4.13.0:</i><br>Release Events<br><br>',
                '<i>4.12.0:</i><br>15th Anniversary Cards<br><br>',
                '<i>4.11.0:</i><br>Secret Lair: Ultimate Edition<br><br>',
                '<i>4.10.0:</i><br>HasCon 2017<br>Open the Helvault<br><br>',
                '<i>4.9.0:</i><br>Mystery Booster Retail Edition Foils<br><br>',
                '<i>4.8.0:</i><br>Unsanctioned<br><br>',
                '<i>4.7.0:</i><br>IDW Comics 2012, 2013, 2014<br><br>',
                '<i>4.6.0:</i><br>Format des decks<br><br>',
                "<i>4.5.0:</i><br>Option pour déplacer des cartes rapidement à l'interieur d'un deck<br><br>",
                '<i>4.4.0:</i><br>Option pour mettre à jour les prix des decks directement<br><br>',
                '<i>4.3.0:</i><br>Modes de grille de données<br>Compteur de ventes<br><br>',
                '<i>4.2.0:</i><br>Amélioration du scanner de cartes<br><br>',
                '<i>4.1.0:</i><br>Améliorations de la page des réglages<br>Images au format PNG<br><br>',
                '<i>4.0.0:</i><br>Extensions étrangères: Renaissance et Foreign Black Border<br>Les terrains sont désormais correctement vus comme sans couleur<br><br>',
                '<i>3.17.0:</i><br>International Collectors Edition<br><br>',
                '<i>3.16.0:</i><br>Theros: Beyond Death<br>Tri personnalisé<br><br>',
                '<i>3.15.0:</i><br>Option pour exclure des super types dans la recherche<br><br>',
                '<i>3.14.0:</i><br>Synchronisation des paramètres<br><br>',
                '<i>3.13.0:</i><br>Mystery Booster<br>Mystery Booster Playtest Cards<br>Secret Lair Drop Series<br><br>',
                '<i>3.12.0:</i><br>Afficher les extensions liées sur la page de bloc<br><br>',
                '<i>3.11.0:</i><br>Nouveau format : Pioneer<br><br>',
                '<i>3.10.0:</i><br>Ponies: The Galloping<br><br>',
                '<i>3.9.0:</i><br>Throne of Eldraine<br><br>',
                '<i>3.8.0:</i><br>Nouvelles options de recherches<br><br>',
                '<i>3.7.0:</i><br>MagicFest 2019<br><br>',
                '<i>3.6.0:</i><br>Support de Dropbox<br><br>',
                '<i>3.5.0:</i><br>Commander 2019<br>Champs and States<br>Recherche par exclusion<br>Mode commandant<br><br>',
                '<i>3.4.0:</i><br>Meilleur écran de gestion des decks<br>Image de fond pour les decks<br>Plus de super types<br><br>',
                '<i>3.3.0:</i><br>Les terrains et les emblèmes sont désormais considérés comme des super types<br>Mode lecture seule<br><br>',
                '<i>3.2.0:</i><br>Filtre pour les commandants<br>Lien EDHRec<br>Super types<br><br>',
                '<i>3.1.0:</i><br>Mode dessin uniquement sur la page des cartes<br><br>',
                '<i>3.0.0:</i><br>Suppression de la couleur Artefact au profit de sans couleur<br><br>',
                "<i>2.16.0:</i><br>Recherche sur les élements de l'histoire<br><br>",
                '<i>2.15.0:</i><br>Liste des Gold Patreons<br><br>',
                '<i>2.14.0:</i><br>Nouveau bloc : World Championship Decks<br><br>',
                '<i>2.13.0:</i><br>Nouvelle extension : Rivals Quick Start Set<br><br>',
                '<i>2.12.0:</i><br>Plus de filtres pour la page de statistiques<br><br>',
                '<i>2.11.0:</i><br>Nouvelles options de recherche : Foil, non foil, promos<br><br>',
                '<i>2.10.0:</i><br>Nouvelle extension : San Diego Comic-Con 2019, Core Set 2020<br><br>',
                '<i>2.9.0:</i><br>Nouvelle extension : Modern Horizons<br><br>',
                '<i>2.8.0:</i><br>Nouvelles extensions : Magic Origins Clash Pack, Fate Reforged Clash Pack<br><br>',
                '<i>2.7.0:</i><br>Nouvelles extensions : Vanguard Series, Dragon Con<br><br>',
                '<i>2.6.0:</i><br>Nouvelles extension : La Guerre des Planeswalkers<br><br>',
                '<i>2.5.0:</i><br>Fusion<br><br>',
                '<i>2.4.0:</i><br>Recherche rapide<br><br>',
                "<i>2.3.0:</i><br>Nouvelles extensions : Collectors' Edition, Deckmasters, Anthologies<br><br>",
                '<i>2.2.0:</i><br>Images haute qualité<br><br>',
                '<i>2.1.0:</i><br>Les ancienes extensions sont maintenant correctement triées<br><br>',
                '<i>2.0.0:</i><br>Nouveau système de synchronisation OneDrive asynchrone<br><br>',
                '<i>1.6.0:</i><br>Prix sur Scryfall<br><br>',
                '<i>1.5.0:</i><br>Support du prix des cartes foils<br><br>',
                '<i>1.4.0:</i><br>Support initial des tokens<br><br>',
                "<i>1.3.0:</i><br>Les Patreons peuvent mettre à jour la couleur d'accent<br><br>",
                '<i>1.2.0:</i><br>Grille de données<br><br>',
                '<i>1.1.0:</i><br>Liste de contrôle<br><br>',
                '<i>1.0.0:</i><br>Patreon<br><br>',
                '<i>0.9.5:</i><br>Importation depuis Magic Arena<br><br>',
                '<i>0.9.0:</i><br>Scanneur de cartes<br><br>',
                '<i>0.8.0:</i><br>Simulateur de mains<br><br>',
                '<i>0.7.0:</i><br>Statistiques pour les decks<br><br>',
                '<i>0.6.0:</i><br>Decks<br>Importation et exportation de decks depuis et vers TappedOut, DeckBox<br><br>',
                '<i>0.5.0:</i><br>Compteurs de vie<br><br>',
                '<i>0.4.0:</i><br>Prix<br><br>',
                '<i>0.0.0:</i><br>Première version avec PWA<br><br>'
            ];
        }

        return [
            '<i>14.6.0:</i><br>Tarkir: Dragonstorm <br><br>',
            '<i>14.5.0:</i><br>Aetherdrift<br><br>',
            '<i>14.4.0:</i><br>Refreshed rules<br><br>',
            '<i>14.3.0:</i><br>Innistrad Remastered<br><br>',
            '<i>14.2.0:</i><br>Deal<br><br>',
            '<i>14.1.0:</i><br>Zoom support for scanner<br><br>',
            '<i>14.0.0:</i><br>New backend server<br><br>',
            '<i>13.1.0:</i><br>Foundations<br><br>',
            '<i>13.0.0:</i><br>Tracking the date when a card is added to the collection<br><br>',
            '<i>12.15.0:</i><br>Duskmourn: House of Horror<br><br>',
            '<i>12.14.0:</i><br>Bloomburrow<br><br>',
            '<i>12.13.0:</i><br>Assassin\'s Creed<br><br>',
            '<i>12.12.0:</i><br>Modern Horizons 3<br><br>',
            '<i>12.11.0:</i><br>Outlaws of Thunder Junction<br><br>',
            '<i>12.10.0:</i><br>Fallout<br><br>',
            '<i>12.9.0:</i><br>Murders at Karlov Manor<br><br>',
            '<i>12.8.0:</i><br>The Lost Caverns of Ixalan<br><br>',
            '<i>12.7.0:</i><br>New option to define a "wanted" number per card (as the opposite of To Trade)<br><br>',
            '<i>12.6.0:</i><br>Doctor Who<br><br>',
            '<i>12.5.0:</i><br>You can now filter serialized cards out from the database<br><br>',
            '<i>12.4.0:</i><br>Wilds of Eldraine<br><br>',
            '<i>12.3.0:</i><br>Commander Masters<br><br>',
            '<i>12.2.0:</i><br>The system now performs a check to prevent loading a backup made with a more recent version<br><br>',
            '<i>12.1.0:</i><br>The Lord of the Rings: Tales of Middle-earth<br><br>',
            '<i>12.0.0:</i><br>Help page<br><br>',
            '<i>11.9.0:</i><br>March of the Machine<br><br>',
            '<i>11.8.0:</i><br>Album view for lists<br><br>',
            '<i>11.7.0:</i><br>Decks can be exported to Moxfield<br>When decks are exported, they are also copied to clipboard<br>Use F3 as a shortcut for search box<br><br>',
            '<i>11.6.0:</i><br>Phyrexia: All Will Be One<br><br>',
            '<i>11.5.0:</i><br>Starter Commander Decks<br>Dominaria Remastered<br><br>',
            '<i>11.4.0:</i><br>The Brothers\' War<br><br>',
            '<i>11.3.0:</i><br>You can now search specifics in the advanced search page<br>New option on mobile to choose the number of columns for the grid view<br><br>',
            '<i>11.2.0:</i><br>Unfinity<br><br>',
            '<i>11.1.0:</i><br>Warhammer 40,000<br><br>',
            '<i>11.0.0:</i><br>Reworked the way individual cards are referenced when dealing with grading, languages and conditions<br><br>',
            '<i>10.8.0:</i><br>New search field for custom icons<br>Dominaria United<br><br>',
            '<i>10.7.0:</i><br>Started reorganizing Secret Lair block (will be a long process :))<br><br>',
            '<i>10.6.0:</i><br>Getting ready for Dominaria United<br>Added name filter for checklists<br>Collection mode is now working in list view as well<br><br>',
            '<i>10.5.0:</i><br>Patreons can now set up a <a target="_blank" href="https://www.deltakosh.com/urzagatherer/announcements/10-5-0">custom icon</a> to differentiate cards<br><br>',
            '<i>10.4.0:</i><br>There is now an option to <a target="_blank" href="https://www.deltakosh.com/urzagatherer/announcements/10-4-0">hide individual cards</a><br><br>',
            '<i>10.3.0:</i><br>You can now flag a card as <a target="_blank" href="https://www.deltakosh.com/urzagatherer/announcements/10-3-0">proxy</a><br><br>',
            '<i>10.2.0:</i><br>There is now a button in the settings to force the application to update the database<br><br>',
            '<i>10.1.0:</i><br>New option to display block progress as number of missing cards<br><br>',
            '<i>10.0.0:</i><br>Patreons now have a new option to synchronize their data without using DropBox or OneDrive<br><br>',
            '<i>9.9.0:</i><br>Double Masters 2022<br><br>',
            '<i>9.8.0:</i><br>You can now display the content of decks in <a target="_blank" href="https://www.deltakosh.com/urzagatherer/announcements/9-8-0">grouped mode</a><br><br>',
            '<i>9.7.0:</i><br>Commander Legends: Battle for Baldur\'s Gate<br><br>',
            '<i>9.6.0:</i><br>More filters are now able to indicate the number of cards per option<br><br>',
            '<i>9.5.0:</i><br>New filter: Full text<br><br>',
            '<i>9.4.0:</i><br>New blocks for Secret Lair, Un-Sets, Signature Spellbooks and Archenemy<br><br>',
            '<i>9.3.0:</i><br>Streets of New Capenna<br><br>',
            '<i>9.2.0:</i><br>All types of special foils (etched, galaxy, gilded) are now under the same "Special Foil" label.<br><br>',
            '<i>9.1.0:</i><br>Patreons can hide commands on cards and only show them when the mouse is over the card (<a target="_blank" href="https://www.deltakosh.com/urzagatherer/announcements/9-1-0">more info</a>)<br><br>',
            '<i>9.0.0:</i><br><a target="_blank" href="https://www.deltakosh.com/urzagatherer/announcements/9-0-0">New way to add cards to a deck</a><br><br>',
            '<i>8.1.0:</i><br>Reworked Swap with a reprint dialog for cards in decks<br><br>',
            '<i>8.0.0:</i><br>It is now possible to have multiple <a target="_blank" href="https://www.deltakosh.com/urzagatherer/announcements/8-0-0">users</a><br>Option to hide sets with no card in the collection<br><br>',
            '<i>7.17.0:</i><br>Kamigawa: Neon Dynasty<br>New search options for card quantity<br><br>',
            '<i>7.16.0:</i><br>Option to display buttons for foils when in collection mode<br><br>',
            '<i>7.15.0:</i><br>Option to display the number of reprints you own on each card<br><br>',
            '<i>7.14.0:</i><br>Fixed rendering on small screens<br>Adding DFC support in the advanced search page<br><br>',
            '<i>7.13.0:</i><br>Internal optimizations<br><br>',
            '<i>7.12.0:</i><br>Better support for foil-etcheds<br><br>',
            '<i>7.11.0:</i><br>Innistrad: Crimson Vow<br><br>',
            '<i>7.10.0:</i><br>You can now export your decks to csv file format<br><br>',
            '<i>7.9.0:</i><br>It is now possible to filter cards by tags<br><br>',
            '<i>7.8.0:</i><br>Innistrad: Midnight Hunt<br><br>',
            '<i>7.7.0:</i><br>Languages, gradings and conditions can now be set per each instance of a card<br><br>',
            '<i>7.6.0:</i><br>Foreign White Border<br>Italian Legends<br><br>',
            '<i>7.5.0:</i><br>Patreons can now create <a target="_blank" href="https://www.deltakosh.com/urzagatherer/announcements/7-5-0">custom blocks</a><br><br>',
            '<i>7.4.0:</i><br>Card deep link<br><br>',
            '<i>7.3.0:</i><br>Patreons can locally download the standard quality images (to be able to fully work offline)<br><br>',
            '<i>7.2.0:</i><br>You can now add comments on sets<br><br>',
            '<i>7.1.0:</i><br><span>Introducing the <a target="_blank" href="https://www.deltakosh.com/urzagatherer/announcements/7-1-0">Shopping Assistant</a></span><br><br>',
            '<i>7.0.0:</i><br><span>Introducing the <a target="_blank" href="https://www.deltakosh.com/urzagatherer/announcements/7-0-0">Lore Explorer</a></span><br><br>',
            '<i>6.8.0:</i><br>New collection visual indicator (can be turned on in the settings page)<br><br>',
            '<i>6.7.0:</i><br>Adventures in the Forgotten Realms<br><br>',
            '<i>6.6.0:</i><br>New login system for Patreon<br><br>',
            '<i>6.5.0:</i><br>Patreons can now change background image<br>Removed stories and wallpapers as Wizards changed (again) their website<br><br>',
            '<i>6.4.0:</i><br>Major update of CardMarket price engine<br><br>',
            '<i>6.3.0:</i><br>Modern Horizons 2<br><br>',
            '<i>6.2.0:</i><br>Automatic dark mode detection<br><br>',
            '<i>6.1.0:</i><br>You can now hide blocks and remove them from the database<br>Commander 2021<br><br>',
            '<i>6.0.0:</i><br>Stories are back!<br>Support of card grading (PSA)<br>Strixhaven<br>Oversized cards<br>Common, uncommon and rare cards can be removed from the database<br><br>',
            '<i>5.11.0:</i><br>Time Spiral Remastered<br><br>',
            '<i>5.10.0:</i><br>Kaldheim<br><br>',
            '<i>5.9.0:</i><br>Modern Horizons Art Series<br>Zendikar Rising Art Series<br><br>',
            '<i>5.8.0:</i><br>Commander Legends<br><br>',
            '<i>5.7.0:</i><br>More colors :)<br>You can now use Commander image as background for Commander decks<br>Hover images for datagrids<br><br>',
            '<i>5.6.0:</i><br>The List<br><br>',
            '<i>5.5.0:</i><br>New logo<br><br>',
            '<i>5.4.0:</i><br>Custom checklist<br>Commander Collection: Green<br><br>',
            '<i>5.3.0:</i><br>Face the Hydra<br>Battle the Horde<br>Defeat a God<br><br>',
            '<i>5.2.0:</i><br>Option to separate extras from main set<br><br>',
            '<i>5.1.0:</i><br>Double Masters<br><br>',
            '<i>5.0.0:</i><br>You can now tag cards, search cards by tags or create group of cards based on tags<br><br>',
            '<i>4.23.0:</i><br>Jumpstart<br><br>',
            '<i>4.22.0:</i><br>Core Set 2021<br><br>',
            '<i>4.21.0:</i><br>Added Love your LGS<br>Removed Promo set for Gatherer<br>New font<br><br>',
            '<i>4.20.0:</i><br>You can now force the system to upload to or download from your synchronization provider<br><br>',
            '<i>4.19.0:</i><br>Signature Spellbook: Chandra<br><br>',
            '<i>4.18.0:</i><br>Judge Gift Cards 2020<br>League Tokens<br><br>',
            '<i>4.17.0:</i><br>Export decks to Magic Arena<br><br>',
            '<i>4.16.0:</i><br>Ikora<br>Guru<br>Junior Super Series<br><br>',
            '<i>4.15.0:</i><br>Commander 2020<br><br>',
            '<i>4.14.0:</i><br>National Promos<br>URL - Convention Promos<br><br>',
            '<i>4.13.0:</i><br>Release Events<br>Removed stories<br><br>',
            '<i>4.12.0:</i><br>15th Anniversary Cards<br><br>',
            '<i>4.11.0:</i><br>Secret Lair: Ultimate Edition<br><br>',
            '<i>4.10.0:</i><br>HasCon 2017<br>Open the Helvault<br><br>',
            '<i>4.9.0:</i><br>Mystery Booster Retail Edition Foils<br><br>',
            '<i>4.8.0:</i><br>Unsanctioned<br><br>',
            '<i>4.7.0:</i><br>IDW Comics 2012, 2013, 2014<br><br>',
            '<i>4.6.0:</i><br>Deck format<br><br>',
            '<i>4.5.0:</i><br>Option to quickly move cards inside a deck<br><br>',
            '<i>4.4.0:</i><br>Option to update deck prices directly<br><br>',
            '<i>4.3.0:</i><br>Datagrid modes<br>Trade counter<br><br>',
            '<i>4.2.0:</i><br>Improved card scanner<br><br>',
            '<i>4.1.0:</i><br>Settings page improvements<br>PNG images<br><br>',
            '<i>4.0.0:</i><br>Foreign sets: Renaissance and Foreign Black Border<br>Lands are now correctly seen as colorless<br><br>',
            '<i>3.17.0:</i><br>International Collectors Edition<br><br>',
            '<i>3.16.0:</i><br>Theros: Beyond Death<br>Custom sort<br><br>',
            '<i>3.15.0:</i><br>Option to exclude super types from the search<br><br>',
            '<i>3.14.0:</i><br>Settings synchronization<br><br>',
            '<i>3.13.0:</i><br>Mystery Booster<br>Mystery Booster Playtest Cards<br>Secret Lair Drop Series<br><br>',
            '<i>3.12.0:</i><br>Display linked sets on block page<br><br>',
            '<i>3.11.0:</i><br>New format: Pioneer<br><br>',
            '<i>3.10.0:</i><br>Ponies: The Galloping<br><br>',
            '<i>3.9.0:</i><br>Throne of Eldraine<br><br>',
            '<i>3.8.0:</i><br>New search options<br><br>',
            '<i>3.7.0:</i><br>MagicFest 2019<br><br>',
            '<i>3.6.0:</i><br>Dropbox support<br><br>',
            '<i>3.5.0:</i><br>Commander 2019<br>Champs and States<br>Search using exclusions<br>Commander mode<br><br>',
            '<i>3.4.0:</i><br>Better deck management page<br>Background image for decks<br>More super types<br><br>',
            '<i>3.3.0:</i><br>Lands and emblems as super types<br>Read-only mode<br><br>',
            '<i>3.2.0:</i><br>Commanders filter<br>EDHRec link<br>Super types<br><br>',
            '<i>3.1.0:</i><br>Card page can now display drawing only instead of full card<br><br>',
            '<i>3.0.0:</i><br>Artifact replaced by colorless from a color standpoint<br><br>',
            '<i>2.16.0:</i><br>Search on story spotlights<br><br>',
            '<i>2.15.0:</i><br>List of Gold Patreons<br><br>',
            '<i>2.14.0:</i><br>New block: World Championship Decks<br><br>',
            '<i>2.13.0:</i><br>New set: Rivals Quick Start Set<br><br>',
            '<i>2.12.0:</i><br>More filters for statistics page<br><br>',
            '<i>2.11.0:</i><br>New search options: Foil, non foil, promos<br><br>',
            '<i>2.10.0:</i><br>New sets: San Diego Comic-Con 2019, Core Set 2020<br><br>',
            '<i>2.9.0:</i><br>New sets: Modern Horizons<br><br>',
            '<i>2.8.0:</i><br>New sets: Magic Origins Clash Pack, Fate Reforged Clash Pack<br><br>',
            '<i>2.7.0:</i><br>New sets: Vanguard Series, Dragon Con<br><br>',
            '<i>2.6.0:</i><br>New set: War of the Spark<br><br>',
            '<i>2.5.0:</i><br>Merge<br><br>',
            '<i>2.4.0:</i><br>Search box<br><br>',
            "<i>2.3.0:</i><br>New sets: Collectors' Edition, Deckmasters, Anthologies<br><br>",
            '<i>2.2.0:</i><br>Ultra high quality images<br><br>',
            '<i>2.1.0:</i><br>Old sets are now correctly sorted<br><br>',
            '<i>2.0.0:</i><br>New asynchronous OneDrive synchronization system<br><br>',
            '<i>1.6.0:</i><br>Scryfall price provider<br><br>',
            '<i>1.5.0:</i><br>Foil card prices support<br><br>',
            '<i>1.4.0:</i><br>Initial support for tokens<br><br>',
            '<i>1.3.0:</i><br>Patreons can update accent color<br><br>',
            '<i>1.2.0:</i><br>Datagrids<br><br>',
            '<i>1.1.0:</i><br>Checklist<br><br>',
            '<i>1.0.0:</i><br>Patreon<br><br>',
            '<i>0.9.5:</i><br>Magic Arena importation<br><br>',
            '<i>0.9.0:</i><br>Card scanner<br><br>',
            '<i>0.8.0:</i><br>Hand simulator<br><br>',
            '<i>0.7.0:</i><br>Deck statistics<br><br>',
            '<i>0.6.0:</i><br>Decks<br>Deck importation and exportation from and to TappedOut, DeckBox<br><br>',
            '<i>0.5.0:</i><br>Life counters<br><br>',
            '<i>0.4.0:</i><br>Prices<br><br>',
            '<i>0.0.0:</i><br>Initial release on PWA<br><br>'
        ];
    }

    constructor() {
        GlobalState.PrepareLocalStorage();
        Collection.GlobalState = this;
        Collection.UseFrenchForTexts = GlobalState.LoadBoolSettings('FrenchDisplay', false);

        this.selectedLang = GlobalState.LoadSettings('SelectedLang', 'EN');

        this.onSelectedMenuIndexChanged.add((selection) => {
            this.selectedMenuIndex = selection.index;
        });

        this._workLocally = GlobalState.LoadBoolSettings('WorkLocally', false);

        this.imageWidth = GlobalState.LoadFloatSettings('imageWidth', 200);

        window.addEventListener('resize', () => {
            this.checkImageSize();
        });

        this.checkImageSize();

        if ('Notification' in window) {
            this.onNotificationRequired.add((message) => {
                Notification.requestPermission().then(function () {
                    if (Notification.permission === 'denied') {
                        return;
                    }
                    const notif = new Notification(message, { icon: '/images/60x60.png', tag: '' });
                    notif.onclick = null; // () => {};
                });
            });
        }

        if (this.needFrenchVersion) {
            this.strings.ZeusLegacyAdsImg = 'Cover-03-fr.jpg';
            this.strings.TooManyPages = 'Trop de pages à afficher';
            this.strings.Deal = 'Le deal';
            this.strings.HideDealPage = 'Cacher la page du deal';
            this.strings.Zoom = 'Zoom :';
            this.strings.MakeSureCamera = 'Assurez-vous que la carte est nette et occupe le plus possible l\'image';
            this.strings.AddedOn = 'Avant le :';
            this.strings.AddedAfter = 'Après le :';
            this.strings.Added = 'Ajouté le';
            this.strings.Support = 'Soutien';
            this.strings.NewerVersionDetected = 'Votre collection provient d\'une version plus récente d\'UrzaGatherer. Veuillez mettre à jour UrzaGatherer vers la dernière version. La base de données est en mode lecture seule pour protéger vos informations.';
            this.strings.HowListsWork = 'Comment fonctionnent les listes ?';
            this.strings.WhyPricesAreIncorrect = 'Pourquoi les prix ne sont pas corrects sur certaines cartes ?';
            this.strings.QuicklyAddingCardsToDeck = 'Ajouter des cartes rapidement à un deck';
            this.strings.SettingCustomIcon = 'Définir des icônes personnalisées';
            this.strings.HidingCards = 'Cacher des cartes';
            this.strings.Features = 'Fonctionnalités';
            this.strings.Troubleshooting = 'Dépannage';
            this.strings.VersionUpdate = 'Pourquoi ne puis-je pas voir la dernière version ?';
            this.strings.Help = 'Aide';
            this.strings.ZodiacLegionAdsImg = 'cover-fr.jpg';
            this.strings.ZodiacLegionAdsLink = 'https://www.amazon.fr/dp/B0BX8WR2NV';
            this.strings.MagicCompendiumAdsImg = 'Cover-fr2.jpg';
            this.strings.MagicCompendiumAdsLink = 'https://www.amazon.fr/Compendium-Magie-David-Catuhe/dp/B0CS4GSX9G';
            this.strings.TokenHelp = 'Veuillez envoyer une message privé sur Patreon pour obtenir votre jeton';
            this.strings.NumberOfColumns = 'Nombre de colonnes en vue grille';
            this.strings.OnlyMissingExcludeReprints = 'Uniquement les manquantes (exclure les réimpressions)';
            this.strings.Premodern = 'Prémoderne';
            this.strings.CardID = 'ID';
            this.strings.Characteristics = 'Détails';
            this.strings.DeselectAll = 'Tout désélectionner';
            this.strings.SelectAll = 'Tout sélectionner';
            this.strings.Money = 'Argent';
            this.strings.Alien = 'Alien';
            this.strings.Star = 'Etoile';
            this.strings.Sun = 'Soleil';
            this.strings.Exclamation = 'Point d\'exclamation';
            this.strings.CustomIconSearch = 'Icône personnalisée :';
            this.strings.CustomIcon = 'Icône personnalisée';
            this.strings.DBUpdateSuccessful = 'Veuillez fermer et redémarrer votre application ou votre navigateur pour terminer la mise à jour.';
            this.strings.HiddenCardList = 'Liste des cartes cachées';
            this.strings.HideCard = 'Cacher cette carte';
            this.strings.HideCardConfirm = 'Voulez-vous vraiment masquer cette carte ? Le changement sera visible après le redémarrage/rechargement de l\'application.';
            this.strings.Proxies = 'Proxies :';
            this.strings.IsProxy = 'Proxy';
            this.strings.ExcludeProxies = 'Exclure les proxies (toutes)';
            this.strings.OnlyProxies = 'Seulement les proxies (toutes)';
            this.strings.ExcludeCardProxies = 'Exclure les proxies (cartes)';
            this.strings.OnlyCardProxies = 'Seulement les proxies (cartes)';
            this.strings.ExcludeDeckProxies = 'Exclure les proxies (decks)';
            this.strings.OnlyDeckProxies = 'Seulement les proxies (decks)';
            this.strings.FlagAsProxy = 'Marquer comme proxy';
            this.strings.UnflagAsProxy = 'Enlever le statut de proxy';
            this.strings.BlockProgressAsCards = 'Afficher la progression des blocs en nombre de cartes';
            this.strings.DeckAsGroupList = 'Afficher le contenu des decks en mode groupé';
            this.strings.CommandsOnHover = 'Afficher les commandes des cartes uniquement au survol de la souris';
            this.strings.DisplayActiveDeckIcon = 'Afficher une icône pour envoyer la carte vers le deck actif (En mode collection)';
            this.strings.Target = 'Cible';
            this.strings.ActiveDeck = 'Deck actif';
            this.strings.NonLands = 'Non terrains';
            this.strings.UniqueOnly = 'Seulement les cartes uniques';
            this.strings.CardDisplay = 'Affichage des cartes';
            this.strings.ImageQuality = 'Qualité des images';
            this.strings.HideZeroCardSet = 'Cacher les extensions sans carte dans la collection';
            this.strings.ManageUsers = 'Gérer';
            this.strings.ManageUsersDesc = 'Gérer les utilisateurs';
            this.strings.Users = 'Utilisateurs';
            this.strings.NewUser = 'Nouvel utilisateur';
            this.strings.CurrentUser = 'Utilisateur actif';
            this.strings.QuantityOptions = 'Options pour la quantité :';
            this.strings.DisplayCollectionCountInAlbumView = 'Afficher le nombre de cartes de la collection sur la vue album';
            this.strings.FoilInCollectionMode = 'Afficher des boutons pour les foils en mode collection';
            this.strings.DisplayReprintCount = 'Afficher le nombre de réimpression que vous possédez sur chaque carte';
            this.strings.DFC_ = 'DFC :';
            this.strings.Include = 'Inclure';
            this.strings.Only = 'Uniquement';
            this.strings.WhenRemovingFromCollection = 'Lors de la suppression de la collection';
            this.strings.Confirmations = 'Confirmations';
            this.strings.TransparentTags = 'Utiliser des icônes semi-transparents sur les cartes';
            this.strings.Excellent = 'Excellent';
            this.strings.Spotlight = 'Carte de focus sur le lore';
            this.strings.IgnoreArtCards = "Ignorer les cartes d'art series";
            this.strings.IgnoreSubstituteCards = 'Ignorer les cartes de substitution';
            this.strings.IgnoreOversizedCards = 'Ignorer les cartes surdimensionnées';
            this.strings.IgnoreMinigames = 'Ignorer les cartes de mini jeux';
            this.strings.IgnoreSerialized = 'Ignorer les cartes sérialisées';
            this.strings.DefaultLanguage = 'Langue des cartes par défaut';
            this.strings.DefaultCondition = 'Condition des cartes par défaut';
            this.strings.DefaultGrading = 'Notation des cartes par défaut';
            this.strings.English = 'Anglais';
            this.strings.French = 'Français';
            this.strings.Italian = 'Italien';
            this.strings.German = 'Allemand';
            this.strings.Spanish = 'Espagnol';
            this.strings.Japanese = 'Japonais';
            this.strings.ChineseSimp = 'Chinois simplifié';
            this.strings.ChineseTrad = 'Chinois traditionnel';
            this.strings.Korean = 'Koréen';
            this.strings.Portuguese = 'Portugais';
            this.strings.Russian = 'Russe';
            this.strings.NeedToAddCardToCollectionFirst = "Vous devez d'abord ajouter la carte à votre collection.";
            this.strings.UseFoilPriceWhenOwned =
                'Utilisez le prix foil pour le tri lorsque vous possédez une carte en foil';
            this.strings.EditBlock = 'Editer bloc';
            this.strings.NewBlock = 'Nouveau bloc';
            this.strings.DeepLink = 'Lien direct';
            this.strings.NonCreatureArtifacts = 'Artefacts non créatures';
            this.strings.NonArtifactCreatures = 'Créatures non artefacts';
            this.strings.DownloadMidImages = 'Télécharger';
            this.strings.DownloadMidImagesDesc =
                'Télécharger localement toutes les images de qualité standard  (~10Gb)';
            this.strings.DownloadMidImagesFromCollection = 'Télécharger';
            this.strings.DownloadMidImagesFromCollectionDesc =
                'Télécharger localement toutes les images de votre collection en qualité standard';
            this.strings.Turn = 'Tour : ';
            this.strings.OpenTab = 'Ouvrir dans un nouvel onglet';
            this.strings.ShoppingAssistant = "Assistant d'achat";
            this.strings.DebugInfo = 'Information de debug';
            this.strings.NoCardWithFlavorFound = "Aucune carte avec un texte d'ambiance n'a été trouvée.";
            this.strings.ScaleDown = 'Taille initiale';
            this.strings.Card = 'Carte';
            this.strings.Contain = 'Contenir';
            this.strings.Cover = 'Recouvrir';
            this.strings.LoreExplorer = 'Explorateur de Lore';
            this.strings.DisplayLargeMissingCollectionIndicator = "Appliquer l'indicateur aux cartes manquantes";
            this.strings.DisplayLargeCollectionIndicator =
                "Utiliser un indicateur sur l'ensemble de l'image pour les cartes collectées";
            this.strings.TokenNotValid =
                'Jeton invalide. Veuillez vous connecter sur Patreon et faire la demande de votre token';
            this.strings.Token = 'Jeton';
            this.strings.SetAsBackgroundImage = 'Définir comme image de fond';
            this.strings.BackgroundImage = 'Définir';
            this.strings.BackgroundImageDesc = 'Définir image de fond';
            this.strings.Dungeons = 'Donjons';
            this.strings.DatabaseID = 'Identifiant interne';
            this.strings.Deactivate = 'Désactiver';
            this.strings.Activate = 'Activer';
            this.strings.LoadConfirm =
                'Êtes-vous sûr de vouloir charger une nouvelle collection (votre collection actuelle sera réinitialisée) ? ';
            this.strings['Share'] = 'Partager';
            this.strings['Card#'] = 'Carte #';
            this.strings['MoxfieldFile'] = 'Fichiers Moxfield';
            this.strings['MtgaFile'] = 'Fichiers Magic Arena';
            this.strings['TxtFile'] = 'Fichiers textes';
            this.strings['CsvFile'] = 'Fichiers CSV';
            this.strings['UgsFile'] = 'Fichers de données UzaGatherer';
            this.strings['UpdateBlockDatabase'] = 'Personnaliser';
            this.strings['UpdateBlockDatabaseDesc'] = 'Personnaliser les blocs actifs';
            this.strings['UpdateSetDatabase'] = 'Personnaliser';
            this.strings['UpdateSetDatabaseDesc'] = 'Personnaliser les extensions actives';
            this.strings['UpdateHiddenCardList'] = 'Gérer';
            this.strings['UpdateHiddenCardListDesc'] = 'Gérer la liste des cartes cachees';
            this.strings['Grading'] = 'Notation';
            this.strings['Grading_'] = 'Notation :';
            this.strings['AllGradings'] = 'Toutes les notations';
            this.strings['IgnoreCommonCards'] = 'Ignorer les cartes communes';
            this.strings['IgnoreUncommonCards'] = 'Ignorer les cartes peu communes';
            this.strings['IgnoreRareCards'] = 'Ignorer les cartes rares';
            this.strings['IgnoreMythicCards'] = 'Ignorer les cartes mythiques';
            this.strings['Commander Duel'] = 'Duel  Commander';
            this.strings.UseCommanderAsBackground = 'Image du Commandeur';
            this.strings.Draft = 'Draft';
            this.strings.Permanents = 'Permanents';
            this.strings.ShowExtrasAsSeparate = "Séparer les extras de l'extension principale";
            this.strings.Add = 'Ajouter';
            this.strings.NewTag = 'Nouvelle étiquette';
            this.strings.Lists = 'Listes';
            this.strings.Tags = 'Etiquettes';
            this.strings.Tags_ = 'Etiquettes :';
            this.strings.TotalCMC = 'Coût mana total';
            this.strings.AvgCMC = 'Coût mana moyen';
            this.strings.GetFromCloud = 'Récupérer';
            this.strings.GetFromCloudDesc = 'Récupérer du Cloud';
            this.strings.SendToCloud = 'Envoyer';
            this.strings.SendToCloudDesc = 'Envoyer vers le Cloud';
            this.strings.AllSetCode = 'Tous les codes';
            this.strings.SetCode = "Code de l'extension :";
            this.strings.DeckInvalid = 'Cette carte est invalide dans ce deck.';
            this.strings.SetToTrade = 'Quantité à échanger';
            this.strings.ToTrade = 'Pour échange';
            this.strings.ToTrade_ = 'Pour échange :';
            this.strings.SetWanted = 'Quantité voulue';
            this.strings.Wanted = 'Voulue';
            this.strings.Wanted_ = 'Voulue :';
            this.strings.PatreonAds =
                'Il semblerait que vous aimez UrzaGatherer :) Vous pouvez le soutenir en allant sur Patreon.com/urzagatherer';
            this.strings.CardScannerLang = 'Langue :';
            this.strings.Misc = 'Divers';
            this.strings.Menu = 'Menu';
            this.strings.Localization = 'Localisation';
            this.strings.UniqueNames = 'Seulement noms uniques';
            this.strings.IgnoreForeignSets = 'Ignorer les extensions étrangères';
            this.strings.ChangeSortDirection = 'Changer la direction du tri';
            this.strings.NeedToConfigureCustomSort =
                'Tri personnalisé non défini. Veuillez aller sur la page des paramètres pour le configurer';
            this.strings.UpdateCustomSort = 'Configurer';
            this.strings.UpdateCustomSortDesc = 'Configurer le tri personnalisé';
            this.strings.UpdateCustomChecklist = 'Configurer';
            this.strings.UpdateCustomChecklistDesc = 'Configurer la liste de contrôle';
            this.strings.CustomFiltersDesc = 'Les cartes seront triées en utilisant la priorité de tri suivante :';
            this.strings.CustomFilters = 'Tri personnalisé';
            this.strings.CustomChecklistDesc = 'Liste des extensions actives pour la liste de contrôle :';
            this.strings.CustomChecklist = 'Liste de contrôle personnalisée';
            this.strings.SyncSettings = 'Synchronisation des paramètres';
            this.strings.DisplayLinkedSet = 'Afficher les extensions liées sur la page de bloc';
            this.strings.Pioneer = 'Pioneer';
            this.strings.Notes = 'Notes';
            this.strings.HideReprints = 'Cacher les réimpressions';
            this.strings.HideSplitCard = 'Masquer les cartes liées (Doubles)';
            this.strings.HideTransformCard = 'Masquer les cartes liées (Transformées)';
            this.strings.InstallationPrompt = 'Voulez-vous installer UrzaGatherer comme une application ?';
            this.strings.Language = 'Langue';
            this.strings.Language_ = 'Langue :';
            this.strings.AllLanguages = 'Toutes les langues';
            this.strings.MergeReprints = 'Sélectionner une seule carte par nom et artiste :';
            this.strings.MergeReprintsForQuantity =
                'Considérer les réimpressions comme la même carte pour la recherche quantité :';
            this.strings.Options = 'Options :';
            this.strings.SetAsCommander = 'Marquer commandant';
            this.strings.RemoveCommander = 'Enlever commandant';
            this.strings.Exclude_ = 'Exclusions :';
            this.strings.None = 'Rien';
            this.strings.NoneDeck = 'Aucun';
            this.strings.Nobackground = "Pas d'image de fond";
            this.strings.PickCardImage = 'Choisissez une carte';
            this.strings.PickCardIcon = 'Choisissez une icône';
            this.strings.ChangeBackground = 'Image';
            this.strings.DeckGroupName = 'Nom du groupe';
            this.strings.ReadOnlyModeEngaged = 'Mode lecture seule : les modifications ne seront pas sauvegardées';
            this.strings.Commanders = 'Commandants';
            this.strings.CroppedCard = 'Voir uniquement dessin';
            this.strings.NormalCard = 'Voir carte entière';
            this.strings.SelectSets = 'Extension à utiliser';
            this.strings.SwapReprints = 'Autre réimpression';
            this.strings.Emblems = 'Emblèmes';
            this.strings.Tokens = 'Tokens';
            this.strings.SynchronizationState = 'Etat de la synchronisation';
            this.strings.Synchronized = 'Synchronisé';
            this.strings['Synchronizing...'] = 'En cours de synchronisation...';
            this.strings.FoilState = 'Status foil';
            this.strings.Foil = 'Foil';
            this.strings.SpecialFoil = 'Foil spécial';
            this.strings.SpecialFoils = 'Foils spéciaux';
            this.strings.OnlySpecialFoil = 'Uniquement foil spécial';
            this.strings.NonFoil = 'Non foil';
            this.strings.Shuffle = 'Mélanger bibliothèque';
            this.strings.OnlyTokens = 'Uniquement les tokens et les emblèmes';
            this.strings.OnlyPromos = 'Uniquement les promos';
            this.strings.OnlyRecents = 'Uniquement les versions récentes';
            this.strings.HideCompleted = 'Masquer les blocs et les ensembles terminés';
            this.strings.OpenBlock = 'Ouvrir bloc';
            this.strings.Download = 'Télécharger';
            this.strings.Merge = 'Fusionner';
            this.strings.MergeDesc = 'Fusionner depuis un fichier';
            this.strings.Thanks = 'Remerciements';
            this.strings.Quantity = 'Quantité :';
            this.strings.NotFound = 'Indisponible';
            this.strings.CardsNotFound = 'Cartes non trouvées';
            this.strings['search...'] = 'Recherche(F3)...';
            this.strings['filter...'] = 'Filtrer...(La liste est limitée à 50 cartes)';
            this.strings['filter:'] = 'Filtre...';
            this.strings.CardScans = 'Scan des cartes';
            this.strings.Emblem = 'Emblème';
            this.strings.DataLoadedFromSync = 'Données chargées avec succès';
            this.strings.LoadServerData =
                'Des données plus récentes ont été trouvées sur votre fournisseur de synchronisation. \r\nVoulez-vous les charger ?';
            this.strings.UnknownError = 'Erreur inconnue';
            this.strings.QuickRemoveFromDeck = 'Supprimer de ce deck';
            this.strings.IssueWith = 'Problème avec';
            this.strings.ReportIssue = 'Signaler un problème';
            this.strings.FoilIndicator = "Afficher une icône pour indiquer qu'une carte que vous possédez est foil";
            this.strings.PickColor = 'Choisissez une couleur';
            this.strings.OnlyForPatreon =
                'Cette fonctionnalité est uniquement disponible pour les Patreons sur www.patreon.com/urzagatherer';
            this.strings.AccentColorDesc = "Changer la couleur d'accentuation";
            this.strings.AccentColor = 'Changer';
            this.strings.DisplayPrice = 'Afficher le prix sur chaque carte';
            this.strings.AddToCollection = 'Ajouter à la collection';
            this.strings.RemoveFromCollection = 'Retirer de la collection';
            this.strings.KeepLocalFile = 'Voulez-vous conserver vos données locales ?';
            this.strings.DeckIndicator = 'Indiquer combien de fois une carte est dans un deck';
            this.strings.Collection_ = 'Collection';
            this.strings.Ordered = 'Commandé';
            this.strings.CleanCacheSuccessful = 'Cache effacé avec succès';
            this.strings.DBUpdateFailed = 'Impossible de mettre l\'application à jour';
            this.strings.CleanCacheDesc = 'Vider le cache local';
            this.strings.CleanCache = 'Vider';
            this.strings.UpdateDBDesc = 'Mise à jour de l\'application';
            this.strings.UpdateDB = 'Mettre à jour';
            this.strings.DataGrid = 'Mode grille de données';
            this.strings.GridView = 'Vue en grille';
            this.strings.ListView = 'Vue en liste';
            this.strings.DataGridInsteadOfList = 'Pour les recherches et les extensions';
            this.strings.DataGridInsteadOfListForCollection = 'Pour la collection';
            this.strings.DataGridInsteadOfListForDecks = 'Pour les decks';
            this.strings.UrzaGathererFiles = 'Fichier UrzaGatherer';
            this.strings.Playset = 'Jeu complet (4 cartes)';
            this.strings.Mode = 'Mode :';
            this.strings.Show = 'Afficher :';
            this.strings.All = 'Tout';
            this.strings.OnlyComplete = 'Seulement les complètes';
            this.strings.OnlyStorySpotlight = "Elements de l'histoire";
            this.strings.Code = 'Code';
            this.strings.Year_ = 'Année';
            this.strings.Date = 'Date de sortie';
            this.strings['Missing (value)'] = 'Manquantes (valeur)';
            this.strings['In collection (value)'] = 'Dans la collection (valeur)';
            this.strings['Total value'] = 'Valeur totale';
            this.strings['Completion %'] = 'Achèvement';
            this.strings.WorkOffline = 'Non merci';
            this.strings.SyncProvider = 'Fournisseur de synchronisation';
            this.strings.ConnectDropboxSync = 'Dropbox';
            this.strings.ConnectDropboxSyncDesc = 'Se connecter à Dropbox';
            this.strings.ConnectUrzaDirectSync = 'UrzaDirect';
            this.strings.ConnectUrzaDirectSyncDesc = 'Se connecter à UrzaDirect';
            this.strings.ConnectOneDriveSync = 'OneDrive';
            this.strings.ConnectOneDriveSyncDesc = 'Se connecter à OneDrive';
            this.strings.DisconnectSync = 'Déconnecter';
            this.strings.DisconnectSyncDesc = 'Se déconnecter de ${0}';
            this.strings.Checklist = 'Liste de contrôle';
            this.strings.Disconnect = 'Déconnecter';
            this.strings.DisconnectDesc = 'Vos avantages Patreon sont actifs';
            this.strings.PatreonTopic =
                'Si vous voulez aider UrzaGatherer et montrer votre soutien, vous pouvez aller sur :';
            this.strings.PatreonDesc = 'Voulez-vous aller sur Patreon.com pour soutenir UrzaGatherer ?';
            this.strings.ThanksPatreon = 'Merci beaucoup de soutenir UrzaGatherer!';
            this.strings.PatreonLogin = 'Connexion';
            this.strings.PatreonLoginDesc = 'Connectez-vous à votre compte Patreon pour activer les avantages';
            this.strings.DisplayReservedList = 'Affiche une icône pour indiquer les cartes de la liste réservée';
            this.strings.Regex = 'Regexp :';
            this.strings.Scanning = 'Scan en cours...';
            this.strings.NoCardFound = 'Aucune carte trouvée. Veuillez réessayer';
            this.strings.ErrorCamera = "Une erreur s'est produite lors de l'accès au flux de la caméra";
            this.strings.NoCamera = 'Caméra indisponible';
            this.strings.Camera = 'Caméra :';
            this.strings.Scan = 'Scanner';
            this.strings.CardScanner = 'Scanneur de cartes';
            this.strings.Draw = 'Tirer une carte';
            this.strings.MoveTo = 'Déplacer vers';
            this.strings.Move = 'Déplacer';
            this.strings.TopLibrary = 'Dessus de la bibliothèque';
            this.strings.BottomLibrary = 'Dessous de bibliothèque';
            this.strings.ShuffleLibrary = 'Mélanger dans la bibliothèque';
            this.strings.Hand = 'Main';
            this.strings.Library = 'Bibliothèque';
            this.strings.Battlefield = 'Champ de bataille';
            this.strings.Graveyard = 'Cimetière';
            this.strings.Exile = 'Exil';
            this.strings.NoDeckFound = "Vous devez d'abord créer un deck sur la page des decks";
            this.strings.Simulator = 'Simulateur de mains';
            this.strings.Unselect = 'Désélectionner';
            this.strings.NonCreatures = 'Non-créature';
            this.strings['Mana curve'] = 'Courbe de mana';
            this.strings['Symbol breakdown'] = 'Répartition des symboles';
            this.strings['Creature breakdown'] = 'Répartition des créatures';
            this.strings['Type breakdown'] = 'Répartition des types';
            this.strings['Color breakdown'] = 'Répartition des couleurs';
            this.strings['Rarity breakdown'] = 'Répartition des raretés';
            this.strings.InCollectionDeck = 'Dans la collection';
            this.strings.MissingDeck = 'Manquantes';
            this.strings.Value = 'Valeur';
            this.strings.TotalCards = 'Nombre total de cartes';
            this.strings.StatisticsFor = 'Statistiques pour ';
            this.strings.ExportTo = 'Sauver vers';
            this.strings.Priorities = 'Extensions prioritaires';
            this.strings.Lock = 'Verrouiller';
            this.strings.Unlock = 'Déverrouiller';
            this.strings.DeckSuccessful = 'Deck mis à jour avec succès';
            this.strings.Cards_ = 'Cartes :';
            this.strings.Deck_ = 'Deck :';
            this.strings.Sideboard_ = 'Sideboard :';
            this.strings.Maybeboard__ = 'Maybeboard';
            this.strings.Maybeboard_ = 'Maybeboard :';
            this.strings.Deck_ = 'Deck';
            this.strings.Deck = 'deck';
            this.strings.Sideboard = 'Sideboard';
            this.strings.Maybeboard = 'maybeboard';
            this.strings.CopyOf = 'Copie de';
            this.strings.Clone = 'Clone';
            this.strings.Edit = 'Modifier';
            this.strings['Edit deck'] = 'Modifier deck';
            this.strings['Edit list'] = 'Modifier liste';
            this.strings.Group = 'Groupe :';
            this.strings['New deck'] = 'Nouveau deck';
            this.strings['New list'] = 'Nouvelle liste';
            this.strings.Delete = 'Supprimer';
            this.strings.Filters = 'Filtres :';
            this.strings.FilterByCard = 'Filtrer par carte :';
            this.strings.Filter = 'Filtre :';
            this.strings.deck = 'deck';
            this.strings.sideboard = 'sideboard';
            this.strings.Decks_ = 'Decks';
            this.strings.SupportTheProject = 'Soutenir UrzaGatherer';
            this.strings.StartingTurn = 'Démarrage du tour #';
            this.strings.EndOfTurn = 'Fin de tour';
            this.strings.NewTurn = 'Nouveau tour';
            this.strings.Player = 'Joueur #';
            this.strings['New player'] = 'Nouveau joueur';
            this.strings.hasenteredthegame = 'a rejoint la partie';
            this.strings.RollA6SidedDice = 'lancer un dé à 6 faces';
            this.strings.RollA20SidedDice = 'lancer un dé à 20 faces';
            this.strings.Heads = 'face';
            this.strings.Tails = 'pile';
            this.strings.FlipACoin = 'Lancer une pièce';
            this.strings['Are you sure you want to delete this counter?'] =
                'Êtes-vous sûr de vouloir supprimer ce compteur ?';
            this.strings.RemoveFromCollectionConfirm = 'Êtes-vous sûr de vouloir supprimer cette carte de votre collection ?';
            this.strings.RemoveFromCollectionConfirmPLural = 'Êtes-vous sûr de vouloir supprimer ces cartes de votre collection ?';
            this.strings.hasleftthegame = 'a quitter la partie';
            this.strings.received = 'a reçu';
            this.strings.won = 'a gagneé';
            this.strings.lost = 'a perdu';
            this.strings['Life:'] = 'Vie :';
            this.strings.Life = 'vie#';
            this.strings.Poison = 'poison#';
            this.strings.Logs = 'Journal';
            this.strings.LifeCounters = 'Compteurs de vie';
            this.strings.UseAdvancedTooltips = 'Utiliser des tooltips avancés';
            this.strings.NoData = 'Aucune donnée trouvée';
            this.strings.Cancel = 'Annuler';
            this.strings.PricesUpdateSuccessful = 'Mise à jour des prix réussie';
            this.strings.UpdateSuccessful = 'Mise à jour réussie';
            this.strings.Provider = 'Fournisseur :';
            this.strings.UpdatePricesDesc = 'Mise à jour des prix';
            this.strings.UpdatePrices = 'Mise à jour';
            this.strings.ID = 'ID';
            this.strings['Multiverse ID'] = 'Multiverse ID';
            this.strings['Foil count'] = 'Nombre de foils';
            this.strings['Promo count'] = 'Nombre de promos';
            this.strings.ExportToCSV = 'Exporter en csv';
            this.strings.SwitchOrderedFlag = 'Statut de la commande';
            this.strings.NormalMode = 'Mode normal';
            this.strings.CollectionMode = 'Mode collection';
            this.strings.Rulings = 'Décisions';
            this.strings.Promos = 'Promos';
            this.strings.Exclude = 'Exclure ';
            this.strings.ExcludePromosAndTokens = 'Exclure promos, tokens et emblèmes';
            this.strings.ExcludePromos = 'Exclure promos';
            this.strings.Foils = 'Foils';
            this.strings.Link = 'Lien';
            this.strings.FrenchDisplay = 'Afficher les textes en français si possible';
            this.strings.Comments = 'Ambiance';
            this.strings.Comments_ = 'Ambiance :';
            this.strings.Undefined = 'Indéfini';
            this.strings.Mint = 'Mint';
            this.strings['Near mint'] = 'Presque mint';
            this.strings.Good = 'Bon';
            this.strings.LightPlayed = 'Légèrement jouée';
            this.strings.Played = 'Jouée';
            this.strings.Poor = 'Mauvaise';
            this.strings.Condition = 'Etat';
            this.strings.Condition_ = 'Etat :';
            this.strings.AllConditions = 'Tous les états';
            this.strings['Page#'] = 'Page #';
            this.strings.AlbumViewFor = 'Vue album pour ';
            this.strings.AlbumView = 'Vue album';
            this.strings.More = 'Plus';
            this.strings.LoadConfirm =
                'Êtes-vous sûr de vouloir charger une nouvelle collection (votre collection actuelle sera réinitialisée) ? ';
            this.strings.DeckDeleteConfirm = 'Êtes-vous sûr de vouloir supprimer ce deck ?';
            this.strings.ListDeleteConfirm = 'Êtes-vous sûr de vouloir supprimer cette liste ?';
            this.strings.SavedSearchDeleteConfirm = 'Êtes-vous sûr de vouloir supprimer cette recherche sauvegardée ?';
            this.strings.SavedSearches = 'Recherches sauvegardées';
            this.strings['Search name'] = 'Nom de la recherche';
            this.strings.Save = 'Sauver';
            this.strings.NumberOfCopies = 'Nombre de copies';
            this.strings.NumberOfFoils = 'Nombre de foils';
            this.strings.NumberOfSpecialFoils = 'Nombre de foils spéciaux';
            this.strings.NumberOfGalaxyFoils = 'Nombre de foils galaxie';
            this.strings.NumberOfPromos = 'Nombre de promos';
            this.strings.Select = 'Sélectionner';
            this.strings.SelectAll = 'Sélectionner tout';
            this.strings.InCollection = 'cartes';
            this.strings.InCollectionUnique = 'cartes uniques';
            this.strings.MissingUnique = 'cartes uniques manquantes';
            this.strings.Missing = 'cartes manquantes';
            this.strings.InCollectionValue = 'valeur de la collection';
            this.strings.CollectionValue = 'Valeur dans la collection';
            this.strings.MissingValue = 'valeur manquante';
            this.strings.Total = 'Total';
            this.strings.Others = 'Autres';
            this.strings.Details = 'Détails';
            this.strings['Global status'] = 'Statut global';
            this.strings['Color status'] = 'Statut par couleur';
            this.strings['Rarity status'] = 'Statut par rareté';
            this.strings.Statistics = 'Statistiques';
            this.strings.AllExpansions = 'Toutes les extensions';
            this.strings.AllExpansionsFromCollection = 'Toutes les extensions de ma collection';
            this.strings.AllDecks = 'Tous les decks';
            this.strings.AllTags = 'Toutes les étiquettes';
            this.strings.NoTag = 'Aucune étiquette';
            this.strings.AllDecksFromCollection = 'Limiter aux cartes de ma collection';
            this.strings['In a deck or not'] = 'Dans un deck où non';
            this.strings['Not used in a deck'] = 'Non utilisé dans un deck';
            this.strings['All decks'] = 'Tous les decks';
            this.strings['All active decks'] = 'Tous les decks actifs';
            this.strings.UseEuroInsteadOfDollar = 'Utiliser des euros(€) plutôt que des dollars($)';
            this.strings.Decks = 'Decks :';
            this.strings.Decks_ = 'Decks';
            this.strings.Expansion_ = 'Extension';
            this.strings.Expansions = 'Extensions';
            this.strings.Expansions_ = 'Extensions :';
            this.strings.ValidFor = 'Valide pour';
            this.strings.Flavor_ = 'Contexte';
            this.strings.Text_ = 'Texte';
            this.strings.Type_ = 'Type';
            this.strings.Reprints = 'Réimpressions';
            this.strings.Reprints_ = 'Réimpr. :';
            this.strings.Misc_ = 'Divers :';
            this.strings.SellAll = 'Voir toutes';
            this.strings.Power_ = 'Puissance';
            this.strings.ConvertedManaCost = 'Coût en mana converti';
            this.strings.Manacost_ = 'Coût en mana';
            this.strings.ManaSymbols = 'Symboles :';
            this.strings.AnyManaSymbols = 'Symboles comme (X) où (W)';
            this.strings.Random = 'Aléatoire';
            this.strings.Color_ = 'Couleur';
            this.strings.Rarity_ = 'Rareté';
            this.strings.Author_ = 'Artiste';
            this.strings.Count = 'Nombre';
            this.strings.Foils = 'Foils';
            this.strings.Promos = 'Promos';
            this.strings.AveragePrice = 'Prix moyen';
            this.strings.AverageFoilPrice = "Prix moyen d'une foil";
            this.strings.Price = 'Prix:';
            this.strings.Price_ = 'Prix';
            this.strings.FoilPrice_ = 'Prix pour foil';
            this.strings['Number:'] = 'Numéro :';
            this.strings.Number = 'Numéro';
            this.strings.TrademarksText =
                "UrzaGatherer n'est pas affilié avec, sponsorisé, ou spécifiquement approuvé par Wizards of the Coast LLC. Magic the Gathering® est une marque déposée de Wizards of the Coast.";
            this.strings.Trademarks = 'Trademarks';
            this.strings.Rarity = 'Rareté :';
            this.strings.ManaSource = 'Source de mana :';
            this.strings.ColorIdentity = 'Identité de couleur (Commander):';
            this.strings.OnlyRegular = 'Uniquement non foil';
            this.strings.FoilNonFoil = 'Foil && non foil';
            this.strings.OnlyFoil = 'Uniquement foil';
            this.strings.CanBeSpecialFoil = 'Peut être foil spécial';
            this.strings.FoilSpecialFoil = 'Foil && foil gravé';
            this.strings.OnlyPromo = 'Cartes promos';
            this.strings.FoilAndPromo = 'Cartes foils et promos';
            this.strings.ReservedList = 'Liste réservée';
            this.strings.OnlyOrdered = 'Cartes commandées';
            this.strings.Collection = 'Collection :';
            this.strings.Reset = 'Réinitialiser';
            this.strings.State = 'Etat :';
            this.strings.MultiColor = 'Multi-couleurs :';
            this.strings.Color = 'Couleur :';
            this.strings['Please wait...'] = 'Veuillez patienter...';
            this.strings['Name...'] = 'Nom...';
            this.strings.AllFormats = 'Tous les formats';
            this.strings.Format = 'Format :';
            this.strings.Format_ = 'Format';
            this.strings.Set = 'Extension :';
            this.strings.Block = 'Bloc :';
            this.strings.Year = 'Année :';
            this.strings.Power = 'Force :';
            this.strings.Toughness = 'Défense :';
            this.strings.Toughness_ = 'Défense';
            this.strings.Manacost = 'Coût en mana :';
            this.strings.AnyWord = "N'importe quel mot";
            this.strings.AnyNumber = "N'importe quel nombre";
            this.strings.AnyWordRegex = 'Une expression régulière';
            this.strings.Author = 'Artiste :';
            this.strings.SearchResults = 'Résultats de la recherche';
            this.strings.Name = 'Nom :';
            this.strings.Name_ = 'Nom';
            this.strings.SuperType = 'Supertype :';
            this.strings.Type = 'Type:';
            this.strings.Text = 'Texte :';
            this.strings.Fulltext = 'Plein texte :';
            this.strings.Fulltext_ = 'Plein texte...';
            this.strings.Flavor = 'Contexte :';
            this.strings.ValuesWithComma = "Mots séparés par ','";
            this.strings.Search = 'Recherche avancée';
            this.strings.AllCardStates = 'Tous les états';
            this.strings.AllCards = 'Toutes les cartes';
            this.strings.KeepAllSuperTypes = 'Toutes les cartes';
            this.strings.OnlyMissing = 'Uniquement les manquantes';
            this.strings.OnlyMissingAndNotOrdered = 'Uniquement les manquantes non achetées';
            this.strings.OnlyInCollection = 'Uniquement dans la collection';
            this.strings.OnlyInCollectionFoil = 'Uniquement dans la collection (foil)';
            this.strings.OnlyInCollectionNonFoil = 'Uniquement dans la collection (non-foil)';
            this.strings.OnlyInCollectionSpecialFoil = 'Uniquement dans la collection (foil spécial)';
            this.strings.Commander = 'Commander';
            this.strings.Brawl = 'Brawl';
            this.strings.Legacy = 'Legacy';
            this.strings.Vintage = 'Vintage';
            this.strings.BlockConstructed = 'Bloc construit';
            this.strings.Standard = 'Standard';
            this.strings.Modern = 'Moderne';
            this.strings.Pauper = 'Pauper';
            this.strings.AllFormats = 'Tous les formats';
            this.strings.Lands = 'Terrains';
            this.strings.Creatures = 'Créatures';
            this.strings.Legendary = 'Légendaires';
            this.strings.Spells = 'Sorts';
            this.strings.Instants = 'Éphémères';
            this.strings.Enchantments = 'Enchantements';
            this.strings.Sorceries = 'Rituels';
            this.strings.Planeswalkers = 'Planeswalkers';
            this.strings.AllTypes = 'Tous les types';
            this.strings.AllRarities = 'Toutes les raretés';
            this.strings.Common = 'Commune';
            this.strings.Uncommon = 'Peu commune';
            this.strings.Rare = 'Rare';
            this.strings.Mythic = 'Mythique';
            this.strings.Special = 'Spéciale';
            this.strings.AllAuthors = 'Tous les artistes';
            this.strings.AllColors = 'Toutes les couleurs';
            this.strings.Colorless = 'Sans couleur';
            this.strings.Black = 'Noir';
            this.strings.Red = 'Rouge';
            this.strings.Blue = 'Bleu';
            this.strings.White = 'Blanc';
            this.strings.Green = 'Vert';
            this.strings.Artifact = 'Artefact';
            this.strings.Artifacts = 'Artefacts';
            this.strings.Land = 'Terrain';
            this.strings.Multicolors = 'Multi-couleurs';
            this.strings.Eldrazi = 'Eldrazi';
            this.strings.Eldrazis = 'Eldrazis';
            this.strings.Schemes = 'Projets';
            this.strings.Planes = 'Plans';
            this.strings.Scheme = 'Projet';
            this.strings.Plane = 'Plan';
            this.strings.Phenomenons = 'Phénomènes';
            this.strings.Phenomenon = 'Phénomène';
            this.strings.Conspiracies = 'Conspirations';
            this.strings.Conspiracy = 'Conspiration';
            this.strings.ByDateAsc = 'Par date (asc.)';
            this.strings.ByDateDesc = 'Par date (desc.)';
            this.strings.ByDate = 'Par date';
            this.strings.ByCount = 'Par nombre';
            this.strings.ByEdition = 'Par édition';
            this.strings.ByPriceAsc = 'Par prix (asc.)';
            this.strings.ByPriceDesc = 'Par prix (desc.)';
            this.strings.ByCollectionValueAsc = 'Par valeur de la collection (asc.)';
            this.strings.ByCollectionValueDesc = 'Par valeur de la collection (desc.)';
            this.strings.ByCollectionCountAsc = 'Par nombre dans la collection (asc.)';
            this.strings.ByCollectionCountDesc = 'Par nombre dans la collection (desc.)';
            this.strings.ByCollectionValue = 'Par valeur de la collection';
            this.strings.ByCollectionCount = 'Par nombre dans la collection';
            this.strings.ByRarity = 'Par rareté';
            this.strings.Custom = 'Personnalisé';
            this.strings.ByManaCostAsc = 'Par coût en mana (asc.)';
            this.strings.ByManaCostDesc = 'Par coût en mana (desc.)';
            this.strings.ByTypeAsc = 'Par type (asc.)';
            this.strings.ByTypeDesc = 'Par type (desc.)';
            this.strings.BySuperTypeAsc = 'Par super type (asc.)';
            this.strings.BySuperTypeDesc = 'Par super type (desc.)';
            this.strings.ByColorAsc = 'Par couleur (asc.)';
            this.strings.ByColorDesc = 'Par couleur (desc.)';
            this.strings.ByNumber = 'Par numéro';
            this.strings.ByName = 'Par nom';
            this.strings.ByColor = 'Par couleur';
            this.strings.ByType = 'Par type';
            this.strings.BySuperType = 'Par super type';
            this.strings.ByManaCost = 'Par coût en mana';
            this.strings.ByPrice = 'Par prix';
            this.strings.ByYear = 'Par année';
            this.strings.ByYearAsc = 'Par année (asc.)';
            this.strings.ByYearDesc = 'Par année (desc.)';
            this.strings.Asc = 'asc.';
            this.strings.Desc = 'desc.';
            this.strings['Downloads in progress'] = ' téléchargement# en cours';
            this.strings.ImageSize = 'Taille des images';
            this.strings.cards = 'carte#';
            this.strings.Display = 'Affichage';
            this.strings.Database = 'Base de données';
            this.strings.HighQualityPictures = 'Images haute qualité';
            this.strings.PNGPictures = 'Utiliser images au format PNG (très lourdes) si disponible (Patreon uniqument)';
            this.strings.UltraHighQualityPictures = 'Utiliser images trés haute qualité si disponible (Patreon uniqument)';
            this.strings.ReadOnlyMode = 'Mode lecture seule';
            this.strings.RefreshDesc = 'Actualiser la base de données depuis votre ${0}';
            this.strings.Refresh = 'Actualiser';
            this.strings.RefreshSuccessful = 'Actualisation réalisée avec succès';
            this.strings.UploadSuccessful = 'Envoi réalisé avec succès';
            this.strings.DownloadSuccessful = 'Récuperation réalisée avec succès';
            this.strings.CacheImages = 'Mettre les images en cache localement';
            this.strings.Export = 'Exporter';
            this.strings.Save = 'Sauver';
            this.strings.SaveDesc = 'Sauver votre collection vers un fichier';
            this.strings.SaveSuccessful = 'Sauvegarde réalisée avec succès';
            this.strings.ExportSuccessful = 'Exportation réalisée avec succès';
            this.strings.Import = 'Charger';
            this.strings.Load = 'Charger';
            this.strings.LoadDesc = 'Charger votre collection depuis un fichier';
            this.strings.LoadSuccessful = 'Chargement réalisé avec succès';
            this.strings.MergeSuccessful = 'Fusion réalisée avec succès';
            this.strings.DarkMode = 'Mode sombre';
            this.strings.AutoDarkMode = 'Détection du mode sombre automatique';
            this.strings.Blocks = 'Blocs';
            this.strings.AllBlocks = 'Tous les blocs';
            this.strings.About = 'A propos';
            this.strings.Settings = 'Réglages';
            this.strings.OK = 'OK';
            this.strings.Cancel = 'Annuler';
            this.strings.Yes = 'Oui';
            this.strings.No = 'Non';
            this.strings.Login = 'Se connecter';
            this.strings.List = 'Liste :';
            this.strings.OnlyOwned = 'Cartes possédées';
            this.strings.Cheapest = 'Moins chères';
            this.strings['Card name...'] = "Nom d'une carte...";
            this.strings.SyncNotAllowed =
                "Urzagatherer peut synchroniser votre collection sur plusieurs appareils à l'aide de fournisseurs de synchronisation. <br><br>Voulez-vous vous connecter?<br><br><i>Vous pouvez toujours le faire plus tard à partir de la page des paramètres</i>";
            this.strings.Expand = 'Agrandir';
            this.strings.CodeBy = 'Code et design par :';
            this.strings.LogoBy = 'Logo par :';
            this.strings.OSS = 'Bibliothèques open source :';
            this.strings.ThanksTo = 'Gold Patreons :';
            this.strings.AboutUrzaGatherer1 =
                'UrzaGatherer est un outil complet et pratique pour gérer votre collection de cartes Magic The Gathering où que vous soyez';
            this.strings.AboutUrzaGatherer2 =
                "Vous pouvez également l'utiliser comme compagnon idéal pour construire et évaluer vos decks ou pour suivre vos compteurs de vie.";
            this.strings.Whatsnew = 'Quoi de neuf ?';
            this.strings.WhatsnewIn = 'Quoi de neuf sur la version';
            this.strings.TokenDown =
                "L'autorisation de votre fournisseur de synchronisation a expiré. Veuillez cliquer ici pour la renouveller.";

            return;
        }
        this.strings.ZeusLegacyAdsImg = 'Cover-03.jpg';
        this.strings.TooManyPages = 'Too many pages to display';
        this.strings.Deal = 'The deal';
        this.strings.HideDealPage = 'Hide the Deal page';
        this.strings.Zoom = 'Zoom:';
        this.strings.MakeSureCamera = 'Make sure the card is in focus and occupies as much of the image as possible';
        this.strings.AddedBefore = 'Before:';
        this.strings.AddedAfter = 'After:';
        this.strings.Added = 'Added on';
        this.strings.Support = 'Support';
        this.strings.NewerVersionDetected = 'Your saved collection is from a newer version of UrzaGatherer. Please update UrzaGatherer to the latest version to use it. Database is in read-only mode to protect your data.';
        this.strings.HowListsWork = 'How lists work?';
        this.strings.WhyPricesAreIncorrect = 'Why prices are not correct on some cards?';
        this.strings.QuicklyAddingCardsToDeck = 'Quickly adding cards to a deck';
        this.strings.SettingCustomIcon = 'Setting custom icons';
        this.strings.HidingCards = 'Hiding cards';
        this.strings.Features = 'Features';
        this.strings.Troubleshooting = 'Troubleshooting';
        this.strings.VersionUpdate = 'Why can\'t I see the latest version?';
        this.strings.Help = 'Help';
        this.strings.ZodiacLegionAdsImg = 'cover.jpg';
        this.strings.ZodiacLegionAdsLink = 'https://www.amazon.com/dp/B0BWT3RS89';
        this.strings.MagicCompendiumAdsImg = 'Cover2.jpg';
        this.strings.MagicCompendiumAdsLink = 'https://www.amazon.com/Magic-Compendium-David-Catuhe/dp/B0CS4N1V7G';
        this.strings.TokenHelp = 'Please send a private message on Patreon to get your token';
        this.strings.NumberOfColumns = 'Number of columns for the grid view';
        this.strings.OnlyMissingExcludeReprints = 'Only missing (exclude reprints)';
        this.strings.Premodern = 'Premodern';
        this.strings.CardID = 'ID';
        this.strings.Characteristics = 'Specifics';
        this.strings.DeselectAll = 'Deselect all';
        this.strings.SelectAll = 'Select all';
        this.strings.Money = 'Money';
        this.strings.Alien = 'Alien';
        this.strings.Sun = 'Sun';
        this.strings.Star = 'Star';
        this.strings.Exclamation = 'Exclamation mark';
        this.strings.CustomIconSearch = 'Custom icon:';
        this.strings.CustomIcon = 'Set custom icon';
        this.strings.DBUpdateSuccessful = 'Please close and restart your app or browser to complete the update.';
        this.strings.HiddenCardList = 'Hidden card list';
        this.strings.HideCard = 'Hide this card';
        this.strings.HideCardConfirm = 'Are you sure you want to hide this card? The change will be visible after restarting/reloading the app.';
        this.strings.IsProxy = 'Proxy';
        this.strings.Proxies = 'Proxies:';
        this.strings.OnlyProxies = 'Only proxies (all)';
        this.strings.ExcludeProxies = 'Exclude proxies (all)';
        this.strings.OnlyDeckProxies = 'Only proxies (decks)';
        this.strings.ExcludeDeckProxies = 'Exclude proxies (decks)';
        this.strings.OnlyCardProxies = 'Only proxies (cards)';
        this.strings.ExcludeCardProxies = 'Exclude proxies (cards)';
        this.strings.FlagAsProxy = 'Flag as proxy';
        this.strings.UnflagAsProxy = 'Remove proxy status';
        this.strings.BlockProgressAsCards = 'Display block progress as number of missing cards';
        this.strings.DeckAsGroupList = 'Show the content of the decks in grouped mode';
        this.strings.CommandsOnHover = 'Display cards commands only on mouse hover';
        this.strings.DisplayActiveDeckIcon = 'Display an icon to send the card to the active deck (In collection mode)';
        this.strings.Target = 'Target';
        this.strings.ActiveDeck = 'Active deck';
        this.strings.NonLands = 'Non lands';
        this.strings.UniqueOnly = 'Only unique cards';
        this.strings.CardDisplay = 'Card display';
        this.strings.ImageQuality = 'Image quality';
        this.strings.HideZeroCardSet = 'Hide sets with no card in the collection';
        this.strings.ManageUsers = 'Manage';
        this.strings.ManageUsersDesc = 'Manage users';
        this.strings.Users = 'Users';
        this.strings.NewUser = 'New user';
        this.strings.CurrentUser = 'Current user';
        this.strings.QuantityOptions = 'Quantity options:';
        this.strings.DisplayCollectionCountInAlbumView = 'Display collection count in album view';
        this.strings.FoilInCollectionMode = 'Display buttons for foils when in collection mode';
        this.strings.DisplayReprintCount = 'Display number of reprints you own on each card';
        this.strings.DFC_ = 'DFC:';
        this.strings.Include = 'Include';
        this.strings.Only = 'Only';
        this.strings.WhenRemovingFromCollection = 'When removing from collection';
        this.strings.Confirmations = 'Confirmations';
        this.strings.TransparentTags = 'Use semi transparent icons on cards';
        this.strings.LightPlayed = 'Light played';
        this.strings.Excellent = 'Excellent';
        this.strings.Spotlight = 'Story spotlight card';
        this.strings.IgnoreArtCards = 'Ignore art series cards';
        this.strings.IgnoreSubstituteCards = 'Ignore substitute cards';
        this.strings.IgnoreMinigames = 'Ignore minigame cards';
        this.strings.IgnoreSerialized = 'Ignore serialized cards';
        this.strings.IgnoreOversizedCards = 'Ignore oversized cards';
        this.strings.DefaultLanguage = 'Default card language';
        this.strings.DefaultCondition = 'Default card condition';
        this.strings.DefaultGrading = 'Default card grading';
        this.strings.English = 'English';
        this.strings.French = 'French';
        this.strings.Italian = 'Italian';
        this.strings.German = 'German';
        this.strings.Spanish = 'Spanish';
        this.strings.Japanese = 'Japanese';
        this.strings.ChineseSimp = 'Chinese simplified';
        this.strings.ChineseTrad = 'Chinese traditional';
        this.strings.Korean = 'Korean';
        this.strings.Portuguese = 'Portuguese';
        this.strings.Russian = 'Russian';
        this.strings['Card#'] = 'Card #';
        this.strings.NeedToAddCardToCollectionFirst = 'You must first add the card to your collection.';
        this.strings.UseFoilPriceWhenOwned = 'Use foil price for sorting when you own a foil card';
        this.strings.EditBlock = 'Edit block';
        this.strings.NewBlock = 'New block';
        this.strings.DeepLink = 'Deep link';
        this.strings.NonCreatureArtifacts = 'Non creature artifacts';
        this.strings.NonArtifactCreatures = 'Non artifact creatures';
        this.strings.DownloadMidImages = 'Download';
        this.strings.DownloadMidImagesDesc = 'Locally download all standard quality images (~10GB)';
        this.strings.DownloadMidImagesFromCollection = 'Download';
        this.strings.DownloadMidImagesFromCollectionDesc =
            'Locally download standard quality images from your collection';
        this.strings.Turn = 'Turn: ';
        this.strings.OpenTab = 'Open in a new tab';
        this.strings.ShoppingAssistant = 'Shopping Assistant';
        this.strings.DebugInfo = 'Debug info';
        this.strings.NoCardWithFlavorFound = 'No card with flavor text found.';
        this.strings.ScaleDown = 'Initial size';
        this.strings.Card = 'Card';
        this.strings.Contain = 'Contain';
        this.strings.Cover = 'Cover';
        this.strings.LoreExplorer = 'Lore Explorer';
        this.strings.DisplayLargeMissingCollectionIndicator = 'Apply the indicator to missing cards instead';
        this.strings.DisplayLargeCollectionIndicator = 'Use an indicator on the entire image for collected cards';
        this.strings.TokenNotValid = 'Invalid token. Please connect to your patreon account and ask for your token';
        this.strings.Token = 'Token';
        this.strings.SetAsBackgroundImage = 'Set as background image';
        this.strings.BackgroundImage = 'Set';
        this.strings.BackgroundImageDesc = 'Set background image';
        this.strings.Dungeons = 'Dungeons';
        this.strings.DatabaseID = 'Internal ID';
        this.strings.Deactivate = 'Deactivate';
        this.strings.Activate = 'Activate';
        this.strings['Share'] = 'Share';
        this.strings['MoxfieldFile'] = 'Moxfield files';
        this.strings['MtgaFile'] = 'Magic Arena files';
        this.strings['TxtFile'] = 'Text files';
        this.strings['CsvFile'] = 'CSV files';
        this.strings['UgsFile'] = 'UzaGatherer data files';
        this.strings['CustomDatabaseDesc'] = 'Customize database';
        this.strings['UpdateBlockDatabase'] = 'Customize';
        this.strings['UpdateBlockDatabaseDesc'] = 'Customize active blocks';
        this.strings['UpdateSetDatabase'] = 'Customize';
        this.strings['UpdateSetDatabaseDesc'] = 'Customize active sets';
        this.strings['UpdateHiddenCardList'] = 'Manage';
        this.strings['UpdateHiddenCardListDesc'] = 'Manage hidden card list';
        this.strings['Grading'] = 'Grading';
        this.strings['Grading_'] = 'Grading:';
        this.strings['AllGradings'] = 'All gradings';
        this.strings['Commander Duel'] = 'Duel Commander';
        this.strings['IgnoreCommonCards'] = 'Ignore common cards';
        this.strings['IgnoreUncommonCards'] = 'Ignore uncommon cards';
        this.strings['IgnoreRareCards'] = 'Ignore rare cards';
        this.strings['IgnoreMythicCards'] = 'Ignore mythic cards';
        this.strings.UseCommanderAsBackground = 'Commander Image';
        this.strings.Draft = 'Draft';
        this.strings.Permanents = 'Permanents';
        this.strings.ShowExtrasAsSeparate = 'Separate extras from main set';
        this.strings.Lists = 'Lists';
        this.strings.Add = 'Add';
        this.strings.NewTag = 'New tag';
        this.strings.Tags = 'Tags';
        this.strings.Tags_ = 'Tags:';
        this.strings.TotalCMC = 'Total mana cost';
        this.strings.AvgCMC = 'Average mana cost';
        this.strings.GetFromCloud = 'Download';
        this.strings.GetFromCloudDesc = 'Get from Cloud';
        this.strings.SendToCloud = 'Upload';
        this.strings.SendToCloudDesc = 'Send to Cloud';
        this.strings.AllSetCode = 'All codes';
        this.strings.SetCode = 'Set code:';
        this.strings.DeckInvalid = 'This card is invalid with this deck.';
        this.strings.SetToTrade = 'Set quantity to trade';
        this.strings.ToTrade = 'To trade';
        this.strings.ToTrade_ = 'To trade:';
        this.strings.SetWanted = 'Set wanted quantity';
        this.strings.Wanted = 'Wanted';
        this.strings.Wanted_ = 'Wanted:';
        this.strings.PatreonAds =
            'Seems like you like UrzaGatherer :) You may want to support it by going to Patreon.com/urzagatherer';
        this.strings.CardScannerLang = 'Language:';
        this.strings.Misc = 'Misc.';
        this.strings.Menu = 'Menu';
        this.strings.Localization = 'Localization';
        this.strings.UniqueNames = 'Only unique names';
        this.strings.IgnoreForeignSets = 'Ignore foreign sets';
        this.strings.ChangeSortDirection = 'Change sort direction';
        this.strings.NeedToConfigureCustomSort =
            'Custom sort not defined. Please go to the settings page to configure it';
        this.strings.UpdateCustomSort = 'Configure';
        this.strings.UpdateCustomSortDesc = 'Configure custom sort';
        this.strings.UpdateCustomChecklist = 'Configure';
        this.strings.UpdateCustomChecklistDesc = 'Configure custom checklist';
        this.strings.CustomFilters = 'Custom sort';
        this.strings.CustomFiltersDesc = 'Cards will be sorted using the following sort priority:';
        this.strings.CustomChecklistDesc = 'These sets will be used for the checklist:';
        this.strings.CustomChecklist = 'Custom checklist';
        this.strings.SyncSettings = 'Settings synchronization';
        this.strings.DisplayLinkedSet = 'Display linked sets on block page';
        this.strings.Pioneer = 'Pioneer';
        this.strings.Notes = 'Notes';
        this.strings.HideReprints = 'Hide reprints';
        this.strings.HideSplitCard = 'Hide linked cards (Split)';
        this.strings.HideTransformCard = 'Hide linked cards (Transform)';
        this.strings.InstallationPrompt = 'Do you want to install UrzaGatherer as an application?';
        this.strings.Language = 'Language';
        this.strings.Language_ = 'Language:';
        this.strings.AllLanguages = 'All languages';
        this.strings.MergeReprints = 'Only take one card per name and artist:';
        this.strings.MergeReprintsForQuantity = 'Consider reprints as the same card for quantity search:';
        this.strings.Options = 'Options:';
        this.strings.SetAsCommander = 'Set as commander';
        this.strings.RemoveCommander = 'Remove commander';
        this.strings.Exclude_ = 'Exclude:';
        this.strings.None = 'None';
        this.strings.NoneDeck = 'None';
        this.strings.Nobackground = 'No background';
        this.strings.PickCardImage = 'Pick a card';
        this.strings.PickCardIcon = 'Pick an icon';
        this.strings.ChangeBackground = 'Background';
        this.strings.DeckGroupName = 'Name of the group';
        this.strings.ReadOnlyModeEngaged = 'Read only mode: Changes will not be saved';
        this.strings.Commanders = 'Commanders';
        this.strings.ByDateAsc = 'By date (asc.)';
        this.strings.ByDateDesc = 'By date (desc.)';
        this.strings.ByDate = 'By date';
        this.strings.ByCollectionValueAsc = 'By collection value (asc.)';
        this.strings.ByCollectionValueDesc = 'By collection value (desc.)';
        this.strings.ByCollectionCountAsc = 'By collection count (asc.)';
        this.strings.ByCollectionCountDesc = 'By collection count (desc.)';
        this.strings.ByCollectionValue = 'By collection value ';
        this.strings.ByCollectionCount = 'By collection count';
        this.strings.ByRarity = 'By rarity';
        this.strings.Custom = 'Custom';
        this.strings.CroppedCard = 'View drawing only';
        this.strings.NormalCard = 'View full card';
        this.strings.SelectSets = 'Set to use';
        this.strings.SwapReprints = 'Swap with a reprint';
        this.strings.Emblems = 'Emblems';
        this.strings.Tokens = 'Tokens';
        this.strings.SynchronizationState = 'Synchronization state';
        this.strings.Synchronized = 'Synchronized';
        this.strings['Synchronizing...'] = 'Synchronizing...';
        this.strings.FoilState = 'Foil state';
        this.strings.Foil = 'Foil';
        this.strings.SpecialFoil = 'Special foil';
        this.strings.SpecialFoils = 'Special foils';
        this.strings.OnlySpecialFoil = 'Only special foil';
        this.strings.NonFoil = 'Non foil';
        this.strings.Shuffle = 'Shuffle library';
        this.strings.OnlyTokens = 'Only tokens and emblems';
        this.strings.OnlyPromos = 'Only promos';
        this.strings.OnlyRecents = 'Only recent reprints';
        this.strings.HideCompleted = 'Hide completed blocks and sets';
        this.strings.OpenBlock = 'Open block';
        this.strings.Download = 'Download';
        this.strings.Merge = 'Merge';
        this.strings.MergeDesc = 'Merge from a file';
        this.strings.Thanks = 'Thanks';
        this.strings.Quantity = 'Quantity:';
        this.strings.NotFound = 'Not found';
        this.strings.CardsNotFound = 'Cards not found';
        this.strings['search...'] = 'Search(F3)...';
        this.strings['filter...'] = 'Filter...(list is limited to 50 results)';
        this.strings['filter:'] = 'Filter...';
        this.strings.CardScans = 'Card scans';
        this.strings.Emblem = 'Emblem';
        this.strings.DataLoadedFromSync = 'Data successfully loaded';
        this.strings.LoadServerData = 'More recent data was found on your ${0}. Do you want to load it?';
        this.strings.UnknownError = 'Unknown error';
        this.strings.QuickRemoveFromDeck = 'Remove from this deck';
        this.strings.IssueWith = 'Issue with';
        this.strings.ReportIssue = 'Report an issue';
        this.strings.FoilIndicator = 'Display an icon to indicate that a card you own is foil';
        this.strings.PickColor = 'Pick a color';
        this.strings.OnlyForPatreon = 'This feature is only available for Patreons on www.patreon.com/urzagatherer';
        this.strings.AccentColorDesc = 'Change accent color';
        this.strings.AccentColor = 'Change';
        this.strings.DisplayPrice = 'Display price on each card';
        this.strings.AddToCollection = 'Add to collection';
        this.strings.RemoveFromCollection = 'Remove from collection';
        this.strings.KeepLocalFile = 'Do you want to keep your local data ?';
        this.strings.DeckIndicator = 'Indicate how many times a card is used in a deck';
        this.strings.Ordered = 'Ordered';
        this.strings.CleanCacheSuccessful = 'Cache cleared successfully';
        this.strings.DBUpdateFailed = 'Unable to update application';
        this.strings.CleanCacheDesc = 'Clear local cache';
        this.strings.CleanCache = 'Clear';
        this.strings.UpdateDBDesc = 'Update application';
        this.strings.UpdateDB = 'Update';
        this.strings.DataGrid = 'Datagrid mode';
        this.strings.GridView = 'Grid view';
        this.strings.ListView = 'List view';
        this.strings.DataGridInsteadOfList = 'For search results and sets';
        this.strings.DataGridInsteadOfListForCollection = 'For your collection';
        this.strings.DataGridInsteadOfListForDecks = 'For the decks';
        this.strings.UrzaGathererFiles = 'UrzaGatherer files';
        this.strings.Playset = 'Playset (4 cards)';
        this.strings.Mode = 'Mode:';
        this.strings.Show = 'Show:';
        this.strings.All = 'All';
        this.strings.OnlyComplete = 'Only complete';
        this.strings.Code = 'Code';
        this.strings.Year_ = 'Year';
        this.strings['Missing (value)'] = 'Missing (value)';
        this.strings['In collection (value)'] = 'In collection (value)';
        this.strings['Total value'] = 'Total value';
        this.strings['Completion %'] = 'Completion';
        this.strings.WorkOffline = 'No thanks';
        this.strings.SyncProvider = 'Synchronization provider';
        this.strings.ConnectDropboxSync = 'Dropbox';
        this.strings.ConnectDropboxSyncDesc = 'Connect to Dropbox';
        this.strings.ConnectUrzaDirectSync = 'UrzaDirect';
        this.strings.ConnectUrzaDirectSyncDesc = 'Connect to UrzaDirect';
        this.strings.ConnectOneDriveSync = 'OneDrive';
        this.strings.ConnectOneDriveSyncDesc = 'Connect to OneDrive';
        this.strings.DisconnectSync = 'Disconnect';
        this.strings.DisconnectSyncDesc = 'Disconnect from ${0}';
        this.strings.Checklist = 'Checklist';
        this.strings.DisconnectDesc = 'Your Patreon benefits are active';
        this.strings.Disconnect = 'Disconnect';
        this.strings.PatreonTopic = 'If you want to help UrzaGatherer and show your support, you can go to:';
        this.strings.PatreonDesc = 'Do you want to go to Patreon.com to support UrzaGatherer?';
        this.strings.ThanksPatreon = 'Thanks a ton for supporting UrzaGatherer!';
        this.strings.PatreonLogin = 'Login';
        this.strings.PatreonLoginDesc = 'Connect to your Patreon account to enable benefits';
        this.strings.DisplayReservedList = 'Display an icon to indicate cards from reserved list';
        this.strings.Regex = 'Regexp:';
        this.strings.Scanning = 'Scanning in progress...';
        this.strings.NoCardFound = 'No card found. Please try again';
        this.strings.ErrorCamera = 'There was an error with accessing the camera stream';
        this.strings.NoCamera = 'Camera not available';
        this.strings.Camera = 'Camera:';
        this.strings.Scan = 'Scan';
        this.strings.CardScanner = 'Card scanner';
        this.strings.Draw = 'Draw a card';
        this.strings.MoveTo = 'Move to';
        this.strings.Move = 'Move';
        this.strings.TopLibrary = 'Top of library';
        this.strings.BottomLibrary = 'Bottom of library';
        this.strings.ShuffleLibrary = 'Shuffle in library';
        this.strings.Hand = 'Hand';
        this.strings.Library = 'Library';
        this.strings.Battlefield = 'Battlefield';
        this.strings.Graveyard = 'Graveyard';
        this.strings.Exile = 'Exile';
        this.strings.NoDeckFound = 'You must first create a deck on the decks page';
        this.strings.Simulator = 'Hand simulator';
        this.strings.Unselect = 'Unselect';
        this.strings.NonCreatures = 'Non-creatures';
        this.strings['Mana curve'] = 'Mana curve';
        this.strings['Symbol breakdown'] = 'Symbol breakdown';
        this.strings['Creature breakdown'] = 'Creature breakdown';
        this.strings['Type breakdown'] = 'Type breakdown';
        this.strings['Color breakdown'] = 'Color breakdown';
        this.strings['Rarity breakdown'] = 'Rarity breakdown';
        this.strings.InCollectionDeck = 'In collection';
        this.strings.MissingDeck = 'Missing';
        this.strings.Value = 'Value';
        this.strings.TotalCards = 'Total cards';
        this.strings.StatisticsFor = 'Statistics for ';
        this.strings.ExportTo = 'Save to';
        this.strings.Priorities = 'Priority sets';
        this.strings.Lock = 'Lock';
        this.strings.Unlock = 'Unlock';
        this.strings.DeckSuccessful = 'Deck updated successfully';
        this.strings.Cards_ = 'Cards:';
        this.strings.Deck_ = 'Deck:';
        this.strings.Sideboard_ = 'Sideboard:';
        this.strings.Maybeboard__ = 'Maybeboard';
        this.strings.Maybeboard_ = 'Maybeboard:';
        this.strings.Deck_ = 'Deck';
        this.strings.Deck = 'deck';
        this.strings.Sideboard = 'Sideboard';
        this.strings.Maybeboard = 'maybeboard';
        this.strings.CopyOf = 'Copy of ';
        this.strings.Clone = 'Clone';
        this.strings.Edit = 'Edit';
        this.strings.Group = 'Group:';
        this.strings['Edit deck'] = 'Edit deck';
        this.strings['Edit list'] = 'Edit list';
        this.strings['New deck'] = 'New deck';
        this.strings['New list'] = 'New list';
        this.strings.Delete = 'Delete';
        this.strings.Filters = 'Filters:';
        this.strings.Filter = 'Filter:';
        this.strings.deck = 'deck';
        this.strings.sideboard = 'sideboard';
        this.strings.Decks_ = 'Decks';
        this.strings.SupportTheProject = 'Support UrzaGatherer';
        this.strings.StartingTurn = 'Starting turn #';
        this.strings.EndOfTurn = 'End of turn';
        this.strings.NewTurn = 'New turn';
        this.strings.Player = 'Player #';
        this.strings['New player'] = 'New player';
        this.strings.hasenteredthegame = 'has entered the game';
        this.strings.RollA6SidedDice = 'roll a 6 sided dice';
        this.strings.RollA20SidedDice = 'roll a 20 sided dice';
        this.strings.Heads = 'heads';
        this.strings.Tails = 'tails';
        this.strings.FlipACoin = 'Flip a coin';
        this.strings['Are you sure you want to delete this counter?'] = 'Are you sure you want to delete this counter?';
        this.strings.hasleftthegame = 'has left the game';
        this.strings.received = 'received';
        this.strings.won = 'won';
        this.strings.lost = 'lost';
        this.strings['Life:'] = 'Life:';
        this.strings.Life = 'life@lives';
        this.strings.Poison = 'poison#';
        this.strings.Logs = 'Logs';
        this.strings.LifeCounters = 'Life counters';
        this.strings.UseAdvancedTooltips = 'Use advanced tooltips';
        this.strings.NoData = 'No data found';
        this.strings.Cancel = 'Cancel';
        this.strings.PricesUpdateSuccessful = 'Price update successful';
        this.strings.UpdateSuccessful = 'Update successful';
        this.strings.Provider = 'Provider:';
        this.strings.UpdatePrices = 'Update';
        this.strings.UpdatePricesDesc = 'Update prices';
        this.strings.ID = 'ID';
        this.strings['Multiverse ID'] = 'Multiverse ID';
        this.strings['Foil count'] = 'Foil count';
        this.strings['Promo count'] = 'Promo count';
        this.strings.ExportToCSV = 'Export to csv';
        this.strings.OnlyOrdered = 'Only ordered';
        this.strings.SwitchOrderedFlag = 'Switch order status';
        this.strings.NormalMode = 'Normal mode';
        this.strings.CollectionMode = 'Collection mode';
        this.strings.Rulings = 'Rulings';
        this.strings.Promos = 'Promos';
        this.strings.Exclude = 'Exclude ';
        this.strings.ExcludePromosAndTokens = 'Exclude promos, tokens and emblems';
        this.strings.ExcludePromos = 'Exclude promos';
        this.strings.Foils = 'Foils';
        this.strings.Link = 'Link';
        this.strings.FrenchDisplay = 'Display texts in french when possible';
        this.strings.Comments = 'Comments';
        this.strings.Comments_ = 'Comments:';
        this.strings.Undefined = 'Undefined';
        this.strings.Mint = 'Mint';
        this.strings['Near mint'] = 'Near mint';
        this.strings.Good = 'Good';
        this.strings.Played = 'Played';
        this.strings.Poor = 'Poor';
        this.strings.Condition = 'Condition';
        this.strings.Condition_ = 'Condition:';
        this.strings.AllConditions = 'All conditions';
        this.strings['Page#'] = 'Page #';
        this.strings.AlbumViewFor = 'Album view for ';
        this.strings.AlbumView = 'Album view';
        this.strings.More = 'More';
        this.strings.LoadConfirm =
            'Are you sure you want to load a new collection (you current collection will be reset) ?';
        this.strings.DeckDeleteConfirm = 'Are you sure you want to delete this deck?';
        this.strings.ListDeleteConfirm = 'Are you sure you want to delete this list?';
        this.strings.RemoveFromCollectionConfirm = 'Are you sure you want to remove this card from your collection?';
        this.strings.RemoveFromCollectionConfirmPlural = 'Are you sure you want to remove these cards from your collection?';
        this.strings.SavedSearchDeleteConfirm = 'Are you sure you want to delete this saved search?';
        this.strings.SavedSearches = 'Saved searches';
        this.strings['Search name'] = 'Search name';
        this.strings.Save = 'Save';
        this.strings.NumberOfCopies = 'Number of copies';
        this.strings.NumberOfFoils = 'Number of foils';
        this.strings.NumberOfSpecialFoils = 'Number of special foils';
        this.strings.NumberOfPromos = 'Number of promos';
        this.strings.Select = 'Select';
        this.strings.SelectAll = 'Select all';
        this.strings.InCollection = 'cards';
        this.strings.InCollectionUnique = 'unique cards';
        this.strings.Missing = 'missing cards';
        this.strings.MissingUnique = 'missing unique cards';
        this.strings.InCollectionValue = 'collection value';
        this.strings.CollectionValue = 'Collection value';
        this.strings.MissingValue = 'missing value';
        this.strings.Total = 'Total';
        this.strings.Others = 'Others';
        this.strings.Details = 'Details';
        this.strings['Global status'] = 'Global status';
        this.strings['Color status'] = 'Color status';
        this.strings['Rarity status'] = 'Rarity status';
        this.strings.Statistics = 'Statistics';
        this.strings.AllExpansions = 'All sets';
        this.strings.AllExpansionsFromCollection = 'All sets from my collection';
        this.strings.AllDecks = 'All decks';
        this.strings.AllTags = 'All tags';
        this.strings.NoTag = 'No tag';
        this.strings.AllDecksFromCollection = 'Limit to cards from my collection';
        this.strings.Expansions = 'Sets';
        this.strings.Expansions_ = 'Sets:';
        this.strings.Decks = 'Decks:';
        this.strings['In a deck or not'] = 'In a deck or not';
        this.strings['Not used in a deck'] = 'Not used in a deck';
        this.strings['All decks'] = 'All decks';
        this.strings['All active decks'] = 'All active decks';
        this.strings.UseEuroInsteadOfDollar = 'Use euros(€) instead of dollars($)';
        this.strings.Decks_ = 'Decks';
        this.strings.Expansion_ = 'Set';
        this.strings.ValidFor = 'Valid for';
        this.strings.Flavor_ = 'Flavor';
        this.strings.Text_ = 'Text';
        this.strings.Type_ = 'Type';
        this.strings.Reprints = 'Versions';
        this.strings.Reprints_ = 'Versions: ';
        this.strings.Misc_ = 'Misc: ';
        this.strings.SellAll = 'See all';
        this.strings.Manacost_ = 'Mana cost';
        this.strings.Power_ = 'Power';
        this.strings.ConvertedManaCost = 'Converted mana cost';
        this.strings.Random = 'Random';
        this.strings.AveragePrice = 'Average price';
        this.strings.AverageFoilPrice = 'Average foil price';
        this.strings.Count = 'Count';
        this.strings.Foils = 'Foils';
        this.strings.Promos = 'Promos';
        this.strings['Number:'] = 'Number:';
        this.strings.Number = 'Number';
        this.strings.TrademarksText =
            'UrzaGatherer is not affiliated with, endorsed, sponsored, or specifically approved by Wizards of the Coast LLC. MAGIC: THE GATHERING® is a trademark of Wizards of the Coast.';
        this.strings.Trademarks = 'Trademarks';
        this.strings.Rarity = 'Rarity:';
        this.strings.ManaSource = 'Mana source:';
        this.strings.ColorIdentity = 'Color identity (Commander):';
        this.strings.MultiColor = 'Multicolors:';
        this.strings.OnlyRegular = 'Only non foil';
        this.strings.FoilNonFoil = 'Foil && non foil';
        this.strings.OnlyFoil = 'Only foil';
        this.strings.CanBeSpecialFoil = 'Can be special foil';
        this.strings.FoilSpecialFoil = 'Foil && special foil';
        this.strings.OnlyPromo = 'Promo cards';
        this.strings.OnlyStorySpotlight = 'Story spotlights';
        this.strings.FoilAndPromo = 'Foil and promo cards';
        this.strings.ReservedList = 'Reserved list';
        this.strings.Date = 'Release date';
        this.strings.List = 'List:';
        this.strings.Reset = 'Reset';
        this.strings.State = 'State:';
        this.strings.Color = 'Color:';
        this.strings.Color_ = 'Color';
        this.strings.Rarity_ = 'Rarity';
        this.strings.Author_ = 'Artist';
        this.strings.Collection = 'Collection:';
        this.strings.Collection_ = 'Collection';
        this.strings['Please wait...'] = 'Please wait...';
        this.strings['Name...'] = 'Name...';
        this.strings.AllFormats = 'All formats';
        this.strings.Format = 'Format:';
        this.strings.Format_ = 'Format';
        this.strings.Set = 'Set:';
        this.strings.Block = 'Block:';
        this.strings.Year = 'Year:';
        this.strings.Power = 'Power:';
        this.strings.Toughness = 'Toughness:';
        this.strings.Toughness_ = 'Toughness';
        this.strings.Manacost = 'Mana cost:';
        this.strings.ManaSymbols = 'Symbols:';
        this.strings.AnyManaSymbols = 'Symbols like (X) or (W)';
        this.strings.AnyWord = 'Any word';
        this.strings.AnyNumber = 'Any number';
        this.strings.AnyWordRegex = 'A regular expression';
        this.strings.Author = 'Artist:';
        this.strings.Price = 'Price:';
        this.strings.Price_ = 'Price';
        this.strings.FoilPrice_ = 'Foil price';
        this.strings.SearchResults = 'Search results';
        this.strings.Name = 'Name:';
        this.strings.Name_ = 'Name';
        this.strings.SuperType = 'Super type:';
        this.strings.Type = 'Type:';
        this.strings.Text = 'Text:';
        this.strings.Fulltext = 'Full text:';
        this.strings.Fulltext_ = 'Full text...';
        this.strings.Flavor = 'Flavor:';
        this.strings.ValuesWithComma = "Words separated by ','";
        this.strings.Search = 'Advanced search';
        this.strings.AllCardStates = 'All states';
        this.strings.AllCards = 'All cards';
        this.strings.OnlyMissing = 'Only missing';
        this.strings.OnlyMissingAndNotOrdered = 'Only missing and not ordered';
        this.strings.OnlyInCollection = 'Only in collection';
        this.strings.OnlyInCollectionFoil = 'Only in collection (foil)';
        this.strings.OnlyInCollectionNonFoil = 'Only in collection (non-foil)';
        this.strings.OnlyInCollectionSpecialFoil = 'Only in collection (special foil)';
        this.strings.Commander = 'Commander';
        this.strings.Brawl = 'Brawl';
        this.strings.Legacy = 'Legacy';
        this.strings.Vintage = 'Vintage';
        this.strings.BlockConstructed = 'Block constructed';
        this.strings.Standard = 'Standard';
        this.strings.Modern = 'Modern';
        this.strings.Pauper = 'Pauper';
        this.strings.AllFormats = 'All formats';
        this.strings.Lands = 'Lands';
        this.strings.Creatures = 'Creatures';
        this.strings.Legendary = 'Legendaries';
        this.strings.Spells = 'Spells';
        this.strings.Instants = 'Instants';
        this.strings.Enchantments = 'Enchantments';
        this.strings.Sorceries = 'Sorceries';
        this.strings.Planeswalkers = 'Planeswalkers';
        this.strings.AllTypes = 'All types';
        this.strings.AllRarities = 'All rarities';
        this.strings.Common = 'Common';
        this.strings.Uncommon = 'Uncommon';
        this.strings.Rare = 'Rare';
        this.strings.Mythic = 'Mythic';
        this.strings.Special = 'Special';
        this.strings.AllAuthors = 'All artists';
        this.strings.AllColors = 'All colors';
        this.strings.Colorless = 'Colorless';
        this.strings.Black = 'Black';
        this.strings.Red = 'Red';
        this.strings.Blue = 'Blue';
        this.strings.White = 'White';
        this.strings.Green = 'Green';
        this.strings.Artifact = 'Artifact';
        this.strings.Artifacts = 'Artifacts';
        this.strings.Land = 'Land';
        this.strings.Multicolors = 'Multicolors';
        this.strings.Eldrazi = 'Eldrazi';
        this.strings.Eldrazis = 'Eldrazis';
        this.strings.Schemes = 'Schemes';
        this.strings.Planes = 'Planes';
        this.strings.Scheme = 'Scheme';
        this.strings.Plane = 'Plane';
        this.strings.Phenomenons = 'Phenomenons';
        this.strings.Phenomenon = 'Phenomenon';
        this.strings.Conspiracies = 'Conspiracies';
        this.strings.Conspiracy = 'Conspiracy';
        this.strings.ByCount = 'By count';
        this.strings.ByEdition = 'By edition';
        this.strings.ByPriceAsc = 'By price (asc.)';
        this.strings.ByPriceDesc = 'By price (desc.)';
        this.strings.ByManaCostAsc = 'By mana cost (asc.)';
        this.strings.ByManaCostDesc = 'By mana cost (desc.)';
        this.strings.ByTypeAsc = 'By type (asc.)';
        this.strings.ByTypeDesc = 'By type (desc.)';
        this.strings.BySuperTypeAsc = 'By super type (asc.)';
        this.strings.BySuperTypeDesc = 'By super type (desc.)';
        this.strings.ByColorAsc = 'By color (asc.)';
        this.strings.ByColorDesc = 'By color (desc.)';
        this.strings.ByNumber = 'By number';
        this.strings.ByName = 'By name';
        this.strings.ByColor = 'By color';
        this.strings.ByYear = 'By year';
        this.strings.ByType = 'By type';
        this.strings.ByPrice = 'By price';
        this.strings.ByManaCost = 'By mana cost';
        this.strings.BySuperType = 'By super type';
        this.strings.ByYearAsc = 'By year (asc.)';
        this.strings.ByYearDesc = 'By year (desc.)';
        this.strings.Asc = 'asc.';
        this.strings.Desc = 'desc.';
        this.strings['Downloads in progress'] = ' download# in progress';
        this.strings.HighQualityPictures = 'High quality images';
        this.strings.PNGPictures = 'Use PNG images (really large) when available (Patreon only)';
        this.strings.UltraHighQualityPictures = 'Use ultra high quality images when available (Patreon only)';
        this.strings.ReadOnlyMode = 'Read only mode';
        this.strings.ImageSize = 'Image size';
        this.strings.cards = 'card#';
        this.strings.Display = 'Display';
        this.strings.Database = 'Database';
        this.strings.RefreshDesc = 'Refresh database from your ${0}';
        this.strings.Refresh = 'Refresh';
        this.strings.RefreshSuccessful = 'Refresh successful';
        this.strings.UploadSuccessful = 'Upload successful';
        this.strings.DownloadSuccessful = 'Download successful';
        this.strings.DarkMode = 'Dark mode';
        this.strings.AutoDarkMode = 'Automatic dark mode detection';
        this.strings.Export = 'Export';
        this.strings.Save = 'Save';
        this.strings.SaveDesc = 'Save your collection to a file';
        this.strings.SaveSuccessful = 'Save successful';
        this.strings.ExportSuccessful = 'Export successful';
        this.strings.Import = 'Import';
        this.strings.Load = 'Load';
        this.strings.FilterByCard = 'Filter by card:';
        this.strings.LoadDesc = 'Load your collection from a file';
        this.strings.LoadSuccessful = 'Load successful';
        this.strings.MergeSuccessful = 'Merge successful';
        this.strings.CacheImages = 'Cache images locally';
        this.strings.Blocks = 'Blocks';
        this.strings.AllBlocks = 'All blocks';
        this.strings.About = 'About';
        this.strings.Settings = 'Settings';
        this.strings.OK = 'OK';
        this.strings.Cancel = 'Cancel';
        this.strings.Yes = 'Yes';
        this.strings.No = 'No';
        this.strings['Card name...'] = 'Card name...';
        this.strings.Login = 'Login';
        this.strings.Expand = 'Expand';
        this.strings.OnlyOwned = 'Owned cards';
        this.strings.Cheapest = 'Cheapest';
        this.strings.SyncNotAllowed =
            'Urzagatherer can synchronize your collection across devices using synchronization provider. <br><br>Do you want to connect? <br><br><i>You can always do it later from the settings page</i>';
        this.strings.CodeBy = 'Code and design by:';
        this.strings.LogoBy = 'Logo by:';
        this.strings.OSS = 'Open source libraries:';
        this.strings.ThanksTo = 'Gold Patreons:';
        this.strings.AboutUrzaGatherer1 =
            'UrzaGatherer is a complete and convenient tool to handle your Magic The Gathering cards collection everywhere';
        this.strings.AboutUrzaGatherer2 =
            'You can also use it as the perfect companion to build and evaluate your decks or to track your life counters';
        this.strings.Whatsnew = "What's new";
        this.strings.WhatsnewIn = "What's new in version";
        this.strings.TokenDown =
            'Your synchronization provider authorization has timed out. Please click here to renew it.';
    }
    /* eslint-enable max-len */

    log(message: string, param?: any) {
        if (param) {
            console.log(message, param);
        } else {
            console.log(message);
        }
        this.logs.push(message + (param ? ' ' + param.toString() : ''));
    }

    translate(key: string): string {
        return this.strings[key];
    }

    translateWithPlural(key: string, count: number): string {
        const value = this.strings[key];

        if (value.indexOf('#') !== -1) {
            if (count !== 1) {
                return value.replace('#', 's');
            }

            return value.replace('#', '');
        }

        if (value.indexOf('@') !== -1) {
            const split = value.split('@');
            if (count !== 1) {
                return split[1];
            }

            return split[0];
        }

        if (count > 1 && value[value.length - 1] !== 's') {
            return value + 's';
        }

        return value;
    }

    storeNumberSettings(key: string, value: number, noSync?: boolean) {
        this.storeSettings(key, value.toString(), noSync);
    }

    storeDateSettings(key: string, value: Date) {
        this.storeSettings(key, value.toString());
    }

    storeBoolSettings(key: string, value: boolean, noSync = false) {
        this.storeSettings(key, value.toString(), noSync);
    }

    storeSettingsCompressed(key: string, value: string) {
        localStorage.setItem(key, LZString.compress(value));
    }

    storeSettings(key: string, value: string, noSync = false) {
        if (!GlobalState.DoesSettingsExist(key + '-lz') && localStorage.getItem(key) === value) {
            return;
        }

        if (value.length > 100) {
            localStorage.removeItem(key);
            this.storeSettingsCompressed(key + '-lz', value);
        } else {
            localStorage.setItem(key, value);
            localStorage.removeItem(key + '-lz');
        }

        if (noSync) {
            return;
        }

        const excludedKey = Collection.ExcludedSettings;

        if (!excludedKey.some(k => key.indexOf(k) !== -1) && GlobalState.LoadBoolSettings(this.syncSettingsKey) && !this._workLocally) {
            Collection.RegisterSaveUserSettings();
        }
    }

    public addOneFoil(card: ICard) {
        if (!card.canBeFoil) {
            return;
        }

        const current = card.foilCount;
        card.foilCount = Math.min(GlobalState.MaxCardCount, card.foilCount + 1);
        card.count += card.foilCount - current;
        this.onCardUpdated.notifyObservers(card);

        if (card.otherCard) {
            this.onCardUpdated.notifyObservers(card.otherCard);
        }
    }

    public removeOneFoil(card: ICard) {
        if (!card.canBeFoil) {
            return;
        }

        const previousFoil = card.foilCount;
        card.foilCount = Math.max(0, card.foilCount - 1);
        card.count = card.count - (previousFoil - card.foilCount);
        this.onCardUpdated.notifyObservers(card);

        if (card.otherCard) {
            this.onCardUpdated.notifyObservers(card.otherCard);
        }
    }


    public addOneSpecialFoil(card: ICard) {
        if (!card.canBeSpecialFoil) {
            return;
        }

        const current = card.specialFoilCount;
        card.specialFoilCount = Math.min(GlobalState.MaxCardCount, card.specialFoilCount + 1);
        card.count += card.specialFoilCount - current;
        this.onCardUpdated.notifyObservers(card);

        if (card.otherCard) {
            this.onCardUpdated.notifyObservers(card.otherCard);
        }
    }

    public removeOneSpecialFoil(card: ICard) {
        if (!card.canBeSpecialFoil) {
            return;
        }

        const previousFoil = card.specialFoilCount;
        card.specialFoilCount = Math.max(0, card.specialFoilCount - 1);
        card.count = card.count - (previousFoil - card.specialFoilCount);
        this.onCardUpdated.notifyObservers(card);

        if (card.otherCard) {
            this.onCardUpdated.notifyObservers(card.otherCard);
        }
    }

    public static get IsThereABackground() {
        return !!GlobalState.LoadSettings('BackgroundImage');
    }

    private static _CachedIsRunningOnFoldableDevice: boolean;

    public static get IsRunningOnFoldableDevice() {
        if (this._CachedIsRunningOnFoldableDevice === undefined) {
            this._CachedIsRunningOnFoldableDevice =
                (window as any).getWindowSegments && (window as any).getWindowSegments().length > 1 ? true : false;
        }

        return this._CachedIsRunningOnFoldableDevice;
    }

    public static get FoldWidth() {
        return 50;
    }

    public static LoadDateSettings(key: string, defaultValue: Date): Date {
        const value = this.LoadSettings(key);

        if (!value) {
            return defaultValue;
        }

        return new Date(value);
    }

    public static LoadFloatSettings(key: string, defaultValue = 0): number {
        const value = this.LoadSettings(key);

        if (!value) {
            return defaultValue;
        }

        return parseFloat(value);
    }

    public static LoadIntSettings(key: string, defaultValue = 0): number {
        const value = this.LoadSettings(key);

        if (!value) {
            return defaultValue;
        }

        return parseInt(value);
    }

    public static DoesSettingsExist(key: string) {
        return localStorage.getItem(key) !== null || localStorage.getItem(key + '-lz') !== null;
    }

    public static LoadBoolSettings(key: string, defaultValue = true): boolean {
        const value = this.LoadSettings(key);

        if (!value) {
            return defaultValue;
        }

        return value === 'true';
    }

    public static LoadSettings(key: string, defaultValue: string = ''): string {
        if (this.DoesSettingsExist(key + '-lz')) {
            return this.LoadSettingsCompressed(key + '-lz', defaultValue);
        }

        return localStorage.getItem(key) || defaultValue;
    }

    public static LoadSettingsCompressed(key: string, defaultValue: string = ''): string {
        const value = localStorage.getItem(key);

        if (!value) {
            return defaultValue;
        }

        return LZString.decompress(value) || defaultValue;
    }

    public static PrepareLocalStorage() {
        if (this.LoadIntSettings('storage-version', 1) < 2) {
            for (let index = 0; index < localStorage.length; index++) {
                const key = localStorage.key(index);
                if (key && key.indexOf('-lz') === -1) {
                    const value = localStorage.getItem(key);
                    if (value && value.length > 100) {
                        localStorage.setItem(key + '-lz', LZString.compress(value));
                        localStorage.removeItem(key);
                    }
                }
            }

            localStorage.setItem('storage-version', '2');
        }
    }

    showQuestionDialog(title: string, value: string, multiline?: boolean, suggestions?: string[], help?: string): Promise<string> {
        return new Promise((resolve) => {
            this.onQuestionAnswered.addOnce((value) => {
                resolve(value);
            });

            this.onQuestionRequired.notifyObservers({
                title: title,
                value: value,
                multiline: multiline || false,
                suggestions: suggestions,
                help: help
            });
        });
    }

    showConfirmDialog(message: string, ok = 'OK', cancel = 'Cancel'): Promise<boolean> {
        return new Promise((resolve) => {
            this.onConfirmDialog(message, ok, cancel, (value) => resolve(value));
        });
    }

    showCountDialog(title: string, defaultValue: number, maxValue: number = GlobalState.MaxCardCount): Promise<number> {
        return new Promise((resolve) => {
            this.onCollectionCount.addOnce((value) => {
                if (value > -1) {
                    resolve(value);
                }
            });

            this.onCollectionCountRequired.notifyObservers({
                title: title,
                defaultValue: defaultValue,
                maxValue: maxValue
            });
        });
    }

    showListDialog(
        title: string,
        options: string[],
        data: string,
        multipleCards?: number,
        decoder?: (value: string) => number[],
        encoder?: (values: number[]) => string,
        values?: number[]
    ): Promise<string> {
        return new Promise((resolve) => {
            this.onListSelected.addOnce((value) => {
                resolve(value);
            });

            const defaultDecoder = (value: string) => [parseInt(value)];
            const defaultEncoder = (values: number[]) => values[0].toString();

            this.onListRequired.notifyObservers({
                title,
                options,
                values,
                data,
                multipleCards,
                decoder: decoder || defaultDecoder,
                encoder: encoder || defaultEncoder
            });
        });
    }

    showMoveInsideDeckDialog(title: string, cards: DeckEntry[], deck: Deck): Promise<boolean> {
        return new Promise((resolve) => {
            this.onMoveInsideDeckDone.addOnce((value) => {
                if (!value) {
                    return;
                }
                resolve(value);
            });

            this.onMoveInsideDeckRequired.notifyObservers({ title: title, cards: cards, deck: deck });
        });
    }

    showDeckCountDialog(
        title: string,
        masterCard: Nullable<ICard>,
        currentDeck: Nullable<Deck>,
        deckValue: number,
        sideboardValue: number,
        reserveValue: number
    ): Promise<{
        deck: Deck;
        deckValue: number;
        sideboardValue: number;
        reserveValue: number;
    }> {
        return new Promise((resolve) => {
            this.onDeckCount.addOnce((value) => {
                if (!value) {
                    return;
                }
                resolve(value);
            });

            this.onDeckCountRequired.notifyObservers({
                title: title,
                masterCard: masterCard,
                currentDeck: currentDeck,
                deckValue: deckValue,
                sideboardValue: sideboardValue,
                reserveValue: reserveValue
            });
        });
    }

    showExpansionPicker(
        title: string
    ): Promise<{ sets: Set[]; owned: boolean; cheapest: boolean; excludePromos: boolean } | undefined> {
        return new Promise((resolve) => {
            this.onExpansionsPicked.addOnce((value) => {
                resolve(value);
            });

            this.onExpansionPickerRequired.notifyObservers(title);
        });
    }

    showColorPicker(color: string): Promise<string> {
        return new Promise((resolve) => {
            this.onColorPicked.addOnce((value) => {
                resolve(value);
            });

            this.onColorDialogRequired.notifyObservers(color);
        });
    }

    showCardImagePicker(cards: ICard[], backgroundMode = true): Promise<{card: Nullable<ICard>, url: string}> {
        return new Promise((resolve) => {
            this.onCardImagePicked.addOnce((value) => {
                resolve(value);
            });

            this.onCardImageDialogRequired.notifyObservers({ cards: cards, options: { backgroundMode: backgroundMode } });
        });
    }

    showCardIconPicker(activeIcon: number): Promise<{icon?: number}> {
        return new Promise((resolve) => {
            this.onCardIconPicked.addOnce((value) => {
                resolve(value);
            });

            this.onCardIconDialogRequired.notifyObservers({ activeIcon: activeIcon });
        });
    }

    showCustomSortDialog(): Promise<void> {
        return new Promise((resolve) => {
            this.onCustomSortDialogRequired.addOnce(() => {
                resolve();
            });

            this.onCustomSortDialogRequired.notifyObservers();
        });
    }

    showCustomChecklistDialog(): Promise<void> {
        return new Promise((resolve) => {
            this.onCustomChecklistDialogRequired.addOnce(() => {
                resolve();
            });

            this.onCustomChecklistDialogRequired.notifyObservers();
        });
    }

    showCustomDatabaseDialog(extension: boolean): Promise<void> {
        return new Promise((resolve) => {
            this.onCustomDatabaseRequired.addOnce(() => {
                resolve();
            });

            this.onCustomDatabaseRequired.notifyObservers(extension);
        });
    }

    showHiddenCardListDialog(): Promise<void> {
        return new Promise((resolve) => {
            this.onCustomDatabaseRequired.addOnce(() => {
                resolve();
            });

            this.onHiddenCardListRequired.notifyObservers();
        });
    }
}
