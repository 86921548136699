import * as React from 'react';
import { GlobalState } from '../globalState';
import { Collection } from '../entities/collection';
import { DataGrid, DataGridHeaderTypes, IColumn } from '../controls/dataGrid';
import { LabelledListbox } from '../controls/labelledListbox';
import { CardColor } from '../entities/cardColor';
import { PriceTools } from '../tools/priceTools';
import { SecureJSON } from '../tools/secureJSON';
import { LabelledTexbox } from '../controls/labelledTextbox';

require('../scss/checklistPage.scss');

interface IChecklistPageProps {
    globalState: GlobalState;
}

export class ChecklistPage extends React.Component<
    IChecklistPageProps,
    {
        showIndex: number;
        modeIndex: number;
        colorIndex: number;
        listIndex: number;
        filter: string;
    }
> {
    private columns: IColumn[];
    private rows: {
        name: string;
        id: number;
        icon: string;
        isComplete: boolean;
        completion: number;
        inCollection: number;
        missing: number;
        count: number;
        totalValue: number;
        inCollectionValue: number;
        missingValue: number;
        date: string;
        code: string;
    }[];

    constructor(props: IChecklistPageProps) {
        super(props);

        this.state = {
            showIndex: GlobalState.LoadIntSettings('ShowIndex', 0),
            modeIndex: GlobalState.LoadIntSettings('ModeIndex', 0),
            colorIndex: GlobalState.LoadIntSettings('ColorIndex', 0),
            listIndex: GlobalState.LoadIntSettings('ListIndex', 0),
            filter: ''
        };

        const translate = this.props.globalState.translate.bind(this.props.globalState);

        this.columns = [
            {
                key: 'name',
                label: translate('Name_'),
                type: DataGridHeaderTypes.string,
                customRender: (row: any, width: string, addClass: string) => {
                    return (
                        <div
                            className={'datagrid-cell-set ' + addClass}
                            key={addClass}
                            onClick={() =>
                                this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({
                                    index: -2,
                                    uniqueID: row.id
                                })
                            }
                            style={{
                                width: width
                            }}
                        >
                            <div className="datagrid-icon">
                                <img src={row.icon} />
                            </div>
                            <div className={'datagrid-label' + (row.isComplete ? ' completed' : '')}>{row.name}</div>
                        </div>
                    );
                }
            },
            { key: 'completion', label: translate('Completion %'), type: DataGridHeaderTypes.percentage },
            { key: 'count', label: translate('TotalCards'), type: DataGridHeaderTypes.int },
            { key: 'inCollection', label: translate('InCollectionDeck'), type: DataGridHeaderTypes.int },
            { key: 'missing', label: translate('Missing'), type: DataGridHeaderTypes.int },
            { key: 'totalValue', label: translate('Total value'), type: DataGridHeaderTypes.price },
            { key: 'inCollectionValue', label: translate('In collection (value)'), type: DataGridHeaderTypes.price },
            { key: 'missingValue', label: translate('Missing (value)'), type: DataGridHeaderTypes.price },
            { key: 'date', label: translate('Date'), type: DataGridHeaderTypes.date, sizeOffset: 10 },
            { key: 'code', label: translate('Code'), type: DataGridHeaderTypes.string }
        ];
    }

    updateHeaderText() {
        let headerText = '';
        const translateWithPlural = this.props.globalState.translateWithPlural.bind(this.props.globalState);

        let inCollectionPrice = 0;
        let totalPrice = 0;
        let cardCount = 0;
        let totalCardCount = 0;

        this.rows.forEach((r) => {
            inCollectionPrice += r.inCollectionValue;
            totalPrice += r.totalValue;
            totalCardCount += r.count;
            cardCount += r.inCollection;
        });

        const inCollectionValue = PriceTools.Format(inCollectionPrice, this.props.globalState);
        const value = PriceTools.Format(totalPrice, this.props.globalState);

        headerText = `${cardCount} ${translateWithPlural(
            'cards',
            cardCount
        )} (${inCollectionValue}) / ${totalCardCount} ${translateWithPlural('cards', totalCardCount)} (${value})`;

        this.props.globalState.onHeaderTextChanged.notifyObservers(headerText);
    }

    componentDidUpdate() {
        this.updateHeaderText();
    }

    componentDidMount() {
        this.updateHeaderText();
    }

    blurFilter(evt: React.KeyboardEvent<HTMLInputElement>) {
        if (evt.which === 13) {
            (evt.nativeEvent.srcElement! as HTMLInputElement).blur();
        }
    }

    render() {
        const translate = this.props.globalState.translate.bind(this.props.globalState);
        const colorIndex = this.state.colorIndex;
        const countToComplete = this.state.modeIndex === 0 ? 1 : 4;
        const excluded = SecureJSON.Parse<string[]>(GlobalState.LoadSettings('CustomChecklist')) || [];
        const filter = this.state.filter.toLowerCase();

        const sets = Collection.Sets.filter((e) => {
            if (excluded?.indexOf(e.name) !== -1) {
                return false;
            }

            if (filter) {

                if (e.name.toLocaleLowerCase().indexOf(filter) === -1) {
                    return false;
                }
            }

            if (this.state.listIndex === 1) {
                if (e.name.toLowerCase().indexOf('tokens') !== -1 || e.name.toLowerCase().indexOf('promos') !== -1) {
                    return false;
                }

                if (e.block.name === 'Tokens' || e.block.name === 'Promo Cards') {
                    return false;
                }

                if (
                    e.block.name === 'Judge Gift Cards' ||
                    e.block.name === 'Magic Player Rewards' ||
                    e.block.name === 'Friday Night Magic' ||
                    e.block.name === 'Arena League'
                ) {
                    return false;
                }

                if (e.block.name === 'World Championship Decks') {
                    return false;
                }
            }

            if (this.state.showIndex) {
                if (this.state.showIndex === 3) {
                    return e.inCollectionCardCount > 0;
                }

                const isComplete =
                    e.inCollectionCardCountPlayset(colorIndex, countToComplete) ===
                    e.cardCountPlayset(colorIndex, countToComplete);

                if (this.state.showIndex === 1) {
                    return !isComplete;
                }
                if (this.state.showIndex === 2) {
                    return isComplete;
                }
            }
            return true;
        });

        this.rows = sets.map((e) => {
                const inCollectionCardCountPlayset = e.inCollectionCardCountPlayset(colorIndex, countToComplete);
                const totalCardCount = e.cardCountPlayset(colorIndex, countToComplete);
                let inCollectionValue = 0;
                let missingValue = 0;
                let totalValue = 0;

                e.cards.forEach((c) => {
                    if (c.isBackFace) {
                        return;
                    }
                    totalValue += c.price * countToComplete;
                    inCollectionValue += Math.min(countToComplete, c.count) * c.price;
                    if (c.count < countToComplete) {
                        missingValue += (countToComplete - c.count) * c.price;
                    }
                });

                return {
                    name: e.name,
                    id: e.id,
                    icon: e.icon,
                    isComplete: inCollectionCardCountPlayset === totalCardCount,
                    completion: (inCollectionCardCountPlayset * 100) / totalCardCount,
                    inCollection: inCollectionCardCountPlayset,
                    missing: totalCardCount - inCollectionCardCountPlayset,
                    count: totalCardCount,
                    totalValue: totalValue,
                    inCollectionValue: inCollectionValue,
                    missingValue: missingValue,
                    date: (e.month < 10 ? '0' : '') + e.month + '/' + e.year,
                    code: e.code
                };
            })
            .filter((e) => {
                return e.count !== 0;
            });

        const suggestionForNames = Collection.Sets.map((s) => s.name);

        return (
            <div className="page">
                <div className="checklist-page">
                    <div className="checklist-page-filter">
                        <LabelledTexbox
                            label={translate('Name')}
                            globalState={this.props.globalState}
                            onKeyPress={(evt) => this.blurFilter(evt)}
                            onChange={(value) => {
                                this.setState({ filter: value });
                                this.props.globalState.pageCustomData.filter = value;
                            }}
                            suggestions={suggestionForNames}
                            value={this.state.filter}
                            placeholder={translate('AnyWord')}
                        />
                    </div>
                    <div className="checklist-filters">
                        <LabelledListbox
                            value={this.state.showIndex.toString()}
                            globalState={this.props.globalState}
                            label={translate('Show')}
                            options={[
                                { value: '0', label: translate('All') },
                                { value: '1', label: translate('OnlyMissing') },
                                { value: '2', label: translate('OnlyComplete') },
                                { value: '3', label: translate('OnlyInCollection') }
                            ]}
                            onChange={(value) => {
                                const valIndex = parseInt(value);
                                this.props.globalState.storeNumberSettings('ShowIndex', valIndex);
                                this.setState({ showIndex: valIndex });
                            }}
                        />

                        <LabelledListbox
                            className="checklist-filter-mode"
                            value={this.state.modeIndex.toString()}
                            globalState={this.props.globalState}
                            label={translate('Mode')}
                            options={[
                                { value: '0', label: translate('Standard') },
                                { value: '1', label: translate('Playset') }
                            ]}
                            onChange={(value) => {
                                const valIndex = parseInt(value);
                                this.props.globalState.storeNumberSettings('ModeIndex', valIndex);
                                this.setState({ modeIndex: valIndex });
                            }}
                        />

                        <LabelledListbox
                            className="checklist-filter-color"
                            value={this.state.colorIndex.toString()}
                            globalState={this.props.globalState}
                            label={translate('Color')}
                            options={CardColor.Colors.map((c) => {
                                return {
                                    value: c.id.toString(),
                                    label: c.name
                                };
                            })}
                            onChange={(value) => {
                                const valIndex = parseInt(value);
                                this.props.globalState.storeNumberSettings('ColorIndex', valIndex);
                                this.setState({ colorIndex: valIndex });
                            }}
                        />

                        <LabelledListbox
                            className="checklist-filter-list"
                            value={this.state.listIndex.toString()}
                            globalState={this.props.globalState}
                            label={translate('List')}
                            options={[
                                { value: '0', label: translate('All') },
                                { value: '1', label: translate('ExcludePromosAndTokens') }
                            ]}
                            onChange={(value) => {
                                const valIndex = parseInt(value);
                                this.props.globalState.storeNumberSettings('ListIndex', valIndex);
                                this.setState({ listIndex: valIndex });
                            }}
                        />
                    </div>
                    <div className="checklist-sets">
                        <DataGrid
                            orderStoreKey="CheckListDataGridOrderByIndex"
                            readOnly={true}
                            globalState={this.props.globalState}
                            columns={this.columns}
                            rows={this.rows}
                            hideTooltip={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
