export class ShareTools {
    public static async ShareAsync(url: string, title: string) {
        const response = await fetch(url);
        const blob = await response.blob();
        const navigatorAsAny = navigator as any;
        const file = new File([blob], title + '.jpg', { type: 'image/jpeg' });

        if (navigatorAsAny.canShare && navigatorAsAny.canShare({ files: [file] })) {
            await navigatorAsAny.share({
                files: [file],
                title: title
            } as any);
        }
    }

    public static get CanShare() {
        const navigatorAsAny = navigator as any;
        return navigatorAsAny.canShare;
    }
}
