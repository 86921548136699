import * as React from 'react';
import { GlobalState } from '../globalState';

interface ICheckboxProps {
    globalState: GlobalState;
    isChecked: boolean;
    onCheckedChanged: (newValue: boolean) => void;
    className?: string;
    readonly?: boolean;
}

export class Checkbox extends React.Component<ICheckboxProps, { isChecked: boolean }> {
    constructor(props: ICheckboxProps) {
        super(props);

        this.state = { isChecked: this.props.isChecked };
    }

    UNSAFE_componentWillUpdate (props: ICheckboxProps, state: { isChecked: boolean }) {
        if (props.isChecked !== state.isChecked) {
            state.isChecked = props.isChecked;
        }

        return true;
    }

    onClick() {
        const newValue = !this.state.isChecked;
        this.setState({ isChecked: newValue });

        this.props.onCheckedChanged(newValue);
    }

    render() {
        return (
            <label
                className={
                    'switch switch_type1' +
                    (this.props.className ? ' ' + this.props.className : '') +
                    (this.props.readonly ? ' readonly' : '')
                }
                role="switch"
            >
                <input
                    type="checkbox"
                    className="switch__toggle"
                    checked={this.props.readonly || this.state.isChecked}
                    onChange={() => this.onClick()}
                />
                <span className="switch__label"></span>
            </label>
        );
    }
}
