import * as React from 'react';
import { GlobalState, SelectionData } from '../globalState';
import { Nullable } from '../tools/nullable';
import { Observer } from '../tools/observable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEllipsisH,
    faCheck,
    faSave,
    faList,
    faTrashAlt,
    IconDefinition,
    faSun,
    faCoins,
    faDiceD6,
    faDiceD20,
    faUndo,
    faFlagCheckered,
    faBox,
    faChartBar,
    faRandom
} from '@fortawesome/free-solid-svg-icons';
import { FooterButton } from './footerButton';
import {
    faTreasureChest,
    faFileImport,
    faHandPaper,
    faHandHoldingMagic,
    faGem,
    faBullseyeArrow
} from '@fortawesome/pro-solid-svg-icons';
import Popup from 'reactjs-popup';
import { Collection } from '../entities/collection';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import { ExpandableFooterButton } from './expandableFooterButton';
import { DeckPicker } from './deckPicker';

interface IFooterProps {
    globalState: GlobalState;
}

export class Footer extends React.Component<
    IFooterProps,
    {
        scaleCommand: boolean;
        isExpanded: boolean;
        resetCommand: boolean;
        selectCommand: boolean;
        saveCommand: boolean;
        saveList: boolean;
        foilCommand: boolean;
        specialFoilCommand: boolean;
        selectModeIsActivated: boolean;
        collectionCommand: boolean;
        deckCommand: boolean;
        saveSearchPopupOpened: boolean;
        customMenu: boolean;
        coinCommand: boolean;
        dice6Command: boolean;
        dice20Command: boolean;
        newTurnCommand: boolean;
        importCommand: boolean;
        statisticsCommand: boolean;
        handSimulator: boolean;
        drawCommand: boolean;
        shuffleCommand: boolean;
        activeDeckCommand: boolean;
    }
> {
    private onSelectedMenuIndexObserver: Nullable<Observer<SelectionData>>;

    constructor(props: IFooterProps) {
        super(props);

        this.state = {
            isExpanded: false,
            scaleCommand: false,
            resetCommand: false,
            selectCommand: false,
            selectModeIsActivated: false,
            collectionCommand: false,
            saveCommand: false,
            saveList: false,
            saveSearchPopupOpened: false,
            customMenu: false,
            foilCommand: false,
            specialFoilCommand: false,
            coinCommand: false,
            dice6Command: false,
            dice20Command: false,
            newTurnCommand: false,
            deckCommand: false,
            importCommand: false,
            statisticsCommand: false,
            handSimulator: false,
            drawCommand: false,
            shuffleCommand: false,
            activeDeckCommand: false
        };
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onSavedSearchesChanged.add(() => {
            this.forceUpdate();
        });

        this.props.globalState.onCustomMenuRequired.add((_menu) => {
            setTimeout(() => {
                this.setState({ customMenu: true });
            });
        });

        this.props.globalState.onCustomMenuClosed.add(() => {
            this.setState({ isExpanded: false });
        });

        this.props.globalState.showFoilMenu.add((state) => {
            this.setState({ foilCommand: state });
        });

        this.props.globalState.showSelectCommand.add((state) => {
            this.setState({ selectCommand: state});
        });

        this.props.globalState.onDataGridModeChanged.add((state) => {
            this.setState({ scaleCommand: !state});
        });


        this.onSelectedMenuIndexObserver = this.props.globalState.onSelectedMenuIndexChanged.add((selection) => {
            let scaleCommand = false;
            let resetCommand = false;
            let selectCommand = false;
            let saveCommand = false;
            let selectModeIsActivated = false;
            let saveList = false;
            let customMenu = false;
            let collectionCommand = false;
            const foilCommand = false;
            let coinCommand = false;
            let dice6Command = false;
            let dice20Command = false;
            let newTurnCommand = false;
            let deckCommand = false;
            let importCommand = false;
            let statisticsCommand = false;
            let handSimulator = false;
            let drawCommand = false;
            let shuffleCommand = false;
            let activeDeckCommand = false;

            let datagridMode = GlobalState.LoadBoolSettings('DataGridInsteadOfList', false);

            switch (selection.index) {
                case -8:
                    scaleCommand = true;
                    resetCommand = true;
                    drawCommand = true;
                    shuffleCommand = true;
                    break;
                case -6:
                    datagridMode = GlobalState.LoadBoolSettings('DataGridInsteadOfListForDecks', false);
                    if (!datagridMode) {
                        scaleCommand = true;
                        customMenu = true;
                        selectCommand = true;
                    }
                    statisticsCommand = true;
                    handSimulator = true;

                    activeDeckCommand = true;
                    break;
                case -4:
                    collectionCommand = true;
                    customMenu = true;
                    deckCommand = true;
                    break;
                case -3:
                    if (!datagridMode) {
                        scaleCommand = true;
                        selectCommand = true;
                        customMenu = true;
                    }
                    activeDeckCommand = true;
                    break;
                case -2:
                    if (!datagridMode) {
                        scaleCommand = true;
                        selectCommand = true;
                    }
                    customMenu = true;
                    activeDeckCommand = true;
                    break;
                case 1:
                    datagridMode = GlobalState.LoadBoolSettings('DataGridInsteadOfListForCollection', false);
                    if (!datagridMode) {
                        scaleCommand = true;
                        selectCommand = true;
                        selectModeIsActivated = false;
                        customMenu = true;
                    }
                    activeDeckCommand = true;
                    break;
                case 2:
                    saveCommand = true;
                    resetCommand = true;
                    saveList = true;
                    break;
                case 3:
                    customMenu = true;
                    break;
                case 9:
                    coinCommand = true;
                    dice6Command = true;
                    dice20Command = true;
                    resetCommand = true;
                    newTurnCommand = true;
                    break;
                case 10:
                    importCommand = true;
                    break;
            }

            this.setState({
                isExpanded: false,
                scaleCommand: scaleCommand,
                resetCommand: resetCommand,
                selectCommand: selectCommand,
                selectModeIsActivated: selectModeIsActivated,
                collectionCommand: collectionCommand,
                saveCommand: saveCommand,
                saveList: saveList,
                customMenu: customMenu,
                saveSearchPopupOpened: false,
                foilCommand: foilCommand,
                coinCommand: coinCommand,
                dice6Command: dice6Command,
                dice20Command: dice20Command,
                newTurnCommand: newTurnCommand,
                deckCommand: deckCommand,
                importCommand: importCommand,
                statisticsCommand: statisticsCommand,
                handSimulator: handSimulator,
                drawCommand: drawCommand,
                shuffleCommand: shuffleCommand,
                activeDeckCommand: activeDeckCommand && this.props.globalState.displayActiveDeckIcon
            });
        });

        this.props.globalState.onCollectionCommandRequired.add((value) => {
            this.setState({ collectionCommand: value });
        });

        this.props.globalState.onDeckCommandRequired.add((value) => {
            this.setState({ deckCommand: value });
        });

        this.props.globalState.onSelectModeActivationRequired.add(() => {
            this.setState({ selectModeIsActivated: true });
        });
    }

    componentWillUnmount() {
        this.props.globalState.onSelectedMenuIndexChanged.remove(this.onSelectedMenuIndexObserver);
    }

    select() {
        this.props.globalState.onSelectMode.notifyObservers(!this.state.selectModeIsActivated);
        this.setState({ selectModeIsActivated: !this.state.selectModeIsActivated });
    }

    addToCollection() {
        this.props.globalState.onCollectionCommandActivated.notifyObservers();
    }

    addToDeck() {
        this.props.globalState.onDeckCommandActivated.notifyObservers();
    }

    onFoils() {
        this.props.globalState.onFoilsCommandActivated.notifyObservers();
    }

    onSpecialFoils() {
        this.props.globalState.onSpecialFoilCommandActivated.notifyObservers();
    }

    reset() {
        this.props.globalState.onReset.notifyObservers();
    }

    expand() {
        this.setState({
            isExpanded: !this.state.isExpanded
        });
    }

    save() {
        this.props.globalState.onSave.notifyObservers();
    }

    changeImageWidth(value: number) {
        this.props.globalState.imageWidth = value;
        this.props.globalState.storeNumberSettings('imageWidth', value);
        this.forceUpdate();
    }

    deleteSavedSearch(search: string) {
        this.setState({ saveSearchPopupOpened: false });
        this.props.globalState
            .showConfirmDialog(this.props.globalState.translate('SavedSearchDeleteConfirm'))
            .then((state) => {
                if (state) {
                    delete Collection.UserStoreExt.SavedSearches[search];
                    Collection.RegisterSaveExt();
                    this.forceUpdate();
                }
            });
    }

    onFileImport(changeEvent: React.ChangeEvent<HTMLInputElement>) {
        const files = changeEvent.target.files;
        if (!files) {
            return;
        }
        const fileToLoad = files[0];

        const reader = new FileReader();

        reader.onloadend = (evt: ProgressEvent) => {
            const target = evt.target as any;
            const regex = /(.+)\.(.+)/g;

            const match = regex.exec(fileToLoad.name);

            if (!match || match.length === 0) {
                return;
            }

            const filename = match[1];
            const extension = match[2];

            if (target && target.readyState === FileReader.DONE) {
                this.props.globalState.onImport.notifyObservers({
                    data: target.result,
                    filename: filename,
                    extension: extension
                });
            }
        };

        reader.readAsText(fileToLoad);
    }

    flipACoin() {
        this.props.globalState.onCoinFlipped.notifyObservers(Math.random() >= 0.5);
    }

    rollDice(count: number) {
        this.props.globalState.onDiceRolled.notifyObservers({ value: (Math.random() * count) | 0, max: count });
    }

    newTurn() {
        this.props.globalState.onNewTurn.notifyObservers();
    }

    draw() {
        this.props.globalState.onDraw.notifyObservers();
    }

    shuffle() {
        this.props.globalState.onShuffle.notifyObservers();
    }

    onStatistics() {
        this.props.globalState.onStatistics.notifyObservers();
    }

    onSimulator() {
        this.props.globalState.onSimulator.notifyObservers();
    }

    render() {
        const notVisible =
            !this.state.scaleCommand &&
            !this.state.resetCommand &&
            !this.state.selectCommand &&
            !this.state.collectionCommand &&
            !this.state.saveCommand &&
            !this.state.saveList &&
            !this.state.customMenu &&
            !this.state.foilCommand &&
            !this.state.specialFoilCommand &&
            !this.state.coinCommand &&
            !this.state.dice6Command &&
            !this.state.dice20Command &&
            !this.state.newTurnCommand &&
            !this.state.deckCommand &&
            !this.state.importCommand &&
            !this.state.statisticsCommand &&
            !this.state.handSimulator &&
            !this.state.drawCommand &&
            !this.state.shuffleCommand;

        const translate = this.props.globalState.translate.bind(this.props.globalState);
        const searches = [];

        if (Collection.UserStoreExt) {
            for (const key in Collection.UserStoreExt.SavedSearches) {
                if (key) {
                    searches.push(key);
                }
            }
        }

        const darkMode = GlobalState.LoadBoolSettings('DarkMode', false);

        let popupContentStyle: any = {
            padding: '0',
            background: '#EEEEEE',
            outline: '1px solid #444444',
            border: '0px',
            color: '#444444'
        };

        if (darkMode) {
            popupContentStyle = {
                padding: '0',
                background: '#444444',
                outline: '1px solid #BBBBBB',
                color: '#BBBBBB',
                border: '0px'
            };
        }

        return (
            <div
                className={
                    'mainFooter' +
                    (notVisible ? ' notVisible' : '') +
                    (!notVisible && this.state.isExpanded ? ' expanded' : '') +
                    (GlobalState.LoadSettings('BackgroundImage') ? ' over-background' : '')
                }
            >
                <div className="leftCommandBar">
                    {
                        this.state.activeDeckCommand &&
                        <ExpandableFooterButton
                            icon={faBullseyeArrow}
                            text={translate('ActiveDeck')}
                            highlightKey={'activeDeck'}
                            isExpanded={this.state.isExpanded}
                            globalState={this.props.globalState}
                            excludedClicks={['textbox-element-suggestion']}
                        >
                            <DeckPicker globalState={this.props.globalState} darkMode={darkMode} />
                        </ExpandableFooterButton>
                    }
                </div>
                <div className="rightCommandBar">
                    {!this.state.customMenu && (
                        <div
                            className={'actionButton' + (this.state.isExpanded ? ' expanded' : '')}
                            title={translate('Expand')}
                            onClick={() => this.expand()}
                        >
                            <FontAwesomeIcon icon={faEllipsisH} />
                        </div>
                    )}
                    {this.state.customMenu && (
                        <div>
                            <FooterButton
                                id="moreButton"
                                icon={faEllipsisH}
                                text=""
                                onclick={() => {
                                    this.expand();
                                    this.props.globalState.onCustomMenuDisplayRequired.notifyObservers();
                                }}
                                isExpanded={this.state.isExpanded}
                                globalState={this.props.globalState}
                            />
                        </div>
                    )}
                    {this.state.handSimulator && (
                        <FooterButton
                            icon={faHandPaper as Icon}
                            text={translate('Simulator')}
                            onclick={() => this.onSimulator()}
                            isExpanded={this.state.isExpanded}
                            globalState={this.props.globalState}
                        />
                    )}
                    {this.state.statisticsCommand && (
                        <FooterButton
                            icon={faChartBar}
                            text={translate('Statistics')}
                            onclick={() => this.onStatistics()}
                            isExpanded={this.state.isExpanded}
                            globalState={this.props.globalState}
                        />
                    )}
                    {this.state.selectCommand && (
                        <FooterButton
                            icon={faCheck}
                            text={translate('Select')}
                            isActivated={this.state.selectModeIsActivated}
                            onclick={() => this.select()}
                            isExpanded={this.state.isExpanded}
                            globalState={this.props.globalState}
                        />
                    )}
                    {this.state.saveList && searches.length > 0 && (
                        <Popup
                            trigger={
                                <div>
                                    <FooterButton
                                        icon={faList}
                                        text={translate('SavedSearches')}
                                        isActivated={this.state.saveSearchPopupOpened}
                                        onclick={() => this.setState({ saveSearchPopupOpened: true })}
                                        isExpanded={this.state.isExpanded}
                                        globalState={this.props.globalState}
                                    />
                                </div>
                            }
                            contentStyle={popupContentStyle}
                            arrow={false}
                            open={this.state.saveSearchPopupOpened}
                            onClose={() => this.setState({ saveSearchPopupOpened: false })}
                            offsetY={1}
                            position="top right"
                        >
                            <div>
                                {searches.map((search) => {
                                    return (
                                        <div key={search} className="saved-search">
                                            <div
                                                className="saved-search-label"
                                                onClick={() =>
                                                    this.props.globalState.onSavedSearchesSelected.notifyObservers(
                                                        search
                                                    )
                                                }
                                            >
                                                {search}
                                            </div>
                                            <div
                                                className="saved-search-trash button"
                                                onClick={() => this.deleteSavedSearch(search)}
                                            >
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </Popup>
                    )}
                    {this.state.saveCommand && (
                        <FooterButton
                            icon={faSave}
                            text={translate('Save')}
                            onclick={() => this.save()}
                            isExpanded={this.state.isExpanded}
                            globalState={this.props.globalState}
                        />
                    )}
                    {this.state.coinCommand && (
                        <FooterButton
                            icon={faCoins}
                            text={translate('FlipACoin')}
                            onclick={() => this.flipACoin()}
                            isExpanded={this.state.isExpanded}
                            globalState={this.props.globalState}
                        />
                    )}
                    {this.state.dice6Command && (
                        <FooterButton
                            icon={faDiceD6}
                            text={translate('RollA6SidedDice')}
                            onclick={() => this.rollDice(6)}
                            isExpanded={this.state.isExpanded}
                            globalState={this.props.globalState}
                        />
                    )}
                    {this.state.dice20Command && (
                        <FooterButton
                            icon={faDiceD20}
                            text={translate('RollA20SidedDice')}
                            onclick={() => this.rollDice(20)}
                            isExpanded={this.state.isExpanded}
                            globalState={this.props.globalState}
                        />
                    )}
                    {this.state.resetCommand && (
                        <FooterButton
                            icon={faUndo}
                            text={translate('Reset')}
                            onclick={() => this.reset()}
                            isExpanded={this.state.isExpanded}
                            globalState={this.props.globalState}
                        />
                    )}
                    {this.state.newTurnCommand && (
                        <FooterButton
                            icon={faFlagCheckered}
                            text={translate('NewTurn')}
                            onclick={() => this.newTurn()}
                            isExpanded={this.state.isExpanded}
                            globalState={this.props.globalState}
                        />
                    )}
                    {this.state.foilCommand && (
                        <FooterButton
                            icon={faSun}
                            text={translate('Foils')}
                            onclick={() => this.onFoils()}
                            isExpanded={this.state.isExpanded}
                            globalState={this.props.globalState}
                        />
                    )}
                    {this.state.specialFoilCommand && (
                        <FooterButton
                            icon={faGem}
                            text={translate('SpecialFoil')}
                            onclick={() => this.onSpecialFoils()}
                            isExpanded={this.state.isExpanded}
                            globalState={this.props.globalState}
                        />
                    )}
                    {this.state.collectionCommand && (
                        <FooterButton
                            icon={faTreasureChest as IconDefinition}
                            text={translate('Collection_')}
                            onclick={() => this.addToCollection()}
                            isExpanded={this.state.isExpanded}
                            globalState={this.props.globalState}
                        />
                    )}
                    {this.state.deckCommand && (
                        <div className={'actionButton-separator' + (this.state.isExpanded ? ' expanded' : '')} />
                    )}
                    {this.state.deckCommand && (
                        <FooterButton
                            icon={faBox}
                            text={translate('Deck')}
                            onclick={() => this.addToDeck()}
                            isExpanded={this.state.isExpanded}
                            globalState={this.props.globalState}
                        />
                    )}
                    {this.state.shuffleCommand && (
                        <FooterButton
                            icon={faRandom as Icon}
                            text={translate('Shuffle')}
                            onclick={() => this.shuffle()}
                            isExpanded={this.state.isExpanded}
                            globalState={this.props.globalState}
                        />
                    )}
                    {this.state.drawCommand && (
                        <FooterButton
                            icon={faHandHoldingMagic as Icon}
                            text={translate('Draw')}
                            onclick={() => this.draw()}
                            isExpanded={this.state.isExpanded}
                            globalState={this.props.globalState}
                        />
                    )}
                    {this.state.importCommand && (
                        <div className="actionButton-file-input-container">
                            <input
                                type="file"
                                id="importCommand"
                                accept={'.csv,.txt,.dek,.mtga'}
                                value=""
                                onChange={(evt) => this.onFileImport(evt)}
                                className="actionButton-file-input"
                            />
                            <label htmlFor="importCommand" className="actionButton-file-input-label">
                                <FooterButton
                                    icon={faFileImport as Icon}
                                    text={translate('Import')}
                                    isExpanded={this.state.isExpanded}
                                    globalState={this.props.globalState}
                                />
                            </label>
                        </div>
                    )}
                </div>
                <div className="centralCommandBar">
                    {(window.innerWidth > 768 || !this.state.selectModeIsActivated) && this.state.scaleCommand && (
                        <div className={'actionSlider' + (this.state.isExpanded ? ' expanded' : '')}>
                            <input
                                type="range"
                                min="150"
                                max={GlobalState.IsRunningOnFoldableDevice ? '400' : '500'}
                                value={this.props.globalState.imageWidth}
                                className={navigator.userAgent.indexOf('Edge') !== -1 ? 'edge-slider' : 'slider'}
                                onChange={(evt) => this.changeImageWidth(parseFloat(evt.target.value))}
                            />
                            <div className={'actionButton-label' + (!this.state.isExpanded ? ' collapsed' : '')}>
                                {translate('ImageSize')}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
