import * as React from 'react';
import { GlobalState } from '../globalState';
import { Button } from './button';

interface IListDialogProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class ListDialog extends React.Component<
    IListDialogProps,
    { isVisible: boolean; title: string; options: string[], multipleCards: number, values?: number[] }
> {
    private _encoder: (values: number[]) => string;
    private _values: number[] = [];

    constructor(props: IListDialogProps) {
        super(props);

        this.state = { isVisible: false, title: '', options: [], multipleCards: 0 };
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onListRequired.add((value) => {
            if (value.multipleCards === 0) {
                this.props.globalState.onError.notifyObservers(this.props.globalState.translate('NeedToAddCardToCollectionFirst'));
                return;
            }

            this._encoder = value.encoder;
            this._values = value.decoder(value.data);

            if (value.multipleCards) {
                this._values = this._values.slice(0, value.multipleCards);
            }

            this.setState({ isVisible: true, title: value.title, options: value.options, values: value.values, multipleCards: value.multipleCards || 1 });

            this.props.globalState.onBlurRequired.notifyObservers(true);
        });

        document.addEventListener('keydown', (evt) => {
            if (evt.keyCode === 27 && this.state.isVisible) {
                this.onConfirmation(false);
            }
        });
    }

    onConfirmation(value: boolean) {
        if (value) {
            this.props.globalState.onListSelected.notifyObservers(this._encoder(this._values));
        } else {
            this.props.globalState.onListSelected.notifyObservers('');
        }

        this.props.globalState.onBlurRequired.notifyObservers(false);
        this.setState({ isVisible: false });
    }

    renderOneLine(index: number, title?: string) {
        return (
            <div className='list-container' key={'item-index' + index}>
                {
                    title !== undefined &&
                    <div className='list-title'>
                        {title}
                    </div>
                }
                <select
                    tabIndex={1}
                    className="query-dialog-list"
                    value={this._values[index]}
                    onChange={(evt) => {
                        this._values[index] = parseInt(evt.target.value);
                        this.forceUpdate();
                    }}
                >
                    {this.state.options.map((o, i) => {
                        return (
                            <option key={i} value={this.state.values ? this.state.values[i] : i}>
                                {o}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    }

    render() {
        if (!this.state.isVisible) {
            return null;
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);
        const lines: JSX.Element[] = [];

        if (this.state.multipleCards === 1) {
            lines.push(this.renderOneLine(0));
        } else {
            for (let index = 0; index < this.state.multipleCards; index++) {
                lines.push(this.renderOneLine(index, translate('Card#') + index));
            }
        }

        const maxHeight = Math.min(100 + lines.length * 50, 400);

        return (
            <div className="query-dialog-container">
                <div className="query-dialog-background"></div>
                <div className={'query-dialog' + (this.props.darkMode ? ' dark' : '')} style={{
                    height: maxHeight + 'px',
                    top: `calc(50% - ${maxHeight / 2}px)`
                }}>
                    <div className="query-dialog-title">{this.state.title}</div>
                    <div className="query-dialog-data" style={{
                        overflow: 'auto',
                        alignContent: 'start',
                        marginTop: '5px'
                    }}>
                        {
                            lines
                        }
                    </div>
                    <div className="query-dialog-accept">
                        <Button
                            tabIndex={2}
                            className="query-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(true)}
                        >
                            {translate('OK')}
                        </Button>
                    </div>
                    <div className="query-dialog-cancel">
                        <Button
                            tabIndex={3}
                            className="query-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(false)}
                        >
                            {translate('Cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
