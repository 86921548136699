import * as React from 'react';
import { GlobalState } from '../globalState';
import { Button } from './button';
import { ICard } from '../entities/ICard';
import { Nullable } from '../tools/nullable';
import { LabelledTexbox } from './labelledTextbox';

require('../scss/controls/cardImageDialog.scss');

interface ICardImageDialogProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class CardImageDialog extends React.Component<
    ICardImageDialogProps,
    { isVisible: boolean, cards: ICard[], selectedCard: Nullable<ICard>, query: string }
> {
    private _sourceCards: ICard[];
    private _isBackgroundMode: boolean;

    constructor(props: ICardImageDialogProps) {
        super(props);

        this.state = { isVisible: false, cards: [], selectedCard: null, query: '' };
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onCardImageDialogRequired.add((data) => {

            this._sourceCards = data.cards;
            this._isBackgroundMode = data.options.backgroundMode;

            const visibleCards = this._sourceCards.slice(0, 50).sort((a, b) => a.nameEn.localeCompare(b.nameEn));

            this.setState({
                isVisible: true,
                cards: visibleCards,
                selectedCard: null,
                query: ''
            });

            this.props.globalState.onBlurRequired.notifyObservers(true);
        });

        document.addEventListener('keydown', (evt) => {
            if (evt.keyCode === 27 && this.state.isVisible) {
                this.onConfirmation(false);
                return;
            }
        });
    }

    onConfirmation(value: boolean) {
        if (value) {
            if (!this.state.selectedCard) {
                this.props.globalState.onCardImagePicked.notifyObservers({ card: null, url: 'none' });
            } else {
                this.props.globalState.onWaitRingRequired.notifyObservers(true);
                this.props.globalState.onCardImagePicked.notifyObservers({ card: this.state.selectedCard, url: this.state.selectedCard.cropUrl });
                this.props.globalState.onWaitRingRequired.notifyObservers(false);
            }
        } else {
            this.props.globalState.onCardImagePicked.notifyObservers({ card: null, url: '' });
        }

        this.setState({ isVisible: false });

        this.props.globalState.onBlurRequired.notifyObservers(false);
    }

    manageError(evt: React.SyntheticEvent<HTMLImageElement>) {
        const img = evt.nativeEvent.srcElement as HTMLImageElement;
        const url = img.src;

        // Remove id
        let regex = /.+?(\d+)\.jpg/;

        let matches = regex.exec(url);

        if (matches && matches.length) {
            img.src = url.replace(matches[1], '');
            return;
        }

        // Remove land number
        regex = /\s\(v. \d+\)\.jpg/;

        matches = regex.exec(url);

        if (matches && matches.length) {
            img.src = url.replace(matches[0], '.jpg');
            return;
        }

        // Not found
        img.src = '/images/back.jpg';
    }

    filter() {
        this.setState({ cards: this._sourceCards.filter(c => !this.state.query || c.nameEn.toLowerCase().indexOf(this.state.query.toLowerCase()) !== -1 || c.set.name.toLowerCase().indexOf(this.state.query.toLowerCase()) !== -1).slice(0, 50).sort((a, b) => a.nameEn.localeCompare(b.nameEn)) });
    }

    blurTextField(evt: React.KeyboardEvent<HTMLInputElement>) {
        if (evt.which === 13) {
            this.filter();
        }
    }

    render() {
        if (!this.state.isVisible) {
            return null;
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);

        const suggestionForNames = this._sourceCards.map((c) => c.nameEn);

        return (
            <div className="card-image-dialog-container">
                <div className="card-image-dialog-background"></div>
                <div className={'card-image-dialog' + (this.props.darkMode ? ' dark' : '')}>
                    <div className="card-image-dialog-title">{translate('PickCardImage')}</div>
                    <div className="card-image-dialog-filter">
                        <LabelledTexbox
                            label=""
                            globalState={this.props.globalState}
                            onlyInput={true}
                            className="search-box-text"
                            onKeyPress={(evt) => this.blurTextField(evt)}
                            onChange={(value) => {
                                this.setState({ query: value });
                                setTimeout(() => this.filter());
                            }}
                            onSuggestionPicked={() => {
                                setTimeout(() => {
                                    this.filter();
                                });
                            }}
                            suggestions={suggestionForNames}
                            value={this.state.query}
                            placeholder={translate('filter...')}
                        />
                    </div>
                    <div className="card-image-dialog-container">
                            {
                                this._isBackgroundMode &&
                                <div
                                    key={-1}
                                    className={'img-preview' + (!this.state.selectedCard ? ' selected' : '')}
                                    onClick={() => this.setState({ selectedCard: null })}
                                >
                                <div className="card-name">{translate('None')}</div>
                                <div className="expansion-name">{translate('Nobackground')}</div>
                            </div>
                            }
                        {this.state.cards.map((card) => {
                            return (
                                <div
                                    key={card.id}
                                    className={'img-preview' + (this.state.selectedCard === card ? ' selected' : '')}
                                    onClick={() => this.setState({ selectedCard: card })}
                                >
                                    <img
                                        src={'https://urzabackend.azurewebsites.net/Mid/' + card.picturePath}
                                        onError={(evt) => this.manageError(evt)}
                                    />
                                    {
                                        this._isBackgroundMode &&
                                        <>
                                            <div className="card-name">{`${card.name} (#${card.number})`}</div>
                                            <div className="expansion-name">{card.set.name}</div>
                                        </>
                                    }
                                    {
                                        !this._isBackgroundMode &&
                                        <>
                                            <div className="card-name">{`${card.set.name}`}</div>
                                            <div className="expansion-name">{card.name}</div>
                                            <div className="card-number">#{card.number}</div>
                                        </>
                                    }
                                </div>
                            );
                        })}
                    </div>
                    <div className="card-image-dialog-accept">
                        <Button
                            tabIndex={2}
                            className="card-image-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(true)}
                        >
                            {translate('OK')}
                        </Button>
                    </div>
                    <div className="card-image-dialog-cancel">
                        <Button
                            tabIndex={3}
                            className="card-image-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(false)}
                        >
                            {translate('Cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
