import * as React from 'react';
import { GlobalState, SearchData } from '../globalState';
import { LinkTools } from '../tools/linkTool';

interface ICardTextLineProps {
    globalState: GlobalState;
    label: string;
    value: string;
    searchProperty?: string;
    searchValue?: any;
    image?: string;
    externalLink?: string;
    className?: string;
}

export class CardTextLine extends React.Component<ICardTextLineProps, Record<string, unknown>> {
    constructor(props: ICardTextLineProps) {
        super(props);
    }

    navigate() {
        if (this.props.externalLink) {
            LinkTools.OpenLink(this.props.externalLink);
            return;
        }
        if (!this.props.searchProperty) {
            return;
        }

        if (this.props.searchProperty === 'block') {
            this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({
                index: -1,
                uniqueID: this.props.searchValue
            });
            return;
        }

        const searchData = new SearchData();
        (searchData as any)[this.props.searchProperty] = this.props.searchValue;

        this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({ index: -3, searchData: searchData });
    }

    render() {
        return (
            <div className={'card-element' + (this.props.className ? ' ' + this.props.className : '')}>
                <div className="card-element-label">{this.props.label}</div>
                <div
                    className={
                        'card-element-value' + (this.props.searchProperty || this.props.externalLink ? ' link' : '')
                    }
                    onClick={() => this.navigate()}
                >
                    {this.props.value}
                </div>
                {this.props.image && <img src={this.props.image} className="card-element-image" />}
                <div className="card-element-border"></div>
            </div>
        );
    }
}
