import * as React from 'react';
import { GlobalState } from '../globalState';
import { Button } from './button';
import { NumberControl } from './numberControl';

interface ICountDialogProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class CountDialog extends React.Component<
    ICountDialogProps,
    { isVisible: boolean; title: string; value: number; max: number }
> {
    constructor(props: ICountDialogProps) {
        super(props);

        this.state = { isVisible: false, title: '', value: 0, max: GlobalState.MaxCardCount };
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onCollectionCountRequired.add((value) => {
            this.setState({ isVisible: true, title: value.title, value: value.defaultValue, max: value.maxValue });

            this.props.globalState.onBlurRequired.notifyObservers(true);
        });

        document.addEventListener('keydown', (evt) => {
            if (!this.state.isVisible) {
                return;
            }

            switch (evt.keyCode) {
                case 27: {
                    this.onConfirmation(false);
                    return;
                }
                case 13: {
                    this.onConfirmation(true);
                    return;
                }
            }
        });
    }

    onConfirmation(value: boolean) {
        if (value) {
            this.props.globalState.onCollectionCount.notifyObservers(this.state.value);
        } else {
            this.props.globalState.onCollectionCount.notifyObservers(-1);
        }

        this.props.globalState.onBlurRequired.notifyObservers(false);

        this.setState({ isVisible: false });
    }

    render() {
        if (!this.state.isVisible) {
            return null;
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);

        return (
            <div className="count-dialog-container">
                <div className="count-dialog-background"></div>
                <div className={'count-dialog' + (this.props.darkMode ? ' dark' : '')}>
                    <div className="count-dialog-title">{this.state.title}</div>
                    <div className="count-dialog-data">
                        <NumberControl
                            isFocused={true}
                            globalState={this.props.globalState}
                            max={this.state.max}
                            value={this.state.value}
                            onChange={(value) => this.setState({ value: value })}
                        />
                    </div>
                    <div className="count-dialog-accept">
                        <Button
                            tabIndex={2}
                            className="count-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(true)}
                        >
                            {translate('OK')}
                        </Button>
                    </div>
                    <div className="count-dialog-cancel">
                        <Button
                            tabIndex={3}
                            className="count-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(false)}
                        >
                            {translate('Cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
