import * as React from 'react';
import { GlobalState } from '../globalState';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { DualSlider } from './dualSlider';

interface IRangeSliderProps {
    globalState: GlobalState;
    label: string;
    min: number;
    max: number;
    valueLeft: number;
    valueRight: number;
    isInteger?: boolean;
    isHorizontal?: boolean;
    onChange?: (min: number, max: number) => void;
}

export class RangeSlider extends React.Component<IRangeSliderProps, { value0: string; value1: string }> {
    private static NumberGenerator = 0;
    private id = 'range-slider-' + RangeSlider.NumberGenerator++;

    constructor(props: IRangeSliderProps) {
        super(props);

        this.state = { value0: this.props.valueLeft.toString(), value1: this.props.valueRight.toString() };
    }

    shouldComponentUpdate(nextProps: IRangeSliderProps, nextState: { value0: string; value1: string }) {
        if (this.props.valueLeft !== nextProps.valueLeft || this.props.valueRight !== nextProps.valueRight) {
            nextState.value0 = nextProps.valueLeft.toString();
            nextState.value1 = nextProps.valueRight.toString();
        }
        return true;
    }

    onChange(value0: number, value1: number) {
        if (this.props.isInteger) {
            value0 = value0 | 0;
            value1 = value1 | 0;
        }

        if (value0 < this.props.min) {
            value0 = this.props.min;
        }

        if (value1 > this.props.max) {
            value1 = this.props.max;
        }

        if (this.props.onChange) {
            this.props.onChange(value0, value1);
        }
    }

    onLeftBlur() {
        const leftValue = document.getElementById(this.id + '-left')! as HTMLInputElement;
        const value0: number | undefined = parseFloat(leftValue.value);

        const rightValue = document.getElementById(this.id + '-right')! as HTMLInputElement;
        const value1: number | undefined = parseFloat(rightValue.value);

        if (isNaN(value0) || value0 < this.props.min || value0 > value1) {
            this.setState({ value0: this.props.min.toFixed(this.props.isInteger ? 0 : 2) });
        } else {
            this.forceUpdate();
        }
    }

    onRightBlur() {
        const leftValue = document.getElementById(this.id + '-left')! as HTMLInputElement;
        const value0: number | undefined = parseFloat(leftValue.value);

        const rightValue = document.getElementById(this.id + '-right')! as HTMLInputElement;
        const value1: number | undefined = parseFloat(rightValue.value);

        if (isNaN(value1) || value1 < value0 || value1 > this.props.max) {
            this.setState({ value1: this.props.max.toFixed(this.props.isInteger ? 0 : 2) });
        } else {
            this.forceUpdate();
        }
    }

    sync() {
        const leftValue = document.getElementById(this.id + '-left')! as HTMLInputElement;
        const rightValue = document.getElementById(this.id + '-right')! as HTMLInputElement;

        let value0: number | undefined = parseFloat(leftValue.value);
        let value1: number | undefined = parseFloat(rightValue.value);

        this.setState({ value0: leftValue.value, value1: rightValue.value });

        if (isNaN(value0)) {
            value0 = undefined;
        }

        if (isNaN(value1)) {
            value1 = undefined;
        }

        if (value0 !== undefined && value1 !== undefined && value1 < value0) {
            return;
        }

        if (value0 !== undefined && value1 !== undefined) {
            if (this.props.onChange) {
                this.props.onChange(value0, value1);
            }
        }
    }

    reset() {
        this.setState({ value0: this.props.min.toString(), value1: this.props.max.toString() });

        if (this.props.onChange) {
            this.props.onChange(this.props.min, this.props.max);
        }
    }

    render() {
        const leftValue = parseFloat(this.state.value0);
        let leftString = this.state.value0;
        const rightValue = parseFloat(this.state.value1);
        let rightString = this.state.value1;

        if (!isNaN(leftValue) && document.activeElement !== document.getElementById(this.id + '-left')) {
            leftString = leftValue.toFixed(this.props.isInteger ? 0 : 2);
        }

        if (!isNaN(rightValue) && document.activeElement !== document.getElementById(this.id + '-right')) {
            rightString = rightValue.toFixed(this.props.isInteger ? 0 : 2);
        }

        const darkMode = GlobalState.LoadBoolSettings('DarkMode', false);
        return (
            <div className={'slider-element' + (this.props.isHorizontal ? ' horizontal' : '')}>
                <div className="slider-row1">
                    <div className="slider-label">{this.props.label}</div>
                    <div className="slider-value">
                        <div className="slider-value-left">
                            <input
                                type="text"
                                autoComplete="none"
                                id={this.id + '-left'}
                                value={leftString}
                                onChange={() => this.sync()}
                                onBlur={() => this.onLeftBlur()}
                            />
                        </div>
                        <div className="slider-separator">&nbsp;-&nbsp;</div>
                        <div className="slider-value-right">
                            <input
                                type="text"
                                autoComplete="none"
                                id={this.id + '-right'}
                                value={rightString}
                                onChange={() => this.sync()}
                                onBlur={() => this.onRightBlur()}
                            />
                        </div>
                    </div>
                    <div className="slider-clear" onClick={() => this.reset()}>
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                </div>
                {!this.props.isHorizontal && (
                    <div className="slider-slider">
                        <DualSlider
                            globalState={this.props.globalState}
                            darkMode={darkMode}
                            min={this.props.min}
                            max={this.props.max}
                            defaultValue0={leftValue}
                            defaultValue1={rightValue}
                            onChange={(value0, value1) => this.onChange(value0, value1)}
                        />
                    </div>
                )}
            </div>
        );
    }
}
