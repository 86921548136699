/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { GlobalState } from '../globalState';

require('../scss/star.scss');

interface IStarPageProps {
    globalState: GlobalState;
}

export class StarPage extends React.Component<IStarPageProps, { showConsole: boolean, showDebugInfo: boolean }> {
    constructor(props: IStarPageProps) {
        super(props);
    }

    componentWillUnmount() {
        this.props.globalState.onBlurRequired.notifyObservers(false);
    }

    componentDidMount() {
        // nothing to do
    }

    render() {
        const translate = this.props.globalState.translate.bind(this.props.globalState);

        return (
            <div className="page">
                <div className="star-page">
                    <h1>
                        The Deal—Get Almost Free Access to the Urzagatherer Patreon
                    </h1>
                    <h3>The Context:</h3>
                    <div className="star-text">
                        Besides working on Urzagatherer, I'm also a writer and illustrator. I have published two illustrated novels:
                        <div className='star-table'>
                            <div className='text'><a href="https://www.amazon.com/dp/B0DSTKJG1G">The Zodiac Legion:</a></div>
                            <div className='image'><a href="https://www.amazon.com/dp/B0DSTKJG1G"><img className="ads" src={'/images/' + translate('ZodiacLegionAdsImg')} /></a></div>
                            <div className='text'><a href="https://www.amazon.com/dp/B0DSTBGLGJ">Magic Compendium:</a></div>
                            <div className='image'><a href="https://www.amazon.com/dp/B0DSTBGLGJ"><img className="ads" src={'/images/' + translate('MagicCompendiumAdsImg')} /></a></div>
                            <div className='text'><a href="https://www.amazon.com/dp/B0DX38CGNZ">Zeus Legacy:</a></div>
                            <div className='image'><a href="https://www.amazon.com/dp/B0DX38CGNZ"><img className="ads" src={'/images/' + translate('ZeusLegacyAdsImg')} /></a></div>
                        </div>
                        Reviews are the lifeblood of any self-published author, and this is where we can make a deal! :)
                    </div>
                    <p>
                        <h3>Here’s what I propose:</h3>
                        <div>
                            If you send me an email at <a href="mailto:david@catuhe.com">david@catuhe.com</a> with a screenshot of a verified purchase review for one of my books,<br></br>
                            <b>AND</b><br></br>
                            if you’re okay with receiving an email from me once a year about my new book,<br></br>
                            <b>THEN</b><br></br>
                            I will reward you with 2 years of <b><u>full Patreon access</u></b> per book reviewed.<br></br>
                            <br></br>
                            So, if you buy one book (in any format—the cheapest being Kindle at $3.99), you’ll receive <b><u>$20</u></b> worth of Patreon access.<br></br>
                            If you buy all books (and, of course, leave an honest review), you’ll get <b><u>$60</u></b> worth of Patreon access.
                            <br></br>
                            <br></br>
                            Pretty great deal, right?
                        </div>
                    </p>
                </div>
            </div>
        );
    }
}
