import * as React from 'react';
import { GlobalState } from '../globalState';
import { Button } from './button';
import { MobileTools } from '../tools/mobileTools';
import { Nullable } from '../tools/nullable';
import { LabelledTexbox } from './labelledTextbox';

interface IQueryDialogProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class QueryDialog extends React.Component<
    IQueryDialogProps,
    { isVisible: boolean; title: string; value: string; multiline: boolean; suggestions: Nullable<string[]>;  help: string}
> {
    constructor(props: IQueryDialogProps) {
        super(props);

        this.state = { isVisible: false, title: '', value: '', multiline: false, suggestions: null, help: '' };
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onQuestionRequired.add((value) => {
            this.setState({
                isVisible: true,
                title: value.title,
                value: value.value,
                multiline: value.multiline,
                suggestions: value.suggestions ?? null,
                help: value.help ?? ''
            });

            this.props.globalState.onBlurRequired.notifyObservers(true);
        });

        document.addEventListener('keydown', (evt) => {
            if (evt.keyCode === 27 && this.state.isVisible) {
                this.onConfirmation(false);
            }
            if (evt.keyCode === 13 && this.state.isVisible && document.activeElement === document.body) {
                this.onConfirmation(true);
                return;
            }
        });
    }

    onConfirmation(value: boolean) {
        if (value) {
            this.props.globalState.onQuestionAnswered.notifyObservers(this.state.value);
        } else {
            this.props.globalState.onQuestionAnswered.notifyObservers(undefined);
        }

        this.setState({ isVisible: false });

        this.props.globalState.onBlurRequired.notifyObservers(false);
    }

    componentDidUpdate() {
        if (MobileTools.IsMobile) {
            return;
        }
        if (this.state.isVisible) {
            const queryText = document.getElementById('queryText');
            queryText?.focus();
        }
    }

    render() {
        if (!this.state.isVisible) {
            return null;
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);

        return (
            <div className="query-dialog-container">
                <div className="query-dialog-background"></div>
                <div
                    className={
                        'query-dialog' +
                        (this.props.darkMode ? ' dark' : '') +
                        (this.state.multiline ? ' multiline' : '')
                    }
                >
                    <div className="query-dialog-title">{this.state.title}</div>
                    {this.state.help && (
                        <div className="query-dialog-help">{this.state.help}</div>
                    )}
                    <div className="query-dialog-data">
                        {this.state.multiline && (
                            <textarea
                                tabIndex={1}
                                id="queryText"
                                className="query-dialog-textarea"
                                value={this.state.value}
                                onChange={(evt) => this.setState({ value: evt.target.value })}
                            />
                        )}
                        {!this.state.multiline && !this.state.suggestions && (
                            <input
                                tabIndex={1}
                                id="queryText"
                                type="text"
                                autoComplete="none"
                                value={this.state.value}
                                onKeyDown={(evt) => {
                                    if (evt.keyCode === 13) {
                                        evt.preventDefault();
                                        this.onConfirmation(true);
                                    } else if (evt.keyCode === 27) {
                                        evt.preventDefault();
                                        this.onConfirmation(false);
                                    }
                                }}
                                onChange={(evt) => this.setState({ value: evt.target.value })}
                            />
                        )}
                        {!this.state.multiline && this.state.suggestions && (
                            <LabelledTexbox
                                id="queryText"
                                label=""
                                onlyInput={true}
                                globalState={this.props.globalState}
                                onChange={(value) => {
                                    this.setState({ value: value });
                                }}
                                minCharacterForSuggestion={0}
                                suggestions={this.state.suggestions}
                                value={this.state.value}
                            />
                        )}
                    </div>
                    <div className="query-dialog-accept">
                        <Button
                            tabIndex={2}
                            className="query-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(true)}
                        >
                            {translate('OK')}
                        </Button>
                    </div>
                    <div className="query-dialog-cancel">
                        <Button
                            tabIndex={3}
                            className="query-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(false)}
                        >
                            {translate('Cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
