import * as React from 'react';
import { GlobalState } from '../globalState';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface ILabelledListboxProps {
    globalState: GlobalState;
    label: string;
    value?: string;
    onChange?: (text: string) => void;
    mapFunction?: () => any[];
    options?: { value: string; label: string }[];
    className?: string;
}

export class LabelledListbox extends React.Component<ILabelledListboxProps, { value?: string }> {
    constructor(props: ILabelledListboxProps) {
        super(props);

        this.state = { value: this.props.value };
    }

    reset() {
        this.setState({ value: '' });

        if (this.props.onChange) {
            this.props.onChange('');
        }
    }

    render() {
        return (
            <div className={'listbox-element ' + this.props.className}>
                <div className="listbox-element-label">{this.props.label}</div>
                <select
                    className="listbox-list"
                    value={this.props.value}
                    onChange={(evt) => {
                        this.setState({ value: evt.target.value });
                        if (this.props.onChange) {
                            this.props.onChange(evt.target.value);
                        }
                    }}
                >
                    {this.props.mapFunction && this.props.mapFunction()}
                    {this.props.options &&
                        this.props.options.map((o, i) => {
                            return (
                                <option key={i} value={o.value}>
                                    {o.label}
                                </option>
                            );
                        })}
                </select>
                <div className="listbox-element-clear" onClick={() => this.reset()}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            </div>
        );
    }
}
