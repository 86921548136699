import { ICard } from '../../entities/ICard';

export class TCGPlayer {
    public static GetCardLink(card: ICard): string {
        if (!card.tcgPlayerId) {
            const cardName = card.prepareCardNameForTCGPlayer(true);
            // eslint-disable-next-line max-len
            return `https://shop.tcgplayer.com/productcatalog/product/show?ProductName=${cardName}&partner=URZGTHR&utm_campaign=affiliate&utm_medium=URZGTHR&utm_source=URZGTHR`;
        }
        // eslint-disable-next-line max-len
        return `https://shop.tcgplayer.com/product/productsearch?id=${card.tcgPlayerId}&partner=URZGTHR&utm_campaign=affiliate&utm_medium=URZGTHR&utm_source=URZGTHR`;
    }
}
