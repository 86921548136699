import * as Dropbox from 'dropbox';
import { FetchResponse } from './syncProvider';
import { Collection } from '../entities/collection';
import { GlobalState } from '../globalState';
import { faDropbox } from '@fortawesome/fontawesome-free-brands';
import { Icon } from '@fortawesome/fontawesome-svg-core';

export class DropBoxSyncProvider {
    private static ClientID = 'jv02f10kago6snz';
    private accessToken: string;

    public name = 'Dropbox';
    public icon = faDropbox as Icon;

    public disconnect() {
        this.accessToken = '';
        Collection.GlobalState.storeSettings('dropbox_token', '');
    }

    public loginAsync(): Promise<void> {
        return new Promise(async (resolve) => {
            this.accessToken = GlobalState.LoadSettings('dropbox_token');

            if (!this.accessToken) {
                const dropboxAuth = new Dropbox.DropboxAuth({
                    clientId: DropBoxSyncProvider.ClientID
                });
                const url =
                    window.location.hostname !== 'localhost' ? 'https://www.urzagatherer.app' : 'http://localhost:1339';
                const authUrl = await dropboxAuth.getAuthenticationUrl(url + '/dropbox.html') as string;

                location.href = authUrl;
                return;
            }

            resolve();
        });
    }

    public saveDataAsync(filename: string, data: string, _onTokenDown: () => void): Promise<void> {
        return new Promise((resolve, reject) => {
            const dbx = new Dropbox.Dropbox({ accessToken: this.accessToken });
            dbx.filesUpload({ path: '/' + filename, contents: data, mode: 'overwrite' as any })
                .then((_response) => {
                    resolve();
                })
                .catch((error) => {
                    if (error.status === 401 || error.status === 400) {
                        _onTokenDown();
                        this.disconnect();
                        resolve();
                        return;
                    }
                    reject(error.message);
                });
        });
    }

    public fetchFileAsync(filename: string, _onTokenDown: () => void): Promise<FetchResponse> {
        return new Promise((resolve) => {
            const dbx = new Dropbox.Dropbox({ accessToken: this.accessToken });
            dbx.filesDownload({ path: '/' + filename })
                .then((data: any) => {
                    const result = new FetchResponse();
                    const blob = data.result.fileBlob;
                    const reader = new FileReader();

                    reader.onload = () => {
                        result.dateModified = new Date(data.result.server_modified);
                        result.content = reader.result as string;
                        resolve(result);
                    };

                    reader.readAsText(blob);
                })
                .catch((_error) => {
                    if (_error.status === 401 || _error.status === 400) {
                        _onTokenDown();
                        this.disconnect();
                    }
                    const result = new FetchResponse();
                    result.error = true;
                    resolve(result);
                });
        });
    }

    public fetchUrl(url: string, response: FetchResponse): Promise<string> {
        return Promise.resolve(response.content);
    }
}
