import { Collection } from './collection';

export class List {
    public Name: string;
    public Tags: string[];
    public Notes: string;
    public ColorCode: string;
    public UniqueID: string;
    public backgroundUrl: string;

    public static GetCards(list: List) {
        if (!list.Tags || list.Tags.length === 0) {
            return Collection.Cards;
        }
        return Collection.Cards.filter((card) => {
            if (!card.tags || card.tags.length < list.Tags.length) {
                return false;
            }
            for (const tag of list.Tags) {
                if (card.tags.indexOf(tag) === -1) {
                    return false;
                }
            }

            return true;
        });
    }
}
