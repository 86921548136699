import * as React from 'react';
import { GlobalState } from 'globalState';

export interface IStatisticsLineProps {
    title: string;
    value: number;
    total: number;
    globalState: GlobalState;
    color?: string;
    logo?: string;
}

export class StatisticsLine extends React.Component<IStatisticsLineProps, { tag: any }> {
    constructor(props: IStatisticsLineProps) {
        super(props);
    }

    render() {
        let percentage = (this.props.value * 100) / this.props.total;

        if (!this.props.total) {
            percentage = 0;
        }

        const style = {
            width: percentage + '%',
            background: this.props.color || ''
        };

        return (
            <div className={'statistic-line' + (this.props.total ? '' : ' dismiss')}>
                <div className="statistic-line-percentage">{percentage.toFixed(1) + '%'}</div>
                <div className="statistic-line-title">{this.props.title}</div>
                <div className="statistic-line-details">{this.props.value + ' / ' + this.props.total}</div>
                <div className="statistic-line-progress-back">
                    <div className="statistic-line-progress" style={style} />
                </div>
                {this.props.logo && <img src={this.props.logo} className="statistic-line-image" />}
            </div>
        );
    }
}
