import { BinaryReader } from '../tools/binaryReader';
import { Block } from './block';
import { Card } from './card';
import { Collection } from './collection';
import { ICard } from './ICard';
import { GlobalState } from '../globalState';
import { StringHelpers } from '../tools/stringHelpers';
import { Nullable } from '../tools/nullable';

export class Set {
    public block: Block;
    public id: number;
    public name: string;
    public mkmName: string;
    public sourceName: string;
    public month: number;
    public year: number;
    public code: string;
    public orderInBlock: number;
    public foreignOnly: boolean;
    public logoPath: string;
    public firstExtra: number;
    public cards: Card[];
    public pricesWereUpdated: boolean;
    public extras: Set;

    public get sortedCards() {
        return this.cards.sort((a, b) => a.name.localeCompare(b.name));
    }

    public get additionalTitle() {
        if (this.block.name === 'Secret Lair' && this.id >= 762) {
            return this.name;
        }

        return '';
    }

    public get logo(): string {
        if (this.block.name === 'Secret Lair' && this.id >= 785) {
            return 'images/logos/Secret Lair Drop Series.png';
        }

        return `images/logos/${this.name
            .replace(':', '_')
            .replace(
                /\sTokens|\sPromos|\sCollectors|\sExtras|\sArt Series|\sArt Series Collectors|\sExpeditions|\sOversized|\sFront Cards|\sJumpstart Front Cards|\sSubstitute Cards|\sMinigames/,
                ''
            )}.png`;
    }

    public get icon(): string {
        if (this.block.name === 'Secret Lair' && this.id >= 785) {
            return 'images/icons/Secret Lair Drop Series.png';
        }
        return `images/icons/${this.name
            .replace(':', '_')
            .replace(
                /\sTokens|\sPromos|\sCollectors|\sExtras|\sArt Series|\sArt Series Collectors|\sExpeditions|\sOversized|\sFront Cards|\sJumpstart Front Cards|\sSubstitute Cards|\sMinigames/,
                ''
            )}.png`;
    }

    public get totalPrice(): number {
        let value = 0;

        this.cards.forEach((c) => {
            if (!c.isBackFace) {
                value += c.price;
            }
        });

        return value;
    }
    public get totalCardCount(): number {
        let total = 0;

        this.cards.forEach((c) => {
            if (!c.isBackFace) {
                total++;
            }
        });

        return total;
    }

    public get inCollectionCardCount(): number {
        let inCollection = 0;

        this.cards.forEach((c) => {
            if (c.count && !c.isBackFace) {
                inCollection++;
            }
        });

        return inCollection;
    }

    public _isArtSeries: Nullable<boolean> = null;
    public get isArtSeries(): boolean {
        if (this._isArtSeries === null) {
            this._isArtSeries = this.name.indexOf('Art Series') > -1;
        }

        return !!this._isArtSeries;
    }

    public _comments: string = '';
    public get comments(): string {
        return this._comments;
    }

    public set comments(value: string) {
        this._comments = value;

        if (Collection.UserStore) {
            if (!Collection.UserStore.SetComments) {
                Collection.UserStore.SetComments = {};
            }
            Collection.UserStore.SetComments[this.id] = value;
        }
    }

    public inCollectionCardCountPlayset(colorId: number, playsetCount: number): number {
        let inCollection = 0;

        this.cards.forEach((c) => {
            if (c.isBackFace) {
                return;
            }

            if (colorId && c.colorId !== colorId) {
                return;
            }

            inCollection += Math.min(playsetCount, c.count);
        });

        return inCollection;
    }

    public cardCountPlayset(colorId: number, playsetCount: number): number {
        let value = 0;

        this.cards.forEach((c) => {
            if (c.isBackFace) {
                return;
            }

            if (colorId && c.colorId !== colorId) {
                return;
            }

            value += playsetCount;
        });

        return value;
    }

    public get isComplete(): boolean {
        return this.inCollectionCardCount === this.totalCardCount;
    }

    public get completionPercentage() {
        return Math.round((this.inCollectionCardCount / this.totalCardCount) * 100);
    }

    public getCardByNumber(value: number) {
        const candidates = this.cards.filter((c) => c.number === value);

        if (!candidates || candidates.length === 0) {
            return null;
        }

        return candidates[0];
    }

    public prepareSetNameForMKM(replaceColon = false): string {
        if (this.mkmName) {
            return this.mkmName;
        }

        let name = this.name;
        if (replaceColon) {
            name = name.replace(':', '');
        }

        name = name.replace(' Extras', ': Extras').replace(' Promos', ': Promos').replace(' Tokens', ': Extras').replace(' Collectors', ': Extras');

        return name;
    }

    public prepareSetNameForTCGPlayer(card: ICard) {
        const name = this.name.replace(': City of Guilds', '');

        if (name.indexOf('Kaladesh Inventions') !== -1) {
            return 'Masterpiece Series Kaladesh Inventions';
        }

        if (name.indexOf('2019 Gift Pack') !== -1) {
            return 'Gift Boxes And Promos';
        }

        if (name.indexOf('Alpha') !== -1) {
            return 'Alpha edition';
        }

        if (name.indexOf('Beta') !== -1) {
            return 'Beta edition';
        }

        if (name.indexOf('Seventh') !== -1) {
            return '7th edition';
        }

        if (name.indexOf('Eight') !== -1) {
            return '8th edition';
        }

        if (name.indexOf('Ninth') !== -1) {
            return '9th edition';
        }

        if (name.indexOf('Tenth') !== -1) {
            return '10th edition';
        }

        if (name.indexOf('Magic 2010') !== -1) {
            return 'magic 2010 (m10)';
        }

        if (name.indexOf('Magic 2011') !== -1) {
            return 'magic 2011 (m11)';
        }

        if (name.indexOf('Magic 2012') !== -1) {
            return 'magic 2012 m12';
        }

        if (name.indexOf('Magic 2013') !== -1) {
            return 'magic 2013 (m13)';
        }

        if (name.indexOf('Magic 2014') !== -1) {
            return 'magic 2014 (m14)';
        }

        if (name.indexOf('Magic 2015') !== -1) {
            return 'magic 2015 (m15)';
        }

        if (name.indexOf('Timeshifted') !== -1) {
            return 'Timeshifted';
        }

        if (name.indexOf('Planechase 2012') !== -1) {
            return 'Planechase 2012';
        }

        if (name.indexOf('Duel Decks: Knights vs. Dragons') !== -1) {
            return 'Duel Decks: Knights vs Dragons';
        }

        if (name.indexOf('Duel Decks: Kiora vs. Elspeth') !== -1) {
            return 'Duel Decks: Elspeth vs. Kiora';
        }

        if (name.indexOf('Commander 2013') !== -1) {
            return 'commander 2013';
        }

        if (name.indexOf('Commander 2014') !== -1) {
            return 'commander 2014';
        }

        if (name.indexOf('Arena') !== -1) {
            return 'Arena Promos';
        }

        if (name.indexOf('Media') !== -1) {
            return 'Media Promos';
        }

        if (name.indexOf('Magic Player') !== -1) {
            return 'magic player rewards';
        }

        if (name.indexOf('Friday Night') !== -1) {
            return 'fnm promos';
        }

        if (name.indexOf('Modern Event Deck') !== -1) {
            return 'magic modern event deck';
        }

        if (name.indexOf('Modern Masters 2015') !== -1) {
            return 'modern masters 2015';
        }

        if (name.indexOf('Modern Masters 2017') !== -1) {
            return 'modern masters 2017';
        }

        if (name.indexOf('Modern Event Deck') !== -1) {
            return 'magic modern event deck';
        }

        if (name.indexOf('Judge') !== -1) {
            return 'judge promos';
        }

        if (name.indexOf('Ultimate Box Topper') !== -1) {
            return 'ultimate masters box toppers';
        }

        if (name === 'Game Day Promos') {
            return 'game day and store championship promos';
        }

        if (name === 'Amonkhet Invocations') {
            return 'masterpiece series amonkhet invocations';
        }

        if (name === 'Gateway Promos' || name.indexOf('Wizards Play Network') !== -1) {
            return 'wpn and gateway promos';
        }

        if (name === 'Collectors’ Edition') {
            return "collector's edition";
        }

        if (card.nameEn === 'Mirrored Depths' && name.indexOf('Planechase') !== -1) {
            return 'planeshase';
        }

        if (name.indexOf(' Promos') !== -1) {
            return 'prerelease cards';
        }

        return name;
    }

    private static _ProcessExpansion(expansion: Set) {
        expansion.cards.forEach((c) => {
            if (c.isBackFace && !c.otherCard) {
                const others = expansion.cards.filter(
                    (other) =>
                        other !== c &&
                        other.number === c.number &&
                        (!c.scryfallNumber || !other.scryfallNumber || other.scryfallNumber === c.scryfallNumber)
                );

                if (others.length === 1) {
                    c.otherCard = others[0];
                    others[0].otherCard = c;
                } else if (others.length > 1) {
                    const list = [c];
                    list.push(...others);

                    for (let index = 0; index < list.length; index++) {
                        const a = list[index];
                        const b = list[index < list.length - 1 ? index + 1 : 0];

                        a.otherCard = b;
                        a.otherCards = list.slice(0).splice(index, 1);
                        Collection.LinkedCardsIndex[a.id] = b.id;
                    }

                    return;
                }

                if (c.otherCard === undefined) {
                    c.isBackFace = false;
                } else {
                    Collection.LinkedCardsIndex[c.id] = c.otherCard.id;
                    Collection.LinkedCardsIndex[c.otherCard.id] = c.id;
                }
            }
        });
    }

    public static Deserialize(reader: BinaryReader, ignoreBlock: boolean): Set {
        const expansion = new Set();

        expansion.id = reader.readInt32();
        expansion.name = reader.readString();
        expansion.mkmName = reader.readString();
        expansion.sourceName = expansion.name;
        expansion.month = reader.readInt32();
        expansion.year = reader.readInt32();
        expansion.code = reader.readString();
        expansion.orderInBlock = reader.readInt32();
        expansion.foreignOnly = reader.readBoolean();
        expansion.logoPath = reader.readString();
        expansion.firstExtra = reader.readInt32();
        reader.readBoolean(); // Has high quality images

        const excluded = GlobalState.LoadSettings('DisabledSetNames', '').split('*');

        const ignoreForeignSet = GlobalState.LoadBoolSettings('IgnoreForeignSets', false);
        const ignoreCommons = GlobalState.LoadBoolSettings('IgnoreCommonCards', false);
        const ignoreUncommons = GlobalState.LoadBoolSettings('IgnoreUncommonCards', false);
        const ignoreRares = GlobalState.LoadBoolSettings('IgnoreRareCards', false);
        const ignoreMythic = GlobalState.LoadBoolSettings('IgnoreMythicCards', false);
        const ignoreArtCards = GlobalState.LoadBoolSettings('IgnoreArtCards', false);
        const ignoreSubstituteCards = GlobalState.LoadBoolSettings('IgnoreSubstituteCards', false);
        const ignoreOversizedCards = GlobalState.LoadBoolSettings('IgnoreOversizedCards', false);
        const ignoreMinigames = GlobalState.LoadBoolSettings('IgnoreMinigames', false);
        const ignoreSerialized = GlobalState.LoadBoolSettings('IgnoreSerialized', false);

        const ignore =
            (ignoreArtCards && StringHelpers.EndsWith(expansion.name, 'Art Series')) ||
            (ignoreSubstituteCards && StringHelpers.EndsWith(expansion.name, 'Substitute Cards')) ||
            (ignoreOversizedCards && StringHelpers.EndsWith(expansion.name, 'Oversized')) ||
            (ignoreMinigames && StringHelpers.EndsWith(expansion.name, 'Minigames')) ||
            (ignoreForeignSet && expansion.foreignOnly) ||
            ignoreBlock ||
            excluded.indexOf(expansion.name) !== -1;
        const separateExtras = GlobalState.LoadBoolSettings('ShowExtrasAsSeparate', false) && expansion.firstExtra > 0;
        const extras: Card[] = [];

        if (ignore) {
            console.log(`Skipping expansion ${expansion.name}`);
        }

        const cardsCount = reader.readInt32();
        const excludedCardIds = Collection.ExcludedCardIds;
        expansion.cards = new Array<Card>();
        for (let index = 0; index < cardsCount; index++) {
            const card = Card.Deserialize(reader, (card) => {
                return (
                    ignore ||
                    (excludedCardIds.indexOf(card.id) !== -1) ||
                    (ignoreCommons && card.rarityId === 1) ||
                    (ignoreUncommons && card.rarityId === 2) ||
                    (ignoreRares && card.rarityId === 3) ||
                    (ignoreMythic && card.rarityId === 4) ||
                    (ignoreSerialized && card.isSerialized) ||
                    (separateExtras &&
                        card.number >= expansion.firstExtra &&
                        excluded.indexOf(expansion.name + ' Extras') !== -1)
                );
            });

            if (excludedCardIds.indexOf(card.id) !== -1) {
                Collection.ExcludedCardNames[card.id] =  `${card.name} (${expansion.name})`;
                continue;
            }

            if (ignoreCommons && card.rarityId === 1) {
                continue;
            }
            if (ignoreUncommons && card.rarityId === 2) {
                continue;
            }
            if (ignoreRares && card.rarityId === 3) {
                continue;
            }
            if (ignoreMythic && card.rarityId === 4) {
                continue;
            }
            if (ignoreSerialized && card.isSerialized) {
                continue;
            }

            if (separateExtras && card.number >= expansion.firstExtra) {
                extras.push(card);
            } else {
                card.set = expansion;
                expansion.cards.push(card);
            }
        }

        if (!ignore) {
            Collection.Sets.push(expansion);
            this._ProcessExpansion(expansion);

            if (separateExtras && excluded.indexOf(expansion.name + ' Extras') === -1) {
                const newExpansion = new Set();

                newExpansion.id = expansion.id + 0.5;
                newExpansion.name = expansion.name + ' Extras';
                newExpansion.mkmName = expansion.name;
                newExpansion.sourceName = expansion.name;
                newExpansion.month = expansion.month;
                newExpansion.year = expansion.year;
                newExpansion.code = expansion.code;
                newExpansion.orderInBlock = expansion.orderInBlock + 0.5;
                newExpansion.foreignOnly = expansion.foreignOnly;
                newExpansion.logoPath = expansion.logoPath;
                newExpansion.cards = [];

                for (const card of extras) {
                    card.set = newExpansion;
                    newExpansion.cards.push(card);
                }

                expansion.extras = newExpansion;

                Collection.Sets.push(newExpansion);
                this._ProcessExpansion(newExpansion);
            }
        }

        return expansion;
    }
}
