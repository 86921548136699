import * as React from 'react';
import { GlobalState } from '../globalState';
import { Collection } from '../entities/collection';
import { DeckCard } from '../controls/cards/deckCard';
import { Nullable } from '../tools/nullable';
import { Observer } from '../tools/observable';
import { DeckGroup } from '../entities/deckGroup';
import { Deck } from '../entities/deck';
import { DeckTools } from '../tools/deckTools';
import { GUID } from '../tools/guid';
import { LabelledTexbox } from '../controls/labelledTextbox';
import { GroupData, GroupedList } from '../controls/groupedList';

require('../scss/decksPage.scss');

interface IDecksPageProps {
    globalState: GlobalState;
}

export class DecksPage extends React.Component<IDecksPageProps, { filter: string }> {
    private scroller: HTMLElement;
    private onColorsChangedObserver: Nullable<Observer<void>>;
    private onImportObserver: Nullable<Observer<{ extension: string; filename: string; data: string }>>;
    private onCustomButtonClickedObserver: Nullable<Observer<void>>;
    private onWindowSizeChangedObserver: Nullable<Observer<number>>;
    private currentDeck: Nullable<Deck>;
    private currentDeckGroup: Nullable<DeckGroup>;

    constructor(props: IDecksPageProps) {
        super(props);

        this.state = {
            filter:
                this.props.globalState.pageCustomData && this.props.globalState.pageCustomData.filter
                    ? this.props.globalState.pageCustomData.filter
                    : ''
        };
    }

    updateDeckFromUI(deck: Deck, value: { name: string; group: string; color: string }) {
        deck.ColorCode = value.color;
        deck.Name = value.name;

        const parent = Collection.DeckGroups.filter((dg) => dg.Name.toLowerCase() === value.group.toLowerCase());

        if (parent && parent.length === 1) {
            parent[0].Decks.push(deck);
        } else {
            const newParent = new DeckGroup();
            newParent.Decks = [deck];
            newParent.Name = value.group;

            Collection.DeckGroups.push(newParent);
        }
        this.forceUpdate();

        Collection.RegisterSave();
    }

    UNSAFE_componentWillMount() {
        this.onColorsChangedObserver = this.props.globalState.onColorsChanged.add(() => {
            this.forceUpdate();
        });

        this.onImportObserver = this.props.globalState.onImport.add((data) => {
            DeckTools.ImportAsync(data.data, data.filename, data.extension, this.props.globalState).then((deck) => {
                if (!deck) {
                    return;
                }
                Collection.RegisterSave();
                this.forceUpdate();
            });
        });

        this.props.globalState.onCustomButtonRequired.notifyObservers(
            window.innerWidth > 768 ? this.props.globalState.translate('New deck') : '+'
        );
        this.onCustomButtonClickedObserver = this.props.globalState.onCustomButtonClicked.add(() => {
            this.props.globalState.onDeckValidated.addOnce((value) => {
                if (!value) {
                    return;
                }

                let deck: Deck;

                if (!this.currentDeck) {
                    deck = new Deck();
                    deck.UniqueID = GUID.Generate();
                    Collection.Decks.push(deck);
                    deck.Cards = [];
                    deck.Reserve = [];
                    deck.Sideboard = [];
                } else {
                    deck = this.currentDeck;

                    if (this.currentDeckGroup) {
                        let index = this.currentDeckGroup.Decks.indexOf(deck);

                        if (index > -1) {
                            this.currentDeckGroup.Decks.splice(index, 1);

                            if (this.currentDeckGroup.Decks.length === 0) {
                                index = Collection.DeckGroups.indexOf(this.currentDeckGroup);
                                Collection.DeckGroups.splice(index, 1);
                            }
                        }
                    }
                }

                this.updateDeckFromUI(deck, value);
            });

            this.props.globalState.onDeckRequired.notifyObservers({
                name: '',
                group: ''
            });
            this.currentDeck = null;
            this.currentDeckGroup = null;
        });

        this.onWindowSizeChangedObserver = this.props.globalState.onWindowSizeChanged.add((width) => {
            this.props.globalState.onCustomButtonRequired.notifyObservers(
                width > 768 ? this.props.globalState.translate('New deck') : '+'
            );
        });
    }

    componentWillUnmount() {
        this.props.globalState.onColorsChanged.remove(this.onColorsChangedObserver);
        this.props.globalState.onCustomButtonClicked.remove(this.onCustomButtonClickedObserver);
        this.props.globalState.onWindowSizeChanged.remove(this.onWindowSizeChangedObserver);
        this.props.globalState.onImport.remove(this.onImportObserver);
    }

    onScroll() {
        this.props.globalState.pageCustomData.scroll = this.scroller.scrollTop;
    }

    componentDidMount() {
        this.scroller = document.getElementById('scrollElement')!;

        const customData = this.props.globalState.pageCustomData;
        const scrollPosition = customData && customData.scroll ? customData.scroll : 0;
        this.scroller.scrollTop = scrollPosition;
    }

    editDeck(deck: Deck, deckGroup: DeckGroup) {
        this.props.globalState.onDeckValidated.addOnce((value) => {
            if (!value) {
                return;
            }

            if (this.currentDeckGroup) {
                let index = this.currentDeckGroup.Decks.indexOf(deck);

                if (index > -1) {
                    this.currentDeckGroup.Decks.splice(index, 1);

                    if (this.currentDeckGroup.Decks.length === 0) {
                        index = Collection.DeckGroups.indexOf(this.currentDeckGroup);
                        Collection.DeckGroups.splice(index, 1);
                    }
                }
            }

            this.updateDeckFromUI(deck, value);
        });

        this.props.globalState.onDeckRequired.notifyObservers({
            name: deck.Name,
            group: deckGroup.Name,
            color: deck.ColorCode
        });
        this.currentDeck = deck;
        this.currentDeckGroup = deckGroup;
    }

    blurFilter(evt: React.KeyboardEvent<HTMLInputElement>) {
        if (evt.which === 13) {
            (evt.nativeEvent.srcElement! as HTMLInputElement).blur();
        }
    }

    render() {
        const translate = this.props.globalState.translate.bind(this.props.globalState);
        const deckGroups = Collection.DeckGroups.sort((a, b) => (a.Name || '').localeCompare(b.Name || ''));

        const suggestionForNames = Collection.Cards.map((c) => c.nameEn);

        if (Collection.UseFrenchForTexts) {
            for (const cardName of Collection.Cards.map((c) => c.nameFr)) {
                if (!cardName) {
                    continue;
                }
                suggestionForNames.push(cardName);
            }
        }

        const groups: GroupData[] = [];

        for (const deckGroup of deckGroups) {
            const group = new GroupData();
            group.setTitle = (title: string) => {
                deckGroup.Name = title;
            };
            group.host = deckGroup;
            group.getTitle = () => `${deckGroup.Name || ''} (${deckGroup.Decks.length})`;
            group.name = deckGroup.Name;

            const decks = deckGroup.Decks.filter((d) => {
                const cards = d.Cards;
                const selectedColors = this.props.globalState.selectedColors;

                if (this.state.filter) {
                    if (!cards.length) {
                        return false;
                    }

                    const filter = this.state.filter.toLowerCase();

                    if (!cards.some((c) => Collection.CardsIndex[c.CardID].nameForSearch.indexOf(filter) !== -1)) {
                        return false;
                    }
                }

                if (cards.length === 0 && selectedColors.length === 0) {
                    return true;
                }

                return cards.some((c) => {
                    return (
                        selectedColors.length === 0 ||
                        selectedColors.indexOf(Collection.CardsIndex[c.CardID].colorId) !== -1
                    );
                });
            }).sort((a, b) => a.Name.localeCompare(b.Name));

            if (decks.length === 0) {
                continue;
            }

            group.items = decks;

            groups.push(group);
        }

        return (
            <div className="page">
                <div className="decks-page">
                    <div className="decks-page-card-filter">
                        <LabelledTexbox
                            label={translate('Name')}
                            globalState={this.props.globalState}
                            onKeyPress={(evt) => this.blurFilter(evt)}
                            onChange={(value) => {
                                this.setState({ filter: value });
                            }}
                            suggestions={suggestionForNames}
                            value={this.state.filter}
                            placeholder={translate('AnyWord')}
                        />
                    </div>

                    <div className="decks-page-list" id="scrollElement" onScroll={() => this.onScroll()}>
                        <GroupedList
                            canEditGroupTitles={true}
                            editGroupTitleLabel={this.props.globalState.translate('DeckGroupName')}
                            globalState={this.props.globalState}
                            groups={groups}
                            onRenderGroupItem={(group, item, index) => {
                                const deck = item as Deck;
                                const deckGroup = group.host as DeckGroup;
                                return (
                                <DeckCard
                                    key={deck.Name + deckGroup.Name + index}
                                    globalState={this.props.globalState}
                                    onDelete={() => this.forceUpdate()}
                                    onClone={() => this.forceUpdate()}
                                    onEdit={() => this.editDeck(deck, deckGroup)}
                                    deck={deck}
                                    deckGroup={deckGroup}
                                />
                            );
                            }}
                            storeKey={'DeckGroup'}
                        />
                    </div>
                    <div className="decks-page-popup-host"></div>
                </div>
            </div>
        );
    }
}
