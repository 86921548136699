import { ICard } from './ICard';

export class ReservedList {
    static List = [
        'Ancestral Recall',
        'Badlands',
        'Bayou',
        'Black Lotus',
        'Blaze of Glory',
        'Braingeyser',
        'Chaos Orb',
        'Contract from Below',
        'Copy Artifact',
        'Cyclopean Tomb',
        'Darkpact',
        'Demonic Attorney',
        'Demonic Hordes',
        'Farmstead',
        'Fastbond',
        'Forcefield',
        'Fork',
        'Gauntlet of Might',
        'Granite Gargoyle',
        'Illusionary Mask',
        'Kudzu',
        'Lich',
        'Mox Emerald',
        'Mox Jet',
        'Mox Pearl',
        'Mox Ruby',
        'Mox Sapphire',
        'Natural Selection',
        'Plateau',
        'Raging River',
        'Roc of Kher Ridges',
        'Rock Hydra',
        'Savannah',
        'Scrubland',
        'Sedge Troll',
        'Taiga',
        'Time Vault',
        'Time Walk',
        'Timetwister',
        'Tropical Island',
        'Tundra',
        'Two-Headed Giant of Foriys',
        'Underground Sea',
        'Vesuvan Doppelganger',
        'Veteran Bodyguard',
        'Volcanic Island',
        'Wheel of Fortune',
        'Word of Command',
        'Ali from Cairo',
        'Bazaar of Baghdad',
        'City in a Bottle',
        'Diamond Valley',
        'Drop of Honey',
        'Elephant Graveyard',
        'Guardian Beast',
        'Ifh-Bíff Efreet',
        'Island of Wak-Wak',
        'Jihad',
        'Juzám Djinn',
        'Khabál Ghoul',
        'King Suleiman',
        'Library of Alexandria',
        'Merchant Ship',
        'Old Man of the Sea',
        'Pyramids',
        "Ring of Ma'rûf",
        'Sandals of Abdallah',
        'Serendib Djinn',
        'Shahrazad',
        'Singing Tree',
        'Argivian Archaeologist',
        'Candelabra of Tawnos',
        'Citanul Druid',
        'Damping Field',
        "Gaea's Avenger",
        'Gate to Phyrexia',
        'Golgothian Sylex',
        'Haunting Wind',
        'Martyrs of Korlis',
        'Mightstone',
        "Mishra's Workshop",
        'Power Artifact',
        'Powerleech',
        'Su-Chi',
        "Tawnos's Coffin",
        'Transmute Artifact',
        "Urza's Miter",
        'Weakstone',
        'The Abyss',
        'Acid Rain',
        'Adun Oakenshield',
        "Al-abara's Carpet",
        "Alchor's Tomb",
        "All Hallow's Eve",
        'Angus Mackenzie',
        'Bartel Runeaxe',
        'Boris Devilboon',
        'Caverns of Despair',
        'Chains of Mephistopheles',
        'Cleanse',
        'Disharmony',
        'Divine Intervention',
        'Elder Spawn',
        'Eureka',
        'Falling Star',
        'Field of Dreams',
        'Firestorm Phoenix',
        'Forethought Amulet',
        'Gosta Dirk',
        'Gravity Sphere',
        'Gwendlyn Di Corci',
        'Halfdane',
        'Hazezon Tamar',
        'Hellfire',
        'Imprison',
        'In the Eye of Chaos',
        'Infinite Authority',
        'Invoke Prejudice',
        'Jacques le Vert',
        'Jovial Evil',
        'Knowledge Vault',
        'Kobold Overlord',
        'Lady Caleria',
        'Lady Evangela',
        'Land Equilibrium',
        'Life Matrix',
        'Lifeblood',
        'Living Plane',
        'Livonya Silone',
        'Mana Matrix',
        'Master of the Hunt',
        'Mirror Universe',
        'Moat',
        'Mold Demon',
        'Nether Void',
        'North Star',
        'Nova Pentacle',
        'Pixie Queen',
        'Planar Gate',
        'Quarum Trench Gnomes',
        'Ragnar',
        'Ramses Overdark',
        'Rapid Fire',
        'Rasputin Dreamweaver',
        'Reverberation',
        'Ring of Immortals',
        'Rohgahh of Kher Keep',
        'Spinal Villain',
        'Spiritual Sanctuary',
        'Storm World',
        'Sword of the Ages',
        'The Tabernacle at Pendrell Vale',
        'Telekinesis',
        'Tetsuo Umezawa',
        'Thunder Spirit',
        'Tuknir Deathlock',
        'Typhoon',
        'Ur-Drago',
        'Willow Satyr',
        'Wood Elemental',
        'City of Shadows',
        'Cleansing',
        'Eternal Flame',
        'Exorcist',
        "Frankenstein's Monster",
        'Goblin Wizard',
        'Grave Robbers',
        'Hidden Path',
        'Knights of Thorn',
        'Lurker',
        'Mana Vortex',
        "Martyr's Cry",
        'Nameless Race',
        'Niall Silvain',
        'Preacher',
        'Psychic Allergy',
        'Scarwood Bandits',
        'Season of the Witch',
        "Sorrow's Path",
        'Stone Calendar',
        'Tracker',
        'Worms of the Earth',
        'Wormwood Treefolk',
        'Aeolipile',
        'Balm of Restoration',
        'Conch Horn',
        "Delif's Cube",
        'Draconian Cylix',
        'Dwarven Armorer',
        'Ebon Praetor',
        'Elven Lyre',
        'Elvish Farmer',
        'Fungal Bloom',
        'Goblin Flotilla',
        'Hand of Justice',
        'Homarid Shaman',
        'Icatian Lieutenant',
        'Icatian Skirmishers',
        'Implements of Sacrifice',
        'Rainbow Vale',
        'Ring of Renewal',
        'River Merfolk',
        'Spirit Shield',
        "Thelon's Curse",
        'Thelonite Monk',
        'Thrull Champion',
        "Tourach's Gate",
        'Vodalian Knights',
        'Vodalian War Machine',
        'Zelyon Sword',
        'Aegis of the Meek',
        'Altar of Bone',
        'Amulet of Quoz',
        'Balduvian Hydra',
        'Blizzard',
        'Brand of Ill Omen',
        'Call to Arms',
        'Chromatic Armor',
        'Earthlink',
        'Energy Storm',
        'Flow of Maggots',
        'Formation',
        'Fyndhorn Pollen',
        'General Jarkeld',
        'Glacial Crevasses',
        'Gravebind',
        'Halls of Mist',
        'Hot Springs',
        'Ice Cauldron',
        'Illusionary Presence',
        'Illusions of Grandeur',
        'Infernal Denizen',
        "Jester's Mask",
        'Kjeldoran Knight',
        'Kjeldoran Phalanx',
        'Land Cap',
        'Lava Tubes',
        'Lightning Blow',
        'Márton Stromgald',
        'Mercenaries',
        'Mesmeric Trance',
        'Minion of Tevesh Szat',
        'Mountain Titan',
        'Mudslide',
        'Musician',
        'Mystic Might',
        'Polar Kraken',
        'Reality Twist',
        'River Delta',
        'Ritual of Subdual',
        'Skeleton Ship',
        'Snowblind',
        'Soldevi Golem',
        'Spoils of Evil',
        'Spoils of War',
        'Storm Spirit',
        'Timberline Ridge',
        'Trailblazer',
        'Veldt',
        "Winter's Chill",
        'An-Zerrin Ruins',
        'Anaba Ancestor',
        'Anaba Spirit Crafter',
        'Apocalypse Chime',
        'Autumn Willow',
        'Aysen Crusader',
        'Aysen Highway',
        "Baki's Curse",
        'Baron Sengir',
        'Beast Walkers',
        'Black Carriage',
        'Chain Stasis',
        'Daughter of Autumn',
        'Didgeridoo',
        'Dwarven Pony',
        'Dwarven Sea Clan',
        'Faerie Noble',
        'Grandmother Sengir',
        'Hazduhr the Abbot',
        'Heart Wolf',
        'Koskun Falls',
        'Leeches',
        'Mammoth Harness',
        'Marjhan',
        'Mystic Decree',
        'Narwhal',
        'Reveka, Wizard Savant',
        'Rysorian Badger',
        'Serra Aviary',
        'Soraya the Falconer',
        'Timmerian Fiends',
        'Veldrane of Sengir',
        'Wall of Kelp',
        'Willow Priestess',
        'Winter Sky',
        "Ashnod's Cylix",
        'Balduvian Trading Post',
        'Chaos Harlequin',
        'Dystopia',
        'Fatal Lore',
        'Floodwater Dam',
        'Gargantuan Gorilla',
        "Gustha's Scepter",
        'Heart of Yavimaya',
        'Helm of Obedience',
        'Ivory Gargoyle',
        'Kaysa',
        'Keeper of Tresserhorn',
        'Kjeldoran Outpost',
        'Krovikan Horror',
        'Lake of the Dead',
        'Lodestone Bauble',
        'Lord of Tresserhorn',
        'Misfortune',
        "Nature's Wrath",
        'Omen of Fire',
        'Phantasmal Sphere',
        'Phelddagrif',
        'Phyrexian Devourer',
        'Phyrexian Portal',
        'Ritual of the Machine',
        'Rogue Skycaptain',
        'Royal Decree',
        'Sheltered Valley',
        'Soldevi Digger',
        'Soldevi Excavations',
        'Splintering Wind',
        'Sustaining Spirit',
        'Sworn Defender',
        'Thawing Glaciers',
        'Thought Lash',
        'Tidal Control',
        'Tornado',
        "Varchild's War-Riders",
        'Wandering Mage',
        "Winter's Night",
        'Acidic Dagger',
        'Afiya Grove',
        'Amulet of Unmaking',
        'Asmira, Holy Avenger',
        'Auspicious Ancestor',
        'Barreling Attack',
        'Bazaar of Wonders',
        'Benthic Djinn',
        'Bone Mask',
        'Brushwagg',
        'Cadaverous Bloom',
        'Canopy Dragon',
        'Carrion',
        'Catacomb Dragon',
        'Chaosphere',
        'Circle of Despair',
        'Cycle of Life',
        'Discordant Spirit',
        'Divine Retribution',
        'Emberwilde Caliph',
        'Emberwilde Djinn',
        'Energy Bolt',
        'Energy Vortex',
        'Forsaken Wastes',
        'Frenetic Efreet',
        'Grim Feast',
        'Hakim, Loreweaver',
        'Hall of Gemstone',
        'Harbinger of Night',
        'Hivis of the Scale',
        "Jabari's Influence",
        'Jungle Patrol',
        'Kukemssa Pirates',
        'Leering Gargoyle',
        "Lion's Eye Diamond",
        'Lure of Prey',
        'Malignant Growth',
        "Mangara's Tome",
        'Mindbender Spores',
        "Misers' Cage",
        'Mist Dragon',
        'Natural Balance',
        'Null Chamber',
        "Paupers' Cage",
        'Phyrexian Dreadnought',
        'Phyrexian Purge',
        'Phyrexian Tribute',
        'Political Trickery',
        'Preferred Selection',
        'Prismatic Lace',
        'Purgatory',
        'Purraj of Urborg',
        'Rashida Scalebane',
        'Razor Pendulum',
        'Reflect Damage',
        'Reparations',
        'Rock Basilisk',
        'Sawback Manticore',
        'Seeds of Innocence',
        'Shallow Grave',
        'Shauku, Endbringer',
        'Shimmer',
        'Sidar Jabari',
        'Soul Echo',
        'Spectral Guardian',
        'Spirit of the Night',
        'Subterranean Spirit',
        'Tainted Specter',
        'Taniwha',
        "Teeka's Dragon",
        "Teferi's Imp",
        "Teferi's Isle",
        "Telim'Tor",
        "Telim'Tor's Edict",
        'Tombstone Stairwell',
        'Torrent of Lava',
        'Unfulfilled Desires',
        'Ventifact Bottle',
        'Warping Wurm',
        'Wellspring',
        'Yare',
        'Zirilan of the Claw',
        'Zuberi, Golden Feather',
        'Aku Djinn',
        'Anvil of Bogardan',
        'Bogardan Phoenix',
        "Breathstealer's Crypt",
        'Chronatog',
        'City of Solitude',
        'Corrosion',
        'Diamond Kaleidoscope',
        'Elkin Lair',
        'Equipoise',
        'Eye of Singularity',
        'Femeref Enchantress',
        'Firestorm Hellkite',
        'Flooded Shoreline',
        'Forbidden Ritual',
        'Griffin Canyon',
        'Guiding Spirit',
        "Kaervek's Spite",
        'Katabatic Winds',
        'Kookus',
        'Lichenthrope',
        'Lightning Cloud',
        'Ogre Enforcer',
        'Phyrexian Marauder',
        'Pillar Tombs of Aku',
        'Pygmy Hippo',
        'Quirion Druid',
        'Rainbow Efreet',
        'Retribution of the Meek',
        'Righteous War',
        'Sands of Time',
        'Squandered Resources',
        "Suleiman's Legacy",
        "Teferi's Realm",
        'Three Wishes',
        'Tithe',
        'Triangle of War',
        'Undiscovered Paradise',
        'Viashivan Dragon',
        'Zhalfirin Crusader',
        'Abeyance',
        'Aboroth',
        'Ancestral Knowledge',
        'Avizoa',
        'Bone Dancer',
        'Bösium Strip',
        'Bubble Matrix',
        'Debt of Loyalty',
        'Dwarven Thaumaturgist',
        "Ertai's Familiar",
        'Firestorm',
        'Fungus Elemental',
        'Gallowbraid',
        'Goblin Bomb',
        'Heart of Bogardan',
        'Heat Stroke',
        'Infernal Tribute',
        'Inner Sanctum',
        'Liege of the Hollows',
        'Lotus Vale',
        'Mana Web',
        'Maraxus of Keld',
        'Morinfen',
        'Mwonvuli Ooze',
        'Null Rod',
        'Paradigm Shift',
        'Peacekeeper',
        'Pendrell Mists',
        'Psychic Vortex',
        'Scorched Ruins',
        'Thran Tome',
        'Tolarian Entrancer',
        'Tolarian Serpent',
        'Urborg Justice',
        'Urborg Stalker',
        'Wave of Terror',
        'Well of Knowledge',
        'Winding Canyons',
        'Xanthic Statue',
        'Aluren',
        'Apocalypse',
        'Avenging Angel',
        'Commander Greven il-Vec',
        'Corpse Dance',
        'Cursed Scroll',
        'Earthcraft',
        'Eladamri, Lord of Leaves',
        'Escaped Shapeshifter',
        'Humility',
        'Intuition',
        'Meditate',
        'Orim, Samite Healer',
        'Recycle',
        'Sarcomancy',
        'Selenia, Dark Angel',
        'Crovax The Cursed',
        'Dream Halls',
        'Mox Diamond',
        'Silver Wyvern',
        'Sliver Queen',
        "Volrath's Shapeshifter",
        "Volrath's Stronghold",
        'City of Traitors',
        'Dominating Licid',
        'Ertai, Wizard Adept',
        'Exalted Dragon',
        'Hatred',
        'Mind Over Matter',
        'Oath of Ghouls',
        'Recurring Nightmare',
        'Survival of the Fittest',
        'Argothian Wurm',
        'Barrin, Master Wizard',
        'Citanul Centaurs',
        "Gaea's Cradle",
        'Gilded Drake',
        'Great Whale',
        'Herald of Serra',
        'Karn, Silver Golem',
        'Lifeline',
        'Lightning Dragon',
        'Morphling',
        'Opal Archangel',
        "Serra's Sanctum",
        'Tolarian Academy',
        'Temporal Aperture',
        'Time Spiral',
        "Yawgmoth's Will",
        'Zephid',
        'Deranged Hermit',
        'Grim Monolith',
        'Memory Jar',
        'Multani, Maro-Sorcerer',
        'Palinchron',
        'Radiant, Archangel',
        'Ring of Gix',
        'Second Chance',
        'Weatherseed Treefolk',
        'Academy Rector',
        'Carnival of Souls',
        'Covetous Dragon',
        'Donate',
        'Masticore',
        'Metalworker',
        'Opalescence',
        'Phyrexian Negator',
        'Powder Keg',
        'Rofellos, Llanowar Emissary',
        'Replenish',
        'Treachery',
        'Yavimaya Hollow',
        "Yawgmoth's Bargain"
    ];

    public static IsReserved(card: ICard): boolean {
        return ReservedList.List.indexOf(card.nameEn) !== -1;
    }
}
