import { GlobalState } from '../globalState';

export class CardLanguage {
    public static CardLanguages: string[] = [];
    public static LanguageKeys: string[] = [];
    public static DecodingOffset = 3;
    public static DecodingMask = 0x7;

    public constructor(public name: string) {}

    public static CreateList(gs: GlobalState) {
        const translate = gs.translate.bind(gs);

        CardLanguage.LanguageKeys.push('Undefined');
        CardLanguage.LanguageKeys.push('English');
        CardLanguage.LanguageKeys.push('French');
        CardLanguage.LanguageKeys.push('German');
        CardLanguage.LanguageKeys.push('Italian');
        CardLanguage.LanguageKeys.push('Japanese');
        CardLanguage.LanguageKeys.push('Spanish');
        CardLanguage.LanguageKeys.push('ChineseSimp');
        CardLanguage.LanguageKeys.push('ChineseTrad');
        CardLanguage.LanguageKeys.push('Korean');
        CardLanguage.LanguageKeys.push('Portuguese');
        CardLanguage.LanguageKeys.push('Russian');

        for (const key of CardLanguage.LanguageKeys) {
            CardLanguage.CardLanguages.push(translate(key));
        }
    }

    public static Decode(encodedLanguages: number, limitTo: number) {
        const languages = [];
        while (encodedLanguages) {
            const index = encodedLanguages & CardLanguage.DecodingMask;
            if (index !== 0) {
                languages.push(CardLanguage.CardLanguages[index]);
            }

            encodedLanguages = encodedLanguages >> CardLanguage.DecodingOffset;
            limitTo--;

            if (limitTo === 0) {
                break;
            }
        }

        const final = [];

        for (const language of languages) {
            final.push(`${1}x${language}`);
        }

        return final.join(', ');
    }

    public static Decoder(encodedLanguages: string): number[] {
        const languages: number[] = [];

        if (encodedLanguages === '') {
            return languages;
        }

        const parts = encodedLanguages.split(',');

        for (const part of parts) {
            const splits = part.split('x');
            const count = parseInt(splits[0]);
            const language = splits[1];
            let languageIndex = CardLanguage.CardLanguages.indexOf(language);
            if (languageIndex === -1) {
                languageIndex = CardLanguage.LanguageKeys.indexOf(language);
            }

            for (let index = 0; index < count; index++) {
                languages.push(languageIndex);
            }
        }

        return languages;
    }

    public static Deserialize(storedValue: string) {
        const indexes = this.Decoder(storedValue);

        return this.Encoder(indexes);
    }

    public static Encoder(languages: number[]) {
        const final = [];

        if (languages.length > 1) {
            const first = languages[0];
            if (languages.every((x) => x === first)) {
                return `${languages.length}x${CardLanguage.CardLanguages[first]}`;
            }
        }

        for (const language of languages) {
            if (language === 0) {
                continue;
            }
            final.push(`${1}x${CardLanguage.CardLanguages[language]}`);
        }
        return final.join(', ');
    }
}
