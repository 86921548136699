import { Nullable } from './nullable';

export class SecureJSON {
    public static Parse<T>(data: string): Nullable<T> {
        try {
            return JSON.parse(data);
        } catch {
            return null;
        }
    }
}
