import * as React from 'react';
import { GlobalState } from '../globalState';

interface IButtonProps {
    globalState: GlobalState;
    onClicked?: () => void;
    className?: string;
    title?: string;
    tabIndex?: number;
    doNotBlockEvents?: boolean;
    children: React.ReactNode;
}

export class Button extends React.Component<IButtonProps> {
    constructor(props: IButtonProps) {
        super(props);
    }

    onClick() {
        if (this.props.onClicked) {
            this.props.onClicked();
        }
    }

    composeStateClasses(core: string) {
        let result = core;

        result += ' is-checked';

        if (this.props.className) {
            result += ' ' + this.props.className;
        }

        return result;
    }

    render() {
        return (
            <div
                className={this.composeStateClasses('ui-btn')}
                onKeyDown={(evt) => {
                    if (evt.keyCode === 13 || evt.keyCode === 32) {
                        this.onClick();
                    }
                }}
                title={this.props.title}
                tabIndex={this.props.tabIndex || 0}
                onClick={(evt) => {
                    if (!this.props.doNotBlockEvents) {
                        evt.stopPropagation();
                        evt.preventDefault();
                    }
                    this.onClick();
                }}
            >
                <div className="text">{this.props.children}</div>
            </div>
        );
    }
}
