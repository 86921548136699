import * as React from 'react';
import { GlobalState } from '../globalState';
import { StatisticsLine } from '../controls/statisticsLine';
import { Collection } from '../entities/collection';
import { PriceTools } from '../tools/priceTools';
import { CardColor } from '../entities/cardColor';
import { CardRarity } from '../entities/cardRarity';
import { ReservedList } from '../entities/reservedList';
require('../scss/statisticsPage.scss');

interface IStatisticsPageProps {
    globalState: GlobalState;
}

export class StatisticsPage extends React.Component<
    IStatisticsPageProps,
    { block: string; set: string; stateIndex: number; reprintIndex: number }
> {
    private totalPercentage: number;

    constructor(props: IStatisticsPageProps) {
        super(props);

        this.state = {
            block: GlobalState.LoadSettings('StatsBlock', ''),
            set: GlobalState.LoadSettings('StatsSet', ''),
            stateIndex: GlobalState.LoadIntSettings('StatsState', 0),
            reprintIndex: GlobalState.LoadIntSettings('StatsReprint', 0)
        };
    }

    polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
        const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

        return {
            x: centerX + radius * Math.cos(angleInRadians),
            y: centerY + radius * Math.sin(angleInRadians)
        };
    }

    describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number) {
        if (endAngle === 360) {
            endAngle = 359.99999;
        }
        const start = this.polarToCartesian(x, y, radius, endAngle);
        const end = this.polarToCartesian(x, y, radius, startAngle);

        const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

        const d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ');

        return d;
    }

    updateArc() {
        document.getElementById('arc')!.setAttribute('d', this.describeArc(21, 21, 18, 0, this.totalPercentage * 360));
    }

    componentDidMount() {
        this.updateArc();
    }

    componentDidUpdate() {
        this.updateArc();
    }

    render() {
        let cards = Collection.Cards;
        const translate = this.props.globalState.translate.bind(this.props.globalState);

        if (this.state.block) {
            cards = cards.filter((card) => {
                const block = card.set.block;
                return block.name === this.state.block;
            });
        }

        if (this.state.set) {
            cards = cards.filter((card) => {
                const set = card.set;
                return set.name === this.state.set;
            });
        }

        if (this.state.stateIndex) {
            cards = cards.filter((card) => {
                if (this.state.stateIndex === 1 && card.canBeStandard) {
                    return false;
                } else if (this.state.stateIndex === 4 && !ReservedList.IsReserved(card)) {
                    return false;
                } else if (this.state.stateIndex === 6 && card.canBeFoil) {
                    return false;
                } else if (this.state.stateIndex === 7 && !(card.canBeFoil && card.canBeStandard)) {
                    return false;
                }
                return true;
            });
        }

        if (this.state.reprintIndex) {
            const reprintChecks: { [key: string]: boolean } = {};

            cards = cards.filter((card) => {
                if (this.state.reprintIndex === 1) {
                    if (
                        card.set.year !==
                        card.reprints.map((c) => c.set.year).reduce((p, c) => Math.max(p, c))
                    ) {
                        return false;
                    }
                } else if (this.state.reprintIndex === 2) {
                    if (!card.isToken && !card.isEmblem) {
                        return false;
                    }
                } else if (this.state.reprintIndex === 3) {
                    if (!card.isPromo) {
                        return false;
                    }
                } else if (this.state.reprintIndex === 4) {
                    if (card.isPromo || card.isToken || card.isEmblem) {
                        return false;
                    }
                } else if (this.state.reprintIndex === 5) {
                    if (reprintChecks[card.nameEn]) {
                        return false;
                    }

                    reprintChecks[card.nameEn] = true;
                }
                return true;
            });
        }

        let totalLands = 0;
        let userLands = 0;
        let totalCreatures = 0;
        let userCreatures = 0;
        let totalInstants = 0;
        let userInstants = 0;
        let totalEnchants = 0;
        let userEnchants = 0;
        let totalSorceries = 0;
        let userSorceries = 0;
        let totalPlaneswalkers = 0;
        let userPlaneswalkers = 0;
        let totalOthers = 0;
        let userOthers = 0;
        let total = 0;
        let user = 0;
        let userUnique = 0;
        let userValue = 0;
        let missingValue = 0;

        const totalColors: number[] = [];
        const userColors: number[] = [];

        CardColor.SortedColors.map((cc) => {
            totalColors[cc.id] = 0;
            userColors[cc.id] = 0;
        });

        const totalRarities: number[] = [];
        const userRarities: number[] = [];

        CardRarity.Rarities.map((cr) => {
            totalRarities[cr.id] = 0;
            userRarities[cr.id] = 0;
        });

        cards.map((c) => {
            if (c.isBackFace) {
                return;
            }

            const cardCount =
                this.state.reprintIndex === 5 ? (c.reprints.filter((cc) => cc.count > 0).length > 0 ? 1 : 0) : c.count;
            let cardCollectionValue =
                this.state.reprintIndex === 5
                    ? c.reprints.map((cc) => cc.collectionValue).reduce((previous, current) => previous + current) /
                    c.reprints.filter((cc) => cc.count).length
                    : c.collectionValue;

            if (isNaN(cardCollectionValue)) {
                cardCollectionValue = 0;
            }

            total += 1;
            if (cardCount > 0) {
                userUnique += 1;
                user += cardCount;
                userValue += cardCollectionValue;
            } else {
                missingValue += c.price;
            }

            if (c.isLand) {
                totalLands += 1;
                if (cardCount > 0) {
                    userLands += 1;
                }
            } else if (c.isCreature) {
                totalCreatures += 1;
                if (cardCount > 0) {
                    userCreatures += 1;
                }
            } else if (c.isInstant) {
                totalInstants += 1;
                if (cardCount > 0) {
                    userInstants += 1;
                }
            } else if (c.isEnchantment) {
                totalEnchants += 1;
                if (cardCount > 0) {
                    userEnchants += 1;
                }
            } else if (c.isSorcery) {
                totalSorceries += 1;
                if (cardCount > 0) {
                    userSorceries += 1;
                }
            } else if (c.isPlaneswalker) {
                totalPlaneswalkers += 1;
                if (cardCount > 0) {
                    userPlaneswalkers += 1;
                }
            } else {
                totalOthers += 1;
                if (cardCount > 0) {
                    userOthers += 1;
                }
            }

            CardColor.SortedColors.map((cc) => {
                if (cc.id === c.colorId) {
                    totalColors[cc.id] += 1;

                    if (cardCount > 0) {
                        userColors[cc.id] += 1;
                    }
                }
            });

            CardRarity.Rarities.map((cr) => {
                if (cr.id === c.rarityId) {
                    totalRarities[cr.id] += 1;

                    if (cardCount > 0) {
                        userRarities[cr.id] += 1;
                    }
                }
            });
        });

        this.totalPercentage = userUnique / total;

        const blockOptions = [];

        blockOptions.push(
            <option key="all" value={''}>
                {translate('AllBlocks')}
            </option>
        );
        blockOptions.push(
            ...Collection.SortedBlocks.map((block) => {
                return (
                    <option key={block.name} value={block.name}>
                        {block.name}
                    </option>
                );
            })
        );

        const setOptions = [];

        setOptions.push(
            <option key="all" value={''}>
                {translate('AllExpansions')}
            </option>
        );
        setOptions.push(
            ...Collection.SortedSets.filter((set) => {
                if (!this.state.block) {
                    return true;
                }

                const block = Collection.GetBlockByName(this.state.block);

                return block.sets.indexOf(set) !== -1;
            }).map((set) => {
                return (
                    <option key={set.name} value={set.name}>
                        {set.name}
                    </option>
                );
            })
        );

        const stateOptions = [
            { value: '0', label: translate('AllCardStates') },
            { value: '6', label: translate('OnlyRegular') },
            { value: '7', label: translate('FoilNonFoil') },
            { value: '1', label: translate('OnlyFoil') },
            { value: '4', label: translate('ReservedList') }
        ];

        const reprintOptions = [
            { value: '0', label: translate('AllCards') },
            { value: '1', label: translate('OnlyRecents') },
            { value: '5', label: translate('UniqueNames') },
            { value: '2', label: translate('OnlyTokens') },
            { value: '3', label: translate('OnlyPromos') },
            { value: '4', label: translate('ExcludePromosAndTokens') }
        ];

        return (
            <div className="page">
                <div className="statistics-page-container">
                    <div className="statistics-sets">
                        <select
                            className="stats-filter"
                            value={this.state.block}
                            onChange={(evt) => {
                                this.setState({ block: evt.target.value, set: '' });
                                this.props.globalState.storeSettings('StatsBlock', evt.target.value);
                                this.props.globalState.storeSettings('StatsSet', '');
                            }}
                        >
                            {blockOptions}
                        </select>

                        <select
                            className="stats-filter"
                            value={this.state.set}
                            onChange={(evt) => {
                                this.props.globalState.storeSettings('StatsSet', evt.target.value);
                                this.setState({ set: evt.target.value });
                            }}
                        >
                            {setOptions}
                        </select>

                        <select
                            className="stats-filter"
                            value={this.state.stateIndex.toString()}
                            onChange={(evt) => {
                                const valIndex = parseInt(evt.target.value);
                                this.props.globalState.storeNumberSettings('StatsState', valIndex);
                                this.setState({ stateIndex: valIndex });
                            }}
                        >
                            {stateOptions.map((stateOption) => {
                                return (
                                    <option key={stateOption.value} value={stateOption.value}>
                                        {stateOption.label}
                                    </option>
                                );
                            })}
                        </select>

                        <select
                            className="stats-filter"
                            value={this.state.reprintIndex.toString()}
                            onChange={(evt) => {
                                const valIndex = parseInt(evt.target.value);
                                this.props.globalState.storeNumberSettings('StatsReprint', valIndex);
                                this.setState({ reprintIndex: valIndex });
                            }}
                        >
                            {reprintOptions.map((reprintOption) => {
                                return (
                                    <option key={reprintOption.value} value={reprintOption.value}>
                                        {reprintOption.label}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="statistics-page">
                        <div className="statistics-block stats">
                            <div className="statistics-global">
                                <div className="statistics-global-chart">
                                    <svg width="100%" height="100%" viewBox="0 0 42 42">
                                        <circle
                                            className="backArc"
                                            cx="21"
                                            cy="21"
                                            r="18"
                                            fill="transparent"
                                            strokeWidth="3"
                                        ></circle>
                                        <path className="arc" id="arc" fill="none" strokeWidth="3" />
                                    </svg>
                                </div>
                                <div className="statistics-global-big">
                                    {(this.totalPercentage * 100).toFixed(1) + '%'}
                                </div>
                                <div className="statistics-global-details">
                                    <div className="statistics-global-value">{user}</div>
                                    {translate('InCollection')}
                                    <div className="statistics-global-value offset">{userUnique}</div>
                                    {translate('InCollectionUnique')}
                                    <div className="statistics-global-value offset">
                                        {PriceTools.Format(userValue, this.props.globalState)}
                                    </div>
                                    {translate('InCollectionValue')}
                                    <div className="statistics-global-value offset">{total - userUnique}</div>
                                    {translate('MissingUnique')}
                                    <div className="statistics-global-value offset">
                                        {PriceTools.Format(missingValue, this.props.globalState)}
                                    </div>
                                    {translate('MissingValue')}
                                    {!this.state.set && (
                                        <div>
                                            <div className="statistics-global-value offset">
                                                {Collection.Blocks.filter(
                                                    (b) => b.inCollectionCardCount === b.totalCardCount
                                                ).length +
                                                    ' / ' +
                                                    Collection.Blocks.length}
                                            </div>
                                            {translate('Blocks')}
                                            <div className="statistics-global-value offset">
                                                {Collection.Sets.filter(
                                                    (e) => e.inCollectionCardCount === e.totalCardCount
                                                ).length +
                                                    ' / ' +
                                                    Collection.Sets.length}
                                            </div>
                                            {translate('Expansions')}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="statistics-others">
                            <div className="statistics-block statistics-details">
                                <div className="statistics-block-header">{translate('Details')}</div>
                                <div className="statistics-block-data">
                                    <StatisticsLine
                                        globalState={this.props.globalState}
                                        title={translate('Lands')}
                                        value={userLands}
                                        total={totalLands}
                                    />
                                    <StatisticsLine
                                        globalState={this.props.globalState}
                                        title={translate('Creatures')}
                                        value={userCreatures}
                                        total={totalCreatures}
                                    />
                                    <StatisticsLine
                                        globalState={this.props.globalState}
                                        title={translate('Instants')}
                                        value={userInstants}
                                        total={totalInstants}
                                    />
                                    <StatisticsLine
                                        globalState={this.props.globalState}
                                        title={translate('Enchantments')}
                                        value={userEnchants}
                                        total={totalEnchants}
                                    />
                                    <StatisticsLine
                                        globalState={this.props.globalState}
                                        title={translate('Sorceries')}
                                        value={userSorceries}
                                        total={totalSorceries}
                                    />
                                    <StatisticsLine
                                        globalState={this.props.globalState}
                                        title={translate('Planeswalkers')}
                                        value={userPlaneswalkers}
                                        total={totalPlaneswalkers}
                                    />
                                    <StatisticsLine
                                        globalState={this.props.globalState}
                                        title={translate('Others')}
                                        value={userOthers}
                                        total={totalOthers}
                                    />
                                    <StatisticsLine
                                        globalState={this.props.globalState}
                                        title={translate('Total')}
                                        value={userUnique}
                                        total={total}
                                    />
                                </div>
                            </div>
                            <div className="statistics-block statistics-colors">
                                <div className="statistics-block-header">{translate('Color status')}</div>
                                <div className="statistics-block-data">
                                    {CardColor.SortedColors.filter((cc) => cc.id > 0).map((cc) => {
                                        return (
                                            <StatisticsLine
                                                key={cc.id}
                                                globalState={this.props.globalState}
                                                title={cc.name}
                                                value={userColors[cc.id]}
                                                total={totalColors[cc.id]}
                                                color={cc.color}
                                                logo={`/images/symbols/${cc.key}.png`}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="statistics-block statistics-rarities">
                                <div className="statistics-block-header">{translate('Rarity status')}</div>
                                <div className="statistics-block-data">
                                    {CardRarity.Rarities.filter((cr) => cr.id > 0).map((cr) => {
                                        return (
                                            <StatisticsLine
                                                key={cr.id}
                                                globalState={this.props.globalState}
                                                title={cr.name}
                                                value={userRarities[cr.id]}
                                                total={totalRarities[cr.id]}
                                                color={cr.color}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
