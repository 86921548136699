import { Counter } from './counter';
import { GameLog } from './gameLog';

export class Game {
    public Counters: Counter[];
    public CurrentTurn: number;
    public TimerStartTime: string;
    public TimerTotalMinutes: number;
    public Log: GameLog[];
}
