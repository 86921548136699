import { Icon } from '@fortawesome/fontawesome-svg-core';
import { faMinusSquare, faPen, faPlusSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { GlobalState } from '../globalState';

export class GroupData {
    public name: string;
    public host: any;
    public setTitle?: (name: string) => void;
    public getTitle: () => string;
    public items: any[];
}

interface IGroupedListProps {
    globalState: GlobalState;
    storeKey: string;
    canEditGroupTitles: boolean;
    editGroupTitleLabel?: string;
    onRenderGroupItem: (group: GroupData, item: any, index: number) => JSX.Element;
    groups: GroupData[];
}

export class GroupedList extends React.Component<IGroupedListProps> {
    constructor(props: IGroupedListProps) {
        super(props);
    }

    isGroupOpen(globalState: GlobalState, name: string) {
        return GlobalState.LoadBoolSettings(this.props.storeKey + '-' + name);
    }

    setGroupOpen(name: string, value: boolean) {
        return this.props.globalState.storeBoolSettings(this.props.storeKey + '-' + name, value);
    }

    renderGroup(group: GroupData) {
        const isOpen = this.isGroupOpen(this.props.globalState, group.name);

        return (
            <div key={group.name} className="group">
                <div className="group-header">
                    <div className="group-title" onClick={() => {
                        this.setGroupOpen(group.name, !isOpen);
                        this.forceUpdate();
                    }}>
                        {group.getTitle()}
                    </div>
                    {this.props.canEditGroupTitles &&
                        <div
                            className="group-edit"
                            onClick={() => {
                                this.props.globalState
                                    .showQuestionDialog(this.props.editGroupTitleLabel!, group.name)
                                    .then((newName) => {
                                        if (!newName || !group.setTitle) {
                                            return;
                                        }
                                        group.setTitle(newName);
                                        this.forceUpdate();
                                    });
                            }}
                        >
                            <FontAwesomeIcon icon={faPen as Icon} />
                        </div>
                    }
                    <div
                        className="group-collapse"
                        onClick={() => {
                            this.setGroupOpen(group.name, !isOpen);
                            this.forceUpdate();
                        }}
                    >
                        <FontAwesomeIcon icon={isOpen ? faMinusSquare : faPlusSquare} />
                    </div>
                </div>
                {isOpen && (
                    <div className="group-deck">
                        {
                            group.items.map((item, index) => {
                                return this.props.onRenderGroupItem(group, item, index);
                            })
                        }
                    </div>
                )}
            </div>
        );
    }

    render() {
        const groups = this.props.groups;
        return (
            <div>
                {
                    groups.map((g) => {
                        return this.renderGroup(g);
                    })
                }
            </div>
        );
    }
}
