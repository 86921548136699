import * as React from 'react';
import { GlobalState, SearchData } from '../globalState';
import { ManaTools } from '../tools/manaTools';

interface ICardTextWithManaLineProps {
    globalState: GlobalState;
    label: string;
    value: string;
    searchProperty?: string;
    searchValue?: any;
    image?: string;
}

export class CardTextWithManaLine extends React.Component<ICardTextWithManaLineProps, Record<string, unknown>> {
    constructor(props: ICardTextWithManaLineProps) {
        super(props);
    }

    navigate() {
        if (!this.props.searchProperty) {
            return;
        }
        const searchData = new SearchData();
        (searchData as any)[this.props.searchProperty] = this.props.searchValue;

        this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({ index: -3, searchData: searchData });
    }

    render() {
        return (
            <div className="card-element">
                <div className="card-element-label">{this.props.label}</div>
                <div
                    className={'card-element-value' + (this.props.searchProperty ? ' link' : '')}
                    onClick={() => this.navigate()}
                >
                    {ManaTools.GenerateSymbols(this.props.value)}
                </div>
                {this.props.image && <img src={this.props.image} className="card-element-image" />}
                <div className="card-element-border"></div>
            </div>
        );
    }
}
