import * as React from 'react';
import { GlobalState } from '../globalState';

import { Checkbox } from './checkbox';

interface ILabelledCheckboxProps {
    globalState: GlobalState;
    label: string;
    value?: boolean;
    onChange?: (value: boolean) => void;
    tabIndex?: number;
}

export class LabelledCheckbox extends React.Component<ILabelledCheckboxProps, { value?: boolean }> {
    constructor(props: ILabelledCheckboxProps) {
        super(props);

        this.state = { value: this.props.value };
    }

    shouldComponentUpdate(nextProps: ILabelledCheckboxProps, nextState: { value?: boolean }) {
        if (this.props.value !== nextProps.value) {
            nextState.value = nextProps.value;
        }

        return true;
    }

    render() {
        return (
            <div className="checkbox-element">
                <div className="checkbox-element-label">{this.props.label}</div>
                <Checkbox
                    className="checkbox-element-control"
                    globalState={this.props.globalState}
                    isChecked={!!this.state.value}
                    onCheckedChanged={(value) => {
                        if (this.props.onChange) {
                            this.props.onChange(value);
                        }
                        this.setState({ value: value });
                    }}
                ></Checkbox>
            </div>
        );
    }
}
