import * as React from 'react';
import { GlobalState } from '../globalState';
import { Button } from './button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Collection } from '../entities/collection';

interface IUserDialogProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class UserDialog extends React.Component<
    IUserDialogProps,
    {
        isVisible: boolean;
        creationMode: boolean;
    }
> {
    private _blockShortcuts = false;

    constructor(props: IUserDialogProps) {
        super(props);

        this.state = { isVisible: false, creationMode: false };
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onUserRequired.add(() => {
            this.setState({
                isVisible: true
            });

            this.props.globalState.onBlurRequired.notifyObservers(true);
        });

        document.addEventListener('keydown', (evt) => {
            if (this._blockShortcuts) {
                return;
            }

            if (evt.keyCode === 13 && this.state.isVisible && document.activeElement === document.body) {
                this.onConfirmation(true);
                return;
            }
            if (evt.keyCode === 27 && this.state.isVisible) {
                this.onConfirmation(false);
            }
        });
    }

    onConfirmation(value: boolean) {
        this.setState({ isVisible: false });
        this.props.globalState.onBlurRequired.notifyObservers(false);

        this.props.globalState.onUserUpdated.notifyObservers(value);
    }

    onAdd() {
        this._blockShortcuts = true;
        const lowercases = Collection.UserList.map(u => u.toLowerCase());

        this.props.globalState
            .showQuestionDialog(this.props.globalState.translate('NewUser'), '', false)
            .then((value) => {
                setTimeout(() => (this._blockShortcuts = false));
                if (value && lowercases.indexOf(value.toLowerCase()) === -1) {
                    Collection.UserList.push(value);
                    Collection.UserList = Collection.UserList.sort();

                    this.forceUpdate();
                }
            });
    }

    onDelete(user: string) {
        const index = Collection.UserList.indexOf(user);

        if (index === -1) {
            return;
        }

        Collection.UserList.splice(index, 1);
        this.forceUpdate();
    }

    render() {
        if (!this.state.isVisible) {
            return null;
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);

        return (
            <div className="user-dialog-container">
                <div className="user-dialog-background"></div>
                <div className={'user-dialog' + (this.props.darkMode ? ' dark' : '')}>
                    <div className="user-dialog-title">{translate('Users')}</div>
                    <div className="user-dialog-users">
                        {Collection.UserList.map((u) => {
                            return (
                                <div key={u} className="user-dialog-user" title={u}>
                                    <div className="user-dialog-user-label">{u}</div>
                                    {
                                        u !== this.props.globalState.currentUser &&
                                        <Button
                                            className={'user-dialog-button delete' + (this.props.darkMode ? '' : ' light')}
                                            globalState={this.props.globalState}
                                            onClicked={() => this.onDelete(u)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    }
                                </div>
                            );
                        })}
                    </div>
                    <div className="user-dialog-new">
                        <Button
                            className={'user-dialog-button add' + (this.props.darkMode ? '' : ' light')}
                            tabIndex={3}
                            globalState={this.props.globalState}
                            onClicked={() => this.onAdd()}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    </div>
                    <div className="user-dialog-accept">
                        <Button
                            className="user-dialog-button"
                            tabIndex={4}
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(true)}
                        >
                            {translate('OK')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
