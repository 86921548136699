import * as React from 'react';
import { GlobalState } from '../globalState';
import { Button } from './button';
import { NumberControl } from './numberControl';
import { Deck } from '../entities/deck';
import { Collection } from '../entities/collection';
import { Nullable } from '../tools/nullable';
import { ICard } from '../entities/ICard';
import { DeckEntry } from '../entities/deckEntry';

interface IDeckCountDialogProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class DeckCountDialog extends React.Component<
    IDeckCountDialogProps,
    {
        isVisible: boolean;
        title: string;
        deckValue: number;
        sideboardValue: number;
        reserveValue: number;
        selectedIndex: number;
    }
> {
    private decks: Deck[];
    private masterCard: Nullable<ICard>;

    constructor(props: IDeckCountDialogProps) {
        super(props);

        this.state = {
            isVisible: false,
            title: '',
            deckValue: 0,
            sideboardValue: 0,
            reserveValue: 0,
            selectedIndex: 0
        };
    }

    syncWithMasterCard(index: number) {
        if (!this.masterCard) {
            return;
        }

        const deck = this.decks[index];

        if (!deck) {
            return;
        }

        const filter = (c: DeckEntry) =>
            c.CardID === this.masterCard!.id || c.CardID === Collection.LinkedCardsIndex[this.masterCard!.id];

        const cardInDeck = deck.Cards.filter(filter);
        const cardInSideboard = deck.Sideboard.filter(filter);
        const cardInReserve = deck.Reserve.filter(filter);

        this.setState({
            deckValue: cardInDeck.length === 1 ? cardInDeck[0].EntryCount : 0,
            sideboardValue: cardInSideboard.length === 1 ? cardInSideboard[0].EntryCount : 0,
            reserveValue: cardInReserve.length === 1 ? cardInReserve[0].EntryCount : 0
        });
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onDeckCountRequired.add((data) => {
            if (!Collection.Decks || Collection.Decks.length === 0) {
                this.props.globalState.onError.notifyObservers(this.props.globalState.translate('NoDeckFound'));
                this.props.globalState.onDeckCount.notifyObservers();
                return;
            }

            this.decks = Collection.Decks.filter((d) => !d.Locked).sort((a, b) => a.Name.localeCompare(b.Name));

            this.masterCard = data.masterCard;

            let defaultIndex = GlobalState.LoadIntSettings('DefaultDeck', 0);

            if (defaultIndex >= this.decks.length) {
                defaultIndex = this.decks.length - 1;
            }

            if (!data.currentDeck) {
                this.setState({
                    isVisible: true,
                    title: data.title,
                    selectedIndex: defaultIndex
                });
                this.syncWithMasterCard(defaultIndex);
            } else {
                this.setState({
                    isVisible: true,
                    title: data.title,
                    selectedIndex: Math.min(
                        this.decks.length - 1,
                        Math.max(0, data.currentDeck ? this.decks.indexOf(data.currentDeck) : defaultIndex)
                    ),
                    deckValue: data.deckValue,
                    sideboardValue: data.sideboardValue,
                    reserveValue: data.reserveValue
                });
            }

            this.props.globalState.onBlurRequired.notifyObservers(true);
        });

        document.addEventListener('keydown', (evt) => {
            if (!this.state.isVisible) {
                return;
            }

            switch (evt.keyCode) {
                case 27: {
                    this.onConfirmation(false);
                    return;
                }
                case 13: {
                    this.onConfirmation(true);
                    return;
                }
            }
        });
    }

    onConfirmation(value: boolean) {
        if (value) {
            this.props.globalState.onDeckCount.notifyObservers({
                deck: this.decks[this.state.selectedIndex],
                deckValue: this.state.deckValue,
                sideboardValue: this.state.sideboardValue,
                reserveValue: this.state.reserveValue
            });
        } else {
            this.props.globalState.onDeckCount.notifyObservers();
        }

        this.props.globalState.onBlurRequired.notifyObservers(false);

        this.setState({ isVisible: false });
    }

    render() {
        if (!this.state.isVisible) {
            return null;
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);

        return (
            <div className="deck-count-dialog-container">
                <div className="deck-count-dialog-background"></div>
                <div className={'deck-count-dialog' + (this.props.darkMode ? ' dark' : '')}>
                    <div className="deck-count-dialog-title">{this.state.title}</div>
                    <div className="deck-count-dialog-deck-label deck-label">{translate('Deck_')}</div>
                    <select
                        className="deck-count-dialog-deck"
                        value={this.state.selectedIndex}
                        tabIndex={1}
                        onChange={(evt) => {
                            let index = parseInt(evt.target.value);
                            if (index > this.decks.length) {
                                index = this.decks.length - 1;
                            }

                            this.syncWithMasterCard(index);
                            this.setState({ selectedIndex: index });
                            this.props.globalState.storeNumberSettings('DefaultDeck', index);
                        }}
                    >
                        {this.decks.map((d, i) => {
                            return (
                                <option key={d.UniqueID} value={i}>
                                    {d.Name}
                                </option>
                            );
                        })}
                    </select>
                    <div className="deck-count-dialog-cards-label deck-label">{translate('Cards_')}</div>
                    <div className="deck-count-dialog-cards">
                        <NumberControl
                            max={50}
                            globalState={this.props.globalState}
                            value={this.state.deckValue}
                            onChange={(value) => {
                                this.setState({ deckValue: value });
                            }}
                        />
                    </div>
                    <div className="deck-count-dialog-sideboard-label deck-label">{translate('Sideboard_')}</div>
                    <div className="deck-count-dialog-sideboard">
                        <NumberControl
                            max={20}
                            globalState={this.props.globalState}
                            value={this.state.sideboardValue}
                            onChange={(value) => this.setState({ sideboardValue: value })}
                        />
                    </div>
                    <div className="deck-count-dialog-reserve-label deck-label">{translate('Maybeboard_')}</div>
                    <div className="deck-count-dialog-reserve">
                        <NumberControl
                            max={20}
                            globalState={this.props.globalState}
                            value={this.state.reserveValue}
                            onChange={(value) => this.setState({ reserveValue: value })}
                        />
                    </div>
                    <div className="deck-count-dialog-accept">
                        <Button
                            tabIndex={2}
                            className="deck-count-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(true)}
                        >
                            {translate('OK')}
                        </Button>
                    </div>
                    <div className="deck-count-dialog-cancel">
                        <Button
                            tabIndex={3}
                            className="deck-count-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(false)}
                        >
                            {translate('Cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
