import * as React from 'react';
import { GlobalState } from '../globalState';
import { Collection } from '../entities/collection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faUndo } from '@fortawesome/free-solid-svg-icons';
import { Counter } from '../entities/counter';
import { GameLog } from '../entities/gameLog';
import { Observer } from '../tools/observable';
import { Nullable } from '../tools/nullable';
import { Game } from '../entities/game';

require('../scss/lifeCountersPage.scss');

interface ILifeCountersPageProps {
    globalState: GlobalState;
}

export class LifeCountersPage extends React.Component<ILifeCountersPageProps> {
    private onCoinFlippedObserver: Nullable<Observer<boolean>>;
    private onResetObserver: Nullable<Observer<void>>;
    private onNewTurnObserver: Nullable<Observer<void>>;
    private onNewPlayerCreatedObserver: Nullable<Observer<Counter>>;
    private onCustomButtonClickedObserver: Nullable<Observer<void>>;
    private onDiceRolledObserver: Nullable<Observer<{ value: number; max: number }>>;
    constructor(props: ILifeCountersPageProps) {
        super(props);
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onCustomButtonRequired.notifyObservers(this.props.globalState.translate('New player'));
        this.onCustomButtonClickedObserver = this.props.globalState.onCustomButtonClicked.add(() => {
            this.props.globalState.onNewPlayerRequired.notifyObservers();
        });

        this.onNewPlayerCreatedObserver = this.props.globalState.onNewPlayerCreated.add((counter) => {
            if (!Collection.UserStore.Game) {
                Collection.UserStore.Game = new Game();
            }
            if (!Collection.UserStore.Game.Counters) {
                Collection.UserStore.Game.Counters = [];
            }
            Collection.UserStore.Game.Counters.push(counter);

            counter.Name =
                counter.Name || this.props.globalState.translate('Player') + Collection.UserStore.Game!.Counters.length;

            if (!Collection.UserStore.Game.Log) {
                Collection.UserStore.Game.Log = [];
            }
            const gameLog = new GameLog();
            Collection.UserStore.Game.Log.splice(0, 0, gameLog);
            gameLog.Color = counter.Color;
            gameLog.Date = new Date().toUTCString();
            gameLog.Name = counter.Name;
            gameLog.State = this.props.globalState.translate('hasenteredthegame');

            this.forceUpdate();
            Collection.RegisterSave();
        });

        this.onCoinFlippedObserver = this.props.globalState.onCoinFlipped.add((value) => {
            const gameLog = new GameLog();

            Collection.UserStore.Game!.Log.splice(0, 0, gameLog);
            gameLog.Color = '#666666';
            gameLog.Date = new Date().toUTCString();
            gameLog.Name = this.props.globalState.translate('FlipACoin');
            gameLog.State = this.props.globalState.translate(value ? 'Heads' : 'Tails');

            this.forceUpdate();

            Collection.RegisterSave();
        });

        this.onDiceRolledObserver = this.props.globalState.onDiceRolled.add((value) => {
            const gameLog = new GameLog();

            Collection.UserStore.Game!.Log.splice(0, 0, gameLog);
            gameLog.Color = '#666666';
            gameLog.Date = new Date().toUTCString();
            gameLog.Name = this.props.globalState.translate(value.max === 20 ? 'RollA20SidedDice' : 'RollA6SidedDice');
            gameLog.State = (value.value + 1).toString();

            this.forceUpdate();

            Collection.RegisterSave();
        });

        this.onResetObserver = this.props.globalState.onReset.add(() => {
            Collection.UserStore.Game!.Log = [];
            Collection.UserStore.Game!.CurrentTurn = 1;

            for (const counter of Collection.UserStore.Game!.Counters) {
                counter.Counter = counter.InitialCounter;
                counter.Poison = 0;

                const gameLog = new GameLog();
                Collection.UserStore.Game!.Log.splice(0, 0, gameLog);
                gameLog.Color = counter.Color;
                gameLog.Date = new Date().toUTCString();
                gameLog.Name = counter.Name;
                gameLog.State = this.props.globalState.translate('hasenteredthegame');
            }

            this.forceUpdate();

            Collection.RegisterSave();
        });

        this.onNewTurnObserver = this.props.globalState.onNewTurn.add(() => {
            Collection.UserStore.Game!.CurrentTurn += 1;
            const gameLog = new GameLog();
            Collection.UserStore.Game!.Log.splice(0, 0, gameLog);
            gameLog.Color = '#666666';
            gameLog.Date = new Date().toUTCString();
            gameLog.Name = this.props.globalState.translate('EndOfTurn');
            gameLog.State = this.props.globalState.translate('StartingTurn') + Collection.UserStore.Game!.CurrentTurn;

            this.forceUpdate();

            Collection.RegisterSave();
        });
    }

    componentWillUnmount() {
        this.props.globalState.onCoinFlipped.remove(this.onCoinFlippedObserver);
        this.props.globalState.onDiceRolled.remove(this.onDiceRolledObserver);
        this.props.globalState.onReset.remove(this.onResetObserver);
        this.props.globalState.onNewTurn.remove(this.onNewTurnObserver);
        this.props.globalState.onCustomButtonClicked.remove(this.onCustomButtonClickedObserver);
        this.props.globalState.onNewPlayerCreated.remove(this.onNewPlayerCreatedObserver);
    }

    rotate(counter: Counter) {
        if (counter.Angle == null) {
            counter.Angle = 0;
        }

        counter.Angle += 180;
        this.forceUpdate();

        Collection.RegisterSave();
    }

    addLife(counter: Counter, count: number) {
        counter.Counter += count;
        const gameLog = new GameLog();

        Collection.UserStore.Game!.Log.splice(0, 0, gameLog);
        gameLog.Color = counter.Color;
        gameLog.Date = new Date().toUTCString();
        gameLog.Name = counter.Name;
        gameLog.State = `${this.props.globalState.translate(
            'won'
        )} ${count} ${this.props.globalState.translateWithPlural('Life', count)} (${counter.Counter})`;

        this.forceUpdate();

        Collection.RegisterSave();
    }

    removeLife(counter: Counter, count: number) {
        if (counter.Counter === 0) {
            return;
        }

        count = Math.min(count, counter.Counter);

        counter.Counter -= count;
        const gameLog = new GameLog();

        Collection.UserStore.Game!.Log.splice(0, 0, gameLog);
        gameLog.Color = counter.Color;
        gameLog.Date = new Date().toUTCString();
        gameLog.Name = counter.Name;
        gameLog.State = `${this.props.globalState.translate(
            'lost'
        )} ${count} ${this.props.globalState.translateWithPlural('Life', count)} (${counter.Counter})`;

        this.forceUpdate();

        Collection.RegisterSave();
    }

    addPoison(counter: Counter, count: number) {
        counter.Poison += count;
        const gameLog = new GameLog();

        Collection.UserStore.Game!.Log.splice(0, 0, gameLog);
        gameLog.Color = counter.Color;
        gameLog.Date = new Date().toUTCString();
        gameLog.Name = counter.Name;
        gameLog.State = `${this.props.globalState.translate(
            'received'
        )} ${count} ${this.props.globalState.translateWithPlural('Poison', count)} (${counter.Poison})`;

        this.forceUpdate();

        Collection.RegisterSave();
    }

    removePoison(counter: Counter, count: number) {
        if (counter.Poison === 0) {
            return;
        }

        count = Math.min(count, counter.Poison);

        counter.Poison -= count;
        const gameLog = new GameLog();

        Collection.UserStore.Game!.Log.splice(0, 0, gameLog);
        gameLog.Color = counter.Color;
        gameLog.Date = new Date().toUTCString();
        gameLog.Name = counter.Name;
        gameLog.State = `${this.props.globalState.translate(
            'lost'
        )} ${count} ${this.props.globalState.translateWithPlural('Poison', count)} (${counter.Poison})`;

        this.forceUpdate();

        Collection.RegisterSave();
    }

    deleteCounter(counter: Counter) {
        this.props.globalState.showConfirmDialog('Are you sure you want to delete this counter?').then((value) => {
            if (!value) {
                return;
            }

            const index = Collection.UserStore.Game!.Counters.indexOf(counter);
            if (index > -1) {
                Collection.UserStore.Game!.Counters.splice(index, 1);
            }

            const gameLog = new GameLog();
            Collection.UserStore.Game!.Log.splice(0, 0, gameLog);
            gameLog.Color = counter.Color;
            gameLog.Date = new Date().toUTCString();
            gameLog.Name = counter.Name;
            gameLog.State = this.props.globalState.translate('hasleftthegame');

            this.forceUpdate();

            Collection.RegisterSave();
        });
    }

    render() {
        const game = Collection.UserStore.Game;

        return (
            <div className="page">
                {game !== undefined && (
                    <div className="life-counters-page">
                        <div className="life-counters-players">
                            {game.Counters.map((counter, i) => {
                                const style = {
                                    color: counter.Color,
                                    transform: `rotate(${counter.Angle}deg)`
                                };

                                const styleBackground = {
                                    background: counter.Color
                                };

                                return (
                                    <div key={'Counter' + i} className={'life-counters-player' + (GlobalState.IsThereABackground ? ' glass' : '')} style={style}>
                                        <div className="life-counters-player-header" style={styleBackground}>
                                            <div
                                                className="life-counters-player-rotate button"
                                                onClick={() => this.rotate(counter)}
                                            >
                                                <FontAwesomeIcon icon={faUndo} />
                                            </div>
                                            <div className="life-counters-player-name">{counter.Name}</div>
                                            <div
                                                className="life-counters-player-delete button"
                                                onClick={() => this.deleteCounter(counter)}
                                            >
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </div>
                                        </div>
                                        <div className="life-counters-player-life life-counters-player-block">
                                            <div
                                                className="life-counters-player-plus-one button"
                                                onClick={() => this.addLife(counter, 1)}
                                            >
                                                +1
                                            </div>
                                            <div
                                                className="life-counters-player-plus-five button"
                                                onClick={() => this.addLife(counter, 5)}
                                            >
                                                +5
                                            </div>
                                            <div className="life-counters-player-value-container">
                                                <div className="life-counters-player-value">{counter.Counter}</div>
                                                <div className="life-counters-player-value-footer">
                                                    {this.props.globalState.translateWithPlural(
                                                        'Life',
                                                        counter.Counter
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className="life-counters-player-minus-one button"
                                                onClick={() => this.removeLife(counter, 1)}
                                            >
                                                -1
                                            </div>
                                            <div
                                                className="life-counters-player-minus-five button"
                                                onClick={() => this.removeLife(counter, 5)}
                                            >
                                                -5
                                            </div>
                                        </div>
                                        <div className="life-counters-player-poison life-counters-player-block">
                                            <div
                                                className="life-counters-player-plus-one button"
                                                onClick={() => this.addPoison(counter, 1)}
                                            >
                                                +1
                                            </div>
                                            <div
                                                className="life-counters-player-plus-five button"
                                                onClick={() => this.addPoison(counter, 5)}
                                            >
                                                +5
                                            </div>
                                            <div className="life-counters-player-value-container">
                                                <div className="life-counters-player-value">{counter.Poison}</div>
                                                <div className="life-counters-player-value-footer">
                                                    {this.props.globalState.translateWithPlural(
                                                        'Poison',
                                                        counter.Poison
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className="life-counters-player-minus-one button"
                                                onClick={() => this.removePoison(counter, 1)}
                                            >
                                                -1
                                            </div>
                                            <div
                                                className="life-counters-player-minus-five button"
                                                onClick={() => this.removePoison(counter, 5)}
                                            >
                                                -5
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        <div className="life-counters-logs">
                            <div className="life-counters-logs-header">{this.props.globalState.translate('Logs')}</div>
                            <div className="life-counters-logs-container">
                                {game.Log.map((log, i) => {
                                    const style = {
                                        color: log.Color
                                    };

                                    if (log.Color === '#666666' || log.Color === '#424242') {
                                        style.color = GlobalState.LoadBoolSettings('DarkMode', false)
                                            ? '#BBBBBB'
                                            : 'black';
                                    }

                                    return (
                                        <div key={'log' + i} className="life-counters-log" style={style}>
                                            <div className="life-counters-log-title">{log.Name}</div>
                                            <div className="life-counters-log-state">{log.State}</div>
                                            <div className="life-counters-log-date">
                                                {new Date(log.Date).toLocaleString()}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
